import { useState } from "react";
import { SCOPE_MAP } from "../utils";
import { OAuthError, postData } from "../backend";
import useScopes from "../hooks/useScopes";
import { useModal } from "../contexts";
import OAuthModal from "./OAuthModal";

type SettingsPermissionsGoogleProps = {
  scope: string;
}

export default function SettingsPermissionsGoogle(props: SettingsPermissionsGoogleProps) {
  const [pendingRefresh, setPendingRefresh] = useState(false);
  const { refreshScopes, scopes, isValidating: isLoading } = useScopes({ revalidateOnFocus: false, shouldRetryOnError: false });
  const { openModal } = useModal();
  const { scope } = props;
  const details = SCOPE_MAP[scope];

  const containsFullScope = (
    scope === "https://www.googleapis.com/auth/drive.file"
    && scopes.includes("https://www.googleapis.com/auth/drive")
  );

  function handleAuthoriseScope(scope: string) {
    postData("/auth/access-token/", { scopes: [scope] })
      .then(() => refreshScopes())
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={() => setPendingRefresh(true)} />);
        else
          console.error(error)
      });
  }

  function handleOpenPermissions() {
    window.open("https://myaccount.google.com/permissions", "_blank");
    setPendingRefresh(true);
  }

  function handleRefresh() {
    refreshScopes();
    setPendingRefresh(false);
  }

  function handleAuthorise() {
    handleAuthoriseScope(scope);
  }

  const Button = () => {
    if (isLoading)
      return <button className="btn w-32 ml-auto" disabled>Loading...</button>

    if (pendingRefresh)
      return <button className="btn btn-yellow w-32 shrink-0 ml-auto" onClick={handleRefresh}>Refresh</button>

    if (scopes.includes(scope) || containsFullScope)
      return <button className="btn btn-green w-32 ml-auto" onClick={handleOpenPermissions}>Granted</button>

    return <button className="btn btn-blue w-32 ml-auto" onClick={handleAuthorise}>Authorise</button>
  }

  return (
    <div className="flex items-center">
      <img src={details.icon} className="w-8 h-8 flex-shrink-0" alt="Icon" />
      <div className="flex flex-col ml-4 mr-8">
        {details.fullScope && <div className="text-green font-semibold">Full Access</div>}
        {details.limitedScope && <div className="text-yellow font-semibold">Limited Access</div>}
        {details.description}
      </div>
      <Button />
    </div>
  );
}
