import Modal from "./Modal";

function SettingsSolutionPartnerNewTeamModal(props: { initialEmail?: string }) {
  return (
    <Modal
      title="New Team"
      subtitle="Create a new team for your clients"
      size="sm"
    >
      <div>
        Please contact support at <a href="mailto:contact@portant.co?subject=Create a New Team in Portant&body=I would like to create a new team in Portant for one of my clients." target="_blank" rel="noreferrer" className="font-semibold underline text-blue">contact@portant.co</a> to create a new team.
      </div>
    </Modal>
  );
}

export default SettingsSolutionPartnerNewTeamModal;
