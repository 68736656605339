import useSWR from "swr";
import { getData } from "../backend";
import { OutputFilters, SortBy, WorkflowAutomationItem } from "../interfaces";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface PagingatedAutomationItems {
  results: Array<WorkflowAutomationItem>;
  numPages: number;
}

export default function useWorkflowAutomationItemsFiltered(workflowId: string, filters: OutputFilters, pageNumber: number, sortBy: SortBy, automationId: string | null) {
  const [lastNumPages, setLastNumPages] = useState<number>(1);

  const sortField = sortBy.direction === 'descending' ? `-${sortBy.field}` : sortBy.field;
  let key = `/workflows/${workflowId}/items/?page=${pageNumber}`;
  if (filters.from)
    key += `&from=${dayjs(filters.from).format("YYYY-MM-DD")}`

  if (filters.to)
    key += `&to=${dayjs(filters.to).format("YYYY-MM-DD")}`

  if (filters.fileName)
    key += `&filename=${filters.fileName}`

  if (filters.status)
    key += `&status=${filters.status}`

  if (sortBy.field)
    key += `&ordering=${sortField}`

  if (automationId)
    key += `&automation=${automationId}`

  const { data, ...rest } = useSWR<PagingatedAutomationItems>(key, getData, { revalidateOnFocus: true });

  useEffect(() => {
    if (data?.numPages)
      setLastNumPages(data.numPages);
  }, [data]);

  if (data)
    return { data, ...rest };
  else
    return { data: { results: undefined, numPages: lastNumPages }, ...rest };
}
