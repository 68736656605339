import useSWR from "swr";
import type { SWRConfiguration } from 'swr'

import { getData } from "../backend";

type ScopesReturn = {
  scopes: string[]
}

export default function useScopes(options?: SWRConfiguration) {
  const { data, mutate: refreshScopes, ...rest } = useSWR<ScopesReturn | null>("/auth/scopes/", getData, options ?? {});

  return { scopes: data?.scopes || [], refreshScopes, ...rest };
}
