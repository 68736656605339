import useSWR from "swr";
import { getData } from "../backend";
import { User, Permission, Team, TeamSummary } from "../interfaces";
import { getAuthToken } from "../auth";
import type { SWRConfiguration } from 'swr'


// User
// -> User === undefined (loading)
// -> User === null (not logged in)

function getUserData(path: string) {
  const hasAuthToken = !!getAuthToken();
  if (!hasAuthToken)
    return null;

  return getData(path);
}

export default function useUser(options?: SWRConfiguration) {
  const { data: user, mutate: refreshUser, ...rest } = useSWR<User | null>("/auth/whoami/", getUserData, options ?? {});

  function hasPermission(team: Team | TeamSummary | null | undefined, p: Permission): boolean {
    return !team || !!user?.permissions.includes(p);
  }

  return { user, refreshUser, hasPermission, ...rest };
}
