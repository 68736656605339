import { useState } from "react";
import { useSlate } from "slate-react";
import { isAlignmentActive, isListActive, isMarkActive, toggleAlignment, toggleList, toggleMark } from "../emailEditorHelpers";
import { Alignment } from "../interfaces";
import WorkflowEmailToolbarButton from "./WorkflowEmailToolbarButton";


function EmailEditorTextFormattingMenu() {
  const editor = useSlate();
  const [showAlignmentOptions, setShowAlignmentOptions] = useState<boolean>(false);

  const isMac = navigator.platform.toLowerCase().indexOf("mac") >= 0;

  function handleClickAlignmentOption(alignmentOption: Alignment) {
    toggleAlignment(editor, alignmentOption);
    setShowAlignmentOptions(false);
  }

  return (
    <div className="flex items-center shadow bg-white h-9 px-1 my-1 w-min relative select-none">
      <WorkflowEmailToolbarButton isActive={isMarkActive(editor, "bold")} iconName="format_bold" tooltipText={`Bold (${isMac ? "⌘" : "Ctrl-"}B)`} className="mr-1" onClick={() => toggleMark(editor, "bold")} />
      <WorkflowEmailToolbarButton isActive={isMarkActive(editor, "italic")} iconName="format_italic" tooltipText={`Italic (${isMac ? "⌘" : "Ctrl-"}I)`} className="mr-1" onClick={() => toggleMark(editor, "italic")} />
      <WorkflowEmailToolbarButton isActive={isMarkActive(editor, "underline")} iconName="format_underline" tooltipText={`Underline (${isMac ? "⌘" : "Ctrl-"}U)`} className="mr-1" onClick={() => toggleMark(editor, "underline")} />
      <div className="w-[1px] h-5 bg-gray-200 mr-1" />
      <WorkflowEmailToolbarButton isActive={false} iconName={`format_align_${isAlignmentActive(editor, "center") ? "center" : isAlignmentActive(editor, "right") ? "right" : "left"}`} tooltipText="Align" className="mr-1" onClick={() => setShowAlignmentOptions(prev => !prev)} isMenu />
      {showAlignmentOptions &&
        <div className="flex flex-col absolute bg-white bottom-full left-[100px] shadow p-1 rounded ml-[5px]">
          <WorkflowEmailToolbarButton isActive={false} iconName="format_align_left" tooltipText="Align left" onClick={() => handleClickAlignmentOption("left")} />
          <WorkflowEmailToolbarButton isActive={false} iconName="format_align_center" tooltipText="Align center" onClick={() => handleClickAlignmentOption("center")} />
          <WorkflowEmailToolbarButton isActive={false} iconName="format_align_right" tooltipText="Align right" onClick={() => handleClickAlignmentOption("right")} />
        </div>
      }
      <div className="w-[1px] h-5 bg-gray-200 mr-1" />
      <WorkflowEmailToolbarButton isActive={isListActive(editor, "numbered-list")} iconName="format_list_numbered" tooltipText="Numbered list" onClick={() => toggleList(editor, "numbered-list")} />
      <WorkflowEmailToolbarButton isActive={isListActive(editor, "bulleted-list")} iconName="format_list_bulleted" tooltipText="Bulleted list" onClick={() => toggleList(editor, "bulleted-list")} />
    </div>
  );
};

export default EmailEditorTextFormattingMenu;
