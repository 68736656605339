import Modal from './Modal';
import useUser from '../hooks/useUser';
import { useModal } from "../contexts";

interface Props {
  oauthUrl: string;
  onClose?: () => void;
}

function OAuthModalCopper(props: Props) {
  const { closeModal } = useModal();
  const { refreshUser } = useUser();

  function handleInstallIntegration() {
    window.open(props.oauthUrl, "_blank");
    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  async function getCurrentUserOnFocus() {
    window.removeEventListener("focus", getCurrentUserOnFocus);

    refreshUser();
    closeModal(props.onClose);
  }

  return (
    <Modal
      title="Connect Copper to Portant"
      subtitle="Portant requires access to opportunities within your Copper account."
      size='sm'
      primaryButtonProps={{
        text: "Connect Copper to Portant",
        onClick: handleInstallIntegration
      }}
    >
    </Modal>
  );
}

export default OAuthModalCopper;
