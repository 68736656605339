import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { postData, useResource } from '../backend';
import { useModal } from '../contexts';
import { SubscriptionTier } from '../interfaces';
import Modal from './Modal';

function PayWallModalTeams() {
  const tier: SubscriptionTier | null = useResource("/billing/tiers/teams/");

  const [checkoutUrl, setCheckoutUrl] = useState<string>();
  const { closeModal } = useModal();

  useEffect(() => {
    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'view_paywall', 'paywall_type': 'teams' });
  }, [])

  useEffect(() => {
    if (!tier)
      return

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'click_upgrade', 'upgrade_tier': tier.slug });

    postData(`/billing/tiers/${tier.slug}/checkout/`, { stripePriceId: tier!.stripeMonthlyPriceId })
      .then(data => setCheckoutUrl(data.url));
  }, [tier]);

  return (
    <Modal
      title="Upgrade to Teams to share workflows"
      subtitle={
        <p className="font-semibold text-sm text-gray-500">
          Your current plan does not provide access to this feature. <br />
          Upgrade to the Teams plan to share workflows with other people.
        </p>
      }
      size='sm'
    >
      {tier && <>
        <div className="flex flex-col gap-1 w-full">
          <div className="font-gilroy font-semibold">
            Teams
          </div>
          <div className="flex items-center">
            <div className="flex items-start flex-wrap flex-shrink-0">
              <div className="text-sm">$</div>
              {!!tier
                ? <div className="text-3xl font-semibold">{String(Math.floor(tier.stripeMonthlyPrice / 100))}<span className="text-xs ml-1 mt-auto mb-1">/month (USD)</span></div>
                : <div className="text-3xl font-semibold loading-ellipse" />
              }
            </div>
          </div>
        </div>
        <button className='btn btn-blue w-full' onClick={() => checkoutUrl && window.open(checkoutUrl, "_blank")}>
          Upgrade
        </button>
        <div className='flex flex-col text-sm font-semibold gap-2 mt-2'>
          <div className='text-gray-400 font-bold'>What's included</div>
          <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;{tier.featureDocumentLimit} documents per month</div>
          <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Create a team with up to 5 members</div>
          <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Shared workflows</div>
        </div>
        <Link to="/settings/billing" className='text-center w-full text-sm text-gray-400 underline' onClick={() => closeModal()}>
          Compare all plans
        </Link>
      </>}
    </Modal>
  );
}

export default PayWallModalTeams;
