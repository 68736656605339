import { Transition } from "@headlessui/react";
import { ArrowDownIcon, CogIcon, ExclamationCircleIcon, ExternalLinkIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useMemo, useState } from "react";
import { WorkflowContext, useModal } from "../contexts";
import WorkflowOutputsItem from "./WorkflowOutputsItem";
import WorkflowOutputFolderModal from "./WorkflowOutputFolderModal";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import WorkflowOutputsPreview from "./WorkflowOutputsPreview";
import useUser from "../hooks/useUser";
import WorkflowOutputsFilter from "./WorkflowOutputsFilter";
import useWorkflowAutomationItemsFiltered from "../hooks/useWorkflowAutomationItemsFiltered";
import { OutputFilters, SortBy } from "../interfaces";
import useDebounce from "../hooks/useDebounce";
import Paginator from "./Paginator";

interface Props {
  contentOffset: number;
}

const initialFilters: OutputFilters = { from: '', to: '', status: '', fileName: '' };

function WorkflowOutputs(props: Props) {
  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const { pathname, search } = useLocation();
  const { url } = useRouteMatch();

  const { openModal } = useModal();

  const searchParams = new URLSearchParams(search);

  const automationId = searchParams.get("automation");
  const previewAutomationItemId = searchParams.get("preview");

  const [sortBy, setSortBy] = useState<SortBy>({ field: '', direction: '' });

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const [filters, setFilters] = useState<OutputFilters>({ ...initialFilters });
  const [displayFilters, setDisplayFilters] = useState(false);
  const debouncedFilters = useDebounce(filters, 500);

  const { data, mutate, isValidating } = useWorkflowAutomationItemsFiltered(workflow.id, displayFilters ? debouncedFilters : {}, currentPageNumber, sortBy, automationId);
  const { results: items, numPages } = data;

  const previewAutomationItem = items?.find(item => item.id === previewAutomationItemId);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [previousLastViewedTime, setPreviousLastViewedTime] = useState<number>(0);

  const enabledPrevious = currentPageNumber > 1;
  const enabledNext = currentPageNumber < numPages;

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [automationId])

  function openFolderModal() {
    openModal(<WorkflowOutputFolderModal workflow={workflow} mutateWorkflow={mutateWorkflow} />);
  }

  function handleClickRefresh() {
    setIsRefreshing(true);
    mutate()
      .finally(() => setIsRefreshing(false));

    if (!workflow.outputFolder)
      mutateWorkflow();
  }

  function onPageChange(newPage: number) {
    setCurrentPageNumber(newPage);
    mutate();
  }

  function handleSortButton(field: string) {
    if (field === sortBy.field) {
      if (sortBy.direction === 'descending')
        setSortBy({ field: field, direction: 'ascending' })
      else if (sortBy.direction === 'ascending')
        setSortBy({ field: '', direction: '' })
    } else {
      setSortBy({ field: field, direction: 'descending' })
    }
  }

  const areFiltersApplied = useMemo(() => {
    let hasFilterApplied = false;
    Object.keys(filters).forEach(key => {
      const keyValue = key as keyof typeof filters;
      if (filters[keyValue] !== initialFilters[keyValue])
        hasFilterApplied = true;
    })

    return hasFilterApplied
  }, [filters])

  useEffect(() => {
    if (!pathname.endsWith("/outputs"))
      return;

    const key = "lastViewedOuputsMap";
    const lastViewedOuputsMapString = localStorage.getItem(key) ?? "{}";
    const lastViewedOuputsMap: Record<string, number> = JSON.parse(lastViewedOuputsMapString);

    setPreviousLastViewedTime(lastViewedOuputsMap[workflow.id] ?? 0)

    lastViewedOuputsMap[workflow.id] = new Date().getTime();
    localStorage.setItem(key, JSON.stringify(lastViewedOuputsMap));

    if (!workflow.outputFolder)
      mutateWorkflow();

    mutate();

  }, [workflow.id, workflow.outputFolder, mutateWorkflow, mutate, pathname])

  if (previewAutomationItem)
    return <WorkflowOutputsPreview automationItem={previewAutomationItem} />

  return (
    <Transition show={pathname.endsWith("/outputs")} className="absolute top-0 flex flex-col w-full overflow-hidden px-8" style={{ height: `calc(100vh - 56px - ${props.contentOffset}px)` }}>

      <div className="flex items-center gap-4 py-4 w-full">
        <div className="font-gilroy font-semibold text-gray-600">
          Workflow Outputs
        </div>
        <button className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 hover:text-blue hover:underline cursor-pointer" onClick={handleClickRefresh}>
          <RefreshIcon className={`h-6 w-6 ${(isRefreshing || isValidating) ? "animate-spin" : "animate-none"}`} />
        </button>
        {automationId &&
          <div className="flex gap-1 ml-auto font-gilroy font-semibold text-gray-600 text-sm items-center">
            <ExclamationCircleIcon className="h-6 w-6 flex-shrink-0 text-blue" />
            Filtering outputs for selected automation,
            <Link to={url + "/outputs"} className="underline hover:text-blue">show all</Link>
          </div>
        }
        <div className="mr-auto" />
        {/* NOTE: Navigation currently hiddend */}
        {/* <Link to={url + "/automations"} className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer">
          <ClockIcon className="h-6 w-6 flex-shrink-0" />
          Automation History
        </Link> */}

        {hasPermission(workflow.team, "workflows.edit") &&

          <button className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer" onClick={openFolderModal}>
            <CogIcon className="h-6 w-6 flex-shrink-0" />
            Customize Folder
          </button>
        }
        {workflow.outputFolder &&
          <a className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 underline text-sm hover:text-blue hover:underline cursor-pointer" href={workflow.outputFolder.url} target="_blank" rel="noreferrer">
            <img src="https://static.portant.co/drive-icon.svg" className="w-6 h-6" alt="Drive icon" />
            {workflow.outputFolder?.name}
            <ExternalLinkIcon className='w-4 h-4 flex-shrink-0 mb-1' />
          </a>
        }
      </div>

      <div className="flex flex-col w-full bg-white mb-0 p-4 shadow rounded" style={{ height: `calc(100vh - 132px - ${props.contentOffset}px)` }}>
        <table className="table-fixed w-full bg-white">
          <thead className="bg-white">
            <tr className="font-semibold text-xs text-gray-400 text-center">
              <th className="w-6" />{/* Chevron */}
              <th className="w-20 font-semibold font-gilroy text-sm text-gray-600">{/* Source item number */}
                {workflow.source?.sourceType === "GOOGLE_FORMS" && "Response #"}
                {workflow.source?.sourceType === "GOOGLE_SHEETS" && "Row #"}
              </th>
              <th className="w-auto">{/* Filename column */}
                <div className="flex flex-1 gap-2">
                  <button
                    className={`flex items-center relative gap-0.5 font-semibold font-gilroy text-sm hover:text-blue hover:underline cursor-pointer ${sortBy.field === 'fileName' ? 'text-blue' : 'text-gray-600'} `}
                    onClick={() => handleSortButton("fileName")}
                  >
                    Filename
                    <ArrowDownIcon className={`h-3 w-3 transition-all ${sortBy.field === 'fileName' && sortBy.direction === 'ascending' ? 'rotate-180' : ''}`} />
                  </button>
                </div>
              </th>
              <th className="w-20" />{/* Tracking column */}
              <th className="w-44">
                <button
                  className={`w-full flex justify-center items-center relative gap-0.5 font-semibold font-gilroy text-sm hover:text-blue hover:underline cursor-pointer ${sortBy.field === 'status' ? 'text-blue' : 'text-gray-600'} `}
                  onClick={() => handleSortButton("status")}
                >
                  Status
                  <ArrowDownIcon className={`h-3 w-3 transition-all ${sortBy.field === 'status' && sortBy.direction === 'ascending' ? 'rotate-180' : ''}`} />
                </button>
              </th>
              <th className="w-44">
                <button
                  className={`flex w-full pr-2 justify-end items-center relative gap-0.5 font-semibold font-gilroy text-sm hover:text-blue hover:underline cursor-pointer ${sortBy.field === 'date' ? 'text-blue' : 'text-gray-600'} `}
                  onClick={() => handleSortButton("date")}
                >
                  Created
                  <ArrowDownIcon className={`h-3 w-3 transition-all ${sortBy.field === 'date' && sortBy.direction === 'ascending' ? 'rotate-180' : ''}`} />
                </button>
              </th>
              <th className="w-10">
                <button
                  className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer"
                  onClick={() => setDisplayFilters(prev => !prev)}
                >
                  <FilterIcon className="h-6 w-6" />
                </button>
              </th>
            </tr>
          </thead>
        </table>
        <Transition
          show={displayFilters}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0 -translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-12"

        >
          <WorkflowOutputsFilter
            filters={filters}
            setFilters={setFilters}
            areFiltersApplied={areFiltersApplied}
          />
        </Transition>
        <div className="w-full h-1 mt-2 border-b-2 border-gray-200" />

        {(items === undefined && isValidating) &&
          <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
            <img src="https://static.portant.co/portant-loading-gray.svg" className="w-24 h-24" alt="Loading Logo" />
            <div className="flex font-gilroy font-semibold text-gray-400">
              Loading workflow outputs...
            </div>
          </div>
        }

        {(items === undefined && !isValidating) &&
          <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
            <div className="flex font-gilroy font-semibold text-gray-400">
              Unable to load items
            </div>
          </div>
        }

        {(items !== undefined && items.length === 0) &&
          <div className="flex flex-col justify-center items-center gap-1 w-full h-full">
            <div className="flex font-gilroy text-gray-600 font-semibold">
              {areFiltersApplied ? "No outputs match the filters" : "No outputs have been created yet"}
            </div>
            <div className="text-sm">
              {areFiltersApplied ? "Please try removing the filters" : "Please run an automation to create your first output"}
            </div>
          </div>
        }

        {(items !== undefined && items.length > 0) &&
          <div className="flex flex-col h-full w-full overflow-auto">
            <div className="h-full overflow-y-scroll scrollbar-visible">
              <table className="table-fixed w-full">
                <tbody>
                  {items.map(item =>
                    <WorkflowOutputsItem key={item.id} item={item} lastViewedTime={previousLastViewedTime} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        }
        <Paginator
          enabledNext={enabledNext}
          enabledPrevious={enabledPrevious}
          onPageChange={onPageChange}
          pageNumber={currentPageNumber}
          numPages={numPages}
        />
      </div>

    </Transition>
  );

}

export default WorkflowOutputs;
