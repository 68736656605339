import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useModal } from "../contexts";
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';
import OAuthModalHubspot from './OAuthModalHubspot';
import GoogleLoginButton from './GoogleLoginButton';
import OAuthModal from './OAuthModal';
import { OAuthError, postData, putData } from '../backend';
import { useHistory } from 'react-router-dom';
import useUser from '../hooks/useUser';


function HubspotLanding() {
  const history = useHistory();

  const { openModal, closeModal } = useModal();
  const { user } = useUser();

  const [hasOpenedGoogle, setHasOpenedGoogle] = useState(false)
  const [hasOpenedHubspot, setHasOpenedHubspot] = useState(false)

  const googleButton = useRef<HTMLButtonElement>(null);
  const hubspotButton = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (hasOpenedGoogle || user !== null || !googleButton.current)
      return;

    googleButton.current.click();
    setHasOpenedGoogle(true);
  }, [hasOpenedGoogle, user]);

  useLayoutEffect(() => {
    if (hasOpenedHubspot || !user || !hubspotButton.current)
      return;

    hubspotButton.current.click();
    setHasOpenedHubspot(true);
  }, [hasOpenedHubspot, user])

  // Prevent new user modal from showing
  useEffect(() => {
    if (!localStorage.getItem("hasNewUserModalShown"))
      localStorage.setItem("hasNewUserModalShown", "true");
  }, []);

  async function createHubSpotWorkflow() {
    openModal(<LoadingModal title='Creating HubSpot Workflow...' subtitle="This may take a minute as we create a template file in your Google&nbsp;Drive." />);

    try {
      await postData("/hubspot/access-token/");

      const initialWorkflow = {
        "color": "#cccccc",
        "icon": "DocumentText",
        "name": "HubSpot Workflow"
      }

      const workflow = await postData("/workflows/", initialWorkflow);

      await postData(`/workflows/${workflow.id}/source/`, { sourceType: "HUBSPOT" });

      const document = await postData(`/workflows/${workflow.id}/documents/`, { documentType: "GOOGLE_DOCS" });
      document.file = await putData(`/workflows/documents/${document.id}/file/`, {
        file: {
          id: "1FXUUnZTbiKqZ4CB4-FnvsukC0y4qs4J1qnbqm6Ht0BU",
          mimeType: "application/vnd.google-apps.document"
        },
        options: {
          copy: true
        }
      });

      closeModal();
      history.push(`/w/${workflow.id}`);

    } catch (error) {
      if (error instanceof OAuthError)
        if (error.oauthUrl.includes("app.hubspot"))
          openModal(<OAuthModalHubspot oauthUrl={error.oauthUrl} />);
        else
          openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {user === undefined &&
        <img src="https://static.portant.co/portant-loading-white.svg" className="m-auto w-24 h-24" alt="Loading Logo" />
      }
      {user === null &&
        <GoogleLoginButton buttonRef={googleButton} className='m-auto' scopes={["https://www.googleapis.com/auth/drive"]} />
      }
      {!!user &&
        <button ref={hubspotButton} className="btn btn-blue m-auto" onClick={createHubSpotWorkflow}>Create HubSpot Workflow</button>
      }
    </div>
  );

}

export default HubspotLanding;
