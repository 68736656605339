import { RenderElementProps } from "slate-react";

import Image from "./ImageElement";
import Link from "./LinkElement";
import Tag from "./TagElement";

function Element(props: RenderElementProps) {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "bulleted-list":
      return (
        <ul className="list-disc list-inside" style={{ textAlign: element.align }} {...attributes}>
          {children}
        </ul>
      );
    case "numbered-list":
      return (
        <ol className="list-decimal list-inside" style={{ textAlign: element.align }} {...attributes}>
          {children}
        </ol>
      );
    case "list-item":
      return (
        <li {...attributes}>
          {children}
        </li>
      );
    case "paragraph":
      return (
        <div style={{ textAlign: element.align }} {...attributes}>
          {children}
        </div>
      );
    case "tag":
      return (
        <Tag attributes={attributes} element={element}>
          {children}
        </Tag>
      );
    case "image":
      return (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      );
    case "link":
      return (
        <Link attributes={attributes} element={element}>
          {children}
        </Link>
      );
  }
}

export default Element;
