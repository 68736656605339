import useSWR from "swr";
import { AchievementResponse } from "../interfaces";
import { getData } from "../backend";
import { useMemo } from "react";
import SegmentProgressBar from "./SegmentProgressBar";
import { Tier, TIER_URLS } from "../utils";
import useUser from "../hooks/useUser";
import dayjs from "dayjs";

function SettingsAchievements() {

  const { data, isLoading } = useSWR<AchievementResponse>("/auth/achievements/", getData);
  const user = useUser();

  const maxXP = data?.tiers?.sort((tierA, tierB) => tierB.points - tierA.points)?.[0]?.points ?? 0;
  const userXP = (user.user?.achievementXp ?? 0) * 100 / maxXP;
  const tiers = useMemo(() => {
    if (!data?.tiers) return [];

    const totalXP = data.tiers.sort((tierA, tierB) => tierB.points - tierA.points)[0].points;
    const formattedTiers = data.tiers.map(tier => {
      const hasLogo = tier.key in TIER_URLS;
      const userXP = user.user?.achievementXp ?? 0;
      return ({
        percent: tier.points * 100 / totalXP,
        component: (
          <div className={`px-8 py-6 flex flex-col items-center gap-2 rounded-xl border cursor-pointer w-36 hover:brightness-95 transition-all ${userXP >= tier.points ? 'bg-blue-50 border-blue-20' : 'bg-white border-gray-300'}`}>
            {hasLogo && <img src={TIER_URLS[tier.key as Tier]} className="h-10 w-10" alt={tier.title} />}
            <p className="font-bold">{tier.title}</p>
            <span
              className={`text-sm ${userXP > tier.points ? 'text-blue' : 'text-gray-600'}`}
            >
              {user.user?.achievementXp} / {tier.points}
            </span>
          </div>
        )
      })
    }).filter(tier => tier.percent !== 0).sort((tierA, tierB) => tierA.percent - tierB.percent);
    return formattedTiers;
  }, [data, user]);

  if (isLoading) return <div className="flex flex-col p-8 w-full">Loading...</div>;

  return (
    <div className="flex flex-col p-8 w-full h-[90vh]">
      <div className="flex flex-col">
        <div className="font-gilroy font-semibold text-lg">
          Achievements
        </div>
        <div className="text-gray-400 text-sm font-semibold mb-10">
          Your progress using Portant.
        </div>
      </div>

      {tiers?.length && (
        <div>
          <h4 className="font-semibold mb-2 text-gray-400">Current Tier: <span className="capitalize text-gray-600 text-lg">{user.user?.achievementTier}</span></h4>
          <SegmentProgressBar
            segments={tiers}
            fillPercentage={userXP}
            className="pb-32"
          />
        </div>
      )}

      <div className="mt-14 mb-6 flex flex-col overflow-y-auto items-start gap-2 divide-y-2">
        {data?.achievements?.map(achievement => {
          const obtainedAt = user.user?.achievements?.find(ach => (ach.key === achievement.key) && ach.completed)?.updatedAt;
          return (
            <div className="flex items-center gap-2 w-full pt-2" key={achievement.key}>
              <span className={`font-bold w-12 ${obtainedAt ? 'text-blue-10' : 'text-gray-500'}`}>+{achievement.points}</span>
              <div>
                <p className={`font-bold ${obtainedAt ? 'text-gray-800' : 'text-gray-500'}`}>{achievement.title}</p>
                <p className="text-gray-500">
                  {achievement.description}{" "}
                  {achievement.link && (
                    <a
                      className="ml-1 font-bold underline text-blue-10 cursor-pointer"
                      target="_blank"
                      rel="noreferrer"
                      href={achievement.link}
                    >
                      Learn more
                    </a>
                  )}
                </p>
                <p className="text-xs text-gray-400">{obtainedAt ? `Obtained ${dayjs(obtainedAt).format('MM/DD/YYYY')}` : 'Not obtained yet'}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default SettingsAchievements;
