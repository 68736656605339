import Modal from './Modal';
import useUser from '../hooks/useUser';
import { useModal } from "../contexts";

interface Props {
  oauthUrl: string;
  onClose?: () => void;
}

function OAuthModalHubspot(props: Props) {
  const { closeModal } = useModal();
  const { refreshUser } = useUser();

  function handleInstallIntegration() {
    window.open(props.oauthUrl, "_blank");
    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  async function getCurrentUserOnFocus() {
    window.removeEventListener("focus", getCurrentUserOnFocus);

    refreshUser();
    closeModal(props.onClose);
  }

  return (
    <Modal
      title="Connect HubSpot to Portant"
      subtitle="Portant requires access to deals, contacts and companies within your HubSpot account."
      className="items-center justify-center"
    >
      <div className="bg-blue text-white font-semibold py-2 px-4 rounded cursor-pointer my-8" onClick={handleInstallIntegration}>
        Connect HubSpot to Portant
      </div>
      <div className='text-left font-semibold text-yellow'>Trouble Connecting?</div>
      <div className="flex flex-col gap-4 text-gray-600 font-semibold text-center text-sm -mt-4">
        HubSpot users must have the "Super Admin" privilege to install Portant.<br />
        This setting can be added from the "Users & Teams" page in HubSpot,
        this permission can then be removed after Portant has been installed.
      </div>
    </Modal>
  );
}

export default OAuthModalHubspot;
