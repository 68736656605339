export type BarSegment = {
  percent: number;
  component: React.ReactNode;
};

type Props = {
  segments?: BarSegment[];
  fillPercentage: number;
  className?: string;
}


function SegmentProgressBar(props: Props) {
  return (
    <div className={`flex flex-col w-full gap-2 ${props.className ?? ''}`}>
      <div className="bg-gray-300 rounded-xl overflow-hidden h-4 w-full relative">
        <div
          className="bg-blue-10 h-full relative"
          style={{ width: `${props.fillPercentage}%` }}
        />
        {props.segments?.map(item => (
          <div
            key={`bar-${item.percent}`}
            className="h-full bg-gray-400 rounded absolute w-0.5 top-0"
            style={{ left: `${Math.min(item.percent, 99.5)}%` }}
          />
        ))}
      </div>
      {props.segments?.length && (
        <div className="flex relative">
          {props.segments?.map(item => (
            <div
              key={`element-${item.percent}`}
              style={{ left: `${item.percent}%` }}
              className={`absolute transform ${item.percent > 90 ? '-translate-x-full' : '-translate-x-1/2'}`}
            >
              {item.component}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SegmentProgressBar;
