import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { OutputFilters, } from "../interfaces";
import { Listbox } from "@headlessui/react";
import TextInputField from "./TextInputField";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import useUser from "../hooks/useUser";
import DateInputField from "./DateInputField";
import { WorkflowContext } from "../contexts";

type WorkflowOutputsFilterProps = {
  filters: OutputFilters;
  setFilters: Dispatch<SetStateAction<OutputFilters>>;
  areFiltersApplied: boolean;
}

function WorkflowOutputsFilter(props: WorkflowOutputsFilterProps) {
  const { user } = useUser();

  const { filters, setFilters } = props;
  const { workflow } = useContext(WorkflowContext);

  // Only show relevant status options based on the workflow configuration


  const statusOptions = useMemo(() => {
    let ret: Record<string, string> = {
      "": ""
    };

    ret["COMPLETED"] = "Completed";
    ret["COMPLETED_WITH_ERROR"] = "Completed with Error";

    if (workflow.review)
      ret["COMPLETED_PARTIALLY;REVIEW_PENDING"] = "In Draft";

    if (workflow.signatureConfig)
      ret["COMPLETED_PARTIALLY;SIGNATURES_PENDING"] = "Signatures Requested";

    return ret;
  }, [workflow])

  const clearFilters = () => {
    setFilters({ from: '', to: '', status: '', fileName: '' })
  }

  return (
    <table className="table-fixed w-full">
      <thead>
        <tr className="font-semibold text-xs text-gray-400 text-center">
          <th className="w-6 py-2" />{/* Chevron */}
          <th className="w-20">
            {
              props.areFiltersApplied && (
                <button
                  className="flex w-full justify-center hover:text-blue  cursor-pointer"
                  onClick={clearFilters}
                >
                  <XIcon className="h-6 w-6" />
                </button>
              )
            }
          </th>
          <th className="w-auto py-2">{/* Filename column */}
            <TextInputField
              className="w-full max-w-[600px] h-11"
              label="Filename"
              value={filters.fileName}
              onChange={(value) => setFilters(prev => ({ ...prev, fileName: value }))}
              isActive={filters.fileName !== ''}
            />
          </th>
          <th className="w-20 py-2" />{/* Tracking column */}
          <th className="w-44 relative py-2">
            <Listbox
              onChange={(value) => setFilters(prev => ({ ...prev, status: value }))}
              value={props.filters.status}
            >
              <div className="relative">
                <Listbox.Button
                  className={`relative border-solid border-2 rounded p-1 pr-8 mt-2 min-h-[36px] max-h-9 w-44 ${filters.status !== '' ? 'border-blue' : 'border-gray-400'}`}
                >
                  {statusOptions[filters.status] || "--"}
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute -translate-x-1/2 left-1/2 mt-2 w-40 overflow-auto rounded-md py-1 shadow-lg ring-1 ring-black/5 focus:outline-none bg-white text-left">
                  {Object.keys(statusOptions).map(status => (
                    <Listbox.Option
                      key={status}
                      value={status}
                      className="hover:bg-gray-100 cursor-pointer p-2"
                    >
                      {statusOptions[status] || "Show All"}
                    </Listbox.Option>))}
                </Listbox.Options>
              </div>
            </Listbox>
          </th>
          <th className="w-[216px] py-2">
            <div className="flex gap-2 justify-end">
              <DateInputField
                value={filters.from}
                label="From"
                format={user?.dateFormat}
                onChange={(newDate) => setFilters(prev => ({ ...prev, from: newDate }))}
                isActive={filters.from !== ''}
              />
              <DateInputField
                value={filters.to}
                label="To"
                format={user?.dateFormat}
                isActive={filters.to !== ''}
                onChange={(newDate) => setFilters(prev => ({ ...prev, to: newDate }))}
              />
            </div>
          </th>
        </tr>
      </thead>
    </table>
  );
}

export default WorkflowOutputsFilter;
