import { useLayoutEffect } from 'react';

function AppCopperLogin(props: { isGoogleLoaded: boolean }) {

  useLayoutEffect(() => {
    if (!props.isGoogleLoaded)
      return;

    // @ts-ignore
    const google = window.google;

    google.accounts.id.prompt();

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        "size": "large",
        "text": "signup_with",
        "theme": "filled_blue",
        "width": "260"
      }
    );
  }, [props.isGoogleLoaded]);

  return (
    <div className='flex flex-col h-full overflow-clip relative'>
      <div className='font-semibold text-xl font-gilroy'>Welcome to Portant ✌️</div>
      <div id="google-signin-prompt" className='relative -left-1.5 hidden' />
      <div className='text-sm font-semibold text-gray-600'>
        Please sign up to automate your first contract, proposal, or quote. <a className='underline hover:text-blue' href='https://docs.portant.co/copper' target='_blank' rel="noreferrer">Learn more</a>
      </div>
      <div id="google-signin-button" className='my-auto' />
    </div>
  )
}

export default AppCopperLogin;
