import { ChevronRightIcon } from '@heroicons/react/outline';
import { Dispatch, SetStateAction, useState } from 'react';
import { getData, patchData } from '../backend';
import { useModal } from '../contexts';
import { HubspotConfig, Workflow } from '../interfaces';
import Modal from './Modal';
import SmallTextInputField from './SmallTextInputField';
import ToggleButton from './ToggleButton';
import useSWR from "swr";
import { KeyedMutator } from "swr";

type PropertyType = "bool" | "enumeration" | "date" | "datetime" | "string" | "number";


interface HubSpotObjectForProperties {
  objectLabel: string;
  objectType: string;
  properties: Array<Property>;
}

export interface Property {
  description: string;
  hubspotDefined: boolean;
  label: string;
  name: string;
  type: PropertyType;
}

interface Props {
  hubspotConfig: HubspotConfig;
  mutateWorkflow: KeyedMutator<Workflow>;
  setIsRefreshing: Dispatch<SetStateAction<boolean>>;
}

function WorkflowModalHubspotFields(props: Props) {
  const { closeModal } = useModal();

  const [openHubspotObject, setOpenHubspotObject] = useState<string>();
  const [checkedProperties, setCheckedProperties] = useState(props.hubspotConfig.hubspotProperties ?? []);

  const [propertyQuery, setPropertyQuery] = useState("");
  const [onlyShowChecked, setOnlyShowChecked] = useState(false);

  const { data: objects, isLoading } = useSWR<Array<HubSpotObjectForProperties>>(`/hubspot/${props.hubspotConfig.id}/properties/`, getData);

  function isPropertyChecked(objectType: string, property: Property) {
    const propertyName = `${objectType}.${property.name}`;
    return checkedProperties.includes(propertyName);
  }

  function handleToggleProperty(objectType: string, property: Property, value?: boolean) {
    if (value === undefined)
      value = !isPropertyChecked(objectType, property);

    const propertyName = `${objectType}.${property.name}`;

    if (value)
      setCheckedProperties(current => [...current, propertyName]);
    else
      setCheckedProperties(current => current.filter((name) => name !== propertyName));
  }

  function handleUpdateHubspotSourceConfig() {
    props.setIsRefreshing(true);

    patchData(`/hubspot/config/${props.hubspotConfig.id}/`, {
      hubspotProperties: Array.from(new Set(checkedProperties))
    })
      .then(() => props.mutateWorkflow())
      .catch(console.error)
      .finally(() => props.setIsRefreshing(false));

    closeModal();
  }

  function handleToggleHubspotObject(objectType: string) {
    setOpenHubspotObject(prev => prev === objectType ? undefined : objectType);
  }

  function filterByQuery(property: Property) {
    const query = propertyQuery.toLowerCase();

    if (query.length === 0)
      return true;
    else
      return (property.name ?? "").toLowerCase().includes(query) ||
        (property.label ?? "").toLowerCase().includes(query) ||
        (property.description ?? "").toLowerCase().includes(query)
  }

  function filterByChecked(objectType: string, property: Property) {
    if (onlyShowChecked)
      return isPropertyChecked(objectType, property);

    return true;
  }

  return (
    <Modal
      title="Manage HubSpot Fields"
      subtitle="Select the HubSpot properties you wish to include as tags."
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Save", onClick: handleUpdateHubspotSourceConfig }}
    >
      <div className="flex justify-between items-center">
        <SmallTextInputField className="my-3 mr-4 flex-grow" placeholder="Search..." value={propertyQuery} onChange={setPropertyQuery} />
        <div className="text-sm flex gap-2 items-center">
          Only Show Checked
          <ToggleButton value={onlyShowChecked} onChange={setOnlyShowChecked} />
        </div>
      </div>

      <div className="flex overflow-auto flex-col gap-2 h-64">
        {isLoading &&
          <div className='text-center'>Fetching HubSpot properties...</div>
        }

        {/* TODO: Add empty/error state to display to the user */}

        {(objects ?? []).map(object =>
          <div key={object.objectType}>

            {/* Object row */}
            <div className='flex gap-2 items-center cursor-pointer' onClick={() => handleToggleHubspotObject(object.objectType)}>
              <ChevronRightIcon className={`w-5 h-5 transition-transform ${openHubspotObject === object.objectType ? "rotate-90" : ""}`} />

              <span className='font-semibold'>
                {object.objectLabel}{props.hubspotConfig.hubspotPrimaryObject === object.objectType.toUpperCase() && " (Primary)"}
              </span>

              <span className='text-gray-500'>
                {object.properties
                  .filter((property) => isPropertyChecked(object.objectType, property)).length}/{object.properties.length} properties
              </span>
            </div>

            {/* Properties */}
            {openHubspotObject === object.objectType &&
              object.properties
                .filter((property) => filterByChecked(object.objectType, property))
                .filter(filterByQuery)
                .map(property =>
                  <div
                    key={`${object.objectType}-${property.name}`}
                    className='flex gap-2 items-center ml-7 cursor-pointer group relative'
                    onClick={() => handleToggleProperty(object.objectType, property)}
                  >
                    <input
                      type="checkbox"
                      checked={isPropertyChecked(object.objectType, property)}
                      onChange={(e) => handleToggleProperty(object.objectType, property, e.currentTarget.checked)}
                    />
                    <span className='whitespace-nowrap font-semibold text-sm'>{property.label}</span>
                    <span className='truncate text-sm text-gray-500'>{property.description}</span>
                    {property.description &&
                      <span className='absolute z-10 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-900 text-white rounded-lg px-2 py-1 text-xs max-w-xs left-1/2 top-full'>
                        {property.description}
                      </span>
                    }
                  </div>
                )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default WorkflowModalHubspotFields;
