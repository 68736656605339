import { OAuthError, postData, putData } from "../backend";
import { useContext, useState } from "react";
import { Document } from "../interfaces";
import { useModal, WorkflowContext } from "../contexts";
import ErrorModal from "./ErrorModal";
import SmallSelectField from "./SmallSelectField";
import SmallTextInputField from "./SmallTextInputField";
import ToggleButton from "./ToggleButton";
import { ChevronDownIcon, CursorClickIcon, DuplicateIcon, InformationCircleIcon, LinkIcon } from "@heroicons/react/outline";
import CopyToClipboard from "react-copy-to-clipboard";
import OAuthModal from "./OAuthModal";
import useUser from "../hooks/useUser";
import SettingsModalStripe from "./SettingsModalStripe";
import { useHistory } from "react-router-dom";
import { CURRENCY_SYMBOL } from "../utils";

interface Props {
  document?: Document;
}

function WorkflowPaymentConfig(props: Props) {
  const { openModal } = useModal();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [isInserting, setIsInserting] = useState(false);

  const { user } = useUser();

  const [paymentConfig, setPaymentConfig] = useState(workflow.stripePaymentConfig);

  const [showFeeInformation, setShowFeeInformation] = useState(false);
  const [showTagSettings, setShowTagSettings] = useState<"LINK" | "BUTTON" | null>(null);

  const history = useHistory();

  async function handleAddStripe() {
    setIsPending(true);

    try {
      const stripePaymentConfig = await postData(`/workflows/${workflow.id}/add-payment-config/`)
      setPaymentConfig(stripePaymentConfig);
      mutateWorkflow({ ...workflow, stripePaymentConfig });
    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }

    setIsPending(false);
  }

  async function onChangePaymentConfig(updates: any) {
    if (!paymentConfig)
      return;

    const updatedPaymentConfig = { ...paymentConfig!, ...updates };
    setPaymentConfig(updatedPaymentConfig);
  }

  async function updatePaymentConfig(updates: any = {}) {
    if (!paymentConfig)
      return;

    const updatedPaymentConfig = { ...paymentConfig!, ...updates };
    setPaymentConfig(updatedPaymentConfig);

    await putData(`/stripe/payment-configs/${paymentConfig!.id}/`, updatedPaymentConfig);
  }

  function handleCopyTag() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }

  function handleInsertButtonImage() {
    if (!props.document)
      return;

    setIsInserting(true);
    postData(`/workflows/documents/${props.document.id}/insert-pay-button/`)
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal title="Full Drive Access is Required to Insert Images" oauthUrl={error.oauthUrl} />);
        else
          console.error(error)
      })
      .finally(() => setTimeout(() => setIsInserting(false), 5000));
  }

  const lineItemsMode = workflow.source?.config?.dataGroupingField && paymentConfig?.lineItemsEnabled;

  // Unused Line Items Mode filters
  // const numberSourceFields = workflow.source?.sourceFields.filter(sf => !sf.fieldId.startsWith("_") && sf.fieldType === "NUMBER") || [];
  // const textSourceFields = workflow.source?.sourceFields.filter(sf => !sf.fieldId.startsWith("_") && sf.fieldType === "TEXT") || [];

  const connectedAccount = (() => {
    if (workflow.team && workflow.team.id === user?.activeTeam?.id)
      return user?.activeTeam?.stripeConnectedAccount;

    return user?.stripeConnectedAccount;
  })();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex items-center font-gilroy font-semibold text-gray-600">
            <img className="h-6 w-6 mr-2 rounded" src=" https://static.portant.co/stripe-logo.png" alt="Stripe" /> Accept payments via Stripe
          </div>
          <div className="font-semibold text-xs text-gray-400">
            Connect your Stripe account to Portant to accept payments directly from your documents
          </div>
        </div>
        {paymentConfig?.isEnabled &&
          <ToggleButton value={paymentConfig?.isEnabled} onChange={(isEnabled) => updatePaymentConfig({ isEnabled })} />
        }
      </div>

      {!paymentConfig?.isEnabled &&
        <button className="btn btn-white w-40 text-xs" onClick={handleAddStripe} disabled={isPending}>
          {isPending ? "Adding..." : "Add"}
        </button>
      }

      {paymentConfig?.isEnabled &&
        <>
          {!connectedAccount?.accountCompleted &&
            <div className="flex flex-col items-start gap-2">
              <div className="font-semibold text-gray-600 text-xs">
                Create or connect your Stripe account to Portant to start accepting payments.
              </div>
              <button className="btn btn-blue w-40 text-xs" onClick={() => openModal(<SettingsModalStripe />)} disabled={isPending}>
                {isPending ? "Creating Link..." : "Connect"}
              </button>
            </div>
          }
          {connectedAccount?.accountCompleted &&
            <div className="flex flex-col items-start gap-2">
              <div className="flex flex-col w-full border border-gray-400 rounded-sm my-2 text-sm">
                {props.document &&
                  <div className="flex flex-col w-full border-b p-2">
                    <div className="flex items-center w-full">
                      <ChevronDownIcon className={`w-4 h-4 mr-2 flex-shrink-0 transform transition-all cursor-pointer ${showTagSettings === "BUTTON" ? "rotate-0" : "-rotate-90"}`} onClick={() => setShowTagSettings(f => f === "BUTTON" ? null : "BUTTON")} />
                      <div className="font-semibold font-gilroy text-[#625af6] pt-2 pb-1" onClick={() => setShowTagSettings(f => f === "BUTTON" ? null : "BUTTON")}>
                        Stripe Payment <b>Button</b>
                      </div>
                      <CursorClickIcon className={`w-4 h-4 ml-auto flex-shrink-0 text-gray-600`} />
                      <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isInserting ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={handleInsertButtonImage} disabled={isInserting}>
                        {isInserting ? "Inserting..." : "Insert"}
                      </button>
                    </div>
                    {showTagSettings === "BUTTON" &&
                      <div className="flex flex-col gap-2 w-full mt-1 text-xs">
                        Portant will insert a button image that will open Stripe when clicked from a PDF document. Example:
                        <div className="mx-auto">
                          <img className="h-20 rounded-md" src="https://static.portant.co/pay-button-text.png" alt="Stripe Payment Button" />
                        </div>
                      </div>
                    }
                  </div>
                }
                <div className="flex flex-col w-full p-2">
                  <div className="flex items-center w-full">
                    <ChevronDownIcon className={`w-4 h-4 mr-2 flex-shrink-0 transform transition-all cursor-pointer ${showTagSettings === "LINK" ? "rotate-0" : "-rotate-90"}`} onClick={() => setShowTagSettings(f => f === "LINK" ? null : "LINK")} />
                    <div className="font-semibold font-gilroy text-[#625af6] pt-2 pb-1" onClick={() => setShowTagSettings(f => f === "LINK" ? null : "LINK")}>
                      Stripe Payment <b>Link</b>
                    </div>
                    <LinkIcon className={`w-4 h-4 ml-auto flex-shrink-0 text-gray-600`} />
                    <CopyToClipboard text={"{{Stripe Payment Link}}"} onCopy={handleCopyTag}>
                      <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isCopied ? "bg-blue text-white" : "bg-white text-blue"}`} onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                        {isCopied ? "Copied!" : <><DuplicateIcon className="w-4 h-4 mr-1" /> Copy</>}
                      </button>
                    </CopyToClipboard>
                  </div>
                  {showTagSettings === "LINK" &&
                    <div className="flex flex-col gap-2 w-full mt-1">
                      <SmallTextInputField label="Display Text" value={paymentConfig.linkDisplayText} placeholder="Pay online" className="w-full" onChange={(linkDisplayText) => updatePaymentConfig({ linkDisplayText })} onEnter={updatePaymentConfig} />
                    </div>
                  }
                </div>
              </div>

              <div className="flex gap-2 w-full">

                <SmallSelectField className="w-20 flex-shrink-0" label="Currency" value={paymentConfig.currency} onChange={(currency) => updatePaymentConfig({ currency })}>
                  <option value="USD">USD</option>
                  <option value="AUD">AUD</option>
                  <option value="CAD">CAD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </SmallSelectField>

                <div className="relative h-full w-full">
                  <SmallTextInputField inputClassName="pl-6 pt-1.5 pb-0.5" label="Amount" value={!lineItemsMode ? paymentConfig.amount : "Calculated from Line Items"} placeholder={"Value or {{Tag}}"} className="w-full" onChange={(amount) => onChangePaymentConfig({ amount })} onEnter={updatePaymentConfig} required={!lineItemsMode} disabled={lineItemsMode} />
                  <div className="absolute left-px top-2 pt-1 h-8 flex justify-center items-center px-2 text-gray-400 font-gilroy text-sm font-semibold">
                    {CURRENCY_SYMBOL[paymentConfig.currency] || "$"}
                  </div>
                </div>
              </div>

              <SmallTextInputField label="Description" value={paymentConfig.description} placeholder="Required" className="w-full" onChange={(description) => updatePaymentConfig({ description })} onEnter={updatePaymentConfig} required />
            </div>
          }
          <div className="text-xs font-semibold text-gray-600 mt-1 -mb-1">
            <div className="flex items-center cursor-pointer gap-1 underline" onClick={() => setShowFeeInformation(f => !f)}>
              <InformationCircleIcon className="w-4 h-4" />Fee Information
            </div>
            {showFeeInformation &&
              <div className="flex flex-col mt-1">
                <div>
                  A fee of 5% + Stripe's processing fee will be charged on each payment. To reduce this fee please <span className="underline text-blue cursor-pointer" onClick={() => history.push("/settings/billing")}>upgrade</span>.
                </div>
                <div className="flex flex-col border w-full mt-1">
                  <div className="flex items-center border-b">
                    <div className="border-r w-full px-1 mt-1">Free {(user?.subscription?.tier === "free" || !user?.subscription) && <span className="font-semibold text-green">(Current)</span>}</div>
                    <div className="w-full px-1 mt-1">5%</div>
                  </div>
                  <div className="flex items-center border-b">
                    <div className="border-r w-full px-1 mt-1">Portant Pro {user?.subscription?.tier === "pro" && <span className="font-semibold text-green">(Current)</span>} </div>
                    <div className="w-full px-1 mt-1">1%</div>
                  </div>
                  <div className="flex items-center">
                    <div className="border-r w-full px-1 mt-1">Portant Teams {user?.subscription?.tier === "teams" && <span className="font-semibold text-green">(Current)</span>}</div>
                    <div className="w-full px-1 mt-1">0.5%</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </>
      }
    </div>
  );

}

export default WorkflowPaymentConfig;
