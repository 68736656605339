import useSWR from "swr";
import { getData } from "../backend";
import { Workflow } from "../interfaces";


export default function useWorkflow(workflowId: string) {
  const key = `/workflows/${workflowId}/`;

  const { data: workflow, mutate: mutateWorkflow, ...rest } = useSWR<Workflow>(key, getData);
  return { workflow, mutateWorkflow, ...rest };
}
