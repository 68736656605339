import { ChevronDownIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { postData, putData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import ToggleButton from "./ToggleButton";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import SmallSelectField from "./SmallSelectField";
import SmallTextInputField from "./SmallTextInputField";
import { convertToRange } from "../utils";
import useUser from "../hooks/useUser";

function WorkflowAutomationPanelSheets() {
  const { user } = useUser();
  const { openModal } = useModal();

  const { workflow, mutateWorkflow, automationOptions, updateAutomationOptions, updateAutoCreate } = useContext(WorkflowContext);

  const hasSheetsAutoCreateFeature = user?.subscription?.featureSheetsAutocreate;

  const [showManualAutomation, setShowManualAutomation] = useState(false);
  const [automationIsActive, setAutomationIsActive] = useState(false);

  const [creatingSourceItemHashes, setCreatingSourceItemHashes] = useState<boolean>(false);
  const [autoCreateOnUpdate, setAutoCreateOnUpdate] = useState<boolean>(workflow.source?.config.autoCreateOnUpdate ?? false)

  function handleChangeRange(value: string) {
    updateAutomationOptions({ range: convertToRange(value) });
  }

  async function handleUpdateAutoCreate(autoCreate: boolean) {
    if (!hasSheetsAutoCreateFeature)
      return openModal(<PayWallModalPro />);

    updateAutoCreate(autoCreate);
  }

  async function updateAutoCreateOnUpdate(autoCreateOnUpdate: boolean) {
    const source = workflow.source;
    if (!source)
      return;

    if (!hasSheetsAutoCreateFeature)
      return openModal(<PayWallModalPro />);

    setAutoCreateOnUpdate(autoCreateOnUpdate);

    const updatedSource = { ...source, config: { ...source.config, autoCreateOnUpdate } };

    if (autoCreateOnUpdate) {
      setCreatingSourceItemHashes(true);
      await postData(`/workflows/sources/${source.id}/source-item-hashes/`)
        .catch(console.error)
        .finally(() => setCreatingSourceItemHashes(false));
    }

    await putData(`/workflows/sources/${source.id}/`, updatedSource)
      .catch(console.error);

    mutateWorkflow();
  }

  return (
    <>
      <div className="flex flex-col gap-2">

        <div className="flex items-start font-gilroy font-semibold text-sm gap-2">
          Auto-Create
          <div className="mr-auto">
            <ProLabel />
          </div>

          <ToggleButton value={workflow.autoCreate} onChange={handleUpdateAutoCreate} bgColor="bg-green" />
        </div>

        <div className="text-xs text-gray-600">
          Portant will check the source <span className="font-semibold">every 2 minutes</span><br /> and run this workflow for all <span className="underline">new</span> rows.
        </div>

        {workflow.autoCreate &&

          <label className={`flex items-center gap-2 select-none cursor-pointer `}>
            <input type="checkbox" checked={autoCreateOnUpdate} onChange={e => updateAutoCreateOnUpdate(e.currentTarget.checked)} disabled={creatingSourceItemHashes} />
            <div className="font-gilroy font-semibold text-xs text-gray-600">
              {creatingSourceItemHashes
                ? <>Initializing<span className="loading-ellipse" /></>
                : <>Process data when <span className="underline">existing</span> rows are updated</>
              }
            </div>
          </label>
        }
      </div>

      <div className="flex items-center gap-2 my-2">
        <div className="h-px bg-gray-400 w-full" />
        <div className="flex-shrink-0 font-gilroy font-semibold text-xs text-gray-600">or</div>
        <div className="h-px bg-gray-400 w-full" />
      </div>

      <div className="flex flex-col gap-2 select-none">
        <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer" onClick={() => setShowManualAutomation(f => !f)}>
          {workflow.autoCreate &&
            <ChevronDownIcon className={`mr-2w-5 h-5 flex-shrink-0 ${showManualAutomation ? "rotate-0" : "-rotate-90"}`} />
          }
          Process Existing Rows
        </div>

        {(showManualAutomation || !workflow.autoCreate) &&
          <>
            <div className="flex items-center justify-between text-sm w-full gap-2">
              <div className="flex-shrink-0">Run workflow for</div>
              <SmallSelectField value={automationOptions.mode} onChange={(mode) => updateAutomationOptions({ mode })} className="w-full" disabled={automationIsActive}>
                <option value="ALL">all data</option>
                <option value="CUSTOM">custom range</option>
              </SmallSelectField>
            </div>

            {(automationOptions.mode === "CUSTOM") &&
              <div className="flex text-sm w-full items-center justify-between">
                <SmallTextInputField label="Row Range" className="w-full" placeholder="e.g. 2-5, 8, 10+" value={automationOptions.range ?? ""} onChange={handleChangeRange} autoFocus invalid={!automationOptions.range} disabled={automationIsActive} />
              </div>
            }

            <WorkflowAutomationPanelManual setIsActive={setAutomationIsActive} disabled={automationOptions.mode === "CUSTOM" && !automationOptions.range} />
          </>
        }
      </div>
    </>
  );

}

export default WorkflowAutomationPanelSheets;
