import { CheckIcon } from "@heroicons/react/outline";

interface Props {
  value: string;
  onChange: (color: string) => void;
}

const COLOURS = [
  "#cccccc", "#1abc9c", "#16a085", "#2ecc71", "#27ae60",
  "#245a5e", "#2c4f6f", "#4248f5", "#0088cc", "#9b59b6",
  "#8e44ad", "#a53860", "#c0392b", "#e74c3c", "#d35400",
  "#e67e22", "#f39c12", "#f1c40f", "#ffa5ab", "#da627d",
];

function ColorPickerField(props: Props) {
  return (
    <div className='grid grid-cols-5 flex-shrink-0 gap-2 pt-1'>
      {COLOURS.map((color) => (
        <div key={color} className="flex items-center justify-center w-6 h-6 rounded cursor-pointer" style={{ backgroundColor: color }} onClick={() => props.onChange(color)}>
          {props.value === color ? (<CheckIcon className="h-6 w-6 text-white" />) : null}
        </div>
      ))}
    </div>
  );
}

export default ColorPickerField;
