import { DocumentIcon } from "@heroicons/react/outline";
import GooglePickerButton, { Ref as GooglePickerRef } from "./GooglePickerButton";
import { useModal } from "../contexts";
import { useEffect, useRef, useState } from "react";
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";
import useScript from "../hooks/useScript";
import useGoogleAccessToken from "../hooks/useGoogleAccessToken";
import { GOOGLE_MIMETYPE_MAP } from "../utils";

const SCOPES = ["https://www.googleapis.com/auth/drive.file"];

interface File {
  id: string;
  mimeType: string;
  name: string
}

export interface DocumentLink {
  webViewLink: string;
  name: string;
}

type Props = {
  onFinish?: (document: DocumentLink) => void
}

function ConversionPdfPicker(props: Props) {
  const status = useScript('https://apis.google.com/js/api.js');
  const { data: accessToken } = useGoogleAccessToken(SCOPES);
  const googlePickerRef = useRef<GooglePickerRef | null>(null);

  const [clientLoaded, setClientLoaded] = useState(false);


  const { openModal, closeModal } = useModal();


  async function handleSelectFile(pickerResult: File) {
    if (!clientLoaded)
      return;

    if (pickerResult.mimeType !== "application/pdf")
      return openModal(<ErrorModal details="This conversion only works with PDF files. Please make sure you've selected a PDF file to continue." />)

    openModal(<LoadingModal title="Converting PDF to Google Docs file" subtitle="This may take a minute as we create the document in your Google&nbsp;Drive." />);

    // @ts-ignore
    const gapi = window.gapi;

    await gapi.client.init({
      "accessToken": accessToken,
      "apiKey": process.env.REACT_APP_GOOGLE_API_KEY,
      "clientId": process.env.REACT_APP_GOOGLE_CLIENT_ID,
      "discoveryDocs": ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
      "scope": SCOPES[0],
    });

    try {
      // https://stackoverflow.com/questions/49313477/using-drive-api-driveapp-to-convert-from-pdfs-to-google-documents
      const response = await gapi.client.request({
        path: `/drive/v3/files/${pickerResult.id}/copy`,
        params: { "fields": "webViewLink,name,exportLinks" },
        method: "POST",
        body: {
          name: `[PORTANT] ${pickerResult.name}`,
          mimeType: GOOGLE_MIMETYPE_MAP["GOOGLE_DOCS"]
        }
      });

      const data = JSON.parse(response.body);
      const file = {
        webViewLink: data["webViewLink"],
        name: data["name"]
      };
      props?.onFinish?.(file);

      closeModal();
    } catch (error) {
      openModal(<ErrorModal details={JSON.stringify(error)} />)
    }
  }

  useEffect(() => {
    if (status !== "ready")
      return;

    // @ts-ignore
    const gapi = window.gapi;

    gapi.load('client',
      () => setClientLoaded(true)
    );
  }, [status]);

  function openGooglePicker() {
    googlePickerRef?.current?.openGooglePicker();
  }

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Select a PDF
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          Select the PDF file you want to transform<br />
          either from your Google Drive or from your device
        </p>
      </div>

      <GooglePickerButton
        className="btn btn-blue mx-auto"
        blockType="PDF"
        onPicked={handleSelectFile}
        onLoad={openGooglePicker}
        onAuth={openGooglePicker}
        ref={googlePickerRef}
        scopes={SCOPES}
        displayUploadTab
        displayUploadTabFirst
      >
        <> <DocumentIcon className="w-6 h-6 mr-2" /> Select PDF File </>
      </GooglePickerButton>
    </>
  );
}

export default ConversionPdfPicker;
