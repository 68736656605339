import { useContext, useState } from "react";
import { WorkflowContext } from "../contexts";
import TextInputField from "./TextInputField";

interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValue?: string;
  onEnter: (value: string) => void;
}

type OutputNameState = "INITIAL" | "LOADED" | "INVALID_EMPTY" | "INVALID_LENGTH" | "INVALID_TAGS" | "VALID";

const TAG_PATTERN = /\{\{(.*?)\}\}/g;

function WorkflowDocumentOutputName(props: Props) {
  const { workflow } = useContext(WorkflowContext);
  const { initialValue, onEnter, ...elProps } = props;

  const [value, setValue] = useState(initialValue ?? "");
  const [outputNameState, setOutputNameState] = useState<OutputNameState>("INITIAL");

  function handleChangeOutputName(outputName: string) {
    setValue(outputName);

    if (!workflow.source)
      return;

    let possibleFields: Array<string> = [];
    for (const field of workflow.source.sourceFields) {
      if (field.fieldName)
        possibleFields.push(field.fieldName);

      if (field.customMapping)
        possibleFields.push(field.customMapping);
    }

    let invalidFields = [];
    for (const match of [...outputName.matchAll(TAG_PATTERN)]) {
      const field = match[1];

      // If field starts with "=", it is a formula field and does not need to be checked
      if (!possibleFields.includes(field) && !field.startsWith("="))
        invalidFields.push(field);
    }

    if (outputName.length === 0)
      setOutputNameState("INVALID_EMPTY");

    else if (outputName.length >= 200)
      setOutputNameState("INVALID_LENGTH");

    else if (invalidFields.length > 0)
      setOutputNameState("INVALID_TAGS");

    else
      setOutputNameState("VALID");
  }

  let outputNameStatus = "Tags from the source field table can be pasted here";
  if (outputNameState === "INVALID_EMPTY")
    outputNameStatus = "Output name cannot be empty";
  else if (outputNameState === "INVALID_LENGTH")
    outputNameStatus = "Output name cannot be longer than 200 characters";
  else if (outputNameState === "INVALID_TAGS")
    outputNameStatus = "Output name cannot contain invalid tags";

  return (
    <div {...elProps}>
      <TextInputField label="Output Name" value={value} onChange={handleChangeOutputName} invalid={outputNameState.startsWith("INVALID")} onEnter={onEnter} enterPrompt />
      <div className={"text-xs mt-1 " + (outputNameState.startsWith("INVALID") ? "text-yellow" : "text-gray-500")}>
        {outputNameStatus}
      </div>
    </div>
  )
}

export default WorkflowDocumentOutputName;
