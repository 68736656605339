import { ChevronDownIcon, LinkIcon, MenuAlt1Icon, MenuIcon, PaperClipIcon, PhotographIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";

interface Props {
  isActive?: boolean;
  iconName: string;
  tooltipText?: string;
  isMenu?: boolean;
  className?: string;
  onClick?: () => void;
}

function WorkflowEmailToolbarButton(props: Props) {
  const { isActive, iconName, tooltipText, isMenu, className, onClick } = props;
  const iconClassName = `h-5 w-5 ${isActive ? "text-black" : "text-gray-700"}`;
  const ICON_MAP: Record<string, ReactNode> = {
    "attach_file": <PaperClipIcon className={iconClassName} />,
    "insert_photo": <PhotographIcon className={iconClassName} />,
    "format_color_text": <span className={`select-none underline decoration-2 text-xl font-mono ${isActive ? "text-black" : "text-gray-700"}`}>A</span>,
    "link": <LinkIcon className={iconClassName} />,
    "format_bold": <span className={`select-none font-black text-xl font-mono ${isActive ? "text-black" : "text-gray-700"}`}>B</span>,
    "format_underline": <span className={`select-none underline text-xl font-mono ${isActive ? "text-black" : "text-gray-700"}`}>U</span>,
    "format_italic": <span className={`select-none italic text-xl font-mono ${isActive ? "text-black" : "text-gray-700"}`}>I</span>,
    "format_align_left": <MenuAlt1Icon className={iconClassName} />,
    "format_align_right": <MenuAlt1Icon className={`scale-x-[-1] ${iconClassName}`} />,
    "format_align_center": <MenuIcon className={iconClassName} />,
    "format_list_numbered": (
      <div className="flex flex-col justify-center w-full h-full m-0.5">
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">1</p> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">2</p> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">3</p> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
      </div>
    ),
    "format_list_bulleted": (
      <div className="flex flex-col justify-center gap-[3px] h-full w-full flex-grow m-0.5">
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${isActive ? "bg-black" : "bg-gray-600"}`} /> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${isActive ? "bg-black" : "bg-gray-600"}`} /> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${isActive ? "bg-black" : "bg-gray-600"}`} /> <div className={`w-full h-0.5 ${isActive ? "bg-black" : "bg-gray-600"}`} /> </div>
      </div>
    )
  };

  const Icon = iconName in ICON_MAP ? ICON_MAP[iconName] : <p>{iconName}</p>;

  return (
    <div className={`group relative flex items-center justify-center ${isMenu ? "w-10" : "w-7"} h-7 cursor-pointer rounded ${isActive ? "bg-gray-200" : "hover:bg-gray-100"} ${className}`} onClick={onClick}>
      {Icon}

      {isMenu &&
        <ChevronDownIcon className={`h-4 w-4 ${isActive ? "text-black" : "text-gray-700"}`} />
      }
      {tooltipText &&
        <span className={`invisible ${!isActive && "group-hover:visible"} bg-gray-600 text-xs text-white text-center rounded py-1 px-2 absolute left-0 z-50 bottom-full whitespace-nowrap`}>{tooltipText}</span>
      }
    </div >
  );
}

export default WorkflowEmailToolbarButton;
