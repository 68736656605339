import { postData } from "../backend";
import { Team, TeamUser } from "../interfaces";
import useUser from "../hooks/useUser";
import Avatar from "./Avatar";
import LoadingModal from "./LoadingModal";
import { useModal } from "../contexts";
import ErrorModal from "./ErrorModal";
import { useHistory } from "react-router-dom";

interface Props {
  team: Team;
  onRemove?: () => void;
}

function SettingsSolutionPartnerTeam(props: Props) {
  const history = useHistory();

  const { user, refreshUser } = useUser();
  const { team } = props;

  const { openModal, closeModal } = useModal();

  const ownerUser = team.members.find((user: TeamUser) => user.id === team.owner);

  function changeActiveTeam(teamId: string) {
    openModal(<LoadingModal title="Switching Teams..." />)

    postData("/auth/switch-team/", { team: teamId })
      .then(async user => {
        refreshUser(user, { revalidate: false });
        closeModal();
      })
      .catch(error => {
        openModal(<ErrorModal details={error} />);
      });
  }

  return (
    <tr className={`${team.id === user?.activeTeam?.id ? "bg-blue-50" : "bg-white"}`}>
      <td className="h-16 w-[200px]">
        <div className="flex items-center gap-2 text-sm my-2 truncate pl-2">
          <div className="font-gilroy font-bold">{team.name}</div>
        </div>
      </td>
      <td className="w-auto">
        <div className="w-40 flex gap-2">
          {ownerUser &&
            <Avatar url={ownerUser.avatar} className="h-10 w-10 flex-shrink-0" />
          }
          <div className="flex flex-col justify-center">
            <div className="font-semibold font-gilroy text-gray-600 truncate max-w-[200px]">{ownerUser?.fullName}</div>
            <div className="text-sm font-semibold text-gray-400 truncate">{ownerUser?.email}</div>
          </div>
        </div>
      </td>
      <td className="text-gray-600 font-semibold text-sm text-left w-[100px]">
        {team.subscriptionName}
      </td>
      <td className="text-gray-600 font-semibold text-sm text-left w-[100px]">
        {team.totalUsage}
      </td>
      <td className="text-gray-400 text-sm text-left w-[200px] pr-[20px]">
        {user?.activeTeam?.id === team.id
          ? <button className="btn btn-blue w-44" onClick={() => history.push("/settings/team")}>Active Team</button>
          : <button className="btn btn-white w-44" onClick={() => changeActiveTeam(team.id)}>Switch to Team</button>
        }
      </td>
    </tr>
  );
}

export default SettingsSolutionPartnerTeam;
