import React from 'react';
import { deleteData } from '../backend';
import { useModal } from '../contexts';
import Modal from './Modal';
import { Workflow } from '../interfaces';
import LoadingModal from './LoadingModal';
import ErrorModal from './ErrorModal';

interface Props {
  workflow: Workflow;
  deleteWorkflow: (id: string) => void;
}

function WorkflowModalDelete(props: Props) {
  const { openModal, closeModal } = useModal();

  function handleConfirm() {
    openModal(<LoadingModal title="Removing workflow..." subtitle='This operation will not remove any of the workflow files from your Google Drive.' />);

    deleteData(`/workflows/${props.workflow.id}/`)
      .then(() => {
        props.deleteWorkflow(props.workflow.id);
        closeModal();
      })
      .catch((error) => openModal(<ErrorModal title="Error removing workflow" details={error} />));
  }

  return (
    <Modal
      title={
        <h3 className="font-gilroy font-bold text-red text-xl">
          Delete Workflow
        </h3>
      }
      subtitle="Are you sure you would like the delete this workflow? This action cannot be undone."
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ className: "btn-red", text: "Confirm", onClick: () => handleConfirm() }}
      size='sm'
    />
  );
}

export default WorkflowModalDelete;
