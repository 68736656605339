import { XIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import { HTMLProps } from "react";
import useUser from "../hooks/useUser";

interface Props extends HTMLProps<HTMLDivElement> {
  isVisible: boolean;
  setIsVisible: (f: boolean) => void;
  openUpgradeModal: () => void;
}

function AppBanner(props: Props) {
  const { isVisible, setIsVisible, openUpgradeModal, ...htmlProps } = props;
  const { user } = useUser();

  if (!isVisible)
    return null;

  return (
    <div {...htmlProps}>
      <div className="relative w-full h-full bg-blue text-white flex items-center justify-center gap-2 font-gilroy font-semibold flex-shrink-0 cursor-pointer">
        <div>
          Upgrade to Pro before your free trial expires <span className="underline cursor-pointer">{moment().to(moment(user?.subscription?.dateValidTo))}</span>!
        </div>
        <button className="btn btn-white py-1 px-4 text-sm font-bold" onClick={openUpgradeModal}>
          Upgrade now
        </button>
        <XIcon className="absolute w-6 h-6 right-12 cursor-pointer opacity-50 hover:opacity-100" onClick={() => setIsVisible(false)} />
      </div>
    </div>
  )
}

export default AppBanner;
