import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

type Props = {
  button: React.ReactNode;
  content: React.ReactNode | string;
}

function Tooltip(props: Props) {
  const [displayTag, setDisplayTag] = useState(false);

  return (
    <div
      onMouseEnter={() => setDisplayTag(true)}
      onMouseLeave={() => setDisplayTag(false)}
    >
      <button onClick={() => setDisplayTag(prev => !prev)}> {props.button} </button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
        as={Fragment}
        show={displayTag}
      >
        <div className="absolute bg-gray-900 text-white rounded-lg p-2 min-w-[12rem]">
          {props.content}
        </div>
      </Transition>
    </div>
  );
}

export default Tooltip;
