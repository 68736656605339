import { DocumentTextIcon } from "@heroicons/react/outline";
import { AIDocument } from "../interfaces";

type Props = {
  document: AIDocument;
  updateSection: (index: number, key: 'name' | 'description' | 'content', value: string) => void;
}

function AIDocumentBuilderEditor(props: Props) {
  const { document, updateSection } = props;

  return (
    <div className="h-auto mx-auto w-full max-w-screen-lg my-4 shadow rounded border">
      <div className="px-8 py-4 flex items-center gap-2 text-lg font-bold bg-white rounded-t-lg">
        <DocumentTextIcon className="h-6 w-6 text-blue" />
        {document.name}
      </div>
      <div className="w-full bg-gray-200 p-8 rounded">
        <div className="bg-white w-full h-full px-32 py-12 shadow-inner flex flex-col gap-2">
          {document.sections.map(section => (
            <div className="flex flex-col" key={section.name}>
              <input
                value={section.name}
                className="focus-visible:outline-none font-bold text-lg"
                onChange={({ target }) => updateSection(section.id, 'name', target.value)}
                maxLength={50}
              />
              <textarea
                value={section.content}
                className="focus-visible:outline-none resize-none min-h-[150px] overflow-visible"
                onChange={({ target }) => updateSection(section.id, 'content', target.value)}
                maxLength={450}
              />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default AIDocumentBuilderEditor;
