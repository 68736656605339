import useSWR from "swr";
import { getData } from "../backend";
import { WorkflowAutomation } from "../interfaces";
import { mutate } from "swr";

export default function useAutomationStatus(automationId?: string): WorkflowAutomation | undefined {

  let key = null;
  if (automationId)
    key = `/workflows/automations/${automationId}/`;

  // fallbackData
  const { data } = useSWR(
    key,
    getData,
    {
      refreshInterval(data?: WorkflowAutomation) {
        if (data && ["PENDING", "RUNNING"].includes(data.status)) {
          return 2000;
        }

        return 0;
      },
      refreshWhenHidden: true,
      onSuccess(data?: WorkflowAutomation) {
        if (!data) {
          return;
        }

        if (["COMPLETED", "CANCELLED", "ERROR"].includes(data.status)) {
          mutate(`/workflows/${data.workflow}/`);
        }
      }
    });

  return data;
}
