import { ExternalLinkIcon, FolderOpenIcon } from '@heroicons/react/outline';
import { GoogleFile, GoogleFolder } from '../interfaces';
import { getMimeTypeIcon } from '../utils';
import Modal from './Modal';

interface Props {
  files?: Array<GoogleFile>;
  folders?: Array<GoogleFolder>;
}

function InaccessibleFilesModal(props: Props) {
  return (
    <Modal
      title={
        <h3 className="font-gilroy font-semibold text-xl text-red">
          Unable to Access Workflow Files
        </h3>
      }
      subtitle={
        <p className='font-semibold text-sm text-gray-500'>
          It looks like you don't have access to all the required files and folders.<br />
          Please ensure you have access to the following files and folders or request access from the owner.
        </p>
      }
    >
      <div className="flex flex-col items-center justify-center gap-4 text-center bg-white">
        <div className='flex flex-col gap-2'>
          {props.files?.map((file) => (
            <div className='flex items-center gap-2 hover:underline'>
              <img src={getMimeTypeIcon(file.mimeType)} className='w-6 h-6 flex-shrink-0' alt="File icon" />
              <a href={file.url} target="_blank" rel="noreferrer" className="text-blue font-semibold font-gilroy cursor-pointer truncate">{file.name}</a>
              <ExternalLinkIcon className='w-4 h-4 flex-shrink-0' />
            </div>
          ))}
          {props.folders?.map((file) => (
            <div className='flex items-center gap-2 hover:underline'>
              <FolderOpenIcon className='w-6 h-6 flex-shrink-0' />
              <a href={file.url} target="_blank" rel="noreferrer" className="text-blue font-semibold font-gilroy cursor-pointer truncate">{file.name}</a>
              <ExternalLinkIcon className='w-4 h-4 flex-shrink-0' />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default InaccessibleFilesModal;
