import Modal from './Modal';
import { useModal } from "../contexts";
import useUser from '../hooks/useUser';

interface Props {
  oauthUrl: string;
  onClose?: () => void;
}

function OAuthModalMicrosoft(props: Props) {
  const { closeModal } = useModal();
  const { refreshUser } = useUser();

  function handleInstallIntegration() {
    window.open(props.oauthUrl, "_blank");
    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  async function getCurrentUserOnFocus() {
    window.removeEventListener("focus", getCurrentUserOnFocus);

    await refreshUser();
    closeModal(props.onClose);
  }

  return (
    <Modal
      title="Authorise Microsoft with Portant"
      subtitle="Portant requires access to files within your Microsoft OneDrive."
      primaryButtonProps={{ className: "flex-shrink-0", text: "Authorise Microsoft with Portant", onClick: handleInstallIntegration }}
      className='flex items-start'
      size='sm'
    >
      <span className='text-gray-400 w-4/5 text-sm'><b>Note</b> You must allow popups from app.portant.co for this integration to function correctly.</span>
    </Modal>
  );
}

export default OAuthModalMicrosoft;
