import useSWRImmutable from "swr/immutable";

import { getData } from "../backend";
import { HubspotPrimaryObject } from "../interfaces";

interface ContactList {
  id: string,
  name: string,
  total: number
}

export default function useHubspotContactLists(selectedHubspotPrimaryObject: HubspotPrimaryObject) {
  return useSWRImmutable<Array<ContactList>>(selectedHubspotPrimaryObject === "CONTACT" ? "/hubspot/contact-lists/" : null, getData);
}
