import { RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";

import { useModal } from "../../contexts";
import { unwrapLink } from "../../emailEditorHelpers";
import { Link } from "../../interfaces";
import WorkflowModalLink from "../WorkflowModalLink";

type LinkRenderElementProps = RenderElementProps & { element: Link };

function LinkElement(props: LinkRenderElementProps) {
  const { attributes, children, element } = props;

  const editor = useSlateStatic();
  const { openModal } = useModal();

  const selected = useSelected();
  const focused = useFocused();

  return (
    <div className="relative inline-block">
      <span {...attributes} className="text-blue underline">
        {children}
      </span>
      <div className={`select-none flex justify-center items-center max-w-sm absolute top-6 bg-white text-xs px-2 py-1 shadow border-black border ${selected && focused ? "inline" : "hidden"}`}>
        <div className="truncate">Go to link: <span className="text-blue underline cursor-pointer" onClick={() => window.open(element.url, "_blank")}>{element.url}</span></div>
        <div className="mx-2 bg-gray-400 w-[1px] h-4" />
        <div className="text-blue underline cursor-pointer" onClick={() => openModal(<WorkflowModalLink editor={editor} />)}>Change</div>
        <div className="mx-2 bg-gray-400 w-[1px] h-4" />
        <div className="text-blue underline cursor-pointer" onClick={() => unwrapLink(editor)}>Remove</div>
      </div>
    </div>
  )
}

export default LinkElement;
