import { useCallback, useRef, useState } from "react";
import { BLOCK_ICON_MAP, HUBSPOT_PRIMARY_OBJECT_ID_MAP, capitalize } from "../utils";
import { CheckCircleIcon, CogIcon, ExclamationIcon, PlusCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import { useModal } from "../contexts";
import { ChatGPTField, Document, SourceField, Workflow } from "../interfaces";
import { BadRequestError, OAuthError, postData } from "../backend";
import WorkflowSourceFieldTableRow from "./WorkflowSourceFieldTableRow";
import WorkflowChatGPTFieldTableRow from "./WorkflowChatGPTFieldTableRow";
import WorkflowChatGPTModal from "./WorkflowChatGPTModal";
import PortantAILabel from "./PortantAILabel";
import useUser from "../hooks/useUser";
import PayWallModalPortantAI from "./PayWallModalPortantAI";
import WorkflowModalHubspotFields from "./WorkflowModalHubspotFields";
import { KeyedMutator } from "swr";
import OAuthModal from "./OAuthModal";
import InaccessibleFilesModal from "./InaccessibleFilesModal";

interface Props {
  workflow: Workflow;
  mutateWorkflow: KeyedMutator<Workflow>;
  document?: Document;
  showRefreshSpinning?: boolean; // Override isRefreshing **only visually** to show the refresh button as spinning
  hideChatGPTField?: boolean;
  disableCopy?: boolean;
}

function WorkflowSourceFieldTableHubSpot(props: Props) {
  const { user } = useUser();
  const { openModal } = useModal();

  const { workflow, mutateWorkflow } = props;
  const source = workflow.source!

  const tableRef = useRef<HTMLDivElement>(null);
  const [openField, setOpenField] = useState<string | null>(null);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<Array<string>>();

  const refresh = useCallback(async () => {
    setIsRefreshing(true);

    await postData(`/hubspot/config/${source.hubspotConfig?.id}/refresh/`)
      .then(hubspotConfig => mutateWorkflow({ ...workflow, source: { ...source, hubspotConfig } }, { revalidate: false }))
      .catch(console.error);

    try {
      await postData(`/workflows/${workflow.id}/check/`)
        .then(() => setMissingFields([])); // No error means no missing fields

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)

      else if (error instanceof BadRequestError) {
        if (error.reason === "MISSING_FIELDS") {
          setMissingFields(error.payload.missingFields);

        } else if (error.reason === "INACCESSIBLE_FILES") {
          openModal(<InaccessibleFilesModal {...error.payload} />);

        }
      }
    };

    setIsRefreshing(false);
    mutateWorkflow();

  }, [workflow, source, mutateWorkflow, openModal]);

  function scrollToFit(rowEl: HTMLDivElement) {
    const tableEl = tableRef.current!
    const tableHeight = tableEl.offsetHeight;
    const tableScrollTop = tableEl.scrollTop;

    const rowTop = rowEl.offsetTop - tableEl.offsetTop;
    const rowHeight = rowEl.clientHeight;
    const rowBottom = rowTop + rowHeight;

    if (rowTop < tableScrollTop) {
      tableEl.scroll({ top: rowTop, behavior: "smooth" });

    } else if (rowBottom > tableHeight) {
      const newScrollTop = rowTop - (300 - rowHeight) + 12; // + buffer to show next scrollable row
      tableEl.scroll({ top: newScrollTop, behavior: "smooth" });
    }
  }

  function handleAddChatGPTField() {
    if (!user || (!user.subscription?.featurePortantAi && user?.aiUsage >= 10))
      return openModal(<PayWallModalPortantAI />);

    openModal(<WorkflowChatGPTModal workflow={workflow} mutateWorkflow={mutateWorkflow} />);
  }

  const fieldsStatus = (() => {
    if (missingFields === undefined)
      return null;

    if (missingFields.length === 0)
      return (
        <div className="flex flex-col text-sm p-2 border-t">
          <div className="flex items-center">
            <CheckCircleIcon className="w-4 h-4 mr-2 text-green" />All inserted document tags are valid
          </div>
        </div>
      );

    return (
      <div className="flex flex-col text-sm p-2 border-t">
        <div className="flex items-center">
          <ExclamationIcon className="w-4 h-4 mr-2 text-yellow" />This document contains invalid tags <RefreshIcon className={`h-4 w-4 ml-auto text-blue cursor-pointer ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={refresh} />
        </div>
        <ul className="list-disc ml-10">
          {missingFields.map(field => <li key={field}>{field}</li>)}
        </ul>
      </div>
    );

  })();

  const rows = source.sourceFields
    .filter((field: SourceField) => !!field.fieldName && field.fieldType !== "OUTPUT")
    .map((field: SourceField) => <WorkflowSourceFieldTableRow key={field.id} field={field} document={props.document} isOpen={openField === field.id} setOpenField={setOpenField} scrollToFit={scrollToFit} workflow={workflow} showUserFieldType disableCopy={props.disableCopy} />);

  const chatGPTRows = source.chatgptFields
    .map((field: ChatGPTField) => <WorkflowChatGPTFieldTableRow key={field.id} field={field} document={props.document} />);

  const objectType = source.hubspotConfig!.hubspotPrimaryObject === "COMPANY" ? "Companies" : capitalize(source.hubspotConfig!.hubspotPrimaryObject) + "s";
  const primaryObjectId = HUBSPOT_PRIMARY_OBJECT_ID_MAP[source.hubspotConfig!.hubspotPrimaryObject];

  const showRefreshing = isRefreshing || props.showRefreshSpinning;

  return (
    <div className="flex flex-col border border-gray-400 rounded-sm">
      <div className="border-b border-gray-400">
        <div className="flex items-center text-sm">
          <img className="w-4 h-4 ml-2 my-2" src={BLOCK_ICON_MAP[source.sourceType]} alt="Data Source Icon" />
          <a className="font-gilroy font-semibold ml-1 mt-1 text-blue underline whitespace-nowrap overflow-ellipsis overflow-hidden w-[300px]" href={`https://app.hubspot.com/contacts/${user?.hubspotCredentials?.hubspotPortalId}/objects/${primaryObjectId}`} target="_blank" rel="noreferrer">HubSpot {objectType}</a>
          <CogIcon className={`h-6 w-6 ml-auto mr-2 text-blue cursor-pointer`} onClick={() => openModal(<WorkflowModalHubspotFields hubspotConfig={source.hubspotConfig!} mutateWorkflow={mutateWorkflow} setIsRefreshing={setIsRefreshing} />)} />
          <RefreshIcon className={`h-6 w-6 mr-2 text-blue cursor-pointer ${showRefreshing ? "animate-spin" : "animate-none"}`} onClick={refresh} />
        </div>
      </div>
      {(showRefreshing) &&
        <div className="flex bg-white text-gray-600 items-center py-1 px-2 text-sm border-b last:border-b-0 w-full font-semibold font-gilroy h-[40px]">
          Updating source fields from&nbsp;<span className="text-[#f57722]">HubSpot</span><span className="loading-ellipse" />
        </div>
      }
      <div ref={tableRef} className="overflow-y-scroll" style={{ maxHeight: 300 - (showRefreshing ? 40 : 0) }}>
        {rows}
        {rows.length === 0 && (
          <div className="text-xs text-gray-400 text-center p-2">
            No fields found.<br />Please ensure the source contains valid fields then refresh the table.
          </div>
        )}
      </div>
      {!props.hideChatGPTField &&
        <div className="overflow-y-scroll border-t" style={{ maxHeight: "210px" }}>
          <div className="flex justify-center py-2 px-2 text-sm border-b last:border-b-0 items-center gap-1 text-blue">
            {/* <img src="https://static.portant.co/openai-icon.svg" className="w-4 h-4" alt="OpenAI Icon" /> */}
            <PlusCircleIcon className="w-4 h-4" />
            <div className="flex items-center gap-1 font-semibold font-gilroy cursor-pointer hover:underline" onClick={handleAddChatGPTField}>
              {/* Add ChatGPT Field */}
              Add AI Content Field
            </div>
            <PortantAILabel />
          </div>
          {chatGPTRows}
        </div>
      }
      {fieldsStatus}
    </div>
  );
};

export default WorkflowSourceFieldTableHubSpot;
