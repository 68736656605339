import { useContext, useLayoutEffect, useRef, useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, RenderElementProps, useSlate } from "slate-react";

import { WorkflowOnlyContext } from "../../contexts";
import { Tag } from "../../interfaces";

import TemplatedTextEditorTagList from "../TemplatedTextEditorTagList";

type TagRenderElementProps = RenderElementProps & { element: Tag };

function TagElement(props: TagRenderElementProps) {
  const { attributes, children, element } = props;

  const { workflow } = useContext(WorkflowOnlyContext);

  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const tagListElement = useRef<HTMLDivElement>(null);
  const [tagListOpen, setTagListOpen] = useState<boolean>(false);

  const isValid = !workflow || workflow.source?.sourceFields.find(field => field.fieldName === element.value);

  useLayoutEffect(() => {
    if (!tagListElement.current)
      return;

    if (tagListOpen) {
      const tagDOMRange = ReactEditor.toDOMRange(editor, { anchor: { path, offset: 0 }, focus: { path, offset: 0 } });
      const tagRect = tagDOMRange.getBoundingClientRect();

      tagListElement.current.style.top = (tagRect.top + window.scrollY + 24) + "px";
      tagListElement.current.style.left = (tagRect.left + window.scrollX) + "px";
      tagListElement.current.style.display = "block";
    } else
      tagListElement.current!.style.display = "hidden";
  }, [tagListOpen, editor, path]);

  function handleUpdateTag(newTag: string) {
    setTagListOpen(false);
    Transforms.setNodes(editor, { value: newTag }, { at: path });
  }

  return (
    <span
      {...attributes}
      className={`text-white rounded-sm py-1 cursor-pointer ${isValid ? "bg-blue" : "bg-yellow"}`}
      contentEditable={false}
      onClick={() => setTagListOpen(true)}
    >
      {children}
      <span className="opacity-60 mx-1">{"{{"}</span>
      {element.value}
      <span className="opacity-60 mx-1">{"}}"}</span>
      {tagListOpen && <TemplatedTextEditorTagList tags={workflow?.source?.sourceFields.map(field => field.fieldName) || []} onSelect={handleUpdateTag} onCancel={() => setTagListOpen(false)} ref={tagListElement} />}
    </span>
  );
}

export default TagElement;
