import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { OAuthError } from '../backend';
import { useModal } from '../contexts';
import useScript from '../hooks/useScript';
import useGoogleAccessToken from '../hooks/useGoogleAccessToken';
import { GOOGLE_MIMETYPE_MAP, GOOGLE_VIEW_ID_MAP } from '../utils';
import OAuthModal from './OAuthModal';


const DEFAULT_SCOPES = ["https://www.googleapis.com/auth/drive"];

interface Props {
  className?: string;
  style?: any;
  blockType?: string;
  multipleBlockTypes?: Array<string>;
  disabled?: boolean;
  folder?: boolean;
  displayUploadTab?: boolean;
  displayUploadTabFirst?: boolean;
  scopes?: string[],
  onLoad?: () => void;
  onAuth?: () => void;
  onPicked: (pickerResult: any) => void;
  children: JSX.Element | JSX.Element[];
}

export interface Ref {
  openGooglePicker: () => void;
}

function GooglePickerButton(props: Props, ref: ForwardedRef<Ref>) {
  const { onLoad, onAuth } = props;

  const status = useScript('https://apis.google.com/js/api.js');
  const { data: accessToken, isLoading, isValidating, error: accessTokenError, mutate } = useGoogleAccessToken(props.scopes ?? DEFAULT_SCOPES);

  const [pickerLoaded, setPickerLoaded] = useState<boolean>(false);
  const [onLoadCalled, setOnLoadCalled] = useState(false);

  const { openModal } = useModal();

  useEffect(() => {
    if (status !== "ready")
      return;

    // @ts-ignore
    const gapi = window.gapi;

    gapi.load('picker',
      () => setPickerLoaded(true)
    );
  }, [status]);

  useEffect(() => {
    if (!onLoad)
      return;

    if (pickerLoaded && !onLoadCalled && !isLoading && !isValidating) {
      onLoad();
      setOnLoadCalled(true)
    }
  }, [pickerLoaded, onLoad, onLoadCalled, isLoading, isValidating]);

  const openGooglePicker = useCallback(() => {
    if (accessTokenError && accessTokenError instanceof OAuthError)
      return openModal(<OAuthModal oauthUrl={accessTokenError.oauthUrl} onClose={() => mutate().then(onAuth)} />);

    if (props.disabled || !pickerLoaded || !accessToken)
      return;

    // @ts-ignore
    const google = window.google;

    function handlePickerSelection(pickerResult: any) {
      if (pickerResult.action === 'picked')
        props.onPicked(pickerResult.docs[0]);
    }

    const picker = new google.picker.PickerBuilder()
      .setAppId(process.env.REACT_APP_GOOGLE_APP_ID)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
      .setOAuthToken(accessToken)
      .setSize(document.body.clientWidth, document.body.clientHeight)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .setCallback(handlePickerSelection)

    if (props.displayUploadTab && props.displayUploadTabFirst)
      picker.addView(new google.picker.DocsUploadView());

    const blockTypes = props.multipleBlockTypes ?? (props.blockType ? [props.blockType] : null);

    if (blockTypes) {
      for (const blockType of blockTypes) {
        const docsView = new google.picker.DocsView(google.picker.ViewId[GOOGLE_VIEW_ID_MAP[blockType]])
        docsView.setMode(google.picker.DocsViewMode.LIST)
        picker.addView(docsView);
      }

      const mimeTypes = blockTypes.map((blockType: string) => GOOGLE_MIMETYPE_MAP[blockType]).join(",");

      const rootView = new google.picker.DocsView()
      rootView.setMode(google.picker.DocsViewMode.LIST)
      rootView.setMimeTypes(mimeTypes)
      rootView.setIncludeFolders(true)
      rootView.setParent("root")
      picker.addView(rootView);

      const sharedView = new google.picker.DocsView()
      sharedView.setMode(google.picker.DocsViewMode.LIST)
      sharedView.setMimeTypes(mimeTypes)
      sharedView.setIncludeFolders(true)
      sharedView.setEnableDrives(true)
      picker.addView(sharedView);

    } else if (props.folder) {
      const rootFolderView = new google.picker.DocsView()
      rootFolderView.setMode(google.picker.DocsViewMode.LIST)
      rootFolderView.setMimeTypes('application/vnd.google-apps.folder')
      rootFolderView.setIncludeFolders(true)
      rootFolderView.setSelectFolderEnabled(true);
      rootFolderView.setParent("root")
      picker.addView(rootFolderView);

      const sharedFolderView = new google.picker.DocsView()
      sharedFolderView.setMode(google.picker.DocsViewMode.LIST)
      sharedFolderView.setMimeTypes('application/vnd.google-apps.folder')
      sharedFolderView.setIncludeFolders(true)
      sharedFolderView.setSelectFolderEnabled(true);
      sharedFolderView.setEnableDrives(true)
      picker.addView(sharedFolderView);
    }

    if (props.displayUploadTab && !props.displayUploadTabFirst)
      picker.addView(new google.picker.DocsUploadView());

    picker.build()
      .setVisible(true);
  }, [props, accessToken, pickerLoaded, openModal, accessTokenError, mutate, onAuth]);

  useImperativeHandle(ref, () => ({
    openGooglePicker
  }), [openGooglePicker]);

  return (
    <button style={props.style} className={props.className} onClick={openGooglePicker} disabled={props.disabled || !pickerLoaded}>
      {props.children}
    </button>
  );
}

export default forwardRef(GooglePickerButton);
