import { CheckCircleIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { postData } from "../backend";
import useUser from "../hooks/useUser";
import Avatar from "./Avatar";

function SettingsReferrals() {
  const { user } = useUser();

  const [email, setEmail] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [sentReferrals, setSentReferrals] = useState<Array<string>>([]);

  function handleCopied() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }

  function handleSendReferralEmail() {
    setIsSending(true);

    postData(`/auth/referral-email/`, { email })
      .finally(() => {
        setIsSending(false);

        setSentReferrals(a => [...a, email]);
        setEmail("");
      });
  }

  return (
    <div className="flex flex-col p-8 h-[calc(100vh-8rem)]">
      <div className="font-gilroy font-semibold text-lg mb-4">
        Referrals and Credits
      </div>
      <div className="mb-4">
        Get <span className="font-semibold text-lg">$10</span> in credit for every person you refer to Portant
      </div>
      <div className="mb-8 text-sm w-3/4">
        You will receive credit when the person you invite signs in to Portant with their Google email. You can redeem this credit to upgrade to a Pro or Teams subscription. Referring someone below will not add them to your team or share any workflows/documents with them. If you would like to collaborate with someone, upgrade to a Teams plan.
      </div>

      <div className="flex flex-col pb-8 w-full">
        <div className="font-gilroy font-bold mb-2">Referral Link</div>
        <div className="flex items-center gap-2">
          <input type="text" className="w-full lg:w-[600px] text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2" value={user?.referralUrl} onClick={e => e.currentTarget.select()} readOnly />
          <CopyToClipboard text={user!.referralUrl} onCopy={handleCopied}>
            <button className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm">
              <ClipboardCopyIcon className="h-4 w-4 group-hover:text-blue" />
              <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
                {isCopied ? "Copied" : "Copy to clipboard"}
              </div>
            </button>
          </CopyToClipboard>
        </div>
      </div>

      <div className="flex flex-col pb-8 w-full">
        <div className="font-gilroy font-bold mb-2">Refer by Email</div>
        <div className="flex items-center gap-2">
          <input id="referral-email-input" type="text" className="w-full text-sm text-gray-600 border rounded-sm outline-none h-8 px-2" value={email} onChange={e => setEmail(e.currentTarget.value)} onKeyDown={e => { if (e.key === "Enter") handleSendReferralEmail() }} />
          <button id="referral-send-button" className="btn btn-blue text-xs h-full px-8 py-0" onClick={handleSendReferralEmail} disabled={isSending || !email || !email.includes("@")}>
            {isSending ? "Sending..." : "Send"}
          </button>
        </div>
        <div className="flex flex-col">
          {sentReferrals.map(email =>
            <div key={email} className="flex items-center text-sm mt-1 gap-1">
              <CheckCircleIcon className="w-4 h-4 text-green" /> Referral email has been sent to <span className="font-semibold">{email}</span>
            </div>
          )}
        </div>
      </div>

      {user?.referredBy && (
        <div className="flex flex-col pb-8 w-full">
          <div className="font-gilroy font-bold mb-2">You Were Referred by</div>
          <div className="flex items-center gap-2 h-8">
            <Avatar url={user.referredBy.avatar} className="h-8" />
            <div className="flex flex-col">
              <div className="font-gilroy text-gray-600 font-semibold">
                {user.referredBy.fullName}
              </div>
              <div className="font-gilroy text-gray-400 font-semibold text-xs">
                {moment(user.dateJoined).fromNow()}
              </div>
            </div>
          </div>
        </div>
      )}

      {user!.referredUsers.length > 0 && (
        <>
          <div className="font-gilroy font-bold mb-2">Your Referrals</div>
          <ul className="flex flex-col gap-6 overflow-auto max-w-2xl min-h-[40px]">
            {user!.referredUsers.map(referredUser =>
              <div key={referredUser.id} className="flex items-center gap-2 h-8 w-[600px]">
                <Avatar url={referredUser.avatar} className="h-8" />
                <div className="flex flex-col">
                  <div className="font-gilroy text-gray-600 font-semibold">
                    {referredUser.fullName}
                  </div>
                  <div className="font-gilroy text-gray-400 font-semibold text-xs">
                    {moment(referredUser.dateJoined).fromNow()}
                  </div>
                </div>
                <div className="text-green font-gilroy font-semibold text-lg ml-auto">
                  + $10
                </div>
              </div>
            )}
          </ul>
          <div className="text-gray-600 font-gilroy font-semibold text-lg mt-8 w-[600px] text-right">
            Total Credits: ${user!.referredUsers.length * 10}
          </div>
        </>
      )}

    </div>
  );
}

export default SettingsReferrals;
