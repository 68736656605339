import { useContext } from "react";
import { WorkflowContext } from "../contexts";
import ToggleButton from "./ToggleButton";

interface Props {
  text: string;
}

function WorkflowAutomationPanelWebhook(props: Props) {
  const { workflow, updateAutoCreate } = useContext(WorkflowContext);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between font-gilroy font-semibold text-sm">
        Auto-Create
        <ToggleButton value={workflow.autoCreate} onChange={updateAutoCreate} bgColor="bg-green" />
      </div>

      <div className="text-xs text-gray-600">
        {props.text}
      </div>
    </div>
  );
}

export default WorkflowAutomationPanelWebhook;
