import { Link, useLocation } from "react-router-dom";


interface Props {
  icon: JSX.Element;
  name: string
  href: string;
}

function SideBarItem(props: Props) {
  const location = useLocation();

  const path = location.pathname.split('/');
  const sidebarHref = props.href.split('/')

  // Compares the current route location with the route provided in props.href to determine if the sidebarItem is active
  // It takes care of nested routes, e.g. regardless if on "/settings/" or "/settings/profile", it will still show the sidebarItem as active
  const isActive =
    (path[1] !== '' && path[1] === sidebarHref[1]) ||
    // A specific case for workflows route as the workflow route can be "/" or "/w/{workflowId}"
    ((props.href === '/' || props.href === '') && (path[1] === '' || path[1] === 'w'))
  return (
    <Link to={props.href} className={`group relative flex flex-row items-center p-4 cursor-pointer text-gray-600 border-l-4 ${isActive ? 'bg-gray-100 border-blue' : ' hover:bg-gray-100 border-white hover:border-gray-100'} w-16 h-16`}>
      {props.icon}
      <div className="hidden group-hover:block absolute bg-black text-white text-xs text-center left-12 w-20 px-2 py-2 rounded z-50">
        {props.name}
      </div>
    </Link>
  );
};

export default SideBarItem;
