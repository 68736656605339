import { SparklesIcon } from "@heroicons/react/outline";

function AIDocumentBuilderPrompt(props: { handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void }) {
  const { handleSubmit } = props;

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <div className="mt-36">
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Documents drafted with AI
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          No credit card required
        </p>
      </div>
      <form onSubmit={handleSubmit} className="flex gap-4 p-3 border-[3px] border-gray-200 rounded-lg w-[600px]">
        <input
          type="text"
          id="prompt-input"
          className="flex flex-grow px-4 focus-visible:outline-none"
          placeholder="Describe a document in a sentence or two"
          maxLength={250}
        />
        <button
          className="btn btn-blue flex gap-2 rounded-lg"
          type="submit"
        >
          <SparklesIcon className="h-7 w-7 text-white" /> Generate
        </button>
      </form>
    </div>
  );
}

export default AIDocumentBuilderPrompt;
