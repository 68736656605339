import escapeHtml from "escape-html";
import { Editor, Text, Element, Transforms, Descendant, Range, Path, Node as NodeType } from "slate";
import { jsx } from "slate-hyperscript";

import { Alignment, Image, Link, Tag } from "./interfaces";


export type Mark = "bold" | "italic" | "underline";

export function isMarkActive(editor: Editor, mark: Mark) {
  const marks = Editor.marks(editor);
  return marks ? marks[mark] === true : false;
}

export function toggleMark(editor: Editor, mark: Mark) {
  const isActive = isMarkActive(editor, mark);

  if (isActive)
    Editor.removeMark(editor, mark);
  else
    Editor.addMark(editor, mark, true);
}

function elementHasAlignment(element: Element, alignmentType: Alignment) {
  switch (element.type) {
    case "bulleted-list":
    case "numbered-list":
    case "paragraph":
      return element.align === alignmentType;
    default:
      return false;
  }
}

export function isAlignmentActive(editor: Editor, alignmentType: Alignment) {
  const { selection } = editor;
  if (!selection)
    return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: node =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        elementHasAlignment(node, alignmentType)
    })
  );

  return !!match;
}

export function toggleAlignment(editor: Editor, alignmentType: Alignment) {
  const isActive = isAlignmentActive(editor, alignmentType);
  Transforms.setNodes(editor, { align: isActive ? undefined : alignmentType });
}

export type List = "bulleted-list" | "numbered-list";

function isListElement(element: Element) {
  const listElements = ["bulleted-list", "numbered-list"];
  return listElements.includes(element.type);
}

export function isListActive(editor: Editor, listType: List) {
  const { selection } = editor;
  if (!selection)
    return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: node =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        node.type === listType
    })
  );

  return !!match;
}

export function toggleList(editor: Editor, listType: List) {
  const isActive = isListActive(editor, listType);

  Transforms.unwrapNodes(editor, {
    match: node =>
      !Editor.isEditor(node) &&
      Element.isElement(node) &&
      isListElement(node),
    split: true
  });

  Transforms.setNodes(editor, { type: isActive ? "paragraph" : "list-item" });

  if (!isActive)
    Transforms.wrapNodes(editor, { type: listType, children: [] });
}

export function serializeToHtml(node: NodeType): string {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if (node.bold)
      string = `<b>${string}</b>`;
    if (node.italic)
      string = `<i>${string}</i>`;
    if (node.underline)
      string = `<u>${string}</u>`;

    return string;
  }

  const children = node.children.map(node => serializeToHtml(node)).join("");

  if (Editor.isEditor(node))
    return children;

  switch (node.type) {
    case "bulleted-list":
      return `<ul${node.align ? ` style="text-align: ${node.align};"` : ""}>${children}</ul>`;
    case "numbered-list":
      return `<ol${node.align ? ` style="text-align: ${node.align};"` : ""}>${children}</ol>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "paragraph":
      return `<p${node.align ? ` style="text-align: ${node.align};"` : ""}>${children}</p>`;
    case "tag":
      return `<span class="tag">{{${node.value}}}</span>`;
    case "image":
      return `<img style="max-width: 600px;" src="${node.fromDrive ? `cid:${node.url}` : node.url}"${node.size === "small" ? ' width="200px"' : ""} />`;
    case "link":
      return `<a href="${node.url}">${children}</a>`
    default:
      return children;
  }
}

export function serializeToText(node: NodeType): string {
  if (Text.isText(node))
    return node.text;

  if (Editor.isEditor(node))
    return node.children.map(node => serializeToText(node)).join("\n");

  switch (node.type) {
    case "paragraph":
      return node.children.map(node => serializeToText(node)).join("");
    case "tag":
      return `{{${node.value}}}`;
    default:
      return node.children.map(node => serializeToText(node)).join("\n");
  }
}

export function deserializeFromHtml(input: string) {
  return _deserialize(htmlToString(input), {});
}

function htmlToString(input: string): HTMLElement {
  return new DOMParser().parseFromString(input, "text/html").body;
}

type MarkAttributes = { bold?: boolean, italic?: boolean, underline?: boolean };

function _deserialize(el: HTMLElement, markAttributes: MarkAttributes = {}): any {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case "B":
      nodeAttributes.bold = true;
      break;
    case "I":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map(node => _deserialize(node as HTMLElement, nodeAttributes))
    .flat();

  if (children.length === 0 && !["UL", "OL"].includes(el.nodeName))
    children.push(jsx("text", nodeAttributes, ""));

  const align = el.style.textAlign;

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "UL":
      return jsx("element", { type: "bulleted-list", ...(align && { align }) }, children);
    case "OL":
      return jsx("element", { type: "numbered-list", ...(align && { align }) }, children);
    case "LI":
      return jsx("element", { type: "list-item" }, children);
    case "P":
      return jsx("element", { type: "paragraph", ...(align && { align }) }, children);
    case "SPAN":
      if (el.classList.contains("tag")) {
        const tag = el.innerHTML.replace("{{", "").replace("}}", "");
        return jsx("element", { type: "tag", value: tag }, [{ text: "" }]);
      }
      break;
    case "IMG":
      let url = el.getAttribute("src") as string;

      const fromDrive = (url.startsWith("{{") && url.endsWith("}}")) || url.startsWith("cid:");
      url = fromDrive ? url.replace("{{", "").replace("}}", "").replace("cid:", "") : url;

      let width = el.getAttribute("width");

      return jsx("element", {
        type: "image",
        url,
        ...(fromDrive && { fromDrive }),
        size: width === "200" ? "small" : "original"
      }, children);
    case "A":
      const href = el.getAttribute("href") as string;
      return jsx("element", { type: "link", url: href }, children);
    default:
      return children;
  }
}

export function deserializeFromText(input: string): Descendant[] {
  if (input.length === 0)
    return jsx("fragment", {}, jsx("element", { type: "paragraph" }, [{ text: "" }]));

  const children: any[] = [];

  // Force manual conversion to template tags
  const tagMatches = input.matchAll(/\{\{(?<tag>.+?)\}\}/g);
  let lastMatchEndIndex = 0;
  for (const match of tagMatches) {
    const tag = match.groups!.tag;

    const start = match.index!;
    const end = start + tag.length + "{{}}".length;

    // Check if text before current match
    if (start - lastMatchEndIndex > 0)
      children.push(jsx("text", {}, input.slice(lastMatchEndIndex, start)));

    children.push(jsx("element", { type: "tag", value: tag }, [{ text: "" }]));

    lastMatchEndIndex = end;
  }

  // Check if any final text
  if (lastMatchEndIndex !== input.length)
    children.push(jsx("text", {}, input.slice(lastMatchEndIndex)));

  return jsx("fragment", {}, jsx("element", { type: "paragraph" }, children));
}

export function insertImage(editor: Editor, url: string, fromDrive?: boolean) {
  const image: Image = { type: "image", url, ...(fromDrive && { fromDrive }), size: "original", children: [{ text: "" }] };

  Transforms.insertNodes(editor, image);
}

export function insertLink(editor: Editor, url: string, displayText: string): void {
  if (editor.selection)
    wrapLink(editor, url, displayText);
}

export function insertTag(editor: Editor, tag: string) {
  const tagNode: Tag = { type: "tag", value: tag, children: [{ text: "" }] };

  Transforms.insertNodes(editor, [tagNode, { text: " " }]);
}

function isLinkActive(editor: Editor): boolean {
  const [link] = Editor.nodes(editor, {
    match: n => Element.isElement(n) && n.type === "link"
  });

  return !!link;
}

export function unwrapLink(editor: Editor): void {
  Transforms.unwrapNodes(editor, {
    match: n => Element.isElement(n) && n.type === "link"
  });
}

export function wrapLink(editor: Editor, url: string, displayText: string) {
  if (isLinkActive(editor))
    unwrapLink(editor);

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link: Link = { type: "link", url, children: isCollapsed ? [{ text: displayText || url }] : [{ text: "" }] };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [link, { text: "" }]);
    Transforms.collapse(editor, { edge: "end" });
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
    Transforms.move(editor, { distance: 1, unit: "offset" });
  }
}

export function wrapTag(editor: Editor, childPath: Path, startOffset: number, endOffset: number, tag: string, isEndOfLine: boolean) {
  Transforms.insertNodes(
    editor,
    [{ type: "tag", value: tag, children: [{ text: "" }] }, { text: " " }],
    { at: { anchor: { path: childPath, offset: startOffset }, focus: { path: childPath, offset: endOffset } }, select: isEndOfLine }
  );
}
