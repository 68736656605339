import { Transition } from "@headlessui/react";
import { useContext } from "react";
import { WorkflowContext } from "../../contexts";

function TransitionWorkflowPageSideBar(props: { children: React.ReactNode }) {
  const { showAutomationPanel } = useContext(WorkflowContext);

  return (
    <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
      enter="transition ease-in-out duration-150 transform"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-150 transform"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      {props.children}
    </Transition.Child>
  );
}

export default TransitionWorkflowPageSideBar;
