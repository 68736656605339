import { Link, useHistory } from "react-router-dom";
import OptionsButton from "./OptionsButton";
import { useModal } from "../contexts";
import LoadingModal from "./LoadingModal";
import { postData } from "../backend";
import { TEMPLATE_ROUTES_MAP } from "../utils";
import ErrorModal from "./ErrorModal";
import { ReactNode } from "react";

type Props = {
  templateOptions: { label: ReactNode, value: string }[]
}

function ConversionTemplatePicker(props: Props) {
  const history = useHistory();
  const { openModal, closeModal } = useModal();
  const { templateOptions } = props;

  const handleTemplate = async (option: keyof typeof TEMPLATE_ROUTES_MAP) => {
    if (option in TEMPLATE_ROUTES_MAP) {
      openModal(<LoadingModal title="Creating Workflow from Template..." subtitle="This may take some time as we create the template documents In your Google&nbsp;Drive." />);
      try {
        const workflow = await postData(`/templates/${TEMPLATE_ROUTES_MAP[option]}/duplicate/`);
        history.push(`/w/${workflow.id}?tour`);
        closeModal();
      } catch (error) {
        openModal(<ErrorModal details={error} />)
      }
    }
  }

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          What would you like to create?
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          Choose a template and we'll help you get started
        </p>
      </div>

      <div className="max-w-3xl">
        <OptionsButton
          onChange={(selected) => handleTemplate(selected[0] as keyof typeof TEMPLATE_ROUTES_MAP)}
          className="flex !flex-row flex-wrap gap-0 justify-between gap-y-4"
          optionClassName="font-bold text-gray-700 hover:bg-blue-50 border-none p-9 transition-colors text-lg w-[49%] justify-center"
          options={templateOptions}
        />
      </div>
      <div className="flex items-center w-full max-w-sm">
        <div className="w-full h-0.5 bg-gray-200" />
        <p className="mx-2 text-gray-400 text-center font-bold">or</p>
        <div className="w-full h-0.5 bg-gray-200" />
      </div>
      <Link className="text-blue font-bold" to="/hubspot-from-scratch">
        <button> Start from scratch </button>
      </Link>
    </>
  );
}

export default ConversionTemplatePicker;
