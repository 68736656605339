import { useContext } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP, ORDINAL_MAP, RECIPIENT_COLOUR_MAP } from "../utils";
import { CheckIcon, ExclamationCircleIcon, TrashIcon, UserCircleIcon } from "@heroicons/react/outline";
import WorkflowModalRemoveBlock from "./WorkflowModalRemoveBlock";
import { deleteData } from "../backend";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoadingModal from "./LoadingModal";

function WorkflowOverviewBlockSignatureRequest() {
  const { openModal, closeModal } = useModal();

  const { hasPermission } = useUser();
  const { mutateWorkflow } = useContext(WorkflowContext);

  const { workflow } = useContext(WorkflowContext);
  const signatureConfig = workflow.signatureConfig!;

  const isValidBlock = (() => {
    for (let n = 1; n <= signatureConfig.numRecipients; n++) {
      // @ts-ignore
      if (signatureConfig["recipientEmail" + n].length === 0)
        return false;
    }

    return workflow.documents.filter(d => d.isSignable).length >= 1;
  })();

  async function removeBlock(force = false) {
    if (!force)
      return openModal(<WorkflowModalRemoveBlock message="All signable documents within this workflows will no longer be signable." onConfirm={() => removeBlock(true)} />);

    try {
      openModal(<LoadingModal title="Deleting Block" subtitle="Please wait as this may take a moment." />);
      await deleteData(`/signatures/signature-configs/${workflow.signatureConfig!.id}/`)
    } catch (error) {
      console.error(error)
    } finally {
      await mutateWorkflow()
      closeModal();
    };
  }

  const history = useHistory();
  const { url } = useRouteMatch();

  function openWorkflowPage() {
    history.push(url + "/signature-request")
  }

  return (
    <div className="relative flex flex-shrink-0 flex-col items-center justify-start border border-gray-300 cursor-pointer group rounded shadow w-[600px] mb-5 pb-4" onClick={openWorkflowPage}>
      <div className={`relative flex flex-shrink-0 items-center justify-start px-8 py-4 bg-white border-b border-gray-300 shadow w-full rounded-t`}>
        {isValidBlock
          ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
          : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
        }
        <img src={BLOCK_ICON_MAP["SIGNATURE_REQUEST"]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
        <div className="flex flex-col w-full overflow-hidden mr-4">
          <div className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
            Signature Request Email
          </div>
          <div className="flex gap-2 font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-500">
            Email Recipients to Complete Signable Documents
          </div>
        </div>
        {hasPermission(workflow.team, "workflows.edit") &&
          <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={(e) => { e.stopPropagation(); removeBlock() }}>
            <TrashIcon className="w-4 h-4" />
          </div>
        }
      </div>

      {signatureConfig.generatePreviewRequest &&
        <div className="flex items-end w-full px-10">
          <div className='w-1 h-20 mb-5 -mt-7 bg-gray-300 rounded-bl' />
          <div className='h-1 w-10 mb-5 -mt-7 bg-gray-300' />
          <div className="flex flex-col items-start justify-start px-4 py-2 bg-white border-b border-gray-300 rounded shadow w-[400px]">
            <div className={`text-xs font-gilroy font-semibold text-gray-400`}>
              Preview
            </div>
            <div className={`text-sm font-semibold text-gray-600`}>
              Generate shareable link to view document(s)
            </div>
          </div>
        </div>
      }

      {[...Array(signatureConfig.numRecipients)].map((_, index: number) => {
        const recipientNumber = index + 1;

        // @ts-ignore
        const recipientEmail: string = signatureConfig["recipientEmail" + recipientNumber];
        const hubspotContactLabel = recipientEmail.match(/{{__hscontact_(.+?)(?::(.+))?}}/)

        return (
          <div key={recipientNumber} className="flex items-end w-full px-10">
            <div className='w-1 h-20 mb-5 -mt-7 bg-gray-300 rounded-bl' />
            <div className='h-1 w-10 mb-5 -mt-7 bg-gray-300' />
            <div className="flex flex-col items-start justify-start px-4 py-2 bg-white border-b border-gray-300 rounded shadow w-[400px]">
              <div className={`text-xs font-gilroy font-semibold ${RECIPIENT_COLOUR_MAP[recipientNumber][0]}`}>
                {ORDINAL_MAP[recipientNumber]} Recipient
              </div>
              <div className={`text-sm font-semibold ${!!recipientEmail ? "text-gray-600" : "text-yellow"}`}>
                {hubspotContactLabel
                  ? <div className="flex items-center"><UserCircleIcon className="h-4 mr-1 text-[#6e839a]" />{hubspotContactLabel[2] || "Contact"}</div>
                  : recipientEmail || "Please provide a valid email or {{Template Tag}}"}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default WorkflowOverviewBlockSignatureRequest;
