import { useState } from 'react';
import { deleteData, patchData, postData } from '../backend';
import { useModal } from '../contexts';
import Modal from './Modal';
import useUser from '../hooks/useUser';
import TextInputField from './TextInputField';

function SettingsModalGlide() {
  const { user, refreshUser } = useUser();
  const { closeModal } = useModal();

  const [authToken, setAuthToken] = useState<string>(user?.glideCredentials?.glideAuthToken ?? "");

  async function handleDeleteGlideCredentials() {
    if (user?.glideCredentials?.id) {
      await deleteData(`/glide/credentials/${user.glideCredentials.id}/`)
        .catch(console.error);

      await refreshUser();
    }

    closeModal();
  }

  async function handleUpdateGlideCredentials() {
    if (!authToken)
      return await handleDeleteGlideCredentials();

    if (user?.glideCredentials?.id) {
      await patchData(`/glide/credentials/${user?.glideCredentials?.id}/`, { glideAuthToken: authToken })
        .catch(console.error);

    } else {
      await postData("/glide/credentials/", { glideAuthToken: authToken })
        .catch(console.error);
    }

    await refreshUser();

    closeModal();
  }

  return (
    <Modal
      title="Update Glide Auth Token"
      primaryButtonProps={{ text: "Update", onClick: handleUpdateGlideCredentials, disabled: !authToken }}
      secondaryButtonProps={{ text: "Delete", onClick: handleDeleteGlideCredentials, className: "btn-red" }}
      size='sm'
    >
      <div className="text-gray-600 text-sm">Please follow our <a href="https://www.portant.co/guide-article/how-to-connect-a-glide-data-source" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">Glide Integration Guide</a> to configure this field.</div>
      <TextInputField className='mt-4 w-full' label="Auth Token" value={authToken} onChange={newAuthToken => setAuthToken(newAuthToken)} />
    </Modal>
  );
}

export default SettingsModalGlide;
