import useSWRInfinite from "swr/infinite";

import { getData } from "../backend";
import { HubspotPrimaryObject } from "../interfaces";

interface HubspotPriamryObjectResponse {
  paging: {
    next: {
      after: string,
      link: string
    }
  } | null,
  results: {
    id: string
  }[],
  total: number
}

export const HUBSPOT_PRIMARY_OBJECT_PATHS = {
  "DEAL": "deals",
  "CONTACT": "contacts",
  "COMPANY": "companies",
  "TICKET": "tickets",
  "MEETING": "meetings",
}

function getKey(path: string, pageIndex: number, previousPage?: HubspotPriamryObjectResponse, queryParams?: Record<string, string | undefined>) {
  if (previousPage && !previousPage.paging) // No more pages left
    return null;

  // @ts-ignore filtering for non-falsey values ensures all values are of type String
  const filteredQueryParams: Record<string, string> = Object.fromEntries(
    Object.entries(queryParams ?? {}).filter(([k, v]) => !!v)
  )

  const params = new URLSearchParams(filteredQueryParams);
  params.set("after", previousPage?.paging?.next.after ?? "0")

  return `/hubspot/${path}/?${params.toString()}`;
}

export default function useHubspotPrimaryObjects(object: HubspotPrimaryObject, queryParams?: Record<string, string | undefined>) {
  const path = HUBSPOT_PRIMARY_OBJECT_PATHS[object];

  return useSWRInfinite<HubspotPriamryObjectResponse>(
    (pageIndex, previousPage) => getKey(path, pageIndex, previousPage, queryParams),
    getData,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateFirstPage: false
    });
}
