import { deleteData } from '../backend';
import { useModal } from '../contexts';
import { BLOCK_ICON_MAP } from '../utils';
import Modal from './Modal';
import useUser from '../hooks/useUser';

function SettingsModalHubspot(props: { onDisconnect?: () => void }) {
  const { refreshUser } = useUser();
  const { closeModal } = useModal();

  async function handleDeletHubspotCredentials() {
    await deleteData("/hubspot/credentials/")
      .catch(console.error);

    props.onDisconnect?.();
    refreshUser();
    closeModal();
  }

  return (
    <Modal
      primaryButtonProps={{ text: "Disconnect", className: "btn-red", onClick: handleDeletHubspotCredentials }}
      className='relative justify-start'
      size='sm'
    >
      <div className="flex items-center gap-2">
        <img className="h-10 w-10" src={BLOCK_ICON_MAP["HUBSPOT"]} alt="Google Sheets Icon" />
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold">
            HubSpot
          </div>
          <div className="text-gray-600 text-sm" onClick={handleDeletHubspotCredentials}>Portant currently has access to your data in HubSpot</div>
        </div>
      </div>
    </Modal>
  );
}

export default SettingsModalHubspot;
