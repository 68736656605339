import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { postData, useResource } from "../backend";
import { SubscriptionTier } from "../interfaces";
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline";
import useUser from "../hooks/useUser";

interface Props {
  isShown: boolean;
  setIsShown: (f: boolean) => void;
}

function PayWallModalTrial(props: Props) {
  const { user } = useUser();

  const proTier: SubscriptionTier | null = useResource("/billing/tiers/pro/");
  const teamsTier: SubscriptionTier | null = useResource("/billing/tiers/teams/");

  const [isLoadingPro, setIsLoadingPro] = useState(false);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);

  const [dontShowAgain, setDontShowAgain] = useState(false);

  async function openProCheckoutPage() {
    if (isLoadingPro || !proTier)
      return;

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'click_upgrade', 'upgrade_tier': proTier.slug });

    setIsLoadingPro(true);

    const stripePriceId = proTier.stripeMonthlyPriceId;
    await postData(`/billing/tiers/${proTier.slug}/checkout/`, { stripePriceId })
      .then(data => window.open(data.url, "_blank"))
      .catch(console.error);

    setIsLoadingPro(false);
  }

  async function openTeamsCheckoutPage() {
    if (isLoadingTeams || !teamsTier)
      return;

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'click_upgrade', 'upgrade_tier': teamsTier.slug });

    setIsLoadingTeams(true);

    const stripePriceId = teamsTier.stripeMonthlyPriceId;
    await postData(`/billing/tiers/${teamsTier.slug}/checkout/`, { stripePriceId })
      .then(data => window.open(data.url, "_blank"))
      .catch(console.error);

    setIsLoadingTeams(false);
  }

  function explicitCloseModal() {
    props.setIsShown(false);

    if (dontShowAgain)
      localStorage.setItem("dontShowUpgradeModal", "true")
  }

  return (
    <Transition show={props.isShown} className="fixed z-50">
      <Dialog onClose={() => props.setIsShown(false)} className="z-50">

        <Transition.Child
          enter="transition duration-100 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-100 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          as={Fragment}>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-20" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center z-30">

          <Transition.Child
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            as={Fragment}>

            <Dialog.Panel className="relative">
              <div className="flex flex-col gap-4 bg-white p-8 rounded shadow w-[600px]">
                <div className="text-xl font-gilroy font-semibold ">
                  Upgrade to keep using premium features that supercharge your workflows
                </div>
                <XIcon className="absolute w-4 h-4 right-4 top-4 cursor-pointer text-gray-400 hover:text-gray-600" onClick={explicitCloseModal} />

                <hr />

                <div className="flex gap-4">

                  <div className="flex flex-col gap-1 w-full">
                    <div className="font-gilroy font-semibold">
                      Pro
                    </div>

                    <div className="flex items-center">
                      <div className="flex items-start flex-wrap flex-shrink-0">
                        <div className="text-sm">$</div>
                        {!!proTier
                          ? <div className="text-3xl font-semibold">{String(Math.floor(proTier.stripeMonthlyPrice / 100))}<span className="text-xs ml-1 mt-auto mb-1">/month (USD)</span></div>
                          : <div className="text-3xl font-semibold loading-ellipse" />
                        }
                      </div>
                    </div>

                    <button className="btn btn-blue w-full" onClick={openProCheckoutPage}>
                      {isLoadingPro ? "Loading..." : "Choose Pro"}
                    </button>

                    <div className="flex flex-col text-sm font-semibold gap-2 mt-2">
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;{proTier ? proTier?.featureDocumentLimit + " documents per month" : <span className="loading-ellipse text-gray-400" />}</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Create PDF documents</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Remove branding from emails and PDFs created with Portant</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Request signatures with Portant from anyone</div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <div className="font-gilroy font-semibold">
                      Teams
                    </div>

                    <div className="flex items-center">
                      <div className="flex items-start flex-wrap flex-shrink-0">
                        <div className="text-sm">$</div>
                        {!!teamsTier
                          ? <div className="text-3xl font-semibold">{String(Math.floor(teamsTier.stripeMonthlyPrice / 100))}<span className="text-xs ml-1 mt-auto mb-1">/month (USD)</span></div>
                          : <div className="text-3xl font-semibold loading-ellipse" />
                        }
                      </div>
                    </div>

                    <button className="btn btn-purple w-full" onClick={openTeamsCheckoutPage}>
                      {isLoadingTeams ? "Loading..." : "Choose Teams"}
                    </button>

                    <div className="flex flex-col text-sm font-semibold gap-2 mt-2">
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;{teamsTier ? teamsTier?.featureDocumentLimit + " documents per month" : <span className="loading-ellipse text-gray-400" />}</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Everything in the Pro tier plus...</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Shared workflows</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Add 5 users per team*</div>
                      <div><span className="text-green">&#9679;</span>&nbsp;&nbsp;Priority customer support</div>
                      <div className="text-xs text-gray-600">
                        *Extra users can be added for $25/month
                      </div>
                    </div>
                  </div>

                </div>

                <Link to="/settings/billing" className="text-center w-full text-sm font-gilroy font-semibold text-gray-600 underline" onClick={() => props.setIsShown(false)}>
                  Compare all plans
                </Link>

                {user?.subscription?.tier === "free" &&
                  <label className="flex items-center font-gilroy text-gray-600 text-xs gap-2 ml-auto select-none cursor-pointer">
                    <input type="checkbox" checked={dontShowAgain} onChange={e => setDontShowAgain(e.currentTarget.checked)} />
                    Dont show me again
                  </label>
                }

              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>

      </Dialog>
    </Transition>
  );
}

export default PayWallModalTrial;
