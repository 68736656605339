import useSWRImmutable from "swr/immutable";
import { postData } from "../backend";
import useUser from "./useUser";

export default function useGoogleAccessToken(scopes: string[]) {
  const { user } = useUser();
  const url = user !== undefined ? "/auth/access-token/" : null; // null to prevent request before user is loaded

  return useSWRImmutable<string>(url, (url: string) => postData(url, { scopes }), {
    refreshInterval: 1000 * 60 * 10, // 10 minutes
    errorRetryCount: 0 // Don't retry - we want to handle errors imperatively
  });
}
