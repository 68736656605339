import { CheckCircleIcon } from "@heroicons/react/outline";
import Modal from "./Modal";
import { useEffect, useLayoutEffect } from "react";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";

function AIDocumentSignUp() {

  const { closeModal } = useModal();
  const { user } = useUser();

  useLayoutEffect(() => {
    // @ts-ignore
    const google = window.google;

    google.accounts.id.prompt();

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        "size": "large",
        "text": "signup_with",
        "theme": "filled_blue",
        "width": "300",
      }
    );
  }, []);

  useEffect(() => {
    if (user)
      return closeModal();
  }, [user, closeModal]);

  return (
    <Modal
      title="You need an account"
      subtitle="You need an account to unlock the following features"
      size='sm'
    >
      <div id="google-signin-prompt" className='relative -left-1.5 hidden' />
      <li className="flex flex-col gap-4">
        <ul className="flex items-center gap-2 text-lg font-gilroy"><CheckCircleIcon className="h-5 w-5 text-purple" /> Save your documents </ul>
        <ul className="flex items-center gap-2 text-lg font-gilroy"><CheckCircleIcon className="h-5 w-5 text-purple" /> Unlimited AI usage </ul>
        <ul className="flex items-center gap-2 text-lg font-gilroy"><CheckCircleIcon className="h-5 w-5 text-purple" /> Export to Google Docs, Word and PDF </ul>
        <ul className="flex items-center gap-2 text-lg font-gilroy"><CheckCircleIcon className="h-5 w-5 text-purple" /> Share with others</ul>
      </li>
      <div id="google-signin-button" className="flex justify-center my-8" />
    </Modal>
  );
}

export default AIDocumentSignUp;
