import { useState } from "react";
import useUser from "../hooks/useUser";
import { ChevronDownIcon, SearchIcon } from "@heroicons/react/outline";
import SmallTextInputField from "./SmallTextInputField";
import { Team } from "../interfaces";
import moment from "moment";
import SettingsSolutionPartnerTeam from "./SettingsSolutionPartnerTeam";
import SettingsSolutionPartnerNewTeamModalContact from "./SettingsSolutionPartnerNewTeamModalContact";
import { useModal } from "../contexts";

type SortingOrder = "ASC" | "DESC";
type SortingField = "NAME" | "OWNER" | "TIER" | "USAGE";

moment.updateLocale(moment.locale(), { invalidDate: "" });

function SettingsSolutionPartner() {
  const { user } = useUser();
  const { openModal } = useModal();

  const [searchValue, setSearchValue] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [sortingField, setSortingField] = useState<SortingField>("NAME");
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>("DESC");

  const sortingMap = {
    "NAME": sortByName,
    "OWNER": sortByName,
    "TIER": sortByName,
    "USAGE": sortByName,
  }

  function toggleSortingField(newField: SortingField) {
    setSortingField(newField as any);
    setSortingOrder(prev => (newField === sortingField && prev === "DESC") ? "ASC" : "DESC");
  }

  function sortByName(a: Team, b: Team) {
    const aStr = a.name.toLowerCase()
    const bStr = b.name.toLowerCase()

    if (aStr < bStr) return sortingOrder === "DESC" ? -1 : 1;
    if (aStr > bStr) return sortingOrder === "DESC" ? 1 : -1;
    return 0;
  }

  if (!user?.teams)
    return null;

  const sortedTeams = user.teams
    .filter((team: Team) => !showSearchBar || !searchValue || (team.name).toLowerCase().includes(searchValue.toLocaleLowerCase()))
    .sort((a: Team, b: Team) => sortingMap[sortingField](a, b))

  return (
    <div className="flex flex-col p-8 w-full">

      <div className="flex items-end">
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold text-lg">
            Solution Partner
          </div>
          <div className="text-gray-400 text-sm font-semibold">
            Manage the teams you have connected to your Portant account
          </div>
        </div>

        <div className="flex items-center ml-auto gap-4">
          <div className='flex items-center relative'>
            <SmallTextInputField className={`transition-all ${showSearchBar ? "w-60 opacity-100" : "w-10 opacity-0 pointer-events-none"}`} value={searchValue} onChange={setSearchValue} placeholder="Search" autoFocus />
            <SearchIcon className={`w-6 h-6 cursor-pointer -ml-8 text-gray-400 hover:text-gray-500 ${showSearchBar ? "z-10" : "z-0"}`} onClick={() => setShowSearchBar(f => !f)} />
          </div>
          {/* TODO: Allow this to be useable for solution partners once the ability to create new teams is added */}
          <button className='btn btn-blue text-sm py-1.5 px-8' onClick={() => openModal(<SettingsSolutionPartnerNewTeamModalContact />)}>
            New Team
          </button>
        </div>
      </div>

      <div className="w-full mt-4 scrollbar-gutter">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-sm font-gilroy text-gray-600 text-left border-b">
              <th className="text-left w-[200px]">
                <div className="flex items-center gap-1 cursor-pointer select-none hover:underline truncate pl-2" onClick={() => toggleSortingField("NAME")}>
                  Team
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "NAME" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </div>
              </th>
              <th className="text-left w-auto">
                <div className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("OWNER")}>
                  Owner
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "OWNER" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </div>
              </th>
              <th className="text-left w-[100px]">
                <div className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("USAGE")}>
                  Tier
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "USAGE" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </div>
              </th>
              <th className="text-left w-[100px]">
                {/* NOTE: No sorting is offered on this field */}
                <div className="select-none cursor-not-allowed">
                  Usage
                </div>
              </th>
              <th className="text-left w-[200px]">
                {/* ... */}
              </th>
            </tr>
            <tr>
            </tr>
          </thead>
        </table>
      </div>

      <div className="overflow-y-scroll h-full scrollbar-visible">
        <table className="table-auto w-full">
          <tbody>
            {sortedTeams.map(team =>
              <SettingsSolutionPartnerTeam key={team.id} team={team} />
            )}
            {sortedTeams.length === 0 &&
              <tr className="w-full">
                <td className="flex justify-center items-center py-8" colSpan={5}>
                  <div className="font-semibold text-gray-400">
                    No results
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="w-full scrollbar-gutter border-t pt-1">
        <table className="table-auto w-full">
          <tbody>
            <tr className="text-sm font-semibold font-gilroy text-gray-600">
              <td className="text-left w-auto">
                <div className="flex gap-1">
                  <div className="text-gray-400">
                    Teams:
                  </div>
                  <div>
                    {user.teams.length}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default SettingsSolutionPartner;
