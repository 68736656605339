import { RefObject } from 'react';
import { OAuthError, postData } from '../backend';
import useUser from '../hooks/useUser';

interface Props {
  className?: string;
  light?: boolean;
  scopes?: Array<string>;
  buttonRef?: RefObject<HTMLButtonElement>;
}

function GoogleLoginButton(props: Props) {
  const { refreshUser } = useUser();

  function getCurrentUserOnFocus() {
    refreshUser();
    window.removeEventListener("focus", getCurrentUserOnFocus);
  }

  async function handleClickSingIn() {
    const scopes = props.scopes ?? [];

    await postData("/auth/access-token/", { scopes })
      .catch(error => {
        if (error instanceof OAuthError)
          window.open(error.oauthUrl);
        else
          console.error(error)
      });

    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  return (
    <div className={props.className}>
      <button ref={props.buttonRef} className={"btn-google " + (props.light && "light")} onClick={handleClickSingIn} />
    </div>
  )
}

export default GoogleLoginButton;
