import { useContext, useState } from "react";
import { WorkflowContext } from "../contexts";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import SmallTextInputField from "./SmallTextInputField";

function WorkflowAutomationPanelCopper() {
  const { automationOptions, updateAutomationOptions } = useContext(WorkflowContext);

  const [automationIsActive, setAutomationIsActive] = useState(false);

  function handleSetCopperId(value: string) {
    updateAutomationOptions({ mode: "COPPER_SOURCE_ITEM_ID", copperSourceItemId: value });
  }

  return (
    <div className="flex flex-col gap-2 select-none">
      <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer">
        Select Copper Opportunity
      </div>
      <div className="flex items-center justify-between text-sm w-full gap-2">
        <SmallTextInputField label="Opportunity ID" className="w-full" placeholder="e.g. 1234" value={automationOptions.copperSourceItemId ?? ""} onChange={handleSetCopperId} autoFocus invalid={!automationOptions.copperSourceItemId} disabled={automationIsActive} />
      </div>

      <WorkflowAutomationPanelManual disabled={!automationOptions.copperSourceItemId} setIsActive={setAutomationIsActive} />
    </div>
  );

}

export default WorkflowAutomationPanelCopper;
