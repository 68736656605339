import { Transition } from "@headlessui/react";
import { ClipboardCopyIcon, DocumentTextIcon, FilterIcon, PencilIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { putData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowSelectModal from "./WorkflowSelectModal";
import WebhookEventTableRow from "./WebhookEventTableRow";
import WorkflowModalWebhookNameChange from "./WorkflowModalWebhookNameChange";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";


function WorkflowSourceZapier() {
  const { openModal } = useModal();

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, showAutomationPanel, mutateWorkflow } = workflowContext;

  const source = workflow.source;
  const events = source?.webhookConfig?.events || [];

  const [isCopied, setIsCopied] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const history = useHistory();
  const { url } = useRouteMatch();

  if (!source || !source.webhookConfig || source.sourceType !== "ZAPIER")
    return null;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;
  const webhookUrl = `${process.env.REACT_APP_WEBHOOK_URL!}/${workflow.source!.webhookConfig!.token}`;

  function handleCopyWebhookUrl() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }

  function handleRefresh() {
    setIsRefreshing(true);
    mutateWorkflow()
      .catch(console.error)
      .finally(() => { setIsRefreshing(false); });
  }

  function handleOpenNameModal() {
    openModal(<WorkflowModalWebhookNameChange source={source!} updateBlock={updateWebhookName} />)
  }

  function updateWebhookName(name: string) {
    return putData(`/workflows/sources/${source!.id}/webhook-config/`, { name })
      .then(() => mutateWorkflow())
      .catch(console.error);
  }

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions && <>
            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} showRefreshSpinning={isRefreshing} disableCopy />

            <div className="flex flex-col gap-1">
              <div className="font-gilroy font-semibold text-gray-600 text-sm">
                Webhook Address
              </div>
              <div className="flex items-center gap-2">
                <input type="text" className="w-full text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2" value={webhookUrl} onClick={e => e.currentTarget.select()} readOnly />
                <CopyToClipboard text={webhookUrl} onCopy={handleCopyWebhookUrl}>
                  <button className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm">
                    <ClipboardCopyIcon className="h-4 w-4 group-hover:text-blue" />
                    <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
                      {isCopied ? "Copied" : "Copy to clipboard"}
                    </div>
                  </button>
                </CopyToClipboard>
              </div>
              <div className="text-gray-600 text-xs">
                For help please checkout our <a href="https://www.portant.co/guide-article/how-to-get-started-with-webhooks" target="_blank" rel="noreferrer" className="text-blue underline">guide</a> on webhooks
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <FilterIcon className="w-4 h-4 mr-2" /> Source Filter Conditions
              </div>
              <div className="flex items-center font-gilroy font-semibold text-sm text-gray-400">
                Only process data that meets certain conditions
              </div>
              <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
              </button>
            </div>
          </>
          }

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Template" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}

        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[source.sourceType]} alt="Zapier Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy">
              <div className="truncate max-w-[400px]">
                {source.webhookConfig?.name || "Untitled Zapier Source"}
              </div>
            </div>
            <div className="flex gap-2 w-full">
              <button className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600 cursor-pointer" onClick={handleOpenNameModal}>
                <PencilIcon className="h-4 mr-1" /> Rename
              </button>
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>

        {events.length > 0 &&
          <div className="w-full h-full border border-gray-400 rounded py-2 overflow-hidden">
            <div className="relative flex items-center border-b px-4 text-sm h-[40px]">
              <div className="w-1/6">Origin</div>
              <div className="w-1/2">Event ID</div>
              <div className="w-1/6">Status</div>
              <div className="w-1/6">Received</div>
              <RefreshIcon className={`absolute right-4 top-2 w-5 h-5 cursor-pointer text-gray-600 hover:text-blue ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={handleRefresh} />
            </div>
            <div className="overflow-y-scroll" style={{ height: "calc(100% - 40px)" }}>
              {events.map((event) => <WebhookEventTableRow key={event.id} event={event} />)}
            </div>
          </div>
        }

        {events.length === 0 &&
          <div className="flex flex-col ml-10 mt-8 overflow-y-auto gap-8">

            <div className="flex flex-col gap-4">

              <div className="font-semibold font-gilroy text-gray-600">
                How to use a Zapier source with Portant
              </div>

              <div className="flex items-center text-sm">
                <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                  1
                </div>
                <span>
                  Open Zapier to <a href="https://help.zapier.com/hc/en-us/articles/8496309697421-Create-Zaps#use-a-blank-zap-0-2" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">create a zap and add a trigger</a>.
                </span>
              </div>

              <div className="flex items-center text-sm">
                <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                  2
                </div>
                <span>
                  Add a Portant action to your zap.
                </span>
              </div>

              <div className="flex items-center text-sm">
                <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                  3
                </div>
                Turn your zap on.
              </div>
            </div>
          </div>
        }

      </Transition.Child>
    </>
  );

}

export default WorkflowSourceZapier;
