import { useContext } from 'react';
import { WorkflowContext } from '../contexts';
import { useModal } from '../contexts';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import WorkflowSelectModal from './WorkflowSelectModal';
import WorkflowOverviewBlockSignatureRequest from './WorkflowOverviewBlockSignatureRequest';
import WorkflowOverviewBlockEmail from './WorkflowOverviewBlockEmail';
import WorkflowOverviewBlockReview from './WorkflowOverviewBlockReview';
import WorkflowOverviewBlockDocument from './WorkflowOverviewBlockDocument';
import WorkflowOverviewBlockSource from './WorkflowOverviewBlockSource';
import WorkflowOverviewBlockSheetsWriteToSource from './WorkflowOverviewBlockSheetsWriteToSource';
import WorkflowOverviewBlockGlideWriteToSource from './WorkflowOverviewBlockGlideWriteToSource';
import WorkflowOverviewBlockOutgoingWebhook from './WorkflowOverviewBlockOutgoingWebhook';
import useUser from '../hooks/useUser';
import WorkflowOverviewBlockPlaceholder from './WorkflowOverviewBlockPlaceholder';
import React from 'react';

interface Props {
  contentOffset: number;
  show: boolean;
}

function WorkflowOverview(props: Props) {
  const { openModal } = useModal();
  const { contentOffset } = props;

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  const showAddConnector = hasPermission(workflow.team, "workflows.edit");

  function handleAddBlock(modalProps: any) {
    openModal(<WorkflowSelectModal title="Add Workflow Block" subtitle="Add a new block to your workflow" {...modalProps} workflowContext={workflowContext} />);
  }

  function Connector(props: { below?: boolean, double?: boolean }) {
    return <div className={`w-1 bg-gray-300 ${props.double ? "h-10" : "h-5"} ${props.below ? "-mt-5" : ""}`} />
  }

  function AddBlockConnector(props: { showDocuments?: boolean, showEmails?: boolean, showReview?: boolean, documentPositionFirst?: boolean, showSignatureRequest?: boolean, showRecordOutputs?: boolean }) {
    if (!showAddConnector) {
      return (
        <div className='flex flex-col items-center -mt-5'>
          <div className='w-1 bg-gray-300 h-5' />
          <button>
            <PlusCircleIcon className='text-gray-300 h-10 -my-1 z-10 cursor-not-allowed' />
          </button>
        </div>
      )
    }

    return (
      <div className='flex flex-col items-center -mt-5'>
        <div className='w-1 bg-gray-300 h-5' />
        <button onClick={() => handleAddBlock(props)}>
          <PlusCircleIcon className='text-gray-300 hover:text-blue h-10 -my-1 z-10 cursor-pointer hover:scale-105 transition-all' />
        </button>
      </div>
    )
  }

  let source: JSX.Element | null = null;
  if (workflow.source)
    source = (
      <div className="flex flex-col items-center">
        <WorkflowOverviewBlockSource block={workflow.source} />
        {(workflow.documents.length === 0 && workflow.emails.length === 0)
          ? <Connector below double={workflow.documents.length === 0} />
          : <AddBlockConnector showDocuments showEmails={workflow.documents.length === 0} documentPositionFirst={true} />
        }
      </div>
    )
  else
    source = (
      <div className="flex flex-col items-center">
        <WorkflowOverviewBlockPlaceholder text="Add source" onClick={() => handleAddBlock({ showSources: true })} />
        <Connector double />
      </div>
    );

  let documents: JSX.Element | null = null;
  if (workflow.documents.length > 0)
    documents = (
      <div className='flex flex-col items-center'>
        <Connector />
        {workflow.documents.map((d, index) => (
          <React.Fragment key={d.id}>
            <WorkflowOverviewBlockDocument key={d.id} block={d} index={index} />
            {(!!workflow.source || index < workflow.documents.length - 1) && <Connector key={`con-${d.id}`} />}
          </React.Fragment>
        ))}
        {!!workflow.source &&
          <AddBlockConnector showDocuments showReview={!workflow.review} showSignatureRequest={!workflow.signatureConfig && !workflow.review} showEmails={!workflow.signatureConfig} documentPositionFirst={false} showRecordOutputs />
        }
      </div>
    )
  else if (workflow.documents.length === 0 && workflow.emails.length === 0)
    documents = (
      <div className="flex flex-col items-center">
        <WorkflowOverviewBlockPlaceholder text="Add template" onClick={() => handleAddBlock({ showDocuments: true, showEmails: true })} />
      </div>
    );


  let review: JSX.Element | null = null;
  if (workflow.review)
    review = (
      <div className='flex flex-col items-center'>
        <Connector double={workflow.documents.length === 0} />
        <WorkflowOverviewBlockReview />
        <AddBlockConnector showEmails showRecordOutputs showSignatureRequest={!workflow.signatureConfig && workflow.documents.length >= 1} />
      </div>
    );

  let signatureRequestBlocks: JSX.Element | null = null;
  if (workflow.signatureConfig)
    signatureRequestBlocks = (
      <div className='flex flex-col items-center'>
        <Connector double={workflow.documents.length === 0 && !workflow.review} />
        <WorkflowOverviewBlockSignatureRequest />
        <div className='group relative flex flex-col items-center -mt-5'>
          <div className='w-1 bg-gray-300 h-5' />
          <div className='absolute top-5 h-8 w-8 bg-white rounded-full' />
          <CheckCircleIcon className='text-green h-10 -my-1 z-10 hover:scale-105 transition-all' onClick={() => handleAddBlock(["SHARING"])} />
          <div className='hidden group-hover:flex absolute top-6 left-12 bg-green text-white text-xs whitespace-nowrap px-1 pt-1 rounded-sm'>
            Once all signature requests are completed
          </div>
        </div>
        {workflow.emails.length === 0 &&
          <>
            <Connector />
            <AddBlockConnector showEmails showRecordOutputs />
          </>
        }
      </div>
    );

  let shareBlocks: JSX.Element | null = null;
  if (workflow.emails.length > 0 || workflow.source?.glideConfig?.isSharingEnabled || workflow.source?.config?.writeToSource || workflow.webhookConfig)
    shareBlocks = (
      <div className='flex flex-col items-center'>
        <Connector />
        {workflow.emails.map((e, index) => (
          <React.Fragment key={e.id}>
            <WorkflowOverviewBlockEmail block={e} index={index} />
            <Connector />
          </React.Fragment>
        ))}
        {workflow.source?.config?.writeToSource &&
          <WorkflowOverviewBlockSheetsWriteToSource />
        }
        {workflow.source?.glideConfig?.isSharingEnabled &&
          <WorkflowOverviewBlockGlideWriteToSource />
        }
        {workflow.webhookConfig &&
          <WorkflowOverviewBlockOutgoingWebhook />
        }
        <AddBlockConnector showEmails showRecordOutputs />
      </div>
    );


  return (
    <Transition show={props.show} className="flex flex-col items-center w-full p-8 pb-20 overflow-auto" style={{ height: `calc(100vh - 56px - ${contentOffset}px)` }}>
      {source}
      {documents}
      {review}
      {signatureRequestBlocks}
      {shareBlocks}
    </Transition>
  );
}

export default WorkflowOverview;
