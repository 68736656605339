import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon, FolderOpenIcon, RefreshIcon } from "@heroicons/react/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import { getData } from "../backend";
import { WorkflowContext } from "../contexts";
import { WorkflowAutomation } from "../interfaces";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import WorkflowAutomationsListItem from "./WorkflowAutomationsListItem";

interface Props {
  contentOffset: number;
}

function WorfklowAutomationsList(props: Props) {
  const { workflow, mutateWorkflow, showAutomationPanel } = useContext(WorkflowContext);
  const [automations, setAutomations] = useState<Array<WorkflowAutomation>>();
  const { pathname } = useLocation();

  const { url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [numPages, setNumPages] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const enabledPrevious = currentPageNumber > 1;
  const enabledNext = currentPageNumber < numPages;

  function handleClickRefresh() {
    setIsRefreshing(true);
    getItems(currentPageNumber)
      .finally(() => setIsRefreshing(false));

    if (!workflow.outputFolder)
      mutateWorkflow();
  }

  function handleChangePage(delta: -1 | 1) {
    const newPageNumber = Math.max(1, Math.min(currentPageNumber + delta, numPages));
    if (newPageNumber === currentPageNumber)
      return;

    setCurrentPageNumber(newPageNumber);
    setIsLoading(true);

    getItems(newPageNumber)
      .finally(() => setIsLoading(false));
  }

  const getItems = useCallback((pageNumber: number) => {
    return getData(`/workflows/${workflow.id}/automations/?page=${pageNumber}`)
      .then(data => {
        const { numPages, results } = data;

        setAutomations(results);
        setNumPages(numPages);
      })
      .catch(console.error);
  }, [workflow.id,]);

  useEffect(() => {
    if (automations)
      return;

    setIsLoading(true);
    getItems(1)
      .then(() => setIsLoading(false));
  }, [automations, getItems]);

  useEffect(() => {
    if (!pathname.endsWith("/automations"))
      return;

    setIsRefreshing(true);
    setCurrentPageNumber(1);

    getItems(1)
      .finally(() => setIsRefreshing(false));

  }, [workflow.id, workflow.outputFolder, getItems, mutateWorkflow, pathname])

  return (
    <Transition show={pathname.endsWith("/automations")} className="absolute top-0 flex flex-col w-full overflow-hidden px-8" style={{ height: `calc(100vh - 56px - ${props.contentOffset}px)` }}>

      <div className="flex items-center gap-4 py-4 w-full">
        <div className="font-gilroy font-semibold text-gray-600">
          Automation History
        </div>
        <button className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 hover:text-blue hover:underline cursor-pointer" onClick={handleClickRefresh}>
          <RefreshIcon className={`h-6 w-6 ${isRefreshing ? "animate-spin" : "animate-none"}`} />
        </button>

        <Link to={url + "/outputs"} className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer ml-auto">
          <FolderOpenIcon className="h-6 w-6 flex-shrink-0" />
          Return to Outputs Page
        </Link>

        {showAutomationPanel &&
          // Offset "Return to Outputs" Button to not be obsecured by the automation panel
          <div className="mr-[300px]" />
        }

      </div>

      <div className="flex flex-col w-full bg-white mb-0 p-4 shadow rounded" style={{ height: `calc(100vh - 132px - ${props.contentOffset}px)` }}>

        {(automations === undefined && isLoading) &&
          <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
            <img src="https://static.portant.co/portant-loading-gray.svg" className="w-24 h-24" alt="Loading Logo" />
            <div className="flex font-gilroy font-semibold text-gray-400">
              Loading automation history...
            </div>
          </div>
        }

        {(automations === undefined && !isLoading) &&
          <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
            <div className="flex font-gilroy font-semibold text-gray-400">
              Unable to load automations
            </div>
          </div>
        }

        {(automations !== undefined && automations.length === 0) &&
          <div className="flex flex-col justify-center items-center gap-1 w-full h-full">
            <div className="flex font-gilroy text-gray-600 font-semibold">
              No automations have been run for this workflow
            </div>
            <div className="text-sm">
              Please run an automation to get started
            </div>
          </div>
        }

        {(automations !== undefined && automations.length > 0) &&
          <div className="flex flex-col h-full w-full">
            <table className="table-fixed w-full">
              <thead className="bg-white border-b-2 border-gray-200">
                <tr className="font-semibold text-xs text-gray-400">
                  <th className="w-6" /> {/* Chevron */}
                  <th className="w-40">Created</th>
                  <th className="w-40">Status</th>
                  <th className="w-auto" /> {/* Spacing */}
                  <th className="w-40">Items Completed</th>
                  <th className="w-2" /> {/* Divider (/) */}
                  <th className="w-40">Items Expected</th>
                  <th className="w-10" />  {/* Kebab menu */}
                  <th className="w-[14px]" />  {/* Scrollbar */}
                </tr>
              </thead>
            </table>
            <div className="h-full overflow-y-scroll scrollbar-visible">
              <table className="table-fixed w-full">
                <tbody>
                  {automations.map(automation =>
                    <WorkflowAutomationsListItem key={automation.id} automation={automation} />
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center items-center gap-2">
              <button className={`flex items-center justify-center w-8 h-8 text-gray-400 ${enabledPrevious ? "hover:text-blue" : "cursor-not-allowed"}`} onClick={() => handleChangePage(-1)}>
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <div className="mt-1 w-6 h-6 text-center font-gilroy font-semibold text-gray-600">
                {currentPageNumber}
              </div>
              <button className={`flex items-center justify-center w-8 h-8 text-gray-400 ${enabledNext ? "hover:text-blue" : "cursor-not-allowed"}`} onClick={() => handleChangePage(+1)}>
                <ChevronRightIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        }
      </div>

    </Transition>
  );

}

export default WorfklowAutomationsList;
