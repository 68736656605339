import { Source, SourceCondition } from "../interfaces";
import { PlusCircleIcon } from "@heroicons/react/outline";
import WorkflowSourceConditionItem from "./WorkflowSourceConditionItem";
import { postData } from "../backend";

interface Props {
  source: Source,
  refreshSource: () => void;
  conditions: Array<SourceCondition>,
  firstGroup: number;
  lastGroup: number;
}

function WorkflowSourceConditionGroup(props: Props) {
  const { source, refreshSource, conditions } = props;

  const group = conditions[0]?.group;
  const lastOrder = conditions.at(-1)?.order;

  function handleAddConditionAnd() {
    const newCondition = { group, order: lastOrder! + 1, comparison: "IS", field: "", value: "" } as SourceCondition;

    postData(`/workflows/sources/${source.id}/conditions/`, newCondition)
      .then(refreshSource)
      .catch(console.error)
  }

  function handleAddConditionOr() {
    const newCondition = { group: props.lastGroup + 1, order: 0, comparison: "IS", field: "", value: "" } as SourceCondition;

    postData(`/workflows/sources/${source.id}/conditions/`, newCondition)
      .then(refreshSource)
      .catch(console.error)
  }

  if (group === undefined || lastOrder === undefined)
    return null;

  return (
    <div className="w-full flex flex-col gap-2 border-b pb-2 last:border-b-0">
      <div className="font-semibold font-gilroy text-sm text-gray-600">
        {group === props.firstGroup ? "Only process workflow when..." : "or..."}
      </div>

      {conditions.map(condition => <WorkflowSourceConditionItem key={condition.id} condition={condition} source={source} refreshSource={refreshSource} />)}

      <div className="w-full flex items-center gap-2">
        <button className="btn btn-white text-xs w-20 px-0 py-1" onClick={handleAddConditionAnd}>
          <PlusCircleIcon className="w-4 h-4 mr-1 text-blue" /> And
        </button>
        {group === props.lastGroup &&
          <button className="btn btn-white text-xs w-20 px-0 py-1" onClick={handleAddConditionOr}>
            <PlusCircleIcon className="w-4 h-4 mr-1 text-blue" /> Or
          </button>
        }
      </div>
    </div>
  )
}

export default WorkflowSourceConditionGroup;
