import useSWR from "swr";
import { getData } from "../backend";
import { WebhookEvent } from "../interfaces";

interface PagingatedWebhookEvent {
  results: Array<WebhookEvent>;
  numPages: number;
  count: number;
}

export default function useWebhookEvents(webhookId: string, pageNumber: number) {
  let key = `/webhooks/webhook-configs/${webhookId}/events/?page=${pageNumber}`;

  const { data, ...rest } = useSWR<PagingatedWebhookEvent>(key, getData, { revalidateOnMount: true });

  if (data)
    return { data, ...rest };
  else
    return { data: { results: undefined, numPages: 0, count: 0, }, ...rest };
}
