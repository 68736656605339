import { ChevronDownIcon, ExclamationIcon } from "@heroicons/react/outline";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { WorkflowContext, useModal } from "../contexts";
import ToggleButton from "./ToggleButton";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import SmallSelectField from "./SmallSelectField";
import { getData, patchData } from "../backend";
import { HubspotConfig } from "../interfaces";
import { Link, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import PayWallModalPro from "./PayWallModalPro";

interface DealStage {
  id: string;
  label: string;
}

interface Pipeline {
  id: string;
  label: string;
  stages: Array<DealStage>;
}

function WorkflowAutomationPanelHubspot() {
  const { workflow, mutateWorkflow, automationOptions, updateAutoCreate } = useContext(WorkflowContext);

  const { user } = useUser();
  const { openModal } = useModal();

  const [showManualAutomation, setShowManualAutomation] = useState(false);

  const [config, setConfig] = useState<HubspotConfig>();
  const [pipelines, setPipelines] = useState<Array<Pipeline>>();

  const { url } = useRouteMatch();

  useEffect(() => {
    setConfig(workflow.source?.hubspotConfig);
  }, [workflow.source?.hubspotConfig]);

  async function handleUpdateAutoCreate(autoCreate: boolean) {
    if (autoCreate && !user?.subscription?.featureHubspotAutocreate)
      return openModal(<PayWallModalPro />);

    updateAutoCreate(autoCreate);
  }


  const updateHubspotSourceConfig = useCallback((updates: any) => {
    setConfig(c => ({ ...c, ...updates }));

    patchData(`/hubspot/config/${workflow.source!.hubspotConfig?.id}/`, updates)
      .then(() => mutateWorkflow())
      .catch(console.error);

  }, [mutateWorkflow, workflow.source])

  useEffect(() => {
    if (!config || config.hubspotPrimaryObject !== "DEAL" || pipelines)
      return;

    getData("/hubspot/pipelines/")
      .then(pipelines => {
        setPipelines(pipelines);

        if (pipelines.length >= 1 && !pipelines.find((p: Pipeline) => p.id === config.notificationPipeline))
          updateHubspotSourceConfig({ notificationPipeline: pipelines[0].id, notificationDealstage: "" })
      })
      .catch(console.error);

  }, [config, pipelines, updateHubspotSourceConfig]);

  const pipelineStages = useMemo<Array<DealStage>>(() => {
    return pipelines?.find(p => p.id === config?.notificationPipeline)?.stages ?? [];
  }, [pipelines, config?.notificationPipeline]);

  return (
    <>
      <div className="flex flex-col gap-2">

        <div className="flex justify-between font-gilroy font-semibold text-sm">
          Auto-Create
          <ToggleButton value={workflow.autoCreate} onChange={handleUpdateAutoCreate} bgColor="bg-green" disabled={config?.hubspotPrimaryObject !== "DEAL"} />
        </div>

        <div className="text-xs text-gray-600">
          Run this workflow when deals are moved into the selected deal stage (only available for deals).

          {workflow.autoCreate &&
            <div className="flex flex-col gap-1 mt-1">
              {((pipelines?.length ?? 0) > 1) &&
                <SmallSelectField value={config!.notificationPipeline} onChange={(notificationPipeline) => updateHubspotSourceConfig({ notificationPipeline, notificationDealstage: "" })} label="Pipeline">
                  {pipelines!.map(p => <option key={p.id} value={p.id}>{p.label}</option>)}
                </SmallSelectField>
              }
              <SmallSelectField value={!!pipelines ? config!.notificationDealstage : ""} onChange={(notificationDealstage) => updateHubspotSourceConfig({ notificationPipeline: config?.notificationPipeline, notificationDealstage })} label="Deal Stage" invalid={config?.notificationDealstage === ""} disabled={pipelines === undefined}>
                <>
                  <option value="" disabled>{pipelines === undefined ? "Loading Pipelines..." : "Please select deal stage"}</option>
                  {pipelineStages.map(ds => <option key={ds.id} value={ds.id}>{ds.label}</option>)}
                </>
              </SmallSelectField>
            </div>
          }
        </div>
      </div>

      <div className="flex items-center gap-2 my-2">
        <div className="h-px bg-gray-400 w-full" />
        <div className="flex-shrink-0 font-gilroy font-semibold text-xs text-gray-600">or</div>
        <div className="h-px bg-gray-400 w-full" />
      </div>

      <div className="flex flex-col gap-2 select-none">
        <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer" onClick={() => setShowManualAutomation(f => !f)}>
          {workflow.autoCreate &&
            <ChevronDownIcon className={`mr-2w-5 h-5 flex-shrink-0 ${showManualAutomation ? "rotate-0" : "-rotate-90"}`} />
          }
          Process Existing Items
        </div>

        {(showManualAutomation || !workflow.autoCreate) &&
          <>
            {(automationOptions.selectedTotal === 0 || !automationOptions.selectedTotal) &&
              <Link to={url + "/source"} className="flex items-center text-sm w-full text-yellow font-semibold hover:underline">
                <ExclamationIcon className="w-4 h-4 mr-2" /> Select HubSpot data from table
              </Link>
            }
            {automationOptions.selectedTotal === 1 &&
              <div className="text-sm w-full text-gray-600 font-semibold">
                Process <b>1</b> selected item
              </div>
            }
            {automationOptions.selectedTotal > 1 &&
              <div className="text-sm w-full text-gray-600 font-semibold">
                Process <b>{automationOptions.selectedTotal}</b> selected items
              </div>
            }

            <WorkflowAutomationPanelManual disabled={automationOptions.selectedTotal === 0 || !automationOptions.selectedTotal} />
          </>
        }
      </div>
    </>
  );

}

export default WorkflowAutomationPanelHubspot;
