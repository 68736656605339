import { ExclamationIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { WorkflowContext } from "../contexts";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";


function WorkflowAutomationPanelXero() {
  const { automationOptions } = useContext(WorkflowContext);

  return (
    <div className="flex flex-col gap-2 select-none">
      <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer">
        Process Existing Items {!!automationOptions?.selectedTotal}
      </div>

      {automationOptions.selectedTotal === 0 &&
        <div className="flex items-center text-sm w-full text-yellow font-semibold">
          <ExclamationIcon className="w-4 h-4 mr-2" /> Select Xero data from table
        </div>
      }
      {automationOptions.selectedTotal === 1 &&
        <div className="text-sm w-full text-gray-600 font-semibold">
          Process <b>1</b> selected item
        </div>
      }
      {automationOptions.selectedTotal > 1 &&
        <div className="text-sm w-full text-gray-600 font-semibold">
          Process <b>{automationOptions.selectedTotal}</b> selected items
        </div>
      }

      <WorkflowAutomationPanelManual disabled={automationOptions.selectedTotal === 0} />
    </div>
  );

}

export default WorkflowAutomationPanelXero;
