import { useState } from "react";
import { postData } from "../backend";
import useUser from "../hooks/useUser";
import TextInputField from "./TextInputField";

type SaveState = "PENDING" | "SAVING" | "SAVED";

function SettingsTeamCreate() {
  const { refreshUser } = useUser();

  const [saveState, setSaveState] = useState<SaveState>("PENDING");
  const [invalid, setInvalid] = useState(false);

  const [newTeamName, setNewTeamName] = useState<string>("");

  function updateTeamName(name: string) {
    setInvalid(!name);
    setNewTeamName(name);
  }

  function handleCreate() {
    if (!newTeamName)
      return setInvalid(true);

    setSaveState("SAVING");
    postData("/auth/team/", { name: newTeamName })
      .then((team) => {
        refreshUser((user: any) => ({ ...user, team }));
      })
      .catch(console.error);
  }

  return (
    <div className="flex flex-col p-8">
      <div className="font-gilroy font-semibold text-lg">
        Team Settings
      </div>
      <div className="text-gray-400 mb-4">
        To get started please enter your team name
      </div>
      <div className="overflow-scroll pr-8 pb-8 pt-4" style={{ width: 800 }}>
        <div className="flex items-end mb-8">
          <div style={{ width: 400 }}>
            <TextInputField className="w-full" label="Team Name" value={newTeamName} onChange={updateTeamName} invalid={invalid} />
          </div>
          <button className="btn btn-blue w-48 ml-auto" onClick={handleCreate} disabled={saveState === "SAVING"}>
            {saveState === "SAVING" ? "Saving..." : saveState === "SAVED" ? "Saved" : "Create Team"}
          </button>
        </div>
        <div className="flex items-end mb-8">
          <div style={{ width: 400 }}>
            <TextInputField className="w-full" label="Invite Team Member" value={""} onChange={() => { }} disabled placeholder="Add a team name first to invite members" />
          </div>
          <button className="btn btn-blue w-48 ml-auto" disabled>
            Invite
          </button>
        </div>
      </div>
    </div>
  );
}

export default SettingsTeamCreate;
