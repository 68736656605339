import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { ButtonHTMLAttributes, ReactNode, useLayoutEffect } from "react";
import { useModal } from "../contexts";

interface CustomButton extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  onClick: () => void;
  text: string | ReactNode;
}

type Props = {
  onClose?: () => void;
  children?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  image?: string;
  primaryButtonProps?: CustomButton;
  secondaryButtonProps?: CustomButton;
  preventClose?: boolean;
  size?: 'sm' | 'lg' | 'xl'
  className?: string;
}

function Modal(props: Props) {
  const { closeModal } = useModal();

  const handleClose = () => {
    if (!props.preventClose) {
      props.onClose?.();
      setTimeout(() => closeModal(), 200);
    }
  }

  const { size = "lg" } = props;
  const { text: primaryText, onClick: primaryOnClick, className: primaryClassName, ...primaryRest } = props.primaryButtonProps ?? {};
  const { text: secondaryText, onClick: secondaryOnClick, className: secondaryClassName, ...secondaryRest } = props.secondaryButtonProps ?? {};

  const sizeClasses = {
    sm: "w-[650px] min-h-[200px]",
    lg: "w-[800px] min-h-[200px]",
    xl: "w-[950px] min-h-[200px]"
  }

  const Title = () => {
    if (typeof props.title === 'string')
      return (
        <Dialog.Title as="h3" className="font-gilroy font-semibold text-xl text-gray-900">
          {props.title}
        </Dialog.Title>
      )

    if (React.isValidElement(props.title))
      return props.title;

    return null;
  }

  const Subtitle = () => {
    if (typeof props.subtitle === 'string') return <p className="font-semibold text-sm text-gray-500"> {props.subtitle} </p>;
    if (React.isValidElement(props.subtitle)) return props.subtitle;

    return null;
  }

  useLayoutEffect(() => {
    function metaEnterHandler(e: KeyboardEvent) {
      if (e.key === 'Enter' && e.metaKey && primaryOnClick)
        primaryOnClick();
    }

    window.addEventListener('keydown', metaEnterHandler);
    return () => {
      window.removeEventListener('keydown', metaEnterHandler);
    };

  }, [primaryOnClick]);

  const isMac = navigator.platform.toLowerCase().indexOf("mac") >= 0;

  return (
    <Transition appear show>
      <Dialog as="div" className="relative z-50 focus:outline-none" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className={`p-8 bg-white rounded-lg shadow-xl relative flex flex-col ${sizeClasses[size] ?? ''}`}>
                {!props.preventClose &&
                  <XIcon
                    className="absolute top-2 right-2 w-6 h-6 text-gray-500 cursor-pointer z-50"
                    onClick={handleClose}
                  />
                }
                <div className="flex flex-grow flex-col">
                  <div className="flex items-center gap-2">
                    {props.image &&
                      <img className="h-10 w-10 rounded" src={props.image} alt="Icon" />
                    }
                    <div className="flex flex-col">
                      <Title />
                      <Subtitle />
                    </div>
                  </div>

                  {props.children && (
                    <div className={`pt-4 flex flex-col gap-4 ${props.className ?? ''}`}>
                      {props.children}
                    </div>
                  )}
                </div>

                {!!(primaryText || secondaryText) && (
                  <div className="mt-8 grid grid-cols-2 gap-4">
                    {!!secondaryText && (
                      <div className="col-start-1 flex flex-col relative items-center mr-auto">
                        <button
                          className={`btn btn-white min-w-[140px] ${secondaryClassName ?? ''}`} onClick={() => { secondaryOnClick?.(); props.onClose?.() }} {...secondaryRest}>
                          {secondaryText}
                        </button>
                        {/* Currently unused */}
                        {/*
                        <div className="text-xs font-semibold font-gilroy text-gray-400 absolute -bottom-5">
                          Esc
                        </div>
                        */}
                      </div>
                    )}
                    {!!primaryText && (
                      <div className="col-start-2 flex flex-col relative items-center ml-auto group">
                        <button className={`btn btn-blue min-w-[140px] ${primaryClassName ?? ''}`} onClick={() => { primaryOnClick?.(); props.onClose?.() }} {...primaryRest}>
                          {primaryText}
                          <div className="text-xs font-semibold font-gilroy text-gray-400 absolute -bottom-5 opacity-0 group-hover:opacity-100 transition-opacity">
                            Shortcut: {isMac ? "⌘" : "Ctrl "} + Enter
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default Modal;
