import { useEffect, useState } from "react";
import { Editor } from "slate";
import { useModal } from "../contexts";
import { insertImage } from "../emailEditorHelpers";
import Modal from "./Modal";
import GooglePickerButton from "./GooglePickerButton";
import SmallTextInputField from "./SmallTextInputField";

interface Props {
  editor: Editor;
}

function WorkflowModalImage(props: Props) {
  const { editor } = props;
  const { closeModal } = useModal();

  const [url, setUrl] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (url && !(url.startsWith("http://") || url.startsWith("https://")))
      setError("The URL is not valid. Make sure that is starts with http:// or https://");
    else
      setError("");
  }, [url])

  function handleInsertDriveImage(file: any) {
    insertImage(editor, file.id, true);
    closeModal();
  }

  function handleInsertImage() {
    insertImage(editor, url, false);
    closeModal();
  }

  return (
    <Modal
      title="Insert Image"
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Insert", onClick: handleInsertImage, disabled: url.length === 0 || error.length > 0 }}
      size="sm"
      className="justify-center"
    >
      <GooglePickerButton onPicked={handleInsertDriveImage} blockType="GOOGLE_IMAGES" displayUploadTab>
        <div className="flex items-center gap-2 text-sm font-gilroy font-semibold text-gray-600 hover:underline hover:text-blue">
          <img src="https://static.portant.co/drive-icon.svg" className="w-5 h-5" alt="Drive icon" />
          Select from Google Drive
        </div>
      </GooglePickerButton>

      <div className='flex items-center justify-between'>
        <div className='bg-gray-400 h-[1px] w-[45%]' />
        <div className='text-gray-400'>or</div>
        <div className='bg-gray-400 h-[1px] w-[45%]' />
      </div>

      <div className="flex flex-col gap-1">
        <SmallTextInputField label="Image URL" placeholder="Paste image URL here" value={url} onChange={value => setUrl(value)} />

        {(url.length > 0 && error.length > 0) &&
          <div className="text-xs text-red font-semibold">{error}</div>
        }

        {(url.length > 0 && error.length === 0) &&
          <img className="max-h-[400px]" src={url} alt="User provided" onError={() => setError("Unable to load image from the provided URL")} onLoad={() => setError("")} />
        }
        {(url.length === 0 && error.length === 0) &&
          <div className="font-semibold text-xs">
            <div className="text-gray-600">
              If your URL is correct, you'll see an image preview here. Remember, using others' images on the web without their permission may be bad manners or worse, copyright infringement.
            </div>
          </div>
        }
      </div>
    </Modal>
  )

}

export default WorkflowModalImage;
