import { useState } from "react";
import { Source, SourceCondition, SourceConditionComparison } from "../interfaces";
import { TrashIcon } from "@heroicons/react/outline";
import SmallSelectField from "./SmallSelectField";
import SmallTextInputField from "./SmallTextInputField";
import { deleteData, putData } from "../backend";
import { HUBSPOT_OBJECT_TYPE_MAP } from "../utils";

interface Props {
  source: Source,
  refreshSource: () => void,
  condition: SourceCondition,
}

function WorkflowSourceConditionItem(props: Props) {
  const { source, refreshSource, condition } = props;

  const [comparison, setComparison] = useState<SourceConditionComparison>(condition.comparison);
  const [field, setField] = useState<string>(condition.field);
  const [value, setValue] = useState<string>(condition.value);

  function handleFieldOnChange(field: string) {
    setField(field);

    return putData(`/workflows/source-conditions/${condition.id}/`, { field, comparison, value })
      .catch(console.error)
  }

  function handleComparisonOnChange(comparison: string) {
    setComparison((comparison as SourceConditionComparison));

    return putData(`/workflows/source-conditions/${condition.id}/`, { field, comparison, value })
      .catch(console.error)
  }

  function handleUpdateValue() {
    return putData(`/workflows/source-conditions/${condition.id}/`, { field, comparison, value })
      .catch(console.error)
  }

  function handleDeleteCondition() {
    deleteData(`/workflows/source-conditions/${condition.id}/`)
      .then(refreshSource)
      .catch(console.error);
  }

  const isValueCondition = ["IS", "IS_NOT"].includes(comparison);

  const sourceFields = (() => {
    if (source.sourceType === "HUBSPOT") {
      const prefix = HUBSPOT_OBJECT_TYPE_MAP[source.hubspotConfig?.hubspotPrimaryObject!];
      return source.sourceFields.filter(field => field.fieldId.startsWith(prefix));
    }

    return source.sourceFields;
  })();

  return (
    <div className="w-full flex items-center gap-1">
      <SmallSelectField className="w-[180px] h-8" value={field} onChange={handleFieldOnChange}>
        {sourceFields.map(field => <option key={field.id} value={field.fieldName}>{field.fieldName}</option>)}
      </SmallSelectField>

      <SmallSelectField className="w-[180px] h-8" value={comparison} onChange={handleComparisonOnChange}>
        <option value="IS">is</option>
        <option value="IS_NOT">is not</option>
        <option value="IS_EMPTY">is empty</option>
        <option value="IS_NOT_EMPTY">is not empty</option>
      </SmallSelectField>

      <SmallTextInputField className="w-[180px] h-8" value={isValueCondition ? value : ""} placeholder={isValueCondition ? "Value" : ""} onChange={value => setValue(value)} onBlur={handleUpdateValue} disabled={!isValueCondition} required={isValueCondition} />

      <button className="relative items-center justify-center group" onClick={handleDeleteCondition}>
        <TrashIcon className="h-5 w-5 text-gray-600 group-hover:text-red" />
        <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-7 right-4 py-1 px-2 z-10 rounded rounded-tr-none">
          Remove condition
        </div>
      </button>
    </div>
  )
}

export default WorkflowSourceConditionItem;
