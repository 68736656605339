import * as HeroIcons from '@heroicons/react/outline'

interface Props {
  icon: string;
  className?: string;
}

function HeroIcon(props: Props) {
  const { ...icons } = HeroIcons;

  let iconName = props.icon;
  if (!iconName.endsWith("Icon"))
    iconName += "Icon";

  // @ts-ignore
  const Icon: JSX.Element = icons[iconName];
  if (!Icon)
    return null;

  let className = "h-6 w-6 text-white";
  if (props.className)
    className = props.className;

  // @ts-ignore
  return <Icon className={className} aria-hidden="true" />;
}

export default HeroIcon;
