import { Transition } from "@headlessui/react";
import { CogIcon, DocumentTextIcon, ExternalLinkIcon, FilterIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { OAuthError, putData } from "../backend";
import { WorkflowContext, useModal } from "../contexts";
import { HubspotPrimaryObject } from "../interfaces";
import { BLOCK_ICON_MAP, HUBSPOT_PRIMARY_OBJECT_ID_MAP, capitalize } from "../utils";
import { useDebounce } from "../hooks/useDebounce";
import useHubspotContactLists from "../hooks/useHubspotContactLists";
import useHubspotPrimaryObjects from "../hooks/useHubspotPrimaryObjects";
import SelectField from "./SelectField";
import SmallTextInputField from "./SmallTextInputField";
import WorkflowModalHubspotFields from "./WorkflowModalHubspotFields";
import WorkflowSelectModal from "./WorkflowSelectModal";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import WorkflowSourceFieldTableHubSpot from "./WorkflowSourceFieldTableHubSpot";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import WorkflowModalHubspotConfirmation from "./WorkflowModalHubspotConfirmation";
import ToggleButton from "./ToggleButton";

const PRIMARY_OBJECT_SETTINGS = {
  "DEAL": {
    path: "deals", // Path in the API to fetch objects: <api>/hubpsot/<path>/
    columns: ["Deal Name", "Deal Stage", "Close Date", "Amount"], // Column headers for table
    columnObjectKey: ["dealname", "dealstage", "closedate", "amount"], // Column header to object property
    propertyType: ["string", "string", "date", "currency"], // Data type for sorting function
  },
  "CONTACT": {
    path: "contacts",
    columns: ["Contact Name", "Email", "Company", "Lead Status"],
    columnObjectKey: ["fullname", "email", "company", "hsLeadStatus"],
    propertyType: ["string", "string", "string", "string"],
  },
  "COMPANY": {
    path: "companies",
    columns: ["Company Name", "Industry", "Country", "Type"],
    columnObjectKey: ["name", "industry", "country", "type"],
    propertyType: ["string", "string", "string", "string"],
  },
  "TICKET": {
    path: "tickets",
    columns: ["Ticket Name", "Category", "Create date", "Priority"],
    columnObjectKey: ["subject", "hsTicketCategory", "createdate", "hsTicketPriority"],
    propertyType: ["string", "string", "date", "string"],
  },
  "MEETING": {
    path: "meetings",
    columns: ["Meeting Name", "Date", "Location", "Outcome"],
    columnObjectKey: ["hsMeetingTitle", "hsTimestamp", "hsMeetingLocation", "hsMeetingOutcome"],
    propertyType: ["string", "date", "string", "string"],
  },
}


function WorkflowSourceHubspot() {
  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, showAutomationPanel, automationOptions, updateAutomationOptions, mutateWorkflow } = workflowContext;

  const history = useHistory();
  const { url } = useRouteMatch();

  const source = workflow.source;

  const [primaryObject, setPrimaryObject] = useState<HubspotPrimaryObject>(source!.hubspotConfig!.hubspotPrimaryObject ?? "DEAL");
  const primaryObjectSettings = PRIMARY_OBJECT_SETTINGS[primaryObject];

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const [selectAllOnPage, setSelectAllOnPage] = useState(false);
  const [selectTotalRecords, setSelectTotalRecords] = useState(false);

  const [selectedContactList, setSelectedContactList] = useState<string>();
  const [selectedObjectIds, setSelectedObjectIds] = useState<Record<string, boolean>>(formatObjectIds());

  const { data: contactLists, isLoading: isContactListsLoading, mutate: mutateContactLists } = useHubspotContactLists(primaryObject);

  const { data, isLoading, isValidating, mutate: mutateObjects, setSize, error } = useHubspotPrimaryObjects(primaryObject, {
    search: debouncedSearch, list: selectedContactList
  });

  const hubspotObjects: Array<any> = data?.flatMap(page => page.results) ?? [];
  const objectTotal = data?.[0]?.total ?? 0;
  const objectCount = hubspotObjects.length;
  const hasMoreObjects = objectCount < objectTotal;

  const primaryObjectId = HUBSPOT_PRIMARY_OBJECT_ID_MAP[workflow.source?.hubspotConfig!.hubspotPrimaryObject ?? "DEAL"];

  useEffect(() => {
    if (primaryObject !== "CONTACT")
      setSelectedContactList(undefined);
  }, [primaryObject]);

  useEffect(() => {
    const selectedAll = selectAllOnPage && selectTotalRecords;
    const blanks = { sourceItemList: undefined, sourceItemIds: undefined }

    if (selectedAll) {
      if (selectedContactList)
        return updateAutomationOptions({ mode: "SOURCE_ITEM_LIST", ...blanks, sourceItemList: selectedContactList, selectedTotal: objectTotal });
      else
        return updateAutomationOptions({ mode: "ALL", ...blanks, selectedTotal: objectTotal });
    }
    const sourceItemIds = Object.entries(selectedObjectIds).filter(([k, v]) => v).map(([k, v]) => k);
    updateAutomationOptions({ mode: "SOURCE_ITEM_IDS", ...blanks, sourceItemIds, selectedTotal: sourceItemIds.length });

  }, [selectedObjectIds, selectTotalRecords, selectAllOnPage, search, selectedContactList, updateAutomationOptions, objectTotal]);

  if (!source)
    return null;

  function formatObjectIds() {
    let ids: Record<string, boolean> = {};
    if (!automationOptions.sourceItemIds)
      return ids;

    automationOptions.sourceItemIds.forEach((objectId: string) => {
      ids[objectId] = true;
    })
    return ids;
  }

  function updatePrimaryObject(object: HubspotPrimaryObject) {
    openModal(<WorkflowModalHubspotConfirmation object={object} onConfirm={confirmPrimaryObject} />);
  }

  function confirmPrimaryObject(object: HubspotPrimaryObject) {
    clearSelection();
    setPrimaryObject(object);

    const updatedSource = { ...source!.hubspotConfig, hubspotPrimaryObject: object };

    setIsRefreshing(true);
    putData(`/hubspot/config/${source!.hubspotConfig!.id}/`, updatedSource)
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setIsRefreshing(false));
  }

  function toggleshowInHubspot(showInHubspot: boolean) {
    const updatedConfig = { ...source!.hubspotConfig!, showInHubspot };
    mutateWorkflow({ ...workflow, source: { ...workflow.source!, hubspotConfig: updatedConfig } }, { revalidate: false });

    setIsRefreshing(true);
    putData(`/hubspot/config/${source!.hubspotConfig!.id}/`, updatedConfig)
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setIsRefreshing(false));
  }

  function refreshObjects() {
    clearSelection();

    mutateObjects();
    mutateContactLists();
  }

  function clearSelection() {
    setSelectAllOnPage(false);
    setSelectTotalRecords(false);

    setSelectedObjectIds({});
  }

  function handleChangeSearch(value: string) {
    setSearch(value);
  }

  function handleToggleObjectSelected(selected: boolean, objectId: string) {
    setSelectedObjectIds(existing => ({ ...existing, [objectId]: selected }));

    setSelectAllOnPage(false);
    setSelectTotalRecords(false);
  }

  function handleSelectAllOnPage(value: boolean) {
    setSelectAllOnPage(value);

    if (!value)
      setSelectTotalRecords(false);

    const updatedSelectedIds = Object.fromEntries(hubspotObjects.map(object => ([object.id, value])));
    setSelectedObjectIds(updatedSelectedIds)
  }

  function handleContactListOnChange(event: ChangeEvent<HTMLSelectElement>) {
    clearSelection();

    const selectedContactList = event.currentTarget.value;
    setSelectedContactList(selectedContactList === "all" ? undefined : selectedContactList);
  }

  function formatProperty(property: string, index: number) {
    const propertyType = primaryObjectSettings.propertyType[index];

    if (propertyType === "date")
      return new Date(property).toDateString();
    else if (propertyType === "currency")
      return `$${property}`
    else
      return property
  }

  const showAddTemplate = workflow.documents.length === 0 && workflow.emails.length === 0;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          <SelectField label="Selected Object" value={primaryObject ?? "DEAL"} onChange={value => updatePrimaryObject(value as HubspotPrimaryObject)}>
            <option value="DEAL">Deal</option>
            <option value="CONTACT">Contact</option>
            <option value="COMPANY">Company</option>
            <option value="TICKET">Ticket</option>
            <option value="MEETING">Meeting</option>
          </SelectField>
          {!showAddTemplate &&
            <>
              <div className="flex justify-between -my-2">
                <button className="flex items-center gap-1 text-sm text-center text-gray-600 font-gilroy font-semibold cursor-pointer hover:text-blue underline" onClick={() => openModal(<WorkflowModalHubspotFields hubspotConfig={source.hubspotConfig!} mutateWorkflow={mutateWorkflow} setIsRefreshing={setIsRefreshing} />)}><CogIcon className="w-4 h-4" /> Manage HubSpot Fields</button>
                <a className="flex items-center gap-1 text-sm text-center text-gray-600 font-gilroy font-semibold cursor-pointer hover:text-blue underline" href="https://www.portant.co/guide-article/how-to-connect-a-hubspot-data-source" target="_blank" rel="noreferrer">Learn more</a>
              </div>

              {/* NOTE: Currently unused */}
              {/* <WorkflowSourceHubspotLabeledContacts /> */}

              <WorkflowSourceFieldTableHubSpot workflow={workflow} mutateWorkflow={mutateWorkflow} showRefreshSpinning={isRefreshing} disableCopy />

              <div className="flex flex-col">
                <div className="flex items-center font-gilroy font-semibold text-gray-600">
                  <FilterIcon className="w-4 h-4 mr-2" /> Source Filter Conditions
                </div>
                <div className="flex items-center font-gilroy font-semibold text-sm text-gray-400">
                  Only process data that meets certain conditions
                </div>
                {(source!.sourceConditions.length > 0) &&
                  <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600">
                    <div>Filtering by <span className="font-semibold">{source!.sourceConditions.length}</span> condition{source!.sourceConditions.length === 1 ? "" : "s"}</div>
                  </div>
                }
                <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                  {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
                </button>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Show in HubSpot
                </div>
                <div className='flex items-center gap-2'>
                  <ToggleButton value={!!source?.hubspotConfig?.showInHubspot} onChange={toggleshowInHubspot} />
                  <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                    Show this workflow in the "Run Workflow" actions menu inside HubSpot
                  </div>
                </div>
              </div>
            </>
          }

          {showAddTemplate &&
            <>
              <div className="flex flex-col gap-4 justify-center h-full">
                <div className="flex items-center">
                  <DocumentTextIcon className="w-6 h-6 mr-2" />
                  <div className="font-gilroy font-semibold text-lg">
                    Connect a Template
                  </div>
                </div>
                <div className="flex flex-col gap-2 font-semibold text-gray-600">
                  Create documents and presentations from a template
                  <div className="flex items-center gap-2">
                    <div className="h-px bg-gray-600 w-full" />
                    <div className="flex-shrink-0">or</div>
                    <div className="h-px bg-gray-600 w-full" />
                  </div>
                  Create and send emails from a templated&nbsp;draft
                </div>
                <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Template" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                  Connect
                </button>
              </div>
            </>
          }
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 rounded-md" src={BLOCK_ICON_MAP["HUBSPOT"]} alt="HubSpot Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="text-gray-400 font-gilroy font-semibold text-sm">
              External Data Source
            </div>
            <div className="flex items-center w-full font-gilroy font-semibold text-gray-600 cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(`https://app.hubspot.com/contacts/${user?.hubspotCredentials?.hubspotPortalId}/objects/${primaryObjectId}`, "_blank")}>
              HubSpot {capitalize(primaryObjectSettings.path)}
              <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1" />
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 text-xs hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>

        <div className="flex flex-col mt-2 gap-4 overflow-hidden border border-gray-200 rounded h-full pt-2 px-1">
          <div className="flex flex-col gap-2 w-full overflow-y-auto">

            <div className="flex gap-2 items-center ml-4">
              <span className="font-gilroy font-semibold">Select {capitalize(primaryObject)} Data</span>

              {(objectTotal > 0) &&
                <span className="font-gilroy text-sm text-gray-600">Showing {objectCount} of {objectTotal} results</span>
              }

              <div className="flex items-center gap-2 ml-auto">
                <RefreshIcon className={`w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ${(isLoading || isValidating) ? "animate-spin" : "animate-none"}`} onClick={refreshObjects} />

                {primaryObject === "CONTACT" &&
                  <select value={selectedContactList ?? (!isContactListsLoading && contactLists ? "all" : "none")} onChange={handleContactListOnChange} className="border-2 rounded-md py-[6px] px-2 w-60 font-gilroy outline-none text-sm border-gray-400">
                    {isContactListsLoading && <option value="none">Loading contact lists...</option>}
                    {(contactLists?.length ?? 0) > 0
                      ? <>
                        <option key="all" value="all">All contacts</option>
                        {contactLists?.map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
                      </>
                      : <option value="none">No contact lists found</option>
                    }
                  </select>
                }
                <SmallTextInputField className="w-60" value={search} placeholder={`Search ${primaryObjectSettings.path}...`} onChange={handleChangeSearch} disabled={primaryObject === "CONTACT" && !!selectedContactList} />
              </div>
            </div>

            <div className="w-full overflow-y-auto relative">
              <table className="w-full">
                <thead>
                  <tr className="text-left">
                    <th className="py-1 sticky top-0 bg-gray-100">
                      <div className="flex justify-center items-center">
                        <input type="checkbox" checked={selectAllOnPage} onChange={e => handleSelectAllOnPage(e.currentTarget.checked)} />
                      </div>
                    </th>

                    {primaryObjectSettings.columns.map((column, index) =>
                      <th key={column} className="p-1 font-semibold sticky top-0 bg-gray-100">
                        <div className="flex gap-2 items-center">
                          {column}
                        </div>
                      </th>
                    )}

                  </tr>
                </thead>
                <tbody>
                  {(selectAllOnPage && hasMoreObjects) &&
                    <tr>
                      <td colSpan={5} className="text-sm p-2 sticky top-8 bg-white">
                        {selectTotalRecords
                          ? <>All {objectTotal} records selected. <button className="text-blue underline" onClick={clearSelection}>Clear selection.</button></>
                          : <>All {Object.entries(selectedObjectIds).length} records currently showing are selected. <button className="text-blue underline" onClick={() => setSelectTotalRecords(true)}>Select all {objectTotal} records.</button></>
                        }
                      </td>
                    </tr>
                  }

                  {isLoading && hubspotObjects.length === 0 &&
                    <tr>
                      <td colSpan={5} className="text-center p-2">
                        Fetching {primaryObjectSettings.path}...
                      </td>
                    </tr>
                  }

                  {hubspotObjects.length > 0 &&
                    <>
                      {hubspotObjects
                        .map(object =>
                          <tr key={object.id} className={`${selectedObjectIds[object.id] ? "bg-blue-50" : "bg-white"} text-sm hover:bg-blue-50/50 cursor-pointer`} onClick={() => handleToggleObjectSelected(!selectedObjectIds[object.id], object.id)}>
                            <td className="py-1"><div className="flex justify-center items-center"><input type="checkbox" checked={selectedObjectIds[object.id] ?? false} onChange={(e) => handleToggleObjectSelected(e.currentTarget.checked, object.id)} /></div></td>
                            {/* @ts-ignore */}
                            <td className="py-1">{formatProperty(object.properties[primaryObjectSettings.columnObjectKey[0]] ?? "-", 0)}</td>
                            {/* @ts-ignore */}
                            <td className="py-1">{formatProperty(object.properties[primaryObjectSettings.columnObjectKey[1]] ?? "-", 1)}</td>
                            {/* @ts-ignore */}
                            <td className="py-1">{formatProperty(object.properties[primaryObjectSettings.columnObjectKey[2]] ?? "-", 2)}</td>
                            {/* @ts-ignore */}
                            <td className="py-1">{formatProperty(object.properties[primaryObjectSettings.columnObjectKey[3]] ?? "-", 3)}</td>
                          </tr>
                        )}
                      {hasMoreObjects &&
                        <tr className="sticky -bottom-px">
                          <td colSpan={5}>
                            <div className="flex justify-center items-center py-2 bg-gradient-to-t from-white gradient">
                              <button className="btn btn-blue shadow" disabled={isLoading || isValidating} onClick={() => setSize(s => s + 1)}>Load more</button>
                            </div>
                          </td>
                        </tr>
                      }
                    </>
                  }

                  {(!isLoading && hubspotObjects.length <= 0 && !error) &&
                    <tr>
                      <td colSpan={5} className="text-center p-2">
                        No {primaryObjectSettings.path} found.
                      </td>
                    </tr>
                  }

                  {!isLoading && (error instanceof OAuthError) &&
                    <tr>
                      <td colSpan={5} className="text-center p-2">
                        It looks like your credentials have expired. Please reconnect to HubSpot via the <a href="/settings/permissions" target="_blank" className="text-blue underline">permissions settings</a>.
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Transition.Child>

    </>
  );

}

export default WorkflowSourceHubspot;
