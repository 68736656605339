import { Link } from 'react-router-dom';
import useUser from '../hooks/useUser';

function Page404() {
  const { user } = useUser();
  if (user === undefined)
    return <div key="loading" className='m-auto'><img src="https://static.portant.co/portant-loading-gray.svg" className="w-48 h-48 rounded-lg" alt="Loading Logo" /></div>;

  return (
    <div className="flex flex-col justify-start-start items-center w-full text-center text-gray-600 my-auto pt-5 text-sm lg:text-lg" style={{ height: "300px" }}>
      <div className="font-gilroy font-bold mb-1">Page Not Found</div>
      <div className="font-gilroy font-semibold mb-8">
        The page you're looking for could not be found.
      </div>
      <Link className="btn btn-blue" to="/">
        Return to Homepage
      </Link>
    </div>
  );
}

export default Page404;
