function Loading() {
  return (
    <div className="relative flex flex-col h-screen font-sans">
      <div id="google-signin-prompt" />
      <div className='flex flex-row items-center justify-between p-2 bg-white text-blue shadow h-14 z-10'>
        <div className="flex flex-shrink-0">
          <img className='h-8 ml-2' src="https://static.portant.co/logo-full.svg" alt='Portant Logo' />
        </div>
      </div>
      <div className='flex w-full bg-gray-100' style={{ height: "calc(100vh - 56px)" }}>
        {/* Sidebar */}
        <div className='flex flex-col w-16 h-full bg-white' />
        <div className='m-auto w-24 h-24'>
          <img src="https://static.portant.co/portant-loading-white.svg" alt="Loading Logo" />
        </div>
      </div>
    </div>
  )
}

export default Loading;
