import { useContext, useState } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import { CheckIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { patchData } from "../backend";
import WorkflowOverviewBlockWriteToSourceModal from "./WorkflowOverviewBlockSheetsWriteToSourceModal";
import useUser from "../hooks/useUser";
import LoadingModal from "./LoadingModal";

function WorkflowOverviewBlockSheetsWriteToSource() {
  const [showBlockModal, setShowBlockModal] = useState(false);
  const { openModal, closeModal } = useModal();

  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const isValidBlock = workflow.source?.config.writeToSource.length > 0;

  function openWorkflowPage() {
    return setShowBlockModal(true);
  }

  async function removeBlock(e: React.MouseEvent) {
    e.stopPropagation();

    const { writeToSource, ...newConfig } = workflow.source!.config;

    try {
      openModal(<LoadingModal title="Deleting Block" subtitle="Please wait as this may take a moment." />);
      await patchData(`/workflows/sources/${workflow.source!.id}/`, { config: newConfig });
    } catch (error) {
      console.error(error);
    } finally {
      await mutateWorkflow();
      closeModal();
    }
  }

  return (
    <>
      <button className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={openWorkflowPage}>
        {isValidBlock
          ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
          : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
        }
        <img src={BLOCK_ICON_MAP["GOOGLE_SHEETS"]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
        <div className="flex flex-col w-full overflow-hidden mr-4">
          <div className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
            Write Links to Source
          </div>
          <div className="flex gap-2 items-center font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-500">
            {!isValidBlock &&
              <div className="text-yellow">Incomplete</div>
            }
            {isValidBlock ? "Save document links back to the source" : "Select documents to save back to the source"}
          </div>
        </div>
        {hasPermission(workflow.team, "workflows.edit") &&
          <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={removeBlock}>
            <TrashIcon className="w-4 h-4" />
          </div>
        }
      </button>
      <WorkflowOverviewBlockWriteToSourceModal open={showBlockModal} setOpen={setShowBlockModal} />
    </>
  );
}

export default WorkflowOverviewBlockSheetsWriteToSource;
