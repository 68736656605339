import { useState, useEffect } from 'react';
import useUser from "../hooks/useUser";
import { useModal } from "../contexts";
import CreateTeamModal from './CreateTeamModal';
import ConnectTeamModal from './ConnectTeamModal';
import { IntegrationType } from '../interfaces';

function LaunchManager() {
  const { user } = useUser();
  const { openModal } = useModal();

  const [hasChecked, setHasChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!user || hasChecked)
      return;

    setHasChecked(true);

    if (user.subscription?.featureTeams && !user.activeTeam)
      return openModal(<CreateTeamModal />);

    if (!user.activeTeam)
      return;

    const missingIntegrations = (() => {
      let missing: Array<IntegrationType> = [];
      for (const integration of user.activeTeam.teamIntegrations) {
        if (!user.userIntegrations.find(i => i === integration))
          missing.push(integration);
      }
      return missing;
    })();

    for (const integration of user.activeTeam.teamIntegrations) {
      if (!user.userIntegrations.find(i => i === integration))
        return openModal(<ConnectTeamModal integrations={missingIntegrations} />);
    }

  }, [user, hasChecked, openModal])

  return null;
}

export default LaunchManager;
