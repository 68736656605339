import { useLayoutEffect, useState } from "react";
import useScript from "../hooks/useScript";

interface Props {
  src: string;
  filename: string;
  className: string;
  saveCallback?: (content: Blob) => Promise<any>;
}

function AdobePDFViewer(props: Props) {

  const adobeStatus = useScript("https://acrobatservices.adobe.com/view-sdk/viewer.js");
  const [adobeDCView, setAdobeDCView] = useState<any>(null);

  useLayoutEffect(() => {
    if (adobeStatus !== "ready" || adobeDCView !== null)
      return;

    // @ts-ignore
    const AdobeDC = window.AdobeDC;

    if (AdobeDC) {
      const view = new AdobeDC.View({ clientId: process.env.REACT_APP_ADOBE_CLIENT_ID, divId: "adobe-dc-view" });
      setAdobeDCView(view);

    } else {
      document.addEventListener("adobe_dc_view_sdk.ready", () => {
        // @ts-ignore
        const view = new window.AdobeDC.View({ clientId: process.env.REACT_APP_ADOBE_CLIENT_ID, divId: "adobe-dc-view" });
        setAdobeDCView(view);
      });
    }

  }, [adobeStatus, adobeDCView]);

  useLayoutEffect(() => {
    if (!adobeDCView || !props.src || !props.filename)
      return;

    // @ts-ignore
    const AdobeDC = window.AdobeDC;

    adobeDCView.previewFile(
      {
        content: { location: { url: props.src } },
        metaData: { fileName: props.filename },
      },
      {
        embedMode: "FULL_WINDOW",
        defaultViewMode: "FIT_WIDTH",
        enableFormFilling: true,
        showDownloadPDF: false,
        showPrintPDF: false,
        showAnnotationTools: false,
        showFullScreen: false,
        showThumbnails: false,
        showBookmarks: false,
        showZoomControl: false,
      });

    if (props.saveCallback) {
      adobeDCView.registerCallback("SAVE_API", function (metaData: any, content: any, options: any) {
        return props.saveCallback!(content)
          .then(() => ({ code: AdobeDC.View.Enum.ApiResponseCode.SUCCESS, data: { metaData: { fileName: props.filename } } }));
      }, {
        autoSaveFrequency: 5,
        enableFocusPolling: true,
        showSaveButton: true,
      });
    }

  }, [adobeDCView, props.src, props.filename, props.saveCallback]);

  return <div id="adobe-dc-view" className={props.className} />

}

export default AdobePDFViewer;
