import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import SettingsModalGlide from "./SettingsModalGlide";
import SettingsModalXero from "./SettingsModalXero";
import SettingsModalHubspot from "./SettingsModalHubspot";
import SettingsModalMicrosoft from "./SettingsModalMicrosoft";
import OAuthModalHubspot from "./OAuthModalHubspot";
import LoadingModal from "./LoadingModal";
import SettingsModalCopper from "./SettingsModalCopper";
import OAuthModalCopper from "./OAuthModalCopper";
import SettingsPermissionsItem from "./SettingsPermissionsItem";
import SettingsPermissionsGoogle from "./SettingsPermissionsGoogle";
import SettingsModalStripe from "./SettingsModalStripe";

export type IntegrationSettings = {
  title: string;
  description: string;
  onConnectClick: (callback?: () => void) => void;
  onManageClick: (callback?: () => void) => void;
  isBeta?: boolean;
}

function SettingsPermissions() {
  const { openModal } = useModal();
  const { user, refreshUser } = useUser({ revalidateOnFocus: false });

  const integrationsData: IntegrationSettings[] = [
    {
      title: 'HubSpot',
      description: 'Portant will have access to read your Deal, Company, and Contact data.',
      onConnectClick: openHubspotModal,
      onManageClick(onDisconnect) { openModal(<SettingsModalHubspot onDisconnect={onDisconnect} />) }
    },
    {
      title: 'Copper',
      description: 'Portant will have access to read your Copper CRM opportunities data.',
      onConnectClick: openCopperModal,
      onManageClick(onDisconnect) { openModal(<SettingsModalCopper onDisconnect={onDisconnect} />) }
    },
    {
      title: 'Microsoft',
      description: 'Portant will have access to your OneDrive for Business and Outlook.',
      onConnectClick: connectToMicrosoft,
      onManageClick(onDisconnect) { openModal(<SettingsModalMicrosoft onDisconnect={onDisconnect} />) },
    },
    {
      title: 'Glide',
      description: 'Portant will have access to write data back to your nominated table in Glide.',
      onConnectClick() { openModal(<SettingsModalGlide />) },
      onManageClick() { openModal(<SettingsModalGlide />) }
    },
    {
      title: 'Xero',
      description: 'Portant will have access to read your Invoice data.',
      onConnectClick() { openModal(<SettingsModalXero />) },
      onManageClick() { openModal(<SettingsModalXero />) }
    },
    {
      title: 'Stripe',
      description: 'Allow Portant to collect payments on your behalf via Stripe Connect.',
      onConnectClick() { openModal(<SettingsModalStripe />) },
      onManageClick() { openModal(<SettingsModalStripe />) }
    },
    // NOTE: Zapier is not yet implemented
    // {
    //   title: 'Zapier',
    //   description: 'Zapier will be able to access your Portant workflows.',
    //   connectClickAction() { openModal(<SettingsModalZapier />) },
    //   manageClickAction() { openModal(<SettingsModalZapier />) }
    // },
  ]

  async function openHubspotModal(onConnect?: () => void) {
    openModal(<LoadingModal />)

    const { url } = await postData("/hubspot/install/");
    openModal(<OAuthModalHubspot oauthUrl={url} onClose={onConnect} />);
  }

  async function openCopperModal(onConnect?: () => void) {
    openModal(<LoadingModal />)
    try {
      await postData("/copper/access-token/");
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(
          <OAuthModalCopper
            oauthUrl={error.oauthUrl}
            onClose={() => {
              onConnect?.();
              refreshUser();
            }}
          />
        );
    }
  }

  async function connectToMicrosoft(onConnect?: () => void) {
    const { url } = await postData("/microsoft/install/");
    window.open(url, "_blank");
    onConnect?.();
  }

  return (
    <div className="flex flex-col p-8 overflow-y-auto">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="font-gilroy font-semibold text-lg">
            Permissions
          </h3>
          <p className="text-gray-400 mb-8">
            Manage services of which you have provided Portant access to
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-8 w-full">
        <div className="font-gilroy font-semibold -mb-6">Gmail</div>
        <SettingsPermissionsGoogle scope="https://www.googleapis.com/auth/gmail.send" />

        <div className="font-gilroy font-semibold -mb-6">Google Drive</div>
        <SettingsPermissionsGoogle scope="https://www.googleapis.com/auth/drive.file" />
        <SettingsPermissionsGoogle scope="https://www.googleapis.com/auth/drive" />

        <div className="font-gilroy font-semibold -mb-6">Integrations</div>
        {integrationsData.map((integration) => (
          <SettingsPermissionsItem key={integration.title} integration={integration} isConnected={integration.title === "Stripe" && user?.stripeConnectedAccount?.accountCompleted} />
        ))}
      </div>
    </div>
  );
}

export default SettingsPermissions;
