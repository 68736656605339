import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";

type Props = {
  enabledPrevious: boolean;
  enabledNext: boolean;
  pageNumber: number;
  onPageChange: (newValue: number) => void;
  numPages: number;
  className?: string;
}

function Paginator(props: Props) {
  const {
    enabledPrevious,
    enabledNext,
    pageNumber,
    onPageChange,
    numPages,
    className
  } = props;
  const pageInputRef = useRef<HTMLInputElement>(null);
  const [pageInputNumber, setPageInputNumber] = useState<number | string>(pageNumber ?? 1);

  function handleChangePage(delta: -1 | 1) {
    const newPageNumber = Math.max(1, Math.min(pageNumber + delta, numPages));
    if (newPageNumber === pageNumber)
      return;

    setPageInputNumber(newPageNumber);
    onPageChange(newPageNumber);
  }

  function handleSetPageInput(e: React.FormEvent<HTMLInputElement | HTMLFormElement>) {
    e.preventDefault();
    if (typeof pageInputNumber === 'number' && pageInputNumber > 0 && pageInputNumber <= numPages)
      onPageChange(pageInputNumber)
    else
      setPageInputNumber(pageNumber)
  }

  function handleChangePageInput({ target }: React.FormEvent<HTMLInputElement>) {
    const value = parseInt((target as HTMLInputElement).value)

    if ((target as HTMLInputElement).value === "")
      setPageInputNumber("");
    else {
      if (value > numPages || value < 1) {
        const paginator = document.getElementById("paginator");
        paginator!.classList!.add("shake");
        setTimeout(() => paginator!.classList!.remove("shake"), 200)
      }
      setPageInputNumber(Math.min(Math.max(value, 1), numPages));
    }
  }

  useEffect(() => {
    setPageInputNumber(pageNumber)
  }, [pageNumber]);

  return (
    <div className={`flex justify-center items-center gap-2 pt-2 ${className ?? ''}`} id="paginator">
      <button className={`flex items-center justify-center w-8 h-8 text-gray-400 ${enabledPrevious ? "hover:text-blue" : "text-gray-300 cursor-not-allowed"}`} onClick={() => enabledPrevious && handleChangePage(-1)}>
        <ChevronLeftIcon className="w-6 h-6" />
      </button>

      <form
        onSubmit={handleSetPageInput}
        className="flex items-center cursor-text h-6 font-gilroy font-semibold text-gray-600 justify-evenly gap-1"
        onClick={() => pageInputRef.current?.focus()}
      >
        <input
          value={pageInputNumber}
          type="text"
          className="w-8 text-center bg-transparent outline-none border-2 border-solid border-transparent border-b-gray-200 focus-visible:border-b-blue"
          onChange={handleChangePageInput}
          onBlur={handleSetPageInput}
          ref={pageInputRef}
        />
        <p>/</p>
        <p className="w-8 text-center border-2 border-solid border-transparent">{numPages}</p>
      </form>
      <button className={`flex items-center justify-center w-8 h-8 text-gray-400 ${enabledNext ? "hover:text-blue" : "text-gray-300 cursor-not-allowed"}`} onClick={() => enabledNext && handleChangePage(+1)}>
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

export default Paginator;
