import { useEffect, useState } from 'react';
import packageJson from '../../package.json';
import Copper from 'copper-sdk';
import useUser from '../hooks/useUser';
import AppCopperLogin from './AppCopperLogin';
import AppCopperWorkflowList from './AppCopperWorkflowList';

function AppCopper(props: { isGoogleLoaded: boolean }) {

  const { user } = useUser();

  const [copperSdk, setCopperSdk] = useState<any>();

  useEffect(() => {
    if (!!copperSdk)
      return;

    const sdk = Copper.init();
    setCopperSdk(sdk);
  }, [copperSdk]);

  return (
    <div className="flex flex-col w-screen gap-1 px-2 h-[200px]">
      {!user
        ? <AppCopperLogin isGoogleLoaded={props.isGoogleLoaded} />
        : <AppCopperWorkflowList sdk={copperSdk} />
      }
      <div className='flex items-center gap-1 text-xs text-gray-400 font-semibold font-gilroy mt-auto'>
        <img className='w-6 h-6' src='https://static.portant.co/logo-mark-blue.svg' alt='Portant Logo' />
        <div className='text-gray-600'>
          Powered by <a href="https://www.portant.co" target='_blank' rel="noreferrer" className='text-black hover:underline hover:text-blue'>Portant</a>
        </div>
        <div className='text-gray-400'>|</div>
        <a className='text-gray-400 hover:text-blue hover:underline cursor-pointer' href="https://calendly.com/portant/portant-copper-demo" target="_blank" rel='noreferrer'>Book a Demo</a>
        <div className='text-gray-400 ml-auto'>{packageJson.version}</div>
      </div>
    </div>
  )
}

export default AppCopper;
