import { useEffect, useState } from "react";
import { Editor, Element, Node, Range, Transforms } from "slate";
import { useModal } from "../contexts";
import { insertLink } from "../emailEditorHelpers";
import Modal from "./Modal";
import SmallTextInputField from "./SmallTextInputField";

interface Props {
  editor: Editor;
  onClose?: () => void;
}

function WorkflowModalLink(props: Props) {
  const { editor, onClose } = props;

  const { closeModal } = useModal();

  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [displayText, setDisplayText] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const [insideLinkElement, setInsideLinkElement] = useState<boolean>(false);

  useEffect(() => {
    const { selection } = editor;

    if (!selection)
      return;

    const isCollapsed = Range.isCollapsed(selection);
    const [parentNode] = Editor.parent(editor, selection);

    setUrl("");
    setDisplayText("");

    if (Element.isElement(parentNode) && parentNode.type === "link") {
      let displayText = "";
      for (const [textNode] of Node.texts(parentNode))
        displayText += textNode.text;

      setDisplayText(displayText);
      setUrl(parentNode.url);
      setInsideLinkElement(true);
    } else if (!isCollapsed && selection) {
      const selectedText = Editor.string(editor, selection);

      setDisplayText(selectedText)
    }
  }, [editor]);

  function handleChangeDisplayText(value: string) {
    setHasChanged(true);
    setDisplayText(value);
  }

  function handleChangeUrl(value: string) {
    setHasChanged(true);
    setUrl(value);
  }

  function handleInsertLink() {
    let externalUrl;
    if (url.startsWith("http://") || url.startsWith("https://"))
      externalUrl = url;
    else
      externalUrl = "http://" + url;

    if (insideLinkElement && editor.selection) {
      const [, path] = Editor.parent(editor, editor.selection)
      Transforms.setNodes(editor, { url: externalUrl }, { at: path });

      const textNodePath = path.concat(0);
      Transforms.insertText(editor, displayText, { at: textNodePath });
    } else
      insertLink(editor, externalUrl, displayText);

    handleOnClose();
  }

  function handleOnClose() {
    if (onClose)
      onClose();

    closeModal();
  }

  return (
    <Modal
      title="Insert Link"
      secondaryButtonProps={{ text: "Cancel", onClick: handleOnClose }}
      primaryButtonProps={{ disabled: !hasChanged, onClick: handleInsertLink, text: "Insert" }}
    >
      <SmallTextInputField label="Display Text" value={displayText} onChange={handleChangeDisplayText} />
      <SmallTextInputField label="URL" value={url} onChange={handleChangeUrl} />
    </ Modal>
  )
}

export default WorkflowModalLink;
