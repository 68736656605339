import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

type Props = {
  currentStep: number;
  steps: string[];
  className?: string;
}

function BreadcrumbStepper(props: Props) {
  const { currentStep, steps, className = '' } = props;

  return (
    <div className={`flex gap-4 items-center text-center font-semibold text-sm ${className}`}>
      {steps.map((stepContent, stepNumber) => (
        <Fragment key={`${stepContent}-${stepNumber}`}>
          <p className={`${currentStep === stepNumber ? 'text-blue' : currentStep > stepNumber ? 'text-green' : 'text-gray-400'}`}>
            {stepContent}
            {currentStep > stepNumber && <CheckCircleIcon className="inline-flex text-green h-4 w-4 ml-1" />}
          </p>
          {stepNumber + 1 < steps.length && <ChevronRightIcon className="text-gray-200 h-7 w-7" />}
        </Fragment>
      ))}
    </div>)
}

export default BreadcrumbStepper;
