import { useContext } from "react";
import { HubspotAppContext } from "../contexts";

interface Props {
  text: string;
  btnClass: "btn-blue" | "btn-white";
  handleStartAutomation: () => void;
  error?: string;
  disabled?: boolean;
}

function AppHubspotButton(props: Props) {

  const { pickerData, currentAutomation, isStarting, currentPage } = useContext(HubspotAppContext);
  const status = !!props.error ? "ERROR" : currentAutomation?.status ?? (isStarting ? "PENDING" : "INITIAL");

  return (
    <>
      {(status === "INITIAL" || status === "COMPLETED" || status === "CANCELLED") &&
        <button className={`btn ${props.btnClass} w-full mt-auto`} onClick={props.handleStartAutomation} disabled={props.disabled}>
          {(currentPage === "ASSOCIATED_OBJECTS" && pickerData?.signatureRequestNumRecipients) ? "Next" : props.text}
        </button>
      }

      {isStarting &&
        <button className={`btn ${props.btnClass} w-full mt-auto`} disabled>
          Starting...
        </button>
      }

      {!isStarting && (status === "PENDING" || status === "RUNNING") &&
        <div className="text-blue font-semibold text-center text-sm mt-auto">
          This automation has been started and will be complete shortly. This window can now be closed.
        </div>
      }

      {(status === "ERROR") &&
        <div className="text-red font-semibold text-center  text-sm mt-auto">
          An error occured when starting this automation. <a href={`mailto:support@portant.co?subject=Hubspot Modal Error&body=${encodeURI(props.error ?? "<Unknown Error>")}`}>Report error</a>.
        </div>
      }
    </>
  )

}

export default AppHubspotButton;
