import { Transition } from "@headlessui/react";
import { ClipboardCopyIcon, DocumentTextIcon, FilterIcon, PencilIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { useModal, WorkflowContext } from "../contexts";
import CopyToClipboard from "react-copy-to-clipboard";
import WorkflowSelectModal from "./WorkflowSelectModal";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import WebhookEventTableRow from "./WebhookEventTableRow";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowModalWebhookNameChange from "./WorkflowModalWebhookNameChange";
import { putData } from "../backend";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";


function WorkflowSourceGlide() {
  const { openModal } = useModal();

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;

  const history = useHistory();
  const { url } = useRouteMatch();

  const source = workflow.source;
  const events = source?.webhookConfig?.events || [];

  const [isCopied, setIsCopied] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  function handleCopyWebhookUrl() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }

  function handleRefresh() {
    setIsRefreshing(true);
    mutateWorkflow()
      .then(() => setIsRefreshing(false));
  }

  function handleOpenNameModal() {
    openModal(<WorkflowModalWebhookNameChange source={source!} updateBlock={updateWebhookName} />)
  }

  function updateWebhookName(name: string) {
    return putData(`/workflows/sources/${source!.id}/webhook-config/`, { name })
      .then(() => mutateWorkflow())
      .catch(console.error);
  }

  if (!source || !source.webhookConfig)
    return null;

  const webhookUrl = `${process.env.REACT_APP_WEBHOOK_URL!}/${workflow.source?.webhookConfig!.token}`;
  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions && <>
            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} showRefreshSpinning={isRefreshing} disableCopy />

            <div className="flex flex-col gap-1">
              <div className="font-gilroy font-semibold text-gray-600 text-sm">
                Webhook Address
              </div>
              <div className="flex items-center gap-2">
                <input type="text" className="w-full text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2" value={webhookUrl} onClick={e => e.currentTarget.select()} readOnly />
                <CopyToClipboard text={webhookUrl} onCopy={handleCopyWebhookUrl}>
                  <button className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm">
                    <ClipboardCopyIcon className="h-4 w-4 group-hover:text-blue" />
                    <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
                      {isCopied ? "Copied" : "Copy to clipboard"}
                    </div>
                  </button>
                </CopyToClipboard>
              </div>
              <div className="text-gray-600 text-xs">
                For help please checkout our <a href="https://www.portant.co/guide-article/how-to-connect-a-glide-data-source" target="_blank" rel="noreferrer" className="text-blue underline">guide</a> on setting up a Glide integration
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <FilterIcon className="w-4 h-4 mr-2" /> Source Filter Conditions
              </div>
              <div className="flex items-center font-gilroy font-semibold text-sm text-gray-400">
                Only process data that meets certain conditions
              </div>
              {(source!.sourceConditions.length > 0) &&
                <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600">
                  <div>Filtering by <span className="font-semibold">{source!.sourceConditions.length}</span> condition{source!.sourceConditions.length === 1 ? "" : "s"}</div>
                </div>
              }
              <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
              </button>
            </div>

          </>}

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Temaplate" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP["GLIDE"]} alt="Glide Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy">
              <div className="truncate max-w-[400px]">
                {source.webhookConfig?.name || "Untitled Glide Source"}
              </div>
            </div>
            {hasPermission(workflow.team, "workflows.edit") &&
              <button className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600 cursor-pointer" onClick={handleOpenNameModal}>
                <PencilIcon className="h-4 mr-1" /> Rename
              </button>
            }
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>

        {events.length > 0 &&
          <div className="w-full h-full border border-gray-400 rounded py-2 overflow-hidden">
            <div className="relative flex items-center border-b px-4 text-sm h-[40px]">
              <div className="w-1/6">Origin</div>
              <div className="w-1/2">Event ID</div>
              <div className="w-1/6">Status</div>
              <div className="w-1/6">Received</div>
              <RefreshIcon className={`absolute right-4 top-2 w-5 h-5 cursor-pointer text-gray-600 hover:text-blue ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={handleRefresh} />
            </div>
            <div className="overflow-y-scroll" style={{ height: "calc(100% - 40px)" }}>
              {events.map((event) => <WebhookEventTableRow key={event.id} event={event} />)}
            </div>
          </div>
        }

        {events.length === 0 &&
          <div className="flex flex-col ml-10 mt-8 overflow-y-auto gap-4">

            <div className="font-semibold font-gilroy text-gray-600">
              How to use a Glide source with Portant
            </div>

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                1
              </div>
              <span>
                Add a <a href="https://www.glideapps.com/docs/reference/actions" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">Glide Action</a>
              </span>
            </div>

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                2
              </div>
              <span>
                Select <span className="font-semibold">Trigger Webhook</span> from the menu
              </span>
            </div>

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                3
              </div>
              <span>
                Select <span className="font-semibold">Add a new Webhook</span> from the menu
              </span>
            </div>
            <img className="shadow-lg rounded w-[400px] mx-auto" src={`${process.env.REACT_APP_STATIC_URL}/glide-tutorial.png`} alt="Glide webhook screenshot" />

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                4
              </div>
              <span>
                Name your webhook and paste the address below in the URL input
              </span>
            </div>

            <div className="flex flex-col justify-center items-center w-[500px] m-auto">
              <div className="flex justify-between w-full">
                <div className="font-gilroy font-semibold text-gray-600 text-lg w-6 h-6 mb-2">Address</div>
                <RefreshIcon className={`w-6 h-6 cursor-pointer text-gray-600 hover:text-blue ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={handleRefresh} />
              </div>
              <input type="text" className="w-full text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2 mb-4" value={webhookUrl} onClick={e => e.currentTarget.select()} readOnly />
              <CopyToClipboard text={webhookUrl} onCopy={handleCopyWebhookUrl}>
                <div className="btn btn-white w-full mb-4">
                  {isCopied ? "Copied" : "Copy address to clipboard"}
                </div>
              </CopyToClipboard>
            </div>

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                5
              </div>
              <span>
                <a href="https://www.glideapps.com/docs/reference/actions/webhook#configuration" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">Add values</a> to the webhook, including a <span className="font-semibold">Row ID</span> value if you wish to send data back to Glide
              </span>
            </div>

            <div className="flex items-center text-sm">
              <div className="flex items-center justify-center font-semibold text-sm rounded-full w-8 h-8 mr-2 bg-gray-400 text-white pt-1">
                6
              </div>
              <span>
                <span>Send a test event and press the refresh <RefreshIcon className={`inline-block w-4 h-4 cursor-pointer text-gray-600 hover:text-blue ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={handleRefresh} /> button</span>
              </span>
            </div>

            <div className="flex items-center text-sm mt-8 font-semibold">
              <div className="flex items-center justify-center font-semibold text-2xl mr-2">
                🎉
              </div>
              <span>
                <span>You can now add a document template and customise it using the field names</span>
              </span>
            </div>

            <div className="flex items-center text-sm">
              <span>
                <a href="https://www.portant.co/guide-article/how-to-connect-a-glide-data-source" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">View Complete Glide Guide</a>
              </span>
            </div>

          </div>
        }

      </Transition.Child>

    </>
  );

}

export default WorkflowSourceGlide;
