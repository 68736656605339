import { Transition } from "@headlessui/react";
import { DocumentTextIcon, ExternalLinkIcon, FilterIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { getData, putData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowSelectModal from "./WorkflowSelectModal";
import SelectField from "./SelectField";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import MicrosoftPickerButton from "./MicrosoftPickerButton";
import { PickerResponse } from "./WorkflowSelectMicrosoftButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";

interface Worksheet {
  id: string,
  name: string
};


function WorkflowSourceMicrosoftExcel() {
  const { openModal } = useModal();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;
  const source = workflow.source;

  const { hasPermission } = useUser();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [worksheets, setWorksheets] = useState<Worksheet[]>();
  const [selectedWorksheetId, setSelectedWorksheetId] = useState<string>();

  function updateSelectedWorksheet(selectedWorksheetId: string) {
    if (!source?.microsoftExcelConfig)
      return;

    setSelectedWorksheetId(selectedWorksheetId);
    const updatedConfig = { ...source.microsoftExcelConfig, selectedWorksheetId: selectedWorksheetId };

    setIsRefreshing(true);
    putData(`/microsoft/config/${source.microsoftExcelConfig.id}/`, updatedConfig)
      .then(() => mutateWorkflow())
      .then(() => setIsRefreshing(false))
      .catch(console.error);
  }

  useEffect(() => {
    if (source?.microsoftExcelConfig)
      setSelectedWorksheetId(source.microsoftExcelConfig.selectedWorksheetId);
  }, [source]);

  useEffect(() => {
    if (source?.microsoftExcelConfig)
      getData(`/microsoft/config/${source.microsoftExcelConfig.id}/worksheets/`)
        .then(setWorksheets)
        .catch(console.error);
  }, [source]);


  async function handleChangeFile(response: PickerResponse) {
    await putData(`/microsoft/config/${source!.microsoftExcelConfig!.id}/`, { fileId: response.value[0].id });
    await mutateWorkflow();
  }

  if (!source)
    return null;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions && <>
            <SelectField label="Selected Sheet" value={selectedWorksheetId ?? "0"} onChange={updateSelectedWorksheet}>
              {worksheets ?
                worksheets.map(sheet => (
                  <option key={sheet.id} value={sheet.id}>{sheet.name}</option>
                ))
                : []
              }
            </SelectField>

            <WorkflowSourceFieldTable showRefreshSpinning={isRefreshing} workflow={workflow} mutateWorkflow={mutateWorkflow} disableCopy />

            <div className="flex flex-col">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <FilterIcon className="w-4 h-4 mr-2" /> Source Filter Conditions
              </div>
              <div className="flex items-center font-gilroy font-semibold text-sm text-gray-400">
                Only process data that meets certain conditions
              </div>
              {(source!.sourceConditions.length > 0) &&
                <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600">
                  <div>Filtering by <span className="font-semibold">{source!.sourceConditions.length}</span> condition{source!.sourceConditions.length === 1 ? "" : "s"}</div>
                </div>
              }
              <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
              </button>
            </div>
          </>}

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Template" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[source.sourceType]} alt="Google File Icon" />
          </div>
          <div className="flex items-center gap-4">
            <div className="flex flex-col h-full justify-center">
              <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(source.microsoftExcelConfig?.fileUrl, "_blank")}>
                <div className="truncate max-w-[400px]">
                  {source.microsoftExcelConfig?.fileName}
                </div>
                {source.microsoftExcelConfig?.fileUrl &&
                  <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
                }
              </div>
              {hasPermission(workflow.team, "workflows.edit") &&
                <MicrosoftPickerButton className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600" blockType={source.sourceType} onPicked={handleChangeFile}>
                  <PencilIcon className="h-4 mr-1" /><>Change selected file</>
                </MicrosoftPickerButton>
              }
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>
        <iframe title="Microsoft File Viewer" height="1000" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={source.microsoftExcelConfig?.fileEmbedViewUrl} />
      </Transition.Child>
    </>
  );

}

export default WorkflowSourceMicrosoftExcel;
