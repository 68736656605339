
import { useModal } from '../contexts';
import Modal from './Modal';
import { HubspotPrimaryObject } from "../interfaces";
import { capitalize } from '../utils';


interface Props {
  object: HubspotPrimaryObject;
  onConfirm: (object: HubspotPrimaryObject) => void;
}

function WorkflowModalHubspotConfirmation(props: Props) {
  const { closeModal } = useModal();

  return (
    <Modal
      title="Change Primary Object"
      subtitle="Please confirm that you would like to change the primary object for this HubSpot source."
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Confirm", onClick: () => { props.onConfirm(props.object); closeModal(); } }}
      size='sm'
      className='!gap-2'
    >
      <h4 className="font-gilroy text-center text-lg text-gray-600">
        New primary object:
      </h4>
      <h5 className="flex flex-col font-semibold font-gilroy text-center text-2xl">
        {capitalize(props.object)}
      </h5>
    </Modal>
  );
}

export default WorkflowModalHubspotConfirmation;
