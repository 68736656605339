import { useState } from "react";
import { TemplateIcon } from '@heroicons/react/outline';
import { Template } from "../interfaces";
import HeroIcon from "./HeroIcon";
import { Link } from "react-router-dom";

interface Props {
  template: Template;
}

function TemplateListCard(props: Props) {
  const { template } = props;

  const [showPreview, setShowPreview] = useState<boolean>(true);

  return (
    <Link
      className="relative flex flex-col border border-gray-200 shadow rounded overflow-hidden cursor-pointer hover:scale-105 transition-transform h-64"
      to={`/templates/${template.id}`}
    >

      <div className="relative w-full h-40 bg-gray-200 border-b border-gray-200 overflow-hidden flex-shrink-0">
        <div className="absolute flex items-center justify-center w-full h-full top-0 left-0"><TemplateIcon className="w-20 h-20 text-gray-300" /></div>
        {(showPreview && template.image) && (
          <img className="relative w-full h-full object-cover" onError={() => setShowPreview(false)} src={template.image} alt="Thumbnail" />
        )}
      </div>

      <div className="absolute top-2 right-2 flex items-center justify-center w-8 h-8 text-white rounded flex-shrink-0" style={{ backgroundColor: template.workflow.color }}>
        <HeroIcon icon={template.workflow.icon} className="w-6 h-6" />
      </div>

      <div className="flex flex-col gap-1 p-4 font-gilroy my-auto w-full">
        <h4 className="font-gilroy font-semibold text-gray-600 truncate">
          {template.name}
        </h4>

        <p className="text-xs font-gilroy text-gray-600 w-full overflow-y-hidden h-20">
          {template.shortDescription}
        </p>
      </div>

    </Link>
  );
}

export default TemplateListCard;

