import { useState } from 'react';
import { useModal } from '../contexts';
import TextInputField from './TextInputField';
import IconPickerField from './IconPickerField';
import ColorPickerField from './ColorPickerField';
import Modal from './Modal';
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";
import { OAuthError, postData } from '../backend';
import { useHistory } from 'react-router-dom';
import { Workflow } from '../interfaces';
import OAuthModal from './OAuthModal';
import useSWR from 'swr';

interface Props {
  workflow: Workflow;
}

function WorkflowModalDuplicate(props: Props) {
  const history = useHistory();
  const { openModal, closeModal } = useModal();

  const { mutate: mutateWorkflowList } = useSWR("/workflows/");

  const [workflow, setWorfklow] = useState(props.workflow);

  async function handleClickCreate() {
    openModal(<LoadingModal title="Duplicating Workflow..." subtitle="This may take some time as we copy over the documents In your Google&nbsp;Drive." />);

    try {
      const newWorkflow = await postData(`/workflows/${props.workflow.id}/duplicate/`, workflow);
      mutateWorkflowList();
      closeModal();

      history.push(`/w/${newWorkflow.id}`);
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }
  }

  return (
    <Modal
      title="Duplicate Workflow"
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Create", onClick: handleClickCreate }}
    >
      <TextInputField label="Workflow Name" value={workflow.name} onChange={(name) => setWorfklow((w: any) => ({ ...w, name }))} required />
      <label className="flex flex-row mt-4 mb-1 font-gilroy font-medium">Appearance</label>
      <div className="flex gap-16">
        <ColorPickerField value={workflow.color} onChange={(color) => setWorfklow((w: any) => ({ ...w, color }))} />
        <div className='flex flex-grow gap-4'>
          <IconPickerField value={workflow.icon} onChange={(icon) => setWorfklow((w: any) => ({ ...w, icon }))} selectColour={workflow.color} />
        </div>
      </div>
    </Modal>
  );
}

export default WorkflowModalDuplicate;
