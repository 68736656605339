import { postData } from "../backend";
import { SubscriptionTier } from "../interfaces";
import { DocumentAddIcon } from "@heroicons/react/outline";
import { useState } from "react";

interface Props {
  tier: SubscriptionTier | undefined;
  currentTier: SubscriptionTier | undefined;
  showAnnualPrice: boolean;
  children: JSX.Element | JSX.Element[];
}

function SettingsBillingTier(props: Props) {
  const { tier, currentTier, showAnnualPrice } = props;

  const [isLoading, setIsLoading] = useState(false)

  if (!tier)
    return null;

  const isCurrent = currentTier?.slug === tier.slug;
  const isDisabled = tier.slug === "free" || tier.slug === "trial" || (currentTier && currentTier?.order > tier.order);

  const isPrimary = (() => {
    if (tier.slug === "pro")
      return currentTier?.slug === "free" || currentTier?.slug === "trial";

    if (tier.slug === "teams")
      return currentTier?.slug === "pro";

    if (tier.slug === "enterprise")
      return currentTier?.slug === "teams";

    return false;
  })()

  const buttonText = (() => {
    if (currentTier?.slug === tier.slug)
      return "Current";

    if (isDisabled || tier.slug === "enterprise")
      return "Select"

    return "Upgrade"
  })()


  async function handleClickButton() {
    if (!tier || isDisabled || isLoading)
      return;

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'click_upgrade', 'upgrade_tier': tier.slug });

    if (tier.slug === "enterprise")
      return window.open("https://www.portant.co/enterprise", "_blank");

    setIsLoading(true);

    if (isCurrent) {
      await postData(`/billing/customer-portal/`, {})
        .then(data => window.open(data.url, "_blank"));

    } else {
      const stripePriceId = showAnnualPrice ? tier.stripeAnnualPriceId : tier.stripeMonthlyPriceId;

      await postData(`/billing/tiers/${tier.slug}/checkout/`, { stripePriceId })
        .then(data => window.open(data.url, "_blank"));
    }

    setIsLoading(false);
  }

  let displayPrice = null;

  if (tier.slug === "free") {
    displayPrice = (
      <div className="flex items-start flex-shrink-0 font-gilroy font-semibold text-gray-600">
        <div>$</div>
        <div className="text-3xl">0</div>
        <div>.00</div>
      </div>
    );
  }

  else if (tier.slug === "enterprise") {
    displayPrice = (
      <div className="flex items-start flex-shrink-0 font-gilroy font-semibold text-gray-600">
        <div className="mt-auto mb-1 text-gray-400">Custom</div>
        <div className="text-3xl">&nbsp;</div> {/* Hack to mantain spacing */}
      </div>
    );
  }

  else {
    const price = showAnnualPrice ? tier.stripeAnnualPrice : tier.stripeMonthlyPrice;

    const dollars = Math.floor(price / 100);
    const cents = String(Math.floor(price % 100)).padStart(2, '0');

    displayPrice = (
      <div className="flex items-start flex-shrink-0 font-gilroy font-semibold text-gray-600">
        <div>$</div>
        <div className="text-3xl">{dollars}</div>
        <div>.{cents}</div>
        <div className="mt-auto mb-1 text-xs">/{showAnnualPrice ? "year" : "month"}</div>
      </div>
    );
  }

  return (
    <div key={tier.id} className="flex shadow-lg lg:shadow-none p-4 lg:p-0 lg:w-48 flex-col flex-shrink-0 rounded-lg" >

      <div className="flex flex-col lg:shadow-lg rounded p-4 h-60">
        {displayPrice}

        <div className="font-gilroy font-semibold text-gray-600 text-lg mt-1">{tier.name}</div>
        <div className="font-gilroy font-semibold text-gray-400 text-sm">{tier.displayText}</div>

        <button className={`btn ${isPrimary ? "btn-blue" : "btn-white"} ${isDisabled ? "border-gray-400 text-gray-400 cursor-not-allowed" : isCurrent && "border-green text-green"} mt-auto`} onClick={handleClickButton}>
          {isLoading ? "Loading..." : (buttonText ?? "Upgrade")}
        </button>
      </div>

      <div className="mt-4 text-sm font-gilroy font-semibold text-gray-600">What's included</div>

      <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
        <DocumentAddIcon className={"text-blue w-4 h-4 mr-2 mt-px flex-shrink-0"} />
        {tier.featureDocumentLimit ?? "Unlimited"}&nbsp;docs per month
      </div>

      {props.children}
    </div>
  )

}

export default SettingsBillingTier;
