import { OAuthError, postData, putData } from "../backend";
import { useContext, useEffect, useState } from "react";
import { Document, SignatureConfig } from "../interfaces";
import { BadgeCheckIcon, ExclamationCircleIcon, PencilIcon, UserAddIcon } from "@heroicons/react/outline";
import WorkflowSignatureConfigItem from "./WorkflowSignatureConfigItem";
import ToggleButton from "./ToggleButton";
import { useModal, WorkflowContext } from "../contexts";
import ProLabel from "./ProLabel";
import useUser from "../hooks/useUser";
import PayWallModalPro from "./PayWallModalPro";
import OAuthModal from "./OAuthModal";
import { useHistory, useRouteMatch } from "react-router-dom";

interface Props {
  document: Document;
  updateDocument: (field: string, value: any) => Promise<void>;
}

function WorkflowSignatureConfig(props: Props) {
  const { document } = props;

  const { user } = useUser();
  const { openModal } = useModal();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const history = useHistory();
  const { url } = useRouteMatch();

  const [isCreating, setIsCreating] = useState(false);
  const [signatureConfig, setSignatureConfig] = useState<SignatureConfig>();

  useEffect(() => {
    if (workflow.signatureConfig)
      setSignatureConfig(workflow.signatureConfig);
  }, [workflow.signatureConfig]);

  async function handleAddSignatureConfig(showOauth = true) {
    setIsCreating(true);

    try {
      await props.updateDocument("isSignable", true);

      if (!signatureConfig) {
        await postData(`/workflows/${workflow.id}/signature-config/`)
          .then(setSignatureConfig)
      }

      mutateWorkflow();

    } catch (error) {
      if (error instanceof OAuthError && showOauth)
        openModal(<OAuthModal title='Portant requires your permission to request signatures via Gmail' oauthUrl={error.oauthUrl} onClose={() => handleAddSignatureConfig(false)} />);
      else
        console.error(error);
    }

    setIsCreating(false);
  }

  async function updateSignatureConfig(updates: any) {
    if (!signatureConfig)
      return;

    const updatedSignatureConfig = { ...signatureConfig!, ...updates };
    setSignatureConfig(updatedSignatureConfig);

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/`, updatedSignatureConfig)
      .then(setSignatureConfig)
      .catch(console.error);

    mutateWorkflow({ ...workflow, signatureConfig: updatedSignatureConfig });
  }

  function handleAddRecipient() {
    if (!user?.subscription?.featureSignatures)
      return openModal(<PayWallModalPro reason="SIGNATURES" />);

    const numRecipients = Math.min(signatureConfig!.numRecipients + 1, 4);
    updateSignatureConfig({ numRecipients });
  }

  return (
    <div className="flex flex-col gap-4">

      <div className="flex flex-col gap-2">
        <div className="flex items-start justify-between">
          <div className="flex flex-col gap-1 w-full">
            <div className="flex items-center font-gilroy font-semibold text-gray-600 w-full">
              <BadgeCheckIcon className="w-6 h-6 mr-1" /> Request a signature
              {(workflow.signatureConfig && workflow.documents.filter(d => d.isSignable).length === 0 && !document.isSignable) &&
                <div className="relative ml-auto group">
                  <ExclamationCircleIcon className="w-6 h-6 text-yellow flex-shrink-0" />
                  <div className="tooltip right-0 top-6 whitespace-normal w-72">This workflow contains an existing signature request but no signable documents</div>
                </div>
              }
            </div>
            <div className="font-semibold text-xs text-gray-400">
              Add recipients to sign document
            </div>
          </div>
          {document.isSignable &&
            <ToggleButton value={document.isSignable} onChange={(value) => props.updateDocument("isSignable", value)} />
          }
        </div>

        {!document.isSignable &&
          <>
            {(workflow.signatureConfig && workflow.documents.filter(d => d.isSignable).length === 0)
              ? (
                <button className="btn btn-blue w-full text-xs" disabled={isCreating} onClick={() => handleAddSignatureConfig()}>
                  {isCreating ? "Linking..." : "Link to Signature Request"}
                </button>
              ) : (
                <button className="btn btn-white w-40 text-xs" disabled={isCreating} onClick={() => handleAddSignatureConfig()}>
                  {isCreating ? "Adding..." : "Add"}
                </button>
              )}
          </>
        }
      </div>

      {
        (signatureConfig && document.isSignable) &&
        <>
          {[...Array(signatureConfig.numRecipients)].map((_, index: number) =>
            <WorkflowSignatureConfigItem key={index} document={props.document} recipientNumber={index + 1} signatureConfig={signatureConfig} updateSignatureConfig={updateSignatureConfig} />
          )}
          {signatureConfig.numRecipients < 4 &&
            <div className="flex items-center">
              <button className="btn btn-white flex items-center text-sm gap-2" onClick={handleAddRecipient}>
                <UserAddIcon className="w-4 h-4" />
                Add another recipient
              </button>
              <ProLabel />
            </div>
          }
          <div className="flex items-center justify-center gap-1 font-gilroy font-semibold text-gray-600 text-sm cursor-pointer hover:underline" onClick={() => history.push(url + "/signature-request")}>
            <PencilIcon className="w-4 h-4" /> Customise Signature Request
          </div>
        </>
      }
    </div>
  );

}

export default WorkflowSignatureConfig;
