import { useHistory } from 'react-router-dom';
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP } from '../utils';
import Modal from './Modal';
import { useModal } from '../contexts';
import { IntegrationType } from '../interfaces';

function ConnectTeamModal(props: { integrations: Array<IntegrationType> }) {
  const { closeModal } = useModal();
  const history = useHistory();

  return (
    <Modal
      title="Connect with All the Tools You Need"
      subtitle="To ensure you can run all the workflows you have access to, please connect the following integrations"
      size='lg'
    >
      <div className='flex flex-col gap-8'>
        {props.integrations.map((integration) =>
          <div className='flex gap-2'>
            <img src={BLOCK_ICON_MAP[integration]} className='w-10 h-10' alt="Integration Icon" />
            <div className='flex flex-col'>
              <div className='font-semibold'>{BLOCK_NAME_MAP[integration]}</div>
              <div className='text-gray-400 font-semibold text-sm'>Connect your {BLOCK_NAME_MAP[integration]} account via the permissions settings</div>
            </div>
            <button className='btn btn-blue ml-auto' onClick={() => { history.push("/settings/permissions"); closeModal(); }}>
              Connect
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ConnectTeamModal;
