import { MailIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { clearAuthToken } from "../auth";
import { postData } from "../backend";
import { User } from "../interfaces";

type StageKey = "INITIAL" | "ACTION" | "SENDING" | "SENT";
interface Stage {
  title: String;
  subtitle: React.ReactNode;
  showLoginButton?: boolean;
  showLogoutButton?: boolean;
  action?: () => void;
}

function MobileLanding(props: { user: User | null | undefined, isGoogleLoaded: boolean }) {
  const stages: Record<StageKey, Stage> = {
    "INITIAL": {
      title: "Automate your document workflows with Portant",
      subtitle: "Create an account",
      showLoginButton: true,
    },
    "ACTION": {
      title: "To get started",
      subtitle: "Send me a magic link to log in on my desktop or laptop",
      showLogoutButton: true,
      action: handleSendLink,
    },
    "SENDING": {
      title: "To get started",
      subtitle: "Send me a magic link to log in on my desktop or laptop",
      showLogoutButton: true,
    },
    "SENT": {
      title: "Almost there 🎉",
      subtitle: <>
        Check your email on desktop or laptop for the link sent to <br />
        <div className="flex items-center"><MailIcon className="w-8 h-8 mr-2" /> {props.user?.email}</div>
      </>,
    },
  }

  const [currentStage, setCurrentStage] = useState<StageKey>("INITIAL");
  const stage = stages[currentStage];

  useEffect(() => {
    if (!props.isGoogleLoaded)
      return;

    // @ts-ignore
    const google = window.google;

    google.accounts.id.renderButton(
      document.getElementById("google-sign-in-button"),
      { theme: "filled_blue", size: "large", text: "signup_with" }
    );

  }, [props.isGoogleLoaded])

  useEffect(() => {
    if (props.user?.id)
      setCurrentStage("ACTION");

  }, [props.user?.id])

  function handleSendLink() {
    if (currentStage !== "ACTION")
      return;

    setCurrentStage("SENDING");
    postData("/auth/login-link/")
      .finally(() => setCurrentStage("SENT"));
  }

  function handleSignOut() {
    if (currentStage !== "ACTION")
      return;

    clearAuthToken();

    // @ts-ignore
    const google = window.google;
    google.accounts.id.disableAutoSelect();

    window.location.reload();
  }

  const logoUrl = (() => {
    if (currentStage === "SENDING") return "https://static.portant.co/portant-loading-blue.svg";
    else return "https://static.portant.co/logo-mark-blue.svg";
  })();

  return (
    <div className='flex flex-col w-full' style={{ height: window.innerHeight }}>
      <div className="w-full h-full flex flex-col items-start justify-center gap-2 px-5 pb-20">
        <img className='h-10 mb-2' src={logoUrl} alt='Portant Logo' />
        <div className="font-semibold text-gray-600 text-3xl">
          {stage.title}
        </div>
        {stage.action
          ? <div className="text-2xl text-blue font-semibold underline" onClick={stage.action}>{stage.subtitle}</div>
          : <div className="text-2xl text-gray-600 font-semibold">{stage.subtitle}</div>
        }
        {stage.showLoginButton &&
          <div id="google-sign-in-button" className="mt-8" />
        }
        {stage.showLogoutButton &&
          <div className="text-xl font-semibold text-gray-400 mt-8" onClick={handleSignOut}>
            Logout
          </div>
        }
      </div>
      {/* Preload loading animation */}
      <img className='h-px w-px opacity-0' src="https://static.portant.co/portant-loading-blue.svg" alt="" />
    </div>
  )
}

export default MobileLanding;
