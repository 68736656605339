import { useModal } from '../contexts';
import Modal from './Modal';


function OAuthModalMicrosoft() {
  const { closeModal } = useModal();
  return (
    <Modal
      title="Microsoft Requires Popups"
      size='sm'
      primaryButtonProps={{ text: "Close", onClick: closeModal }}
    >
      <p>
        If you can't see a Microsoft File Picker window right now, you might have popups blocked.<br />
        Please enable popups to use the Microsoft integration and try again.
      </p>
    </Modal>
  );
}

export default OAuthModalMicrosoft;
