import { useEffect, useState } from 'react';
import { deleteData, getData, postData } from '../backend';
import { useModal } from '../contexts';
import { BLOCK_ICON_MAP } from '../utils';
import { Organistaion } from './WorkflowSourceXero';
import Modal from './Modal';
import useUser from '../hooks/useUser';

function SettingsModalXero() {
  const { user, refreshUser } = useUser();
  const { closeModal } = useModal();

  const [oauthUrl, setOauthUrl] = useState("");
  const [organisations, setOrganisations] = useState<Organistaion[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    postData("/xero/install/")
      .then(({ url }) => setOauthUrl(url))
      .catch(console.error);
  }, []);

  useEffect(() => {
    setLoading(true);

    getData("/xero/organisations/")
      .then(setOrganisations)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [user]);

  async function handleDeleteXeroCredentials() {
    if (user?.xeroCredentials?.id) {
      await deleteData(`/xero/credentials/`)
        .catch(console.error);

      await refreshUser();
    }

    closeModal();
  }

  async function handleAddXeroOrganistaion() {
    if (!oauthUrl)
      return;

    window.open(oauthUrl, "_blank");
    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  function getCurrentUserOnFocus() {
    refreshUser();
    window.removeEventListener("focus", getCurrentUserOnFocus);
  }

  return (
    <Modal size='sm'>
      <div className="relative flex flex-col justify-center gap-6">

        <div className="flex items-center gap-2">
          <img className="h-10 w-10" src={BLOCK_ICON_MAP["XERO"]} alt="Google Sheets Icon" />
          <div className="flex flex-col">
            <div className="font-gilroy font-semibold">
              Xero
            </div>
            <div className="text-gray-600 text-sm">Congratulations, you've successfully connected your organisation. You can manage your connected organisations below.</div>
          </div>
        </div>

        {loading &&
          <div className='text-gray-600 h-12'>Fetching organisations<span className='loading-ellipse' /></div>
        }

        {!loading && organisations.length === 0 &&
          <button className='cursor-pointer mx-auto' onClick={handleAddXeroOrganistaion}>
            <img className='h-12' src="https://static.portant.co/xero-connect-blue.svg" alt="Connect to Xero" />
          </button>
        }

        {!loading && organisations.length > 0 &&
          <div className="flex flex-col gap-2">
            <div className='flex items-center gap-2'>
              <div className="text-gray-600 text-sm font-semibold font-gilroy">
                Connected organisations
              </div>
              <div className='text-gray-400 text-sm'>|</div>
              <button className='text-xs text-gray-400 font-semibold hover:text-blue cursor-pointer' onClick={handleAddXeroOrganistaion}>
                Add another
              </button>
              <div className='text-gray-400 text-sm'>|</div>
              <button className='text-xs text-gray-400 font-semibold hover:text-red cursor-pointer' onClick={handleDeleteXeroCredentials}>
                Remove all
              </button>
            </div>

            {organisations.map(organisation =>
              <div key={organisation.tenantId} className="">
                •&nbsp;{organisation.tenantName}
              </div>
            )}
          </div>
        }
      </div>
    </Modal>
  );
}

export default SettingsModalXero;
