import { useModal } from '../contexts';
import { DotsVerticalIcon, PencilIcon, DocumentDuplicateIcon, TrashIcon, UserGroupIcon } from '@heroicons/react/outline';
import WorkflowModalEdit from "./WorkflowModalEdit";
import WorkflowModalDuplicate from "./WorkflowModalDuplicate";
import WorkflowModalDelete from "./WorkflowModalDelete";
import { Workflow } from "../interfaces";
import { deleteData, postData, putData } from "../backend";
import useUser from "../hooks/useUser";
import { Popover } from "@headlessui/react";
import PayWallModalTeams from "./PayWallModalTeams";
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';
import moment from 'moment-timezone';
import { KeyedMutator } from 'swr';

interface Props {
  workflow: Workflow;
  mutateWorkflowList: KeyedMutator<any>;
}

function WorkflowListItemOptions(props: Props) {
  const { mutateWorkflowList } = props;
  const { user, hasPermission } = useUser();

  const { openModal, closeModal } = useModal();

  function updateWorkflow(workflow: Workflow) {
    const updatedAt = moment().toISOString();
    const lastEditedBy = workflow.team?.members?.find(u => u.id === user?.id) ?? workflow.lastEditedBy;

    const optimisticWorkflow = { ...workflow, updatedAt, lastEditedBy };
    updateWorkflowList(optimisticWorkflow, false);

    putData(`/workflows/${workflow.id}/`, workflow)
      .then(updateWorkflowList)
      .catch((err) => openModal(<ErrorModal details={err} />));
  }

  function updateWorkflowListRemoved(workflowId: string) {
    mutateWorkflowList((data: { results: Array<Workflow> }) => {
      const { results: ws } = data;
      return ({ ...data, results: ws?.filter(w => w.id !== workflowId) });
    });
  }

  function updateWorkflowList(workflow: Workflow, revalidate = false) {
    mutateWorkflowList((data: { results: Array<Workflow> }) => {
      const { results: ws } = data;
      const i = ws?.findIndex(w => w.id === props.workflow.id)
      if (i !== undefined)
        ws[i] = {
          ...ws[i],
          ...workflow
        };

      return ({ ...data, results: ws });
    }, { revalidate });
  }

  function openEditModal() {
    openModal(<WorkflowModalEdit workflow={props.workflow} updateWorkflow={(w: Workflow) => { updateWorkflow(w); closeModal(); }} />);
  }

  function openDuplicateModal() {
    openModal(<WorkflowModalDuplicate workflow={{ ...props.workflow, name: "Copy of " + props.workflow.name }} />);
  }

  function openDeleteModal() {
    openModal(<WorkflowModalDelete workflow={props.workflow} deleteWorkflow={updateWorkflowListRemoved} />);
  }

  async function toggleTeam() {
    if (!user?.activeTeam)
      return openModal(<PayWallModalTeams />)

    try {
      if (!props.workflow.team) {
        openModal(<LoadingModal title="Sharing workflow" />);
        const newWorkflow = await postData(`/workflows/${props.workflow.id}/team/`)
        await updateWorkflowList(newWorkflow);
      } else {
        openModal(<LoadingModal title="Removing workflow from team" />);
        const newWorkflow = await deleteData(`/workflows/${props.workflow.id}/team/`)
        await updateWorkflowList(newWorkflow);
      }
    } catch (error) {
      <ErrorModal details={error} />
    } finally {
      closeModal();
    }
  }

  return (
    <Popover className="relative" onClick={(e: React.MouseEvent) => { e.preventDefault(); e.stopPropagation(); }}>

      <Popover.Button className="flex justify-center items-center w-8 h-8 shadow rounded bg-white hover:text-blue outline-none focus-visible:outline-black">
        <DotsVerticalIcon className="w-4 h-4 focus-visible:outline-red focus-visible:outline" tabIndex={-1} />
      </Popover.Button>

      <Popover.Panel className="absolute mt-1 right-0 z-20 flex flex-col w-42 bg-white border rounded shadow overflow-y-auto">

        {/* Fallback option */}
        <Popover.Button className="hidden last:flex items-center font-medium text-xs text-gray-600 w-full px-4 py-2 whitespace-nowrap">
          No options available
        </Popover.Button>

        {hasPermission(props.workflow.team, "workflows.edit") &&
          <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={openEditModal}>
            <PencilIcon className="w-4 h-4 mr-2" /> Edit
          </Popover.Button>
        }

        {hasPermission(props.workflow.team, "workflows.delete") &&
          <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={openDeleteModal}>
            <TrashIcon className="w-4 h-4 mr-2" /> Delete
          </Popover.Button>
        }

        {hasPermission(props.workflow.team, "workflows.create") &&
          <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={openDuplicateModal}>
            <DocumentDuplicateIcon className="w-4 h-4 mr-2" /> Duplicate
          </Popover.Button>
        }

        {hasPermission(props.workflow.team, "workflows.edit") &&
          <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={toggleTeam}>
            <UserGroupIcon className="w-4 h-4 mr-2" />
            {props.workflow.team ? "Remove from Team" : "Share with Team"}
          </Popover.Button>
        }

      </Popover.Panel>
    </Popover>
  );

}

export default WorkflowListItemOptions;

