import { RenderLeafProps } from "slate-react";

function Leaf(props: RenderLeafProps) {
  const { attributes, leaf } = props;
  let { children } = props;

  if (leaf.bold)
    children = <strong>{children}</strong>;
  if (leaf.italic)
    children = <em>{children}</em>;
  if (leaf.underline)
    children = <u>{children}</u>;

  return (
    <span {...attributes}>
      {children}
    </span>
  )
}

export default Leaf;
