import { Transition } from "@headlessui/react";

function TransitionWorkflowPage(props: { show: boolean, children: React.ReactNode }) {
  return (
    <Transition show={props.show} className="absolute top-0 flex flex-row-reverse flex-shrink-0 w-full h-full overflow-hidden" appear unmount>
      {props.children}
    </Transition>
  );
}

export default TransitionWorkflowPage;
