import { ForwardedRef, forwardRef, KeyboardEvent, useImperativeHandle, useRef, useState } from "react";
import { BasePoint, Descendant, Editor, Element, Node, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { useModal } from "../contexts";
import { insertImage, toggleList, toggleMark, unwrapLink, insertLink, serializeToHtml, serializeToText, deserializeFromHtml } from "../emailEditorHelpers";

import TemplatedTextEditor, { TemplatedTextEditorRef } from "./TemplatedTextEditor";
import WorkflowModalLink from "./WorkflowModalLink";
import EmailEditorTextFormattingMenu from "./EmailEditorTextFormattingMenu";
import { createPortal } from "react-dom";

const INLINE_ELEMENTS = ["image", "link"];
const LIST_ELEMENTS = ["bulleted-list", "numbered-list"];
const VOID_ELEMENTS = ["image"];

export type EmailEditorRef = {
  insertImage: (url: string, fromDrive?: boolean) => void;
  openEditorLinkModal: () => void;
  getTextValue: () => string;
} & TemplatedTextEditorRef;

interface Props {
  initialValue: string;
  showFormattingMenu: boolean;
  formattingMenuContainer: React.MutableRefObject<HTMLDivElement | null>;
  onChange: (value: Descendant[]) => void;
  onFocus: () => void;
  onDoubleUp?: () => void;
  onDoubleDown?: () => void;
  className?: string;
  disabled?: boolean;
}

function EmailEditor(props: Props, forwardedRef: ForwardedRef<EmailEditorRef>) {
  const { openModal } = useModal();
  const { initialValue, onChange, onFocus } = props;

  const [lastUpFocus, setLastUpFocus] = useState<BasePoint>();
  const [lastDownFocus, setLastDownFocus] = useState<BasePoint>();

  const templatedTextEditorRef = useRef<TemplatedTextEditorRef>(null);

  useImperativeHandle(forwardedRef, () => ({
    allowFormatting: () => true,
    focus: templatedTextEditorRef.current!.focus,
    getEditor: templatedTextEditorRef.current!.getEditor,
    getValue: templatedTextEditorRef.current!.getValue,
    insertTag: templatedTextEditorRef.current!.insertTag,
    getTextValue: () => serializeToText(templatedTextEditorRef.current!.getEditor()),


    insertImage: (url: string, fromDrive?: boolean) => {
      if (templatedTextEditorRef.current)
        insertImage(templatedTextEditorRef.current.getEditor(), url, fromDrive)
    },
    openEditorLinkModal,
  }));

  function openEditorLinkModal() {
    if (!templatedTextEditorRef.current)
      return;

    const editor = templatedTextEditorRef.current.getEditor();
    const { selection } = editor;

    if (!selection)
      return;

    const isCollapsed = Range.isCollapsed(selection);
    const [parentNode] = Editor.parent(editor, selection);

    if (Element.isElement(parentNode) && parentNode.type === "link")
      unwrapLink(editor)
    else if (isCollapsed)
      openModal(<WorkflowModalLink editor={editor} onClose={() => ReactEditor.focus(editor)} />);
    else {
      const text = Editor.string(editor, selection);

      try {
        new URL(text);

        insertLink(editor, text, text);
      } catch {
        openModal(<WorkflowModalLink editor={editor} onClose={() => ReactEditor.focus(editor)} />);
      }
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (!templatedTextEditorRef.current)
      return;

    const editor = templatedTextEditorRef.current.getEditor();

    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case "b":
          event.preventDefault();
          toggleMark(editor, "bold");
          break;
        case "i":
          event.preventDefault();
          toggleMark(editor, "italic");
          break;
        case "u":
          event.preventDefault();
          toggleMark(editor, "underline");
          break;
      }
    } else if (event.key === "Backspace") {
      const { selection } = editor;

      if (selection && selection.anchor.offset === 0 && Range.isCollapsed(selection)) {
        const node = editor.children[selection.anchor.path[0]];

        if (Element.isElement(node) && LIST_ELEMENTS.includes(node.type))
          toggleList(editor, node.type as "bulleted-list");
      }
    } else if (event.key === "ArrowUp") {
      const { selection } = editor;

      if (selection?.focus === lastUpFocus)
        props.onDoubleUp?.();

      setLastUpFocus(selection?.focus);
    } else if (event.key === "ArrowDown") {
      const { selection } = editor;

      if (selection?.focus === lastDownFocus)
        props.onDoubleDown?.();

      setLastDownFocus(selection?.focus);
    }
  }

  return (
    <TemplatedTextEditor
      initialValue={initialValue.startsWith("<p>") ? initialValue : `<p>${initialValue}</p>`}
      className={props.className ?? "w-full h-full overflow-auto"}
      multiline
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      withOverrides={withOverrides}
      deserializer={deserializeFromHtml}
      serializer={serializeToHtml}
      ref={templatedTextEditorRef}
      disabled={props.disabled}
    >
      {(props.showFormattingMenu && props.formattingMenuContainer.current) &&
        createPortal(
          <EmailEditorTextFormattingMenu />,
          props.formattingMenuContainer.current
        )
      }
    </TemplatedTextEditor>
  );
};

const _EmailEditor = forwardRef<EmailEditorRef, Props>(EmailEditor);
export default _EmailEditor;

function withOverrides(editor: Editor): Editor {
  const { insertData, isInline, isVoid, normalizeNode } = editor;

  editor.isInline = element => INLINE_ELEMENTS.includes(element.type) || isInline(element);
  editor.isVoid = element => VOID_ELEMENTS.includes(element.type) || isVoid(element);

  editor.insertData = data => {
    const text = data.getData("text/plain");

    try {
      new URL(text);
      insertLink(editor, text, text);
    } catch {
      insertData(data);
    }
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === "link") {
      let displayText = "";

      for (const [textNode] of Node.texts(node))
        displayText += textNode.text;

      if (displayText.length === 0)
        Transforms.removeNodes(editor, { at: path });
    }
    normalizeNode(entry);
  }

  return editor;
}
