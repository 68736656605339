import { useCallback, useEffect, useState } from "react";
import { OAuthError, getData, postData } from "../backend";
import { useModal } from "../contexts";
import { TeamUser, Workflow } from "../interfaces";
import useUser from "../hooks/useUser";
import Modal from "./Modal";
import OAuthModal from "./OAuthModal";
import HeroIcon from "./HeroIcon";
import useWorkflows from "../hooks/useWorkflows";
import Paginator from "./Paginator";


type ACCESS = "CHECKING" | "VALID" | "INVALID" | "PARTIAL" | "PENDING" | "GRANTING" | "ERROR";

function SettingsTeamUserModalAccess(props: { teamUser: TeamUser }) {
  const { openModal, closeModal } = useModal();

  const { user } = useUser();
  const team = user?.activeTeam!;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const { data, isValidating } = useWorkflows({ workflowType: "TEAM" }, currentPageNumber);
  const { results: teamWorkflows, numPages } = data;
  const enabledPrevious = currentPageNumber > 1;
  const enabledNext = currentPageNumber < numPages;

  const [isCheckingAccess, setIsCheckingAccess] = useState(false);
  const [isGrantingAccess, setIsGrantingAccess] = useState(false);

  const [hasCheckedSinceUpdate, setHasCheckedSinceUpdate] = useState(false);

  const [userAccess, setUserAccess] = useState<{ [workflowId: string]: ACCESS }>();
  const nonValidWorkflowIds = Object.entries(userAccess ?? {}).filter(([_, access]) => access !== "VALID").map(([id, _]) => id);

  const checkUserAccess = useCallback(async (workflowId: string) => {
    return await getData(`/workflows/${workflowId}/check-access/`);
  }, []);

  const checkAccessToAll = useCallback(async () => {
    if (!teamWorkflows?.length || isCheckingAccess) return;

    setIsCheckingAccess(true);

    for (const workflow of teamWorkflows)
      setUserAccess(prev => ({ ...prev, [workflow.id]: "PENDING" }));

    for (const workflow of teamWorkflows ?? []) {
      setUserAccess(prev => ({ ...prev, [workflow.id]: "CHECKING" }));

      try {
        const workflowAccess = await checkUserAccess(workflow.id)
        setUserAccess(prev => ({ ...prev, [workflow.id]: workflowAccess[props.teamUser.email] ?? "INVALID" }));
      } catch (error) {
        if (error instanceof OAuthError) {
          setUserAccess(prev => ({ ...prev, [workflow.id]: "PENDING" }));
          setIsCheckingAccess(false);
          return openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
        } else {
          setUserAccess(prev => ({ ...prev, [workflow.id]: "ERROR" }));
        }
      }
    }

    setIsCheckingAccess(false);
  }, [checkUserAccess, teamWorkflows, props.teamUser.email, openModal, isCheckingAccess]);

  const grantAccess = useCallback(async (workflowId: string) => {
    return await postData(`/workflows/${workflowId}/grant-access/`, { user: props.teamUser.id });
  }, [props.teamUser.id]);

  const handleClickGrant = () => {
    if (nonValidWorkflowIds.length > 0)
      grantAccessToAll()
    else
      closeModal();
  }

  const grantAccessToAll = useCallback(async () => {
    setIsGrantingAccess(true);

    for (const workflowId of nonValidWorkflowIds)
      setUserAccess(prev => ({ ...prev, [workflowId]: "PENDING" }));

    for (const workflowId of nonValidWorkflowIds) {
      setUserAccess(prev => ({ ...prev, [workflowId]: "GRANTING" }))

      try {
        await grantAccess(workflowId)
        setUserAccess(prev => ({ ...prev, [workflowId]: "VALID" }))
      } catch (e) {
        setUserAccess(prev => ({ ...prev, [workflowId]: "ERROR" }))
      }
    }

    setIsGrantingAccess(false);
  }, [grantAccess, nonValidWorkflowIds]);

  useEffect(() => {
    if (hasCheckedSinceUpdate)
      return;

    setHasCheckedSinceUpdate(true);
    checkAccessToAll();

  }, [checkAccessToAll, hasCheckedSinceUpdate]);

  useEffect(() => {
    setHasCheckedSinceUpdate(false);
  }, [currentPageNumber, teamWorkflows]);

  const sortedUsers: Array<TeamUser> = team.members
    .sort((a: TeamUser, b: TeamUser) => {
      if (a.teamRole === "OWNER") return -1;
      if (b.teamRole === "OWNER") return 1;

      const aStr = a.fullName.toLowerCase() + a.email.toLowerCase();
      const bStr = b.fullName.toLowerCase() + b.email.toLowerCase();

      if (aStr < bStr) return -1;
      if (aStr > bStr) return 1;
      return 0;
    });

  if (!data)
    return null;

  return (
    <Modal
      title={`Teams Workflows Access for '${props.teamUser.fullName || props.teamUser.email}'`}
      subtitle="Ensure access to the output folder, source, and template files in all workflows."
      secondaryButtonProps={{
        text: "Cancel",
        onClick: () => closeModal()
      }}
      primaryButtonProps={{
        text: isCheckingAccess ? "Checking..." : (nonValidWorkflowIds.length > 0 ? `Grant Access (${nonValidWorkflowIds.length})` : "Done"),
        onClick: handleClickGrant,
        disabled: isCheckingAccess || isGrantingAccess
      }}
      size="xl"
    >
      <div className="w-full mt-4 scrollbar-gutter">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-sm font-gilroy text-gray-600 text-left border-b">
              <th className="text-left w-auto">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Workflow
                </div>
              </th>
              <th className="text-left w-[100px]">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Access
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="overflow-y-scroll h-ful max-h-[300px] scrollbar-visible">
        <table className="table-auto w-full">
          <tbody>
            {isValidating && !teamWorkflows && (
              <tr>
                <td>
                  <img src="https://static.portant.co/portant-loading-gray.svg" alt="Loading Logo" className='m-auto w-10 h-10 my-14' />
                </td>
              </tr>
            )}
            {teamWorkflows?.map((workflow: Workflow) =>
              <tr key={workflow.id}>
                <td className="w-auto">
                  <div className="flex items-center h-full py-2">
                    <div className="flex justify-center items-center w-10 h-10 mr-4 rounded bg-blue cursor-pointer select-none" style={{ backgroundColor: workflow.color }}>
                      <HeroIcon icon={workflow.icon || "DocumentText"} />
                    </div>
                    <div className="font-semibold text-gray-600 group-hover:text-gray-800 overflow-ellipsis overflow-hidden whitespace-nowrap">
                      {workflow.name}
                    </div>
                  </div>
                </td>
                <td className="w-[100px] text-sm">
                  {userAccess?.[workflow.id] === undefined &&
                    <div className="font-semibold font-gilroy text-gray-400">---</div>
                  }
                  {userAccess?.[workflow.id] === "INVALID" &&
                    <div className="font-semibold font-gilroy text-red">Invalid</div>
                  }
                  {userAccess?.[workflow.id] === "PARTIAL" &&
                    <div className="font-semibold font-gilroy text-yellow">Partial</div>
                  }
                  {userAccess?.[workflow.id] === "VALID" &&
                    <div className="font-semibold font-gilroy text-green">Valid</div>
                  }
                  {userAccess?.[workflow.id] === "PENDING" &&
                    <div className="font-semibold font-gilroy text-gray-400">Pending</div>
                  }
                  {userAccess?.[workflow.id] === "CHECKING" &&
                    <div className="font-semibold font-gilroy text-gray-600">Checking<span className="loading-ellipse" /></div>
                  }
                  {userAccess?.[workflow.id] === "GRANTING" &&
                    <div className="font-semibold font-gilroy text-gray-600">Granting<span className="loading-ellipse" /></div>
                  }
                  {userAccess?.[workflow.id] === "ERROR" &&
                    <div className="font-semibold font-gilroy text-red">Error</div>
                  }
                </td>
              </tr>
            )}
            {sortedUsers.length === 0 &&
              <tr className="w-full">
                <td className="flex justify-center items-center py-8" colSpan={5}>
                  <div className="font-semibold text-gray-400">
                    No results
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <Paginator
        enabledNext={enabledNext}
        enabledPrevious={enabledPrevious}
        onPageChange={(newPage) => setCurrentPageNumber(newPage)}
        pageNumber={currentPageNumber}
        numPages={numPages}
        className="mt-auto"
      />
    </Modal>
  )

}

export default SettingsTeamUserModalAccess;
