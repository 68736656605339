import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ChevronDownIcon, CollectionIcon, DuplicateIcon, PhotographIcon } from "@heroicons/react/outline";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SmallSelectField from "./SmallSelectField";
import SmallTextInputField from "./SmallTextInputField";
import HeroIcon from "./HeroIcon";
import { SourceField, SourceFieldType, Workflow } from "../interfaces";
import { OAuthError, postData, putData } from "../backend";
import { useModal } from "../contexts";
import { Document } from "../interfaces";
import OAuthModal from "./OAuthModal";
import { DATE_FORMAT_MAP, NUMBER_FORMAT_MAP, TIME_FORMAT_MAP } from "../utils";

// NOTE: WorkflowSourceField has both an `id` and `fieldId` property.
//  - id:       Globally unique database id this is mainly used for backend API calls and can be used for keys in the frontend.
//  - fieldId:  Unique with source, this is the inherent id for the field itself used for source field logic.
//      ^ This is most likely what you're looking to use.


interface Props {
  workflow: Workflow;
  field: SourceField;
  document?: Document;
  isOpen: boolean;
  setOpenField: (tag: string | null) => void;
  scrollToFit: (el: HTMLDivElement) => void;
  dataGroupingField?: string | undefined;
  updateDataGroupingField?: (field: string | undefined) => void;
  showUserFieldType?: boolean;
  disableCopy?: boolean;
}

function WorkflowSourceFieldTableRow(props: Props) {
  const [field, setField] = useState<SourceField>(props.field);
  const source = props.workflow.source!;

  const documentType = props.document?.documentType;

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [submitChanges, setSubmitChanges] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const { isOpen, scrollToFit } = props;

  const { openModal } = useModal();

  useLayoutEffect(() => {
    if (isOpen && ref.current)
      scrollToFit(ref.current);

  }, [ref, isOpen, scrollToFit, field.fieldType]);

  useEffect(() => {
    setField(props.field);
  }, [props.field])

  useEffect(() => {
    if (!submitChanges)
      return;

    putData(`/workflows/source-fields/${field.id}/`, field)
      .catch(console.error);

    setSubmitChanges(false);
  }, [submitChanges, field, source.id]);

  function handleCopyTag() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }

  function handleInsertImage() {
    if (!props.document)
      return;

    setIsCopied(true);
    postData(`/workflows/documents/${props.document.id}/insert-image-placeholder/`, {
      tag: field.fieldName,
      customMapping: field.customMapping
    })
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal title="Full Drive Access is Required to Insert Images" oauthUrl={error.oauthUrl} />);
        else
          console.error(error)
      })
      .finally(() => setTimeout(() => setIsCopied(false), 5000));
  }

  const [icon, iconTooltipText] = (() => {
    if (props.dataGroupingField === field.fieldName)
      return ["CollectionIcon", "Data Grouping Field"]

    return {
      "TEXT": ["TagIcon", "Text Field"],
      "LINK": ["LinkIcon", "Link Field"],
      "DATE": ["CalendarIcon", "Date Field"],
      "NUMBER": ["CalculatorIcon", "Number Field"],
      "IMAGE": ["PhotographIcon", "Image Field"],
      "OUTPUT": ["TagIcon", "Text Field"],
      "USER": ["UserCircleIcon", "User"],
    }[field.fieldType || "TEXT"];
  })();

  return (
    <div ref={ref} key={field.id} className="flex flex-col justify-center py-1 px-2 text-sm border-b last:border-b-0 w-full">
      <div className="flex w-full items-center gap-1">
        <ChevronDownIcon className={`w-4 h-4 flex-shrink-0 transform transition-all cursor-pointer ${props.isOpen ? "rotate-0" : "-rotate-90"}`} onClick={() => props.setOpenField(props.isOpen ? null : field.id)} />
        {!props.isOpen
          ? <div className="font-semibold pt-2 pb-1 truncate" onClick={() => props.setOpenField(field.id)}>{field.customMapping || field.fieldName}</div>
          : <div className="font-semibold pt-2 pb-1" onClick={() => props.setOpenField(null)}>{field.fieldName}</div>
        }
        <div className="ml-auto relative group" onClick={() => props.setOpenField(props.isOpen ? null : field.id)}>
          <HeroIcon icon={icon} className={`w-4 h-4 flex-shrink-0 cursor-pointer ${!field.customMapping ? "text-gray-600" : "text-blue"}`} />
          <div className="hidden group-hover:flex absolute -top-1 right-6 bg-black text-white text-xs rounded-sm z-10 px-2 py-1 whitespace-nowrap select-none">
            <svg height="8px" width="4px" xmlns="http://www.w3.org/2000/svg" className="absolute text-black" style={{ right: "-4px", top: "calc(100% / 2 - 4px)" }}>
              <polygon points="0,0 4,4 0,8" fill="currentColor" />
            </svg>
            {iconTooltipText}
          </div>
        </div>
        {(!props.disableCopy && (field.fieldType !== "IMAGE" || !documentType)) &&
          <CopyToClipboard text={"{{" + (field.customMapping || field.fieldName) + "}}"} onCopy={handleCopyTag}>
            <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isCopied ? "bg-blue text-white" : "bg-white text-blue"}`} onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}>
              {isCopied ? "Copied!" : <><DuplicateIcon className="w-4 h-4 mr-1" /> Copy</>}
            </button>
          </CopyToClipboard>
        }
        {(!props.disableCopy && (field.fieldType === "IMAGE" && documentType)) &&
          <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isCopied ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={handleInsertImage}>
            {isCopied ? "Inserting..." : <><PhotographIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        }
      </div>
      <div className={`${props.isOpen ? "h-auto mb-1" : "h-0"} flex flex-col overflow-hidden transition-all`}>
        <SmallTextInputField className="mb-2" label="Custom Tag" value={field.customMapping} placeholder={field.fieldName} onChange={(customMapping: string) => { setField({ ...field, customMapping }) }} onBlur={() => setSubmitChanges(true)} />
        <SmallSelectField className="w-48" label="Insert value as" value={field.fieldType ?? "TEXT"} onChange={(value: string) => { setField({ ...field, fieldType: value as SourceFieldType }); setSubmitChanges(true); }} disabled={field.fieldId?.startsWith("_")}>
          <option value="TEXT">Text</option>
          <option value="LINK">Link</option>
          <option value="IMAGE">Image</option>
          <option value="DATE">Date</option>
          <option value="NUMBER">Number</option>
          {props.showUserFieldType
            ? <option value="USER">User</option>
            : <></>
          }
        </SmallSelectField>
        {field.fieldType === "LINK" &&
          <SmallTextInputField className="mt-2" label="Link Display Text" placeholder="Display link as is" value={field.linkDisplayText ?? ""} onChange={(linkDisplayText: string) => { setField({ ...field, linkDisplayText }) }} onBlur={() => setSubmitChanges(true)} />
        }
        {field.fieldType === "DATE" &&
          <div className="flex gap-2">
            <SmallSelectField className='mt-2 flex-1' label="Date Format" value={field.dateFormat} onChange={(dateFormat: string) => { setField({ ...field, dateFormat }); setSubmitChanges(true); }}>
              {Object.entries(DATE_FORMAT_MAP)
                .map(([value, label]) => {
                  if (value === props.workflow.owner?.dateFormat) // Default
                    return <option key={value} value={""}>{label} (Default)</option>;

                  return <option key={value} value={value}>{label}</option>;
                })}
            </SmallSelectField>
            <SmallSelectField className="mt-2 flex-1" label="Time Format" value={field.timeFormat} onChange={(timeFormat: string) => { setField({ ...field, timeFormat }); setSubmitChanges(true); }}>
              {Object.entries(TIME_FORMAT_MAP)
                .map(([value, label]) => {
                  if (value === props.workflow.owner?.timeFormat) // Default
                    return <option key={value} value={""}>{label} (Default)</option>;

                  return <option key={value} value={value}>{label}</option>;
                })}
            </SmallSelectField>
          </div>
        }
        {field.fieldType === "NUMBER" &&
          <SmallSelectField className="mt-2 flex-1" label="Number Format" value={field.numberFormat} onChange={(numberFormat: string) => { setField({ ...field, numberFormat }); setSubmitChanges(true); }}>
            <option value="">Default ({NUMBER_FORMAT_MAP[props.workflow.owner?.numberFormat ?? ""]})</option>
            <option value="COMMA">Comma Separated (e.g. 1,234.00)</option>
            <option value="PERIOD">Period Separated (e.g. 1.234,00)</option>
          </SmallSelectField>
        }
        {field.fieldType === "USER" &&
          <SmallSelectField className="mt-2 flex-1" label="User Format" value={field.userFormat || "FULL_NAME"} onChange={(userFormat: string) => { setField({ ...field, userFormat }); setSubmitChanges(true); }}>
            <option value="FIRST_NAME">First Name</option>
            <option value="LAST_NAME">Last Name</option>
            <option value="FULL_NAME">Full Name</option>
            <option value="EMAIL">Email</option>
            <option value="NAME_EMAIL">Name &lt;Email&gt;</option>
          </SmallSelectField>
        }
        {field.fieldType === "IMAGE" && documentType === "GOOGLE_DOCS" && (
          <div className="text-xs mt-2">
            The image placeholder will be inserted at the <b>end&nbsp;of&nbsp;the&nbsp;document</b>. This placeholder can be moved, resized, rotated, and copied.&nbsp;
            <a href="https://www.portant.co/guides/image-tags" target="_blank" rel="noreferrer" className="underline text-blue" >Learn more</a>
          </div>
        )}
        {field.fieldType === "IMAGE" && documentType === "GOOGLE_SLIDES" && (
          <div className="text-xs mt-2">
            The image placeholder will be inserted on the <b>last&nbsp;slide&nbsp;of&nbsp;the&nbsp;presentation</b>. This placeholder can be moved and copied to other slides.&nbsp;
            <a href="https://www.portant.co/guides/image-tags" target="_blank" rel="noreferrer" className="underline text-blue" >Learn more</a>
          </div>
        )}
        {field.fieldId === "_email" && (
          <div className="text-xs mt-2">
            Value will be missing if <b>Collect email addresses</b> is not enabled in the form's settings. <a className="text-blue underline" target="_blank" rel="noreferrer" href="https://support.google.com/docs/answer/139706#zippy=%2Ccollect-respondents-email-addresses">Learn more</a>
          </div>
        )}
        {field.fieldId === "_date" && (
          <div className="text-xs mt-2">
            Timezone can be configured from the <a href="/settings" target="_blank" className="text-blue underline">profile</a> page
          </div>
        )}
        {(["GOOGLE_SHEETS", "MICROSOFT_EXCEL"].includes(source.sourceType) && field.fieldType === "TEXT") &&
          <div className="flex flex-col text-sm mt-2 gap-1 ">
            <div className="flex items-center font-gilroy font-semibold">
              <CollectionIcon className="w-4 h-4 mr-2 text-gray-600" /> Data Grouping
            </div>
            <div className="text-xs text-gray-600">
              Create documents such as invoices by placing groups of rows within a table. <a className="text-blue font-semibold underline ml-auto" href="https://www.portant.co/guide-article/portant-workflow-data-grouping" target="_blank" rel="noreferrer">Learn more</a>
            </div>
            <div className="flex items-center gap-2 cursor-pointer" onClick={() => props.updateDataGroupingField?.(field.fieldName)}>
              <input type="checkbox" checked={props.dataGroupingField === field.fieldName} onChange={() => props.updateDataGroupingField?.(field.fieldName)} />
              <div className="text-gray-600 text-xs">
                Group data by <span className="font-semibold">{field.fieldName}</span>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default WorkflowSourceFieldTableRow;
