import { useState } from "react";
import { putData } from "../backend";
import { useModal, WorkflowContextData } from "../contexts";
import Modal from "./Modal";
import SmallSelectField from "./SmallSelectField";
import TeamsLabel from "./TeamsLabel";
import SmallTextInputField from "./SmallTextInputField";
import useUser from "../hooks/useUser";
import PayWallModalTeams from "./PayWallModalTeams";
import { DATE_FORMAT_MAP, TIME_FORMAT_MAP } from "../utils";
import SettingsTeamModalEdit from "./SettingsTeamModalEdit";

interface Props extends WorkflowContextData { }

function WorkflowSignaturesConfigModal(props: Props) {
  const { user } = useUser();
  const { openModal } = useModal();

  const { workflow, mutateWorkflow } = props;

  const [redirectUrl, setRedirectUrl] = useState(workflow.signatureConfig!.redirectUrl);
  const [redirectUrlInvalid, setRedirectUrlInvalid] = useState(false);

  const [signatureConfig, setSignatureConfig] = useState(workflow.signatureConfig);

  async function updateSignatureConfig(updates: any) {
    if (!signatureConfig)
      return;

    const currentSignatureConfig = { ...signatureConfig! };
    const updatedSignatureConfig = { ...signatureConfig!, ...updates };

    setSignatureConfig(updatedSignatureConfig)

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/`, updatedSignatureConfig)
      .catch(error => {
        setSignatureConfig(currentSignatureConfig);
        console.error(error);
      });

    await mutateWorkflow();
  }

  function handleOpenCustomBranding() {
    if (user?.subscription?.tier !== "teams")
      return openModal(<PayWallModalTeams />);

    openModal(<SettingsTeamModalEdit />);
  }


  function handleSaveRedirectUrl() {
    if (user?.subscription?.tier !== "teams") {
      setRedirectUrlInvalid(true);
      return openModal(<PayWallModalTeams />);
    }

    updateSignatureConfig({ redirectUrl });
  }

  if (!signatureConfig)
    return null;

  return (
    <Modal title="Signature Request Settings" size="sm">
      <div className='flex flex-col'>
        <div className='font-gilroy font-semibold text-gray-600 text-sm'>
          Date Field Format
        </div>
        <div className='text-xs font-semibold text-gray-400'>
          Control the format in which the automatic date is added to signed documents when using a date field
        </div>
        <div className="flex gap-2 mt-1">
          <SmallSelectField className="flex-1 w-3/4" label="Date Format" value={signatureConfig.dateFormat} onChange={(dateFormat: string) => updateSignatureConfig({ dateFormat })}>
            {Object.entries(DATE_FORMAT_MAP)
              .map(([value, label]) => {
                if (value === workflow.owner?.dateFormat) // Default
                  return <option key={value} value={""}>{label} (Default)</option>;

                return <option key={value} value={value}>{label}</option>;
              })}
          </SmallSelectField>
          <SmallSelectField className="flex-1" label="Time Format" value={signatureConfig.dateTimeFormat} onChange={(dateTimeFormat: string) => updateSignatureConfig({ dateTimeFormat })}>
            {Object.entries(TIME_FORMAT_MAP)
              .map(([value, label]) => {
                if (value === workflow.owner?.timeFormat) // Default
                  return <option key={value} value={""}>{label} (Default)</option>;

                return <option key={value} value={value}>{label}</option>;
              })}
          </SmallSelectField>
        </div>
      </div>

      <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-2'>
          <div className='text-sm font-semibold font-gilroy text-gray-600'>
            Custom Confirmation Page
          </div>
          <TeamsLabel />
        </div>
        <div className='text-xs font-semibold text-gray-400'>
          When a recipient has completed a signature request redirect them automatically to your own webpage
        </div>
        <SmallTextInputField value={redirectUrl} onChange={value => { setRedirectUrl(value); setRedirectUrlInvalid(false); }} onEnter={handleSaveRedirectUrl} label='URL' placeholder='https://www.example.com/thankyou' invalid={redirectUrlInvalid} />
      </div>

      <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-2'>
          <div className='text-sm font-semibold font-gilroy text-gray-600'>
            Custom eSign Portal Branding
          </div>
          <TeamsLabel />
        </div>
        <div className='text-xs font-semibold text-gray-400'>
          Control the appearance of the eSign portal recipients will visit when to complete your signature request
        </div>
        <button className='text-sm btn btn-white py-1' onClick={handleOpenCustomBranding} >Customise Branding</button>
      </div>
    </Modal>
  );
}

export default WorkflowSignaturesConfigModal;
