import { SparklesIcon } from "@heroicons/react/outline";
import { useModal } from "../contexts";
import PayWallModalPro from "./PayWallModalPro";
import useUser from "../hooks/useUser";

function ProLabel(props: { hidden?: boolean }) {
  const { openModal } = useModal();
  const { user } = useUser();

  function handleOpen() {
    openModal(<PayWallModalPro />)
  }

  const tier = user?.subscription?.tier;
  if (props.hidden || tier === "pro" || tier === "teams" || tier === "enterprise")
    return null;

  return (
    <div className="flex justify-center items-center bg-gray-100 w-14 rounded-sm shadow ml-auto cursor-pointer flex-shrink-0" onClick={handleOpen}>
      <div className="font-gilroy font-semibold text-black text-sm mr-1">Pro</div>
      <SparklesIcon className="h-4 w-4 text-yellow" />
    </div>
  )
}

export default ProLabel;
