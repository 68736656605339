import useSWR, { KeyedMutator } from "swr";
import { getData } from "../backend";
import { useEffect, useState } from "react";
import { Workflow } from "../interfaces";

interface WorkflowFilters {
  search?: string;
  workflowType: "ALL" | "PERSONAL" | "TEAM";
}

interface PaginatedWorkflowData {
  results: Array<Workflow> | undefined;
  total: number | undefined;
  numPages: number;
}

// Replicated from SWRResponse<...>
interface PaginatedWorkflowResponse {
  data: PaginatedWorkflowData;
  error: Error | undefined;
  mutate: KeyedMutator<PaginatedWorkflowData>;
  isValidating: boolean;
  isLoading: boolean;
}

export default function useWorkflows(filters?: WorkflowFilters, pageNumber?: number): PaginatedWorkflowResponse {
  const [lastNumPages, setLastNumPages] = useState<number>(1);
  const [lastTotal, setLastTotal] = useState<number>();

  let key = '/workflows/';

  if (pageNumber) {
    key += `?page=${pageNumber}`;
    if (filters?.workflowType)
      key += `&type=${filters.workflowType}`;
    if (filters?.search)
      key += `&search=${filters.search}`;
  } else {
    key += '?page=1&type=ALL';
  }

  const { data, ...rest } = useSWR<PaginatedWorkflowData>(key, getData, { fallbackData: { results: undefined, numPages: lastNumPages, total: lastTotal } });

  useEffect(() => {
    if (data?.numPages)
      setLastNumPages(data.numPages);

    if (data?.total)
      setLastTotal(data.total);
  }, [data]);


  if (data) {
    return { data, ...rest };
  } else
    return { data: { results: undefined, numPages: lastNumPages, total: lastTotal }, ...rest };
}
