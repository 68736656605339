import { Popover } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/outline";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import { useHistory } from 'react-router-dom';
import { Workflow } from '../interfaces';

interface Props {
  value: string | null;
  setValue: (value: string | null) => void;
  workflow: Workflow;
  sendFromOwner: boolean;
  allowEditing: boolean;
  aliasDisplay: string | null;
}

function EmailAliasPopover(props: Props) {
  const { user } = useUser();
  const history = useHistory();

  const { openModal } = useModal();

  function openAliasesPage() {
    if (user?.subscription?.featureAliases)
      history.push("/settings/")
    else
      openModal(<PayWallModalPro />);
  }

  if (!user)
    return null;

  const isWorkflowOwner = (props.workflow.owner?.id === user?.id);

  const defaultDisplay = (() => {
    if (!props.workflow.team)
      return `${user.fullName} <${user.email}>`;

    if (props.sendFromOwner)
      return `Workflow Owner: ${user.fullName} <${user.email}>`;
    else
      return "Workflow Runner";
  })();

  const aliasOptions = [
    { id: null, display: defaultDisplay },
    ...user.aliases.map(alias => ({ id: alias.id, display: `Alias: ${alias.aliasName} <${alias.aliasEmail}>` }))
  ]

  return (
    <Popover className="relative">
      <Popover.Button className='flex items-center gap-2 bg-gray-100 rounded px-2 -ml-2 text-gray-600 font-semibold'>
        {aliasOptions.find(alias => alias.id === props.value)?.display ?? props.aliasDisplay ?? defaultDisplay}
        <ChevronDownIcon className='w-4 h-4' />
      </Popover.Button>

      <Popover.Panel className="absolute -left-2 top-6 flex flex-col bg-white text-gray-600 shadow rounded text-sm z-40 min-w-[400px]">
        {(isWorkflowOwner && props.allowEditing) &&
          <>
            {aliasOptions.filter(alias => alias.id !== props.value).map(alias =>
              <Popover.Button key={alias.id} className='font-gilroy font-semibold p-2 hover:bg-gray-50 cursor-pointer whitespace-nowrap text-left' onClick={() => props.setValue(alias.id)}>
                {alias.display}
              </Popover.Button>
            )}
            <div className='flex items-center gap-2 font-gilroy font-semibold p-2 text-blue hover:bg-gray-50 cursor-pointer' onClick={openAliasesPage}>
              <PlusIcon className='w-4 h-4' /> Add Alias
              <ProLabel />
            </div>
          </>
        }
        {!!props.workflow.team &&
          <div className='px-2 py-1 text-xs text-gray-400 font-semibold'>
            {(isWorkflowOwner || props.sendFromOwner || props.value)
              ? "When an alias is selected, emails will always be sent from the Gmail account of the workflow owner"
              : "Emails will be sent from the Gmail account of the person who ran the workflow or the workflow owner for Auto-create automations"
            }
          </div>
        }
      </Popover.Panel>
    </Popover>
  );
}

export default EmailAliasPopover;
