import { putData } from "../backend";
import { Team, TeamUser } from "../interfaces";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP } from "../utils";
import useUser from "../hooks/useUser";
import Avatar from "./Avatar";
import moment from "moment";
import SettingsTeamUserRole from "./SettingsTeamUserRole";
import { useMemo, useState } from "react";
import SettingsTeamUserModalAccess from "./SettingsTeamUserModalAccess";
import { useModal } from "../contexts";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

interface Props {
  team: Team;
  user: TeamUser;
  onRemove?: () => void;
}

function SettingsTeamUser(props: Props) {
  const { user: teamUser, team } = props;
  const { refreshUser } = useUser();

  const [isUpdating, setIsUpdating] = useState(false);

  const { openModal } = useModal();

  const missingIntegrations = useMemo(() => {
    let missing: Array<string> = [];
    for (const integration of team.teamIntegrations) {
      if (!teamUser.userIntegrations.find(i => i === integration))
        missing.push(integration);
    }
    return missing;
  }, [teamUser, team]);

  async function handleRemoveUser() {
    setIsUpdating(true);
    try {
      const newTeam = await putData(`/auth/team/remove/`, { user: teamUser.id })
      refreshUser((user: any) => ({ ...user, newTeam }));
    } catch (error) {
      console.error(error)
    } finally {
      setIsUpdating(false);
      props?.onRemove?.();
    }
  }

  function handleChangeUserRole(role: string) {
    setIsUpdating(true);
    putData(`/auth/team/change-user-role/`, { user: teamUser.id, role })
      .then((team: Team) => {
        refreshUser((user: any) => ({ ...user, team }));
        props?.onRemove?.();
        openModal(<SettingsTeamUserModalAccess teamUser={teamUser} />);
      })
      .catch(console.error)
      .finally(() => setIsUpdating(false));
  }

  return (
    <tr>
      <td className="w-auto">
        <div className="flex items-center gap-2 text-sm my-2">
          <Avatar url={teamUser.avatar} className="h-10 w-10 flex-shrink-0" />
          <div className="flex flex-col">
            <p className="font-gilroy font-bold whitespace-nowrap overflow-hidden text-ellipsis">{teamUser.fullName}</p>
            <p className="text-gray-400 whitespace-nowrap overflow-hidden text-ellipsis">{teamUser.email}</p>
          </div>
        </div>
      </td>
      <td className="w-[200px]">
        <div className="w-40">
          <SettingsTeamUserRole teamUser={teamUser} role={teamUser.teamRole} onSelectRole={handleChangeUserRole} onRemove={handleRemoveUser} isUpdating={isUpdating} />
        </div>
      </td>
      <td className="text-gray-400 text-sm text-left w-[100px]">
        {teamUser.usage}
      </td>
      <td className="w-[200px]">
        <div className="flex items-center gap-1 h-full pr-10">
          {teamUser.userIntegrations.map(icon => <img key={icon} src={BLOCK_ICON_MAP[icon]} className="w-4 h-4 flex-shrink-0" alt="Linked account icon" />)}
          {missingIntegrations.length > 0 &&
            <div className="relative ml-auto group">
              <ExclamationCircleIcon className="w-4 h-4 bg-yellow text-white rounded-full" />
              <div className="hidden group-hover:block absolute right-2 top-4 items-center w-80 border border-gray-400 rounded rounded-tr-none shadow bg-white p-2 z-10 text-sm">
                This user is missing the following integrations used within your team: <span className="font-semibold">{missingIntegrations.map(i => BLOCK_NAME_MAP[i]).join(", ")}</span>.
              </div>
            </div>
          }
          {missingIntegrations.length === 0 &&
            <div className="relative ml-auto group">
              <CheckCircleIcon className="w-4 h-4 bg-green text-white rounded-full" />
              <div className="hidden group-hover:flex absolute right-2 top-4 items-center w-80 border border-gray-400 rounded rounded-tr-none shadow bg-white p-2 z-10 text-sm">
                This user has linked all integrations used within your team.
              </div>
            </div>
          }
        </div>
      </td>
      <td className="text-gray-400 text-sm text-left w-[200px] pr-[20px]">
        {moment(teamUser.dateLastLogin).format("MMM D, h:mma")}
      </td>
    </tr>
  );
}

export default SettingsTeamUser;
