import { SupportIcon } from "@heroicons/react/outline";

function openGuides() {
  window.open("https://www.portant.co/guides", "_blank");
}

function GuidesButton() {
  return (
    <button className='flex items-center gap-1 font-gilroy text-sm font-semibold hover:underline my-auto text-gray-400 hover:text-blue cursor-pointer' onClick={openGuides}>
      <SupportIcon className='w-4 h-4' /> Guides
    </button>
  );
}

export default GuidesButton;