import { useEffect, useState } from 'react';
import { useModal } from "../contexts";
import { getAuthToken } from '../auth';
import useUser from "../hooks/useUser";
import ReloadModal from './ReloadModal';

function SessionManager() {
  const { user, error } = useUser();
  const { openModal } = useModal();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const token = getAuthToken();
    if (!user || !token || modalIsOpen)
      return;

    if (error) {
      openModal(<ReloadModal />);
      setModalIsOpen(true);
    }

  }, [user, error, openModal, modalIsOpen]);

  return null;
}

export default SessionManager;
