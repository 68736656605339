import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
}

function WorkflowOverviewBlockModal({ open, setOpen, children }: React.PropsWithChildren<Props>) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        onClose={setOpen}
        className="fixed inset-0 z-20"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 z-20" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="absolute top-1/2 left-1/2 p-8 bg-white rounded-lg overflow-hidden shadow-xl z-50" style={{ transform: "translate(-50%, -50%)" }}>
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default WorkflowOverviewBlockModal;
