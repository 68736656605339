import { useCallback, useEffect, useState } from 'react';
import { BLOCK_ICON_MAP } from '../utils';
import BetaLabel from './BetaLabel';
import { IntegrationSettings } from './SettingsPermissions';
import { postData } from '../backend';

type SettingsPermissionsItemProps = {
  integration: IntegrationSettings;
  isBeta?: boolean;
  refresh?: () => void;
  pendingRefresh?: boolean;
  isConnected?: boolean;
}

const shouldRefresh: string[] = ['hubspot', 'copper', 'xero', 'microsoft'];

function SettingsPermissionsItem(props: SettingsPermissionsItemProps) {
  const [pendingRefresh, setPendingRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasValidCredentials, setHasValidCredentials] = useState(false);
  const { integration } = props;

  const {
    title,
    description,
    onConnectClick,
    onManageClick,
    isBeta
  } = integration;

  const iconName = title.toUpperCase();

  const handleRefresh = useCallback(() => {
    if (!shouldRefresh.includes(title.toLowerCase())) return;

    setPendingRefresh(false);
    setLoading(true);
    postData(`/${title.toLowerCase()}/access-token/`, {})
      .then(() => setHasValidCredentials(true))
      .catch(() => setHasValidCredentials(false))
      .finally(() => {
        setLoading(false)
      })
  }, [title]);

  const Button = () => {
    if (hasValidCredentials === undefined || loading)
      return <button className="btn w-32 ml-auto" disabled>Loading...</button>

    if (pendingRefresh)
      return <button className="btn btn-yellow w-32 shrink-0 ml-auto" onClick={() => handleRefresh()}>Refresh</button>

    if (hasValidCredentials || props.isConnected)
      return (
        <button
          className="btn btn-green w-32 ml-auto"
          onClick={() => onManageClick(() => setPendingRefresh(true))}
        >
          {props.isConnected ? "Connected" : "Manage"}
        </button>
      )

    return (
      <button
        className="btn btn-blue w-32 ml-auto"
        onClick={() => onConnectClick(() => setPendingRefresh(true))}
      >
        Connect
      </button>
    )
  }

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <div className="flex items-center">
      <img src={BLOCK_ICON_MAP[iconName]} className="w-8 h-8 flex-shrink-0 rounded" alt={`${title} Icon`} />
      <div className="flex flex-col ml-4 mr-8">
        <div className="font-semibold font-gilroy">
          {title}
        </div>
        {description}
      </div>
      {isBeta && <BetaLabel />}
      {<Button />}
    </div>
  );
}

export default SettingsPermissionsItem;
