import { useContext, useEffect } from "react";
import { useResource } from "../backend";
import { Template } from "../interfaces";
import TemplateListCard from "./TemplateListCard";
import { SectionsContext } from "../contexts";

function TemplateList(props: { contentOffset: number }) {
  const templates: Array<Template> | null = useResource("/templates/");

  const categories: Array<string> = useResource("/templates/categories/") ?? [];
  const allCategories = ["All", ...categories];

  const { template, updateTemplateData } = useContext(SectionsContext);
  const { templateCategory } = template;

  useEffect(() => {
    const pageTitle = `Templates | Portant`;
    if (document.title !== pageTitle)
      document.title = pageTitle;

    return (() => { document.title = "Portant" })
  }, [])

  if (!templates || !categories)
    return <div className="m-auto"><img src="https://static.portant.co/portant-loading-light-gray.svg" className="w-32 h-32" alt="Loading Logo" /></div>;

  let filteredTemplates = templates;
  if (templateCategory && templateCategory !== "All")
    filteredTemplates = templates.filter((t: any) => t.categories.includes(templateCategory));

  return (
    <div className="flex flex-col h-full">

      <div className="flex flex-col bg-white font-gilroy h-full rounded m-4 px-4">

        <div className='flex items-center px-4 py-2 gap-12 border-b border-gray-400 flex-shrink-0 h-14'>
          {allCategories.map((category: string) =>
            <div
              key={category}
              className={`font-semibold font-gilroy ${category === templateCategory ? "text-blue" : "text-gray-400 hover:text-gray-600"} cursor-pointer select-none text-center`}
              onClick={() => updateTemplateData('templateCategory', category)}
            >
              {category}
            </div>
          )}
        </div>

        <div className="grid grid-cols-4 grid-rows-none gap-8 row bg-white p-4 overflow-y-auto font-gilroy w-full" style={{ maxHeight: `calc(100vh - 160px - ${props.contentOffset}px)` }}>
          {filteredTemplates.map((template: Template) =>
            <TemplateListCard key={template.id} template={template} />
          )}
        </div>

      </div>
    </div>
  );
}

export default TemplateList;
