import Modal from './Modal';

function ReloadModal() {
  return (
    <Modal
      preventClose={true}
      title="Session Expired"
      subtitle="Reload this page to continue using Portant"
      primaryButtonProps={{ text: "Reload", onClick: () => window.location.reload() }}
      size='sm'
    />
  );
}

export default ReloadModal;
