import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from "react";

interface Props {
  tags: string[];
  onSelect: (tag: string) => void;
  onCancel: () => void;
};

function TemplatedTextEditorTagList({ tags, onSelect, onCancel }: Props, forwardedRef: ForwardedRef<HTMLDivElement>) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setSelectedIndex(index => index === tags.length - 1 ? 0 : index + 1);
        break;
      case "ArrowUp":
        event.preventDefault();
        setSelectedIndex(index => index === 0 ? tags.length - 1 : index - 1);
        break;
      case "Tab":
      case "Enter":
        event.preventDefault();
        onSelect(tags[selectedIndex]);
        break;
      case "Escape":
        event.preventDefault();
        onCancel();
        break;
    }
  }, [selectedIndex, tags, onSelect, onCancel]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="fixed hidden z-50 bg-white text-black max-h-52 w-32 overflow-y-auto border border-gray-400 rounded shadow" ref={forwardedRef}>
      {tags.map((tag, index) => (
        <div key={index} className={`p-1 cursor-pointer rounded-sm truncate ${index === selectedIndex ? "bg-blue-40" : "bg-transparent"}`} onMouseDown={() => onSelect(tag)} onMouseEnter={() => setSelectedIndex(index)}>
          {tag}
        </div>
      ))}
    </div>
  );
};

const _TemplatedTextEditorTagList = forwardRef<HTMLDivElement, Props>(TemplatedTextEditorTagList);
export default _TemplatedTextEditorTagList;
