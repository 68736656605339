import { useState } from 'react';
import useUser from '../hooks/useUser';
import Modal from './Modal';
import ErrorModal from './ErrorModal';
import { BLOCK_ICON_MAP } from '../utils';
import { postData } from '../backend';
import { useModal } from '../contexts';
import { CheckCircleIcon } from '@heroicons/react/outline';
import ToggleButton from './ToggleButton';
import { TeamSummary } from '../interfaces';

function SettingsModalStripe() {

  const { user, refreshUser, hasPermission } = useUser();
  const { openModal } = useModal();

  const [isPending, setIsPending] = useState(false);

  const [showRefresh, setShowRefresh] = useState(false);
  const [accountConnectUrl, setAccountConnectUrl] = useState<string>();

  async function handleConnect() {
    setIsPending(true);

    try {
      const { completed: accountCompleted, url } = await postData(`/stripe/connected-accounts/connect/`)
      setAccountConnectUrl(url);

      if (!accountCompleted && url)
        window.open(url, "_blank");

      refreshUser({ ...user!, stripeConnectedAccount: { ...user!.stripeConnectedAccount!, accountCompleted } });
      setShowRefresh(true);

    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }

    setIsPending(false);
  }

  async function handleDisconnect() {
    setIsPending(true);

    try {
      await postData(`/stripe/connected-accounts/disconnect/`);
      refreshUser({ ...user!, stripeConnectedAccount: null });
    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }

    setIsPending(false);
  }

  async function handleToggleTeam() {
    setIsPending(true);

    try {
      const { enabled } = await postData(`/stripe/connected-accounts/toggle-team/`);
      refreshUser({ ...user!, stripeConnectedAccount: { ...user!.stripeConnectedAccount!, team: enabled ? (user!.activeTeam as TeamSummary) : null } });
    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }

    setIsPending(false);
  }

  return (
    <Modal
      title="Stripe Connect"
      subtitle="Allow Portant to collect payments on your behalf via Stripe Connect."
      image={BLOCK_ICON_MAP["STRIPE"]}
      size='sm'
    >
      <>
        {user?.stripeConnectedAccount?.accountCompleted &&
          <div className='flex flex-col gap-2'>
            <div className='text-green font-semibold flex items-center gap-1'>
              <CheckCircleIcon className='w-4 h-4 flex-shrink-0' /> Your account is connected to Stripe.
            </div>
            <div className='text-sm text-gray-400 font-semibold flex items-center gap-1'>
              Base on your subscription plan, Portant will charge a <b>{(user.subscription?.featureStripeFeePercent ?? 0.05) * 100}%</b> fee on all payments.
            </div>
            {user.activeTeam && hasPermission(user.activeTeam, 'teams.admin') &&
              <div className='flex items-center font-gilroy font-semibold text-gray-600 gap-2'>
                <ToggleButton value={!!user?.stripeConnectedAccount?.team} onChange={handleToggleTeam} disabled={isPending} />
                Use this account for team payments
              </div>
            }
            <button className="btn w-52 btn-red mt-4" onClick={handleDisconnect} disabled={isPending}>
              Disconnect
            </button>
          </div>
        }
        {!user?.stripeConnectedAccount?.accountCompleted &&
          <div className='flex flex-col gap-2'>
            <div className='text-sm text-gray-400 font-semibold flex items-center gap-1'>
              Create or connect your Stripe account to Portant to start accepting payments.
            </div>
            <button className={`btn w-52 ml-auto mt-4 ${showRefresh ? "btn-yellow" : "btn-blue"}`} onClick={handleConnect} disabled={isPending}>
              {showRefresh
                ? (isPending ? "Refreshing..." : "Refresh")
                : (isPending ? "Creating Link..." : "Connect")
              }
            </button>
            {(accountConnectUrl && showRefresh) &&
              <div className="font-semibold text-gray-600 text-xs text-right">
                If the Stripe Connect window did not open please <a className="underline text-blue" href={accountConnectUrl} target="_blank" rel="noreferrer">click here</a>
              </div>
            }
          </div>
        }
      </>
    </Modal>
  );
}

export default SettingsModalStripe;
