import Modal from './Modal';

interface Props {
  title?: string
  details: any;
}

function ErrorModal(props: Props) {
  return (
    <Modal
      title={
        <h3 className="font-gilroy font-semibold text-xl text-red">
          {props.title ?? "Something went wrong!"}
        </h3>
      }
      className='flex flex-col items-center justify-center gap-4 text-center'
    >
      <div className="overflow-auto w-full">{String(props.details)}</div>
    </Modal>
  );
}

export default ErrorModal;
