import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { patchData } from "../backend";
import { WorkflowContext } from "../contexts";
import useUser from "../hooks/useUser";
import { GlideConfig } from "../interfaces";
import WorkflowOverviewBlockModal from "./WorkflowOverviewBlockModal";
import TextInputField from "./TextInputField";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function WorkflowOverviewBlockGlideWriteToSourceModal(props: Props) {
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);
  const { user, hasPermission } = useUser();

  const [config, setConfig] = useState<GlideConfig>(workflow.source?.glideConfig ?? {} as GlideConfig);

  function updateGlideConfig() {
    patchData(`/glide/config/${config.id}/`, config)
      .catch(console.error)
      .finally(mutateWorkflow);

    props.setOpen(false);
  }

  if (!workflow.source?.glideConfig)
    return null;

  return (
    <WorkflowOverviewBlockModal open={props.open} setOpen={props.setOpen} >
      <div className="flex flex-col gap-4 w-[400px]">
        <div className="font-gilroy font-bold">Update Glide Configuration</div>
        <div className="text-gray-600 text-sm">Please follow our <a href="https://www.portant.co/guide-article/how-to-connect-a-glide-data-source" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">Glide Integration Guide</a> to configure these fields.</div>
        <TextInputField label="App ID" value={config.glideAppId} onChange={glideAppId => setConfig(config => ({ ...config, glideAppId }))} disabled={!hasPermission(workflow.team, "workflows.edit")} />
        <TextInputField label="Table Name" value={config.glideTableName} onChange={glideTableName => setConfig(config => ({ ...config, glideTableName }))} disabled={!hasPermission(workflow.team, "workflows.edit")} />
        <TextInputField label="Column Name" value={config.glideColumnName} onChange={glideColumnName => setConfig(config => ({ ...config, glideColumnName }))} disabled={!hasPermission(workflow.team, "workflows.edit")} />
        {!user?.glideCredentials?.glideAuthToken &&
          <div className="flex gap-2 justify-center text-sm">
            <ExclamationCircleIcon className="w-6 h-6 text-yellow" />
            <span>Invalid or empty authentication token. Please configure the token in <a href="/settings/permissions" target="_blank" rel="noreferrer" className="text-blue underline font-semibold cursor-pointer hover:underline">Settings → Permissions → Glide</a>.</span>
          </div>
        }
        <div className="text-sm font-semibold">Note: The Glide API is limited to Pro/Enterprise level Glide apps</div>
        {hasPermission(workflow.team, "workflows.edit") &&
          <div className="flex justify-between">
            <button className="font-semibold text-sm" onClick={() => props.setOpen(false)} type="button">Cancel</button>
            <button className="btn btn-blue" onClick={updateGlideConfig}>Update</button>
          </div>
        }
      </div>
    </WorkflowOverviewBlockModal>
  );
}

export default WorkflowOverviewBlockGlideWriteToSourceModal;
