import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import { OAuthError, postData, useResource } from '../backend';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/outline';
import { useModal } from '../contexts';
import LoadingModal from "./LoadingModal";
import ErrorModal from "./ErrorModal";
import HeroIcon from "./HeroIcon";
import OAuthModal from "./OAuthModal";
import { Template } from "../interfaces";
import { BLOCK_ICON_MAP } from "../utils";
import useUser from "../hooks/useUser";

function TemplateDetail() {
  const { user } = useUser();

  const templateId = useParams<{ id: string }>().id;
  const template: Template | null = useResource(`/templates/${templateId}`);

  const { hasPermission } = useUser();

  const { openModal, closeModal } = useModal();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auto = searchParams.get("auto") !== null;

  const [hasAutoRun, setHasAutoRun] = useState(false);

  useEffect(() => {
    const pageTitle = `${template?.name} | Portant`;
    if (template?.name && document.title !== pageTitle)
      document.title = pageTitle;

    return (() => { document.title = "Portant" })
  }, [template?.name])

  const createWorkflowRef = useCallback((node: any) => {
    if (!auto || user === undefined || !node || hasAutoRun) {
      return;
    }

    node.click();
    setHasAutoRun(true);
  }, [auto, user, hasAutoRun]);

  const embeddedUrl = (() => {
    const workflow = template?.workflow;

    if (workflow?.previewDocumentType === "GOOGLE_SLIDES")
      return `${workflow?.previewFileUrl}&rm=minimal`;

    return `${template?.workflow?.previewFileUrl?.replace("/edit", "/preview")}?rm=minimal`;
  })();

  if (!template)
    return <div key="loading" className='m-auto'><img src="https://static.portant.co/portant-loading-light-gray.svg" className="w-32 h-32" alt="Loading Logo" /></div>

  async function handleCreateWorkflow() {
    openModal(<LoadingModal title="Creating Workflow from Template..." subtitle="This may take some time as we create the template documents In your Google&nbsp;Drive." />);

    try {
      const workflow = await postData(`/templates/${template!.id}/duplicate/`);

      history.push(`/w/${workflow.id}?tour`);
      closeModal();

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={handleCreateWorkflow} />)
      else
        openModal(<ErrorModal details={error} />)
    }
  }

  // async function createWorkflowOnFocus() {
  //   await refreshUser();
  //   handleCreateWorkflow();
  // }

  return (
    <div className="flex flex-col h-full overflow-hidden">

      <div className="flex items-center border-b border-gray-400 mx-8" style={{ height: "60px" }}>
        <ChevronLeftIcon
          className="h-6 mr-4 my-auto text-gray-600 hover:text-blue cursor-pointer"
          onClick={() => history.push("/templates")}
        />
        <div className="flex justify-center items-center my-auto mr-2 rounded bg-blue" style={{ backgroundColor: template.workflow.color, width: '40px', height: '40px' }}>
          <HeroIcon icon={template.workflow.icon || "DocumentText"} />
        </div>
        <div className="flex flex-col h-full justify-center cursor-pointer group" style={{ maxWidth: "800px" }}>
          <div className="flex items-center w-full">
            <div className="font-semibold font-gilroy truncate">{template.name}</div>
          </div>
        </div>
        <button id="copy-template-button" className='btn btn-blue text-sm py-1 px-4 ml-auto' ref={createWorkflowRef} onClick={handleCreateWorkflow} disabled={!hasPermission(user?.activeTeam, "workflows.create")}>
          <PlusIcon className='w-4 h-4 mr-2' /> Copy Workflow
        </button>
      </div>

      <div className="flex overflow-hidden mx-8 mb-4 bg-[#f9fafa] h-full">
        <div className="relative w-2/3 flex items-center justify-center m-4">
          {embeddedUrl &&
            <iframe title="Goolge File Preview" className="w-full h-full" src={embeddedUrl} />
          }
          {!embeddedUrl &&
            <div className="text-gray-500">Preview Unavailable</div>
          }
        </div>
        <div className="flex flex-col w-2/5 p-8 bg-white h-full overflow-y-auto">
          <div className="text-gray-600 font-semibold font-gilroy whitespace-pre-wrap pb-4">
            {template.shortDescription}
          </div>
          <div className="flex items-center mx-auto mb-4 gap-4">
            <img className="w-6 h-6" src={BLOCK_ICON_MAP[template.workflow?.previewSourceType]} alt="Source icon" />
            <ChevronRightIcon className="w-5 h-5 text-gray-400" />
            <img className="w-6 h-6" src={BLOCK_ICON_MAP[template.workflow?.previewDocumentType]} alt="Document icon" />
          </div>
          <div className="text-gray-600 whitespace-pre-wrap pb-4">
            {template.description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateDetail;
