import { DatabaseIcon, FolderOpenIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Workflow } from "../interfaces";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { useContext } from "react";

interface Props {
  className?: string;
  workflow: Workflow;
  size?: 'sm' | 'lg'
}

function WorkflowDetailNavigation(props: Props) {
  const { workflow, className, size = "lg" } = props;
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModal();
  const { url } = useRouteMatch();
  const workflowContext = useContext(WorkflowContext);
  const { automationOptions } = workflowContext;

  function openSource() {
    if (workflow?.source)
      history.push(url + "/source")
    else
      openModal(<WorkflowSelectModal title="Select Source" subtitle="Create or select a source where your data will come from" showSources workflowContext={workflowContext} />)
  }

  const lastViewedOutputs = (() => {
    if (!workflow)
      return 0;

    const key = "lastViewedOuputsMap";
    const lastViewedOuputsMapString = localStorage.getItem(key) ?? "{}";

    const lastViewedOuputsMap: Record<string, number> = JSON.parse(lastViewedOuputsMapString);
    return lastViewedOuputsMap[workflow.id] ?? 0;
  })();

  const iconSizeClasses = {
    'lg': 'h-7 w-7',
    'sm': 'h-5 w-5',
  }

  const titleSizeClasses = {
    'lg': 'text-xs text-center font-gilroy font-semibold',
    'sm': 'hidden',
  }

  return (
    <div className={`w-full flex flex-row justify-center items-center gap-2 ${className}`}>
      <button className={`group relative w-28 px-6 py-1 rounded-xl cursor-pointer ${location.pathname === (url + "/source") ? "bg-blue-50 text-blue" : "text-gray-400 hover:bg-gray-100"}`} onClick={openSource}>
        <DatabaseIcon className={`mx-auto ${iconSizeClasses[size]}`} />
        <p className={`${titleSizeClasses[size]}`}>
          Source
        </p>
        {(automationOptions?.sourceItemIds ?? []).length > 0 &&
          <div className="absolute bg-blue border-2 border-white drop-shadow-sm w-4 h-4 top-1 right-4 rounded-full" />
        }
      </button>

      <Link
        to={url}
        className={`group relative w-28 px-6 py-1 rounded-xl cursor-pointer ${location.pathname === url ? "bg-blue-50 text-blue" : "text-gray-400 hover:bg-gray-100"}`}
      >
        <LightningBoltIcon className={`mx-auto ${iconSizeClasses[size]}`} />
        <p className={`${titleSizeClasses[size]}`}>
          Workflow
        </p>
      </Link>

      <Link
        className={`group relative w-28 px-6 py-1 rounded-xl cursor-pointer ${location.pathname === (url + "/outputs") ? "bg-blue-50 text-blue" : "text-gray-400 hover:bg-gray-100"}`}
        to={url + "/outputs"}
      >
        <FolderOpenIcon className={`mx-auto ${iconSizeClasses[size]}`} />
        <p className={`${titleSizeClasses[size]}`}>
          Outputs
        </p>
        {(workflow.latestAutomation && workflow.latestAutomation.itemsCompleted > 0 && (lastViewedOutputs < new Date(workflow.latestAutomation.updatedAt).getTime())) &&
          <div className="absolute bg-green border-2 border-white drop-shadow-sm w-4 h-4 top-1 right-4 rounded-full" />
        }
      </Link>
    </div>
  );
}

export default WorkflowDetailNavigation;