import { useEffect, useState } from 'react';
import { postData } from '../backend';
import { Workflow, WorkflowAutomation } from '../interfaces';
import HeroIcon from './HeroIcon';
import useAutomationStatus from '../hooks/useAutomationStatus';

function AppCopperWorkflow(props: { workflow: Workflow, sdk: any }) {
  const { workflow } = props;

  const [requestPending, setRequestPending] = useState(false);
  const [error, setError] = useState<any>();

  const [currentAutomation, setCurrentAutomation] = useState<WorkflowAutomation>();
  const latestAutomationStatus = useAutomationStatus(currentAutomation?.id);

  async function handleStart() {
    setError(undefined);
    setRequestPending(true);

    const { context } = await props.sdk.getContext();
    const copperSourceItemId = context["id"];
    try {
      const automation = await postData(`/workflows/${workflow.id}/run/`, {
        options: {
          mode: "COPPER_SOURCE_ITEM_ID",
          concurrent: true,
          copperSourceItemId,
        }
      });

      setCurrentAutomation(automation);
    } catch (error) {
      setError(error);
    } finally {
      setRequestPending(false);
    }
  }

  useEffect(() => {
    props.sdk.refreshUI({ name: 'ActivityLog' });
    props.sdk.refreshUI({ name: 'Related', data: "files" });
  }, [latestAutomationStatus?.status, props.sdk]);

  const isRunning = requestPending || latestAutomationStatus?.status === "PENDING" || latestAutomationStatus?.status === "RUNNING";
  const isComplete = !requestPending && !error && latestAutomationStatus?.status === "COMPLETED";
  const isError = !requestPending && (!!error || latestAutomationStatus?.status === "ERROR" || latestAutomationStatus?.status === "CANCELLED");

  return (
    <div className="w-full flex flex-row items-center hover:bg-blue-50">
      <div className="flex items-center justify-center w-6 h-6 mr-2 text-white rounded cursor-pointer" style={{ backgroundColor: workflow.color }}>
        <HeroIcon icon={workflow.icon} className="w-4 h-4" />
      </div>
      <div className="flex items-center gap-2 mr-2">
        <div className="font-semibold font-gilroy truncate max-w-[180px] text-gray-600 cursor-pointer hover:underline" onClick={() => window.open(process.env.PUBLIC_URL + "/w/" + workflow.id, workflow.id)}>
          {workflow.name}
        </div>
      </div>
      {
        workflow.status !== "COMPLETE"
          ? <button className={`btn btn-white ml-auto text-xs py-1 w-5 hover:btn-blue before:content-['Configure']`} onClick={() => window.open(process.env.PUBLIC_URL + "/w/" + workflow.id, workflow.id)} />
          : (
            <>
              {isRunning
                ? <button className={`btn btn-white ml-auto text-xs py-1 w-5 before:content-['Running']`} disabled />
                : <button className={`btn btn-white ml-auto text-xs py-1 w-5 hover:btn-blue hover:before:content-['Start'] ${isError ? "btn-red before:content-['Error']" : (isComplete ? "btn-green before:content-['Done']" : "before:content-['Start']")}`} onClick={handleStart} />
              }
            </>
          )
      }
    </div>
  )
}

export default AppCopperWorkflow;
