import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ExternalLinkIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { WorkflowContext } from "../contexts";
import { MIMETYPE_ICON_MAP } from "../utils";
import { WorkflowAutomationItem } from "../interfaces";
import { deleteData, postData } from "../backend";
import useReviewItems from "../hooks/useReviewItems";
import TransitionWorkflowPage from "./transitions/TransitionWorkflowPage";
import TransitionWorkflowPageContent from "./transitions/TransitionWorkflowPageContent";
import TransitionWorkflowPageSideBar from "./transitions/TransitionWorkflowPageSideBar";

function WorkflowReview() {
  const history = useHistory();
  const { url } = useRouteMatch();

  const location = useLocation();
  const itemId = new URLSearchParams(location.search).get("item");

  const { id: workflowId } = useParams<{ id?: string }>();
  const { data: reviewItems, mutate } = useReviewItems(workflowId);

  const [currentItem, setCurrentItem] = useState<WorkflowAutomationItem>();
  const [isReady, setIsReady] = useState(false);

  const { showAutomationPanel } = useContext(WorkflowContext);
  const [currentOutputIndex, setCurrentOutputIndex] = useState(0);

  const [currentState, setCurrentState] = useState<"INITIAL" | "APPROVING" | "DELETING">("INITIAL");

  useEffect(() => {
    if (!(itemId && reviewItems))
      return;

    setCurrentItem(reviewItems.find(item => item.id === itemId));
    setCurrentOutputIndex(0);
  }, [itemId, reviewItems]);

  function setCurrentItemOutput(item: WorkflowAutomationItem, outputIndex: number) {
    history.push(url + "/review?item=" + item.id);
    setCurrentOutputIndex(outputIndex);
  }

  async function handleApprove() {
    setCurrentState("APPROVING");
    await postData(`/workflows/automation-items/${itemId}/approve-and-process/`)
      .catch(console.error);

    setTimeout(moveToNextItem, 1000);
  }

  async function handleDeleteItem() {
    setCurrentState("DELETING");
    await deleteData(`/workflows/automation-items/${itemId}/`)
      .catch(console.error);

    setTimeout(moveToNextItem, 1000);
  }

  function moveToNextItem() {
    mutate();
    setCurrentState("INITIAL");
    setCurrentOutputIndex(0);

    const index = reviewItems!.findIndex(item => item.id === currentItem?.id);

    const nextIndex = index + 1;
    const nextItem = reviewItems![nextIndex];

    if (nextItem)
      return history.push(url + "/review?" + new URLSearchParams({ item: nextItem.id }).toString());

    const previousIndex = index - 1;
    const previousItem = reviewItems![previousIndex];

    if (previousItem)
      return history.push(url + "/review?" + new URLSearchParams({ item: previousItem.id }).toString());

    history.push(url + "/outputs");
  }

  if (!location.pathname.endsWith("/review"))
    return null;

  if (!currentItem)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className='w-24 h-24'>
          <img src="https://static.portant.co/portant-loading-white.svg" alt="Loading Logo" />
        </div>
      </div>
    );

  const currentOutput = currentItem.outputs[currentOutputIndex];

  return (
    <TransitionWorkflowPage show={location.pathname.endsWith("/review")}>
      <TransitionWorkflowPageSideBar>
        <div className={`flex flex-col gap-1 h-full ${showAutomationPanel && "blur-sm"}`}>
          <div className="flex items-center justify-between font-semibold font-gilroy text-gray-600">
            Documents within this item ({currentItem.outputs.length})
          </div>

          {currentItem.outputs.map((output, index) =>
            <div key={output.id} className="flex items-center gap-1 w-full ">
              <div className="flex items-center gap-1 w-full">
                <img className="h-4 w-4 flex-shrink-0" src={MIMETYPE_ICON_MAP[output.file?.mimeType!]} alt="Google File Icon" />
                <div className={`font-gilroy truncate w-full ${output.id === currentOutput.id ? "text-blue font-semibold" : "text-gray-600 hover:font-semibold"} cursor-pointer`} onClick={() => setCurrentItemOutput(currentItem, index)}>
                  {output.file?.name}
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center gap-2 mt-8">
            {currentState === "INITIAL" &&
              <>
                <button className="btn btn-green text-sm h-10 w-full" onClick={handleApprove}>
                  Approve and Process
                </button>
                <button className="group flex items-center justify-center rounded w-10 h-10 p-0 text-gray-400 border border-gray-400 hover:border-red hover:bg-red flex-shrink-0" onClick={handleDeleteItem}>
                  <TrashIcon className="w-5 h-5 group-hover:text-white" />
                </button>
              </>
            }
            {currentState === "APPROVING" &&
              <button className="btn btn-blue text-sm h-10 w-full" disabled>
                Processing...
              </button>
            }
            {currentState === "DELETING" &&
              <button className="btn btn-red text-sm h-10 w-full" disabled>
                Removing...
              </button>
            }
          </div>
        </div>
      </TransitionWorkflowPageSideBar>

      <TransitionWorkflowPageContent className="relative">
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={MIMETYPE_ICON_MAP[currentOutput.file?.mimeType ?? "application/vnd.google-apps.document"]} alt="Google File Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(currentOutput.file?.url, "_blank")}>
              <div className="truncate max-w-[400px]">
                {currentOutput.file?.name}
              </div>
              {currentOutput.file?.url &&
                <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
              }
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url + "/outputs")} />
        </div>

        {(currentOutput.file && currentOutput.file.mimeType !== "application/pdf")
          ? <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${currentOutput.file.url}?widget&rm=embedded`} onLoad={() => setIsReady(true)} />
          : <div className="m-auto">Unable to display file</div>
        }

        {!isReady && currentOutput.file && (
          <div className='w-full h-full flex flex-col gap-4 mt-14 items-center justify-center bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
            <img src="https://static.portant.co/portant-loading-blue.svg" alt="Loading Logo" className="w-24 h-24" />
            <h3 className="font-gilroy font-semibold text-xl text-gray-900"> Loading File... </h3>
          </div>
        )}
      </TransitionWorkflowPageContent>
    </TransitionWorkflowPage>
  );

}

export default WorkflowReview;
