import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";
import UserPanel from "./User";
import { useState } from "react";
import AIDocumentBuilderPrompt from "./AIDocumentBuilderPrompt";
import AIDocumentExportModal from "./AIDocumentExportModal";
import { useModal } from "../contexts";
import AIDocumentBuilderCardList from "./AIDocumentBuilderCardList";
import AIDocumentSignUp from "./AIDocumentSignUp";
import AIDocumentBuilderEditor from "./AIDocumentBuilderEditor";
import BreadcrumbStepper from "./BreadcrumbStepper";
import { AIDocument, AIDocumentSection } from "../interfaces";
import { postData } from "../backend";

function AIDocumentBuilder() {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { openModal } = useModal();
  const [generatedDocument, setGeneratedDocument] = useState<AIDocument | undefined>();
  const [documentData, setDocumentData] = useState<AIDocument | undefined>();
  const [tab, setTab] = useState(0);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputValue: string = (document.getElementById("prompt-input") as HTMLInputElement).value;
    if (inputValue?.length) {
      setLoading(true);
      try {
        const sections = await postData("/documentai/sections/", { prompt: inputValue });

        setDocumentData(sections);
        setGeneratedDocument(sections); // Duplicate to "undo" if user changes the sections
        setTab(tab => tab + 1);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const deleteSection = (sectionId: number) => {
    setDocumentData(prevDocument => {
      if (!prevDocument?.sections) return;
      let newSections = prevDocument.sections.filter(section => section.id !== sectionId);

      return ({ ...prevDocument, sections: newSections })
    })
    setHasChanges(true);
  }

  const updateSection = (sectionId: number, key: 'name' | 'description' | 'content', value: string) => {
    setDocumentData(prevDocument => {
      if (!prevDocument?.sections) return;
      const index = prevDocument.sections.findIndex(section => section.id === sectionId);
      if (index === -1) return;
      let newSections = prevDocument.sections;
      newSections[index][key] = value;
      tab === 1 && setHasChanges(true);
      return { ...prevDocument, sections: newSections };
    })
  }

  const insertSection = (sectionId?: number) => {
    setDocumentData(prevDocument => {
      if (!prevDocument?.sections) return;
      const index = prevDocument.sections.findIndex(section => section.id === sectionId);
      if (index === -1) return;
      let newSections = [...prevDocument.sections];
      newSections.splice(index + 1, 0, { id: Math.round(Math.random() * 1000), description: "This sections' description", name: 'Section Title' })
      setHasChanges(true);
      return { ...prevDocument, sections: newSections };
    })
  }

  const handleUndoChanges = () => {
    if (generatedDocument) {
      setDocumentData({ ...generatedDocument })
      setHasChanges(false);
    }
  }

  const handleNext = async () => {

    if (tab === 1)
      await generateDocumentSampleData();

    if (tab < 2)
      setTab(tab => tab + 1);

    if (!user && tab === 2)
      return openModal(<AIDocumentSignUp />)

    if (user && tab === 2)
      return openModal(<AIDocumentExportModal document={documentData!} />)
  }

  const generateDocumentSampleData = async () => {
    if (!documentData || documentData?.sections?.length < 1)
      return;

    try {
      setLoading(true);
      const content: AIDocumentSection[] = await postData("/documentai/samples/", { sections: documentData.sections });
      setDocumentData(d => ({ ...d!, sections: content }));

    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col h-screen">
      <header className="grid grid-cols-3 items-center bg-white pt-4 px-6">
        <Link to="/" className='h-10 w-24 col-start-1 flex items-center justify-start cursor-pointer hover:bg-gray-100'>
          <img src="https://static.portant.co/logo-mark-blue.svg" className='w-6 h-6' alt="Portant Logo" />
          <h1 className='font-causten font-semibold text-blue text-xl ml-1' style={{ textDecorationColor: user?.activeTeam?.brandingColour }}>portant</h1>
        </Link>
        {tab > 0 && (
          <div className="col-start-2 flex items-center justify-center">
            <BreadcrumbStepper currentStep={tab} steps={['Enter prompt', 'Edit contents', 'Generate document']} />
          </div>
        )}
        {tab > 0 && (
          <div className="flex justify-end col-start-3 gap-8">
            {hasChanges && (
              <button
                className="btn btn-white border-none shadow-none font-bold hover:shadow-none"
                onClick={handleUndoChanges}
                disabled={loading}
              >
                Undo changes
              </button>
            )}
            {tab === 2 && (
              <button
                className="btn btn-blue flex gap-2"
                onClick={handleNext}
                disabled={loading}
              >
                Export
              </button>
            )}
            {user && <UserPanel hideGuides hideUsage />}
          </div>
        )
        }
      </header>
      <main className="overflow-auto flex-grow pt-24">
        {loading && (
          <div className="flex flex-col items-center justify-center gap-4">
            <h2 className="text-2xl font-gilroy font-bold">Generating...</h2>
            <img src="https://static.portant.co/portant-loading-blue.svg" className="w-24 h-24" alt="Loading Logo" />
          </div>
        )}
        {(!loading && tab === 0) &&
          <AIDocumentBuilderPrompt handleSubmit={handleSubmit} />
        }
        {(!loading && tab === 1 && documentData) &&
          <AIDocumentBuilderCardList deleteSection={deleteSection} documentData={documentData} updateSection={updateSection} insertSection={insertSection} onNext={handleNext} />
        }
        {(!loading && tab === 2 && documentData) &&
          <AIDocumentBuilderEditor document={documentData} updateSection={updateSection} />
        }
      </main>
    </div>
  );
}

export default AIDocumentBuilder;
