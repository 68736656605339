import { deleteData } from '../backend';
import { useModal } from '../contexts';
import { BLOCK_ICON_MAP } from '../utils';
import Modal from './Modal';
import useUser from '../hooks/useUser';

function SettingsModalCopper(props: { onDisconnect?: () => void; }) {
  const { refreshUser } = useUser();
  const { closeModal } = useModal();

  async function handleDeleteCopperCredentials() {
    await deleteData("/copper/access-token/")
      .catch(console.error);

    props.onDisconnect?.();
    refreshUser();
    closeModal();
  }

  return (
    <Modal
      primaryButtonProps={{ text: "Disconnect", onClick: handleDeleteCopperCredentials, className: "btn-red" }}
      className='relative justify-start'
      size='sm'
    >
      <div className="flex items-center gap-2">
        <img className="h-10 w-10" src={BLOCK_ICON_MAP["COPPER"]} alt="Copper Icon" />
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold">
            Copper
          </div>
          <div className="text-gray-600 text-sm" onClick={handleDeleteCopperCredentials}>Portant currently has access to your data in Copper</div>
        </div>
      </div>
    </Modal>
  );
}

export default SettingsModalCopper;
