import { useEffect, useState } from 'react';
import { postData, useResource } from '../backend';
import { SubscriptionTier } from '../interfaces';
import Modal from './Modal';


function PayWallModalPortantAI() {
  const tier: SubscriptionTier | null = useResource("/billing/tiers/portant-ai/");

  const [checkoutUrl, setCheckoutUrl] = useState<string>();

  useEffect(() => {
    if (!tier)
      return

    postData(`/billing/tiers/${tier.slug}/checkout/`, { stripePriceId: tier.stripeMonthlyPriceId })
      .then(data => setCheckoutUrl(data.url));
  }, [tier]);

  return (
    <Modal
      title="Portant AI"
      subtitle="Generate content for documents easily with ChatGPT in Portant"
      primaryButtonProps={{ text: "Add to Portant", onClick: () => checkoutUrl && window.open(checkoutUrl, "_blank") }}
      size='sm'
    >
      <div className="flex flex-col gap-1 w-full">
        <div className="font-gilroy font-semibold text-purple">
          Portant AI
        </div>
        <div className="flex items-center">
          <div className="flex items-start flex-wrap flex-shrink-0">
            <div className="text-sm">$</div>
            {!!tier
              ? <div className="text-3xl font-semibold">{String(Math.floor(tier.stripeMonthlyPrice / 100))}<span className="text-xs ml-1 mt-auto mb-1">/month (USD)</span></div>
              : <div className="text-3xl font-semibold loading-ellipse" />
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PayWallModalPortantAI;
