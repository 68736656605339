import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { AIDocument, AIDocumentSection } from "../interfaces";

type Props = {
  documentData: AIDocument;
  updateSection: (index: number, key: 'name' | 'description', value: string) => void;
  deleteSection: (index: number) => void;
  insertSection: (index: number) => void;
  onNext: () => void;
}

function AIDocumentBuilderCardList(props: Props) {
  const { documentData, updateSection, deleteSection, insertSection } = props;

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <h1 className="font-semibold text-gray-600 text-4xl text-center">{documentData.name}</h1>
      <h4 className="text-gray-400 text-center font-semibold mb-4">Edit or delete sections to refine the structure of your document</h4>
      <div className="flex items-center gap-4 overflow-x-scroll w-[calc(100%-100px)] p-4 bg-gray-100 rounded shadow border">
        {documentData.sections.map((section: AIDocumentSection) => (
          <div className="relative flex flex-col bg-white p-8 group hover:outline-blue outline outline-transparent transition-colors flex-shrink-0 rounded shadow border w-[300px] h-[400px] gap-8" key={section.id}>
            <input
              value={section.name}
              className="focus-visible:outline-none font-bold"
              onChange={({ target }) => updateSection(section.id, 'name', target.value)}
            />
            <textarea
              value={section.description}
              className="focus-visible:outline-none resize-none min-h-[100px] overflow-visible"
              onChange={({ target }) => updateSection(section.id, 'description', target.value)}
              maxLength={150}
            />
            <button
              onClick={() => deleteSection(section.id)}
              className="absolute -top-2 -right-2 w-7 h-7 rounded flex items-center justify-center bg-gray-400 bg-opacity-90 group-hover:visible invisible hover:bg-red"
            >
              <TrashIcon className="h-4 w-4 text-white" />
            </button>
            <button
              onClick={() => insertSection(section.id)}
              className="absolute -right-4 top-1/2 -translate-y-1/2 w-7 h-7 rounded flex items-center justify-center bg-gray-400 group-hover:visible invisible hover:bg-blue"
            >
              <PlusIcon className="h-4 w-4 text-white" />
            </button>
          </div>
        ))}
      </div>
      <button className="btn btn-blue text-xl w-80 mt-4" onClick={props.onNext}>
        Next
      </button>
    </div>
  );
}

export default AIDocumentBuilderCardList;
