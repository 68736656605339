import { postData, useResource } from "../backend";
import useUser from "../hooks/useUser";
import moment from "moment";
import { SubscriptionTier } from "../interfaces";
import SettingsBillingTier from "./SettingsBillingTier";
import ToggleButton from "./ToggleButton";
import { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { useModal } from "../contexts";
import UpgradeSuccessModal from "./UpgradeSuccessModal";

function SettingsBilling() {
  const tiers: Array<SubscriptionTier> | undefined = useResource("/billing/tiers/") || undefined;

  const { pathname } = useLocation();

  const { user } = useUser();
  const { openModal } = useModal();

  const [showAnnualPrices, setShowAnnualPrices] = useState(false);
  const [hasShownSuccessModal, setHasShownSuccessModal] = useState(false);

  function getTier(slug: string) {
    return tiers?.find((tier: SubscriptionTier) => tier.slug === slug);
  }

  function openCustomerPortal() {
    postData(`/billing/customer-portal/`, {})
      .then(data => window.open(data.url, "_blank"))
  }

  function openIntercom() {
    // @ts-ignore
    window.Intercom("show");
  }

  const currentTier: SubscriptionTier | undefined = tiers?.find((tier: SubscriptionTier) => user?.subscription?.tier === tier.slug);

  useEffect(() => {
    if (!pathname.endsWith("success") || hasShownSuccessModal)
      return;

    openModal(<UpgradeSuccessModal />);
    setHasShownSuccessModal(true);

  }, [pathname, openModal, hasShownSuccessModal, setHasShownSuccessModal])

  useEffect(() => {
    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'view_billing_page' });
  }, []);

  return (
    <div className="flex flex-col p-6 m-2 overflow-hidden w-full items-start overflow-y-auto mb-10">
      <div className="font-gilroy font-semibold text-lg">
        Billing
      </div>
      <div className="text-gray-400 mb-4">
        Manage your subscription with Portant Workflow.
      </div>

      {!tiers &&
        <div className="w-full h-full flex items-center justify-center">
          <img src="https://static.portant.co/portant-loading-gray.svg" className="m-16 w-24 h-24" alt="Loading Logo" />
        </div>
      }

      {tiers &&
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap items-center font-gilroy text-gray-600 w-full pb-4 border-b bordeer-gray-400">
            <div className=" text-2xl font-semibold text-black">Current Plan</div>
            <div className="font-semibold ml-20 flex origin-center gap-2">
              {currentTier?.name}
              {user?.subscription?.featurePortantAi &&
                <div className="text-purple">
                  + Portant AI
                </div>
              }
            </div>

            {user?.subscription?.stripeCustomerId &&
              <button className="btn btn-white ml-auto" onClick={openCustomerPortal} disabled={user!.id !== user?.subscription?.owner}>
                Manage
              </button>
            }

            <div className="text-xs w-full flex items-center gap-1">
              {!!user?.subscription?.dateValidTo &&
                <div>Current subscription period valid until <span className="font-semibold">{moment(user?.subscription?.dateValidTo).format('DD MMM YYYY')}</span>.</div>
              }
              <div>If you have any questions, please <button className="hover:text-blue underline cursor-pointer" onClick={openIntercom} >contact us</button>.</div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col flex-shrink-0 mr-4 lg:w-40">

              <div className="flex flex-col font-gilroy text-gray-400 text-sm w-full gap-8">
                <div className="flex flex-col">
                  <div className="text-2xl text-black font-semibold">Upgrade Plan</div>
                  <div>Choose the perfect plan to meet your needs.</div>
                </div>

                <div className="">All prices shown in <span className="font-semibold">USD</span></div>

                <div className="flex flex-col gap-1">
                  <div>
                    <span className="inline-block font-semibold px-2 bg-green text-white rounded-sm">Save 16%</span> with an <span className="font-semibold">annual</span>&nbsp;subscription
                  </div>
                  <div className="flex items-center font-semibold gap-2">
                    <ToggleButton value={showAnnualPrices} onChange={setShowAnnualPrices} bgColor="bg-purple" />
                    Show prices
                  </div>
                </div>
              </div>

            </div>
            <div className="flex flex-col lg:flex-row gap-4 pt-6">
              <SettingsBillingTier tier={getTier("free")} currentTier={currentTier} showAnnualPrice={showAnnualPrices}>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Create document and email* workflows
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Create PDF documents*
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Chat and email support
                </div>
                <div className="flex items-start text-xs mt-2 font-semibold text-gray-400 ml-6">
                  *with Portant branding
                </div>
              </SettingsBillingTier>

              <SettingsBillingTier tier={getTier("pro")} currentTier={currentTier} showAnnualPrice={showAnnualPrices}>
                <div className="flex items-start text-sm mt-2 font-semibold text-gray-400">
                  Everything in Free plus:
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Remove Portant branding
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Send emails from aliases
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Review document blocks
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Request multiple signatures with Portant
                </div>
              </SettingsBillingTier>

              <SettingsBillingTier tier={getTier("teams")} currentTier={currentTier} showAnnualPrice={showAnnualPrices}>
                <div className="flex items-start text-sm mt-2 font-semibold text-gray-400">
                  Everything in Pro plus:
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Create teams in Portant of up to 5 users*
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Personal and teams folders
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Access to priority support
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Custom branded eSignature portal and confirmation link
                </div>
                <div className="flex items-start text-xs mt-2 font-semibold text-gray-400 ml-6">
                  *additional users $25/mo
                </div>
              </SettingsBillingTier>

              <SettingsBillingTier tier={getTier("enterprise")} currentTier={currentTier} showAnnualPrice={showAnnualPrices}>
                <div className="flex items-start text-sm mt-2 font-semibold text-gray-400">
                  Everything in Teams plus:
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Bespoke amount of users
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Bespoke billing
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  User audit trail
                </div>
                <div className="flex items-start font-gilroy font-semibold text-gray-600 text-sm mt-2">
                  <CheckCircleIcon className={"text-green w-4 h-4 mr-2 mt-px flex-shrink-0"} />
                  Custom solutions depending on business needs
                </div>
              </SettingsBillingTier>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsBilling;
