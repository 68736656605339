import React from 'react';
import { useModal } from '../contexts';
import Modal from './Modal';

interface Props {
  onConfirm: () => void;
  message?: string;
  block?: any;
}

function WorkflowModalRemoveBlock(props: Props) {
  const { closeModal } = useModal();

  let deleteText = <p className="font-semibold text-sm text-gray-500">{props.message}</p>;

  if (props.block?.documentType)
    deleteText = <p className="font-semibold text-sm text-gray-500">This will <b>NOT</b> delete the attached file from your Google Drive.</p>

  if (props.block?.emailType)
    deleteText = <p className="font-semibold text-sm text-gray-500">This will delete the email content and settings.</p>

  return (
    <Modal
      title={
        <h3 className="font-gilroy font-semibold text-xl text-red">
          Remove Block
        </h3>
      }
      subtitle={deleteText}
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Confirm", onClick: props.onConfirm }}
    />
  );
}

export default WorkflowModalRemoveBlock;
