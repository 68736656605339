import DOMPurify from 'dompurify';
import Modal from './Modal';
import { ALLOWED_TAGS } from '../utils';

interface Props {
  htmlString: string;
}

function HTMLPreviewModal(props: Props) {
  const { htmlString } = props;

  return (
    <Modal size='xl'>
      <div className='w-full h-full relative min-w-[50vw] min-h-[50vh] max-h-[90vh] overflow-auto'>
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlString, { ALLOWED_TAGS }) }}
        />
      </div>
    </Modal>
  );
}

export default HTMLPreviewModal;
