import { Transforms } from "slate";
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";

import { Image } from "../../interfaces";


type ImageRenderElementProps = RenderElementProps & { element: Image };

function ImageElement(props: ImageRenderElementProps) {
  const { attributes, children, element } = props;

  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes} className="inline-block">
      {children}
      <div contentEditable={false} className="relative">
        <img
          alt="User selected"
          src={element.fromDrive ? `https://drive.google.com/thumbnail?id=${element.url}&sz=w${element.size === "small" ? "200" : "600"}` : element.url}
          width={element.size === "small" ? "200px" : "auto"}
          className="block max-w-600"
          style={{ ...(selected && focused && { outline: "4px solid #1875FB", outlineOffset: "-4px" }) }}
        />
        <div className={`absolute justify-center items-center top-2 left-2 bg-white text-xs p-2 shadow border-black border-[1px] w-52 ${selected && focused ? "flex" : "hidden"}`}>
          <div
            className={`${element.size === "small" ? "text-black font-semibold" : "text-blue underline cursor-pointer"}`}
            onClick={() => element.size !== "small" && Transforms.setNodes(editor, { size: "small" }, { at: path })}
          >
            Small
          </div>
          <div className="mx-2 bg-gray-400 w-[1px] h-4" />
          <div
            className={`${element.size === "original" ? "text-black font-semibold" : "text-blue underline cursor-pointer"}`}
            onClick={() => element.size !== "original" && Transforms.setNodes(editor, { size: "original" }, { at: path })}
          >
            Original size
          </div>
          <div className="mx-2 bg-gray-400 w-[1px] h-4" />
          <div className="text-blue underline cursor-pointer" onClick={() => Transforms.removeNodes(editor, { at: path })}>Remove</div>
        </div>
      </div>
    </div>
  )
}

export default ImageElement;
