import { ExternalLinkIcon, XIcon } from "@heroicons/react/outline";
import { useContext, } from "react";
import { WorkflowContext } from "../contexts";
import { MIMETYPE_ICON_MAP } from "../utils";
import { Transition } from '@headlessui/react'
import { WorkflowAutomationItem } from "../interfaces";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";

interface Props {
  automationItem?: WorkflowAutomationItem;
}

function WorkflowOutputsPreview(props: Props) {
  const { automationItem } = props;
  const { showAutomationPanel } = useContext(WorkflowContext);

  const { url } = useRouteMatch();

  const history = useHistory();
  const location = useLocation();

  const index = (() => {
    const hash = location.hash.split("#")[1];
    return (parseInt(hash) || 1) - 1;
  })();

  const currentOutput = automationItem?.outputs[index];

  if (!automationItem || !currentOutput)
    return null;

  // TODO: The transition on this one needs to be fixed up as it will be unmounted before tranisitioned out

  return (
    <Transition show={true} className="absolute top-0 flex flex-row-reverse w-full h-full overflow-hidden" appear unmount>

      <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-2 overflow-auto ${showAutomationPanel && "blur-sm"}`}
        enter="transition ease-in-out duration-150 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-150 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >

        <div className="flex flex-col">
          <div className="font-semibold font-gilroy text-gray-600">
            Preview
          </div>
          <div className="font-semibold font-gilroy text-gray-400 text-sm">
            Documents created from the same source item
          </div>
        </div>

        {automationItem.outputs.map((output, index) =>
          <div key={output.id} className="flex items-center gap-1 w-full">
            <div className="flex items-center gap-1">
              <img className="h-4 w-4" src={MIMETYPE_ICON_MAP[output.file?.mimeType!]} alt="Google File Icon" />
              <Link to={`${url}/outputs?preview=${automationItem.id}#${index + 1}`} className={`font-gilroy truncate max-w-[280px] ${output.id === currentOutput.id ? "text-blue font-semibold" : "text-gray-600 hover:font-semibold"} cursor-pointer`}>
                {output.file?.name}
              </Link>
            </div>
          </div>
        )}
      </Transition.Child>

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={MIMETYPE_ICON_MAP[currentOutput.file?.mimeType!]} alt="Google File Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(currentOutput.file?.url, "_blank")}>
              <div className="truncate max-w-[400px]">
                {currentOutput.file?.name}
              </div>
              {currentOutput.file?.url &&
                <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
              }
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url + "/outputs")} />
        </div>
        {(currentOutput.file && currentOutput.file.mimeType !== "application/pdf")
          ? <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${currentOutput.file!.url}?widget&rm=embedded`} />
          : <div className="m-auto">Unable to display preview</div>
        }
      </Transition.Child>

    </Transition>
  );

}

export default WorkflowOutputsPreview;
