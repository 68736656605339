import { useState } from "react";
import { postData } from "../backend";
import { BLOCK_ICON_MAP } from "../utils";
import Modal from "./Modal";
import { AIDocument } from "../interfaces";

interface Props {
  document: AIDocument
}

function AIDocumentExportModal(props: Props) {

  const [exportingMap, setExportingMap] = useState<any>({});
  const [outputs, setOutputs] = useState<any>({});

  function exportWithFormat(format: string) {
    if (outputs[format]) {
      const data = outputs[format];
      return window.open(data.url, "_blank");
    }

    if (exportingMap[format])
      return;

    setExportingMap((prev: any) => ({ ...prev, [format]: true }));

    postData("/documentai/export/", { document: props.document, format })
      .then(data => {
        setOutputs((outputs: any) => ({ ...outputs, [format]: data }));
        window.open(data.url, "_blank");
      }).finally(() => setExportingMap((prev: any) => ({ ...prev, [format]: false })));
  }

  return (
    <Modal
      title="Export"
      subtitle="Please select a file format to export"
      className="gap-y-12"
      size='sm'
    >
      <div className="flex flex-col gap-4">
        <button className="flex items-center gap-4" onClick={() => exportWithFormat("application/vnd.google-apps.document")}>
          <img src={BLOCK_ICON_MAP.GOOGLE_DOCS} className="h-10 w-10" alt="list bullet" />
          {outputs["application/vnd.google-apps.document"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">{outputs["application/vnd.google-apps.document"].name}</p>
              <div className="text-xs font-semibold text-gray-400 truncate w-80">{outputs["application/vnd.google-apps.document"].url}</div>
            </div>
          }
          {!outputs["application/vnd.google-apps.document"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">Export as Google Doc</p>
              {exportingMap["application/vnd.google-apps.document"] &&
                <div className="text-xs font-semibold text-gray-400">Exporting<span className="loading-ellipse" /></div>
              }
            </div>
          }
        </button>
        <button className="flex gap-4 items-center" onClick={() => exportWithFormat("application/pdf")}>
          <img src={BLOCK_ICON_MAP.PDF} className="h-10 w-10" alt="list bullet" />
          {outputs["application/pdf"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">{outputs["application/pdf"].name}</p>
              <div className="text-xs font-semibold text-gray-400 truncate w-80">{outputs["application/pdf"].url}</div>
            </div>
          }
          {!outputs["application/pdf"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">Export as PDF</p>
              {exportingMap["application/pdf"] &&
                <div className="text-xs font-semibold text-gray-400">Exporting<span className="loading-ellipse" /></div>
              }
            </div>
          }
        </button>
        <button className="flex gap-4 items-center" onClick={() => exportWithFormat("application/vnd.openxmlformats-officedocument.wordprocessingml.document")}>
          <img src={BLOCK_ICON_MAP.MICROSOFT_WORD} className="h-10 w-10" alt="list bullet" />
          {outputs["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">{outputs["application/vnd.openxmlformats-officedocument.wordprocessingml.document"].name}</p>
              <div className="text-xs font-semibold text-gray-400  truncate w-80">{outputs["application/vnd.openxmlformats-officedocument.wordprocessingml.document"].url}</div>
            </div>
          }
          {!outputs["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] &&
            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold text-gray-600 hover:text-blue">Export as Microsoft Word</p>
              {exportingMap["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] &&
                <div className="text-xs font-semibold text-gray-400">Exporting<span className="loading-ellipse" /></div>
              }
            </div>
          }
        </button>
      </div>
    </Modal>
  );
}

export default AIDocumentExportModal;
