import { RefObject, useEffect, useRef, useState } from "react";
import { LastFocusedEditorContext, useModal } from "../contexts";
import { ChatGPTField, Workflow } from "../interfaces";
import Modal from "./Modal";
import TextInputField from "./TextInputField";
import TemplatedTextEditor, { TemplatedTextEditorRef } from "./TemplatedTextEditor";
import { deserializeFromText, serializeToText } from "../emailEditorHelpers";
import { deleteData, postData, putData } from "../backend";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { CheckIcon } from "@heroicons/react/outline";
import PortantAILabel from "./PortantAILabel";
import useUser from "../hooks/useUser";
import PayWallModalPortantAI from "./PayWallModalPortantAI";
import { KeyedMutator } from "swr";

interface Props {
  workflow: Workflow
  mutateWorkflow: KeyedMutator<Workflow>;
  field?: ChatGPTField
}

function WorkflowChatGPTModal(props: Props) {
  const { workflow, mutateWorkflow, field } = props;
  const { openModal, closeModal } = useModal();

  const [fieldType, setFieldType] = useState<string>();
  const [fieldName, setFieldName] = useState<string>();
  const [hasPromptValue, setHasPromptValue] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const promptRef = useRef<TemplatedTextEditorRef>(null);
  const [lastFocusedEditor, setLastFocusedEditor] = useState<RefObject<TemplatedTextEditorRef> | null>(promptRef);

  const { user } = useUser();

  useEffect(() => {
    setFieldName(field?.fieldName ?? "");
    setFieldType(field?.fieldType ?? "TEXT");
    setHasPromptValue(!!field?.prompt);
  }, [field]);

  async function handleSave() {
    setIsSaving(true);
    const prompt = promptRef.current?.getValue();

    if (field)
      await putData(`/chatgpt/fields/${field!.id}/`, { fieldType, fieldName, prompt })
        .catch(console.error)
        .finally(() => setIsSaving(false));
    else
      await postData(`/workflows/sources/${workflow.source!.id}/chatgpt-fields/`, { fieldName, prompt })
        .catch(console.error)
        .finally(() => setIsSaving(false));

    mutateWorkflow();
    closeModal();
  }

  async function handleDelete() {
    await deleteData(`/chatgpt/fields/${field!.id}/`)
      .catch(console.error)

    mutateWorkflow();
    closeModal();
  }

  return (
    <Modal
      title={
        <div className="flex items-start w-64 gap-2">
          <h3 className="font-gilroy font-semibold text-xl text-gray-900 flex-shrink-0">AI Content Field</h3>
          <PortantAILabel />
        </div>
      }
      subtitle="Use the power of ChatGPT to generate content based on a prompt configured with your source fields"
      primaryButtonProps={{
        onClick: handleSave,
        text: isSaving ? "Saving..." : (!!field ? "Save" : "Create"),
        disabled: isSaving || !fieldName || !hasPromptValue
      }}
      secondaryButtonProps={{
        onClick: handleDelete,
        text: "Delete",
        className: `btn-red ${!!field ? 'block' : 'hidden'}`
      }}
      size="xl"
      className="gap-2 flex flex-col"
    >
      <div className="flex items-center gap-2">
        <TextInputField label="Field Name" className="w-5/12" value={fieldName || ""} onChange={setFieldName} />

        {/* NOTE: Image field types are currently disabled */}
        {/* <SelectField label="Field Type" className="w-1/4" value={fieldType || "TEXT"} onChange={setFieldType}>
            <option value="TEXT">Text</option>
            <option value="IMAGE">Image</option>
          </SelectField> */}

        {!user?.subscription?.featurePortantAi &&
          <div className="flex flex-col font-gilroy font-semibold ml-auto">
            <div className="text-sm text-gray-600">
              Usage
            </div>
            <div className="flex items-center gap-2">
              <div>
                {user?.aiUsage}/<span className="text-xs">10</span>
              </div>
              <button className="text-sm text-blue cursor-pointer hover:underline" onClick={() => openModal(<PayWallModalPortantAI />)}>
                Upgrade
              </button>
            </div>
          </div>
        }

      </div>

      <LastFocusedEditorContext.Provider value={{ editorRef: lastFocusedEditor, setEditorRef: setLastFocusedEditor }}>
        <div className="flex gap-2 mt-2 w-full">

          <div className="relative border-2 rounded border-gray-400 w-7/12 flex-shrink-0 flex flex-col justify-between">
            <div className="absolute text-xs left-2 -top-2 bg-white z-10 px-1 font-gilroy font-semibold text-gray-600">
              Prompt
            </div>

            <TemplatedTextEditor
              initialValue={field?.prompt ?? ""}
              className="w-full overflow-y-auto h-48 p-2"
              onChange={() => setHasPromptValue(!!promptRef.current?.getValue())}
              onFocus={() => setLastFocusedEditor(promptRef)}
              deserializer={deserializeFromText}
              serializer={serializeToText}
              ref={promptRef}
              workflow={workflow}
            />

            <div className="bg-gray-100 rounded-b-sm p-2 flex flex-col gap-1 select-none text-xs">
              <div className="font-semibold font-gilroy text-gray-600">
                Examples
              </div>
              <ul className="list-disc ml-3">
                <li>Write a [short/long] email to invite [name] to a demo of [product/service]</li>
                <li>Write the key responsibilities and qualifications for a [position] role</li>
                <li>Write a [short/long] social media marketing plan for [product/brand]</li>
              </ul>
            </div>
          </div>

          <div className="w-5/12">
            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} hideChatGPTField />
          </div>

        </div>
      </LastFocusedEditorContext.Provider>

      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col">
          <div className="text-xs text-gray-600">
            <div>
              ChatGPT is trained on data collected from the internet and may produce <b>inaccurate</b> or <b>incorrect</b> content.
            </div>
            <div className="flex items-center gap-1">
              Please consider using a <a className="text-blue underline cursor-pointer" href=" https://www.youtube.com/watch?v=q-aATNuVIUU" target="_blank" rel="noreferrer">Review Block</a> {!!workflow.review && <CheckIcon className="w-4 h-4 text-green" />} when generating content using ChatGPT. <a className="text-blue underline" href="https://www.portant.co/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
}

export default WorkflowChatGPTModal;
