import { useModal } from '../contexts';
import { useState } from 'react';
import { postData } from '../backend';
import { useHistory } from 'react-router-dom';
import useUser from '../hooks/useUser';
import Modal from './Modal';
import TextInputField from './TextInputField';

function CreateTeamModal() {
  const history = useHistory();

  const { refreshUser } = useUser();
  const { closeModal } = useModal();

  const [teamName, setTeamName] = useState<string>('');

  const [invalid, setInvalid] = useState(false);
  const [saving, setSaving] = useState(false);

  function handleUpdateName(name: string) {
    setInvalid(!name);
    setTeamName(name);
  }

  function handleCreate() {
    if (!teamName)
      return setInvalid(true);

    setSaving(true);
    postData("/auth/team/", { name: teamName })
      .then((team) => {
        setSaving(false);

        refreshUser();
        closeModal(() => history.push("/settings/team"));
      })
      .catch(console.error);
  }

  return (
    <Modal
      title="Thank you for signing up to Teams 🎉"
      subtitle="What is the name of your team?"
      preventClose
      primaryButtonProps={{
        onClick: handleCreate,
        disabled: saving,
        text: saving ? "Creating Team..." : "Create Team"
      }}
      size='sm'
    >
      <TextInputField className="w-full mb-2" value={teamName} onChange={handleUpdateName} onEnter={handleCreate} invalid={invalid} />
      <p className="text-sm font-semibold mb-4 text-center">You can change this later in Settings &gt; Team</p>
    </Modal>
  );
}

export default CreateTeamModal;
