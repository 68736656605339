import Modal from './Modal';
import { DragEvent, useRef, useState } from 'react';
import { DocumentTextIcon, UploadIcon } from '@heroicons/react/outline';
import { useModal } from '../contexts';
import { BLOCK_ICON_MAP } from '../utils';

interface Props {
  onUpload: (file: File) => void;
}

function FileUploadModal(props: Props) {

  const { closeModal } = useModal();

  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    setFile(file);
  };

  return (
    <Modal
      title="Upload File"
      size="sm"
      primaryButtonProps={{
        text: 'Upload',
        onClick: () => { props.onUpload(file!) },
        disabled: !file
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: () => closeModal()
      }}
      className='flex flex-col items-center justify-center gap-4 text-center'
    >
      <div className='flex flex-col gap-4 w-full h-full'>
        <div
          className='w-full h-full min-h-[250px] rounded-lg border border-dashed border-gray-300 flex flex-col gap-4 items-center justify-center mt-4 cursor-pointer hover:bg-gray-50 transition-colors'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => uploadRef.current?.click()}
        >
          <div className='relative w-16 h-16'>
            <DocumentTextIcon className='w-16 h-16 text-gray-400' />
            <span className='bg-purple absolute right-0 bottom-0 rounded-full p-1'>
              <UploadIcon className='text-white w-4 h-4' />
            </span>
          </div>
          <p className='text-xs font-bold'>
            {isDragging
              ? <>Drop your files here...</>
              : <>Drag and drop file here or <u>select file</u></>
            }
          </p>
        </div>
        <div className='flex justify-between items-center -mt-2'>
          <p className='text-xs text-gray-400 font-semibold'>Supported formats: PDF</p>
          <p className='text-xs text-gray-400 font-semibold'>PDFs must contain form fields</p>
        </div>
        <div className='bg-gray-100 rounded w-full flex items-center gap-2 px-2 h-12'>
          {!file &&
            <div className='font-semibold text-gray-400 font-gilroy text-sm'>No file selected</div>
          }
          {file &&
            <>
              <img src={BLOCK_ICON_MAP["PDF"]} alt='PDF' className='w-8 h-8' />
              <div className='font-semibold text-gray-600 font-gilroy text-sm'>{file.name}</div>
            </>
          }
        </div>
        <input
          className='h-0 w-0 invisible absolute pointer-events-none'
          type="file"
          ref={uploadRef}
          accept='application/pdf'
          onChange={e => setFile(e.currentTarget.files?.[0] ?? null)}
        />
      </div>
    </Modal>
  );
}

export default FileUploadModal;
