import Modal from './Modal';
import { useModal } from "../contexts";
import { SCOPE_MAP } from "../utils";

interface Props {
  scopes?: Array<string>;
  oauthUrl: string;
  onClose?: () => void;
  title?: string;
}

function OAuthModal(props: Props) {
  const { closeModal } = useModal();

  function handleClickSingIn() {
    window.open(props.oauthUrl);
    window.addEventListener("focus", closeModalOnFocus);
  }

  async function closeModalOnFocus() {
    window.removeEventListener("focus", closeModalOnFocus);
    closeModal(props.onClose);
  }

  const urlSearchParams = new URLSearchParams(props.oauthUrl);
  const params = Object.fromEntries(urlSearchParams.entries());
  const scopes = props.scopes || params.scope?.split(" ") || ["https://www.googleapis.com/auth/drive"];

  const descriptions = scopes.map(scope => {
    const scopeInfo = SCOPE_MAP[scope];
    if (!scopeInfo)
      return null;

    return (
      <div key={scope} className="flex gap-2 items-center">
        <img src={scopeInfo.icon} className='w-16 h-16 flex-shrink-0' alt="Icon" />
        <div key={scope} className="flex flex-col">
          <div className='font-gilroy font-semibold text-gray-600'>
            {scopeInfo.title}
          </div>
          <div style={{ width: "460px" }}>
            {scopeInfo.description}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Modal
      title={props.title || "Portant requires access to your Google Drive"}
      subtitle={
        <div className="mt-4 flex flex-col gap-4 text-gray-400">
          {descriptions}
        </div>
      }
      size='sm'
      className='flex justify-center'
    >
      <button className='btn-google self-end' onClick={handleClickSingIn} />
    </Modal>
  );
}

export default OAuthModal;
