interface Props {
  value: boolean
  onChange: (f: boolean) => void;
  disabled?: boolean;
  bgColor?: string;
}

function ToggleButton(props: Props) {

  function handleToggle() {
    if (props.disabled)
      return;

    props.onChange(!props.value);
  }

  let className = `justify-end ${props.disabled ? "bg-gray-400" : "bg-gray-300"}`;
  if (props.value)
    className = `justify-start ${props.disabled ? "bg-gray-400" : (props.bgColor ?? "bg-blue")}`;

  let style = {};
  if (props.value)
    style = { transform: `translateX(16px)` }

  return (
    <button className={`relative ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={handleToggle}>
      <input type="checkbox" className="sr-only" checked={props.value} onChange={handleToggle} tabIndex={-1} />
      <div className={`flex text-white rounded-full items-center select-none transition-colors ${className}`} style={{ width: "40px", height: "24px" }} />
      <div className={`absolute ${props.disabled ? "bg-gray-100" : "bg-white"} rounded-full transition`} style={{ width: "18px", height: "18px", top: "3px", left: "3px", ...style }}></div>
    </button>
  );
}

export default ToggleButton;
