import { PlusCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { getData, postData } from "../backend";
import { useModal } from "../contexts";
import { SourceCondition, Workflow } from "../interfaces";
import Modal from "./Modal";
import WorkflowSourceConditionGroup from "./WorkflowSourceConditionGroup";

interface Props {
  workflow: Workflow
  mutateWorkflow: () => void;
}

function WorkflowSourceConditionModal(props: Props) {
  const { workflow } = props;
  const { closeModal } = useModal();

  const [source, setSource] = useState(workflow.source!);

  function refreshSource() {
    getData(`/workflows/sources/${source.id}/`)
      .then(setSource)
      .catch(console.error);
  }

  let groups: Array<Array<SourceCondition>> = [];
  for (const condition of (source.sourceConditions ?? [])) {
    const group = groups[condition.group] ?? [];

    group[condition.order] = condition;
    groups[condition.group] = group;
  }

  // Filter out empty groups as groups may have been deleted
  groups = groups.filter(g => !!g)
    .map(group => group.filter(c => !!c))

  const firstGroup = groups[0]?.[0]?.group ?? 0;
  const lastGroup = groups[groups.length - 1]?.[0]?.group ?? 0;

  function handleAddCondition() {
    const newCondition = { group: 0, order: 0, comparison: "IS", field: "", value: "" } as SourceCondition;

    postData(`/workflows/sources/${workflow.source!.id}/conditions/`, newCondition)
      .then(refreshSource)
      .catch(console.error)
  }

  return (
    <Modal
      title="Source Filter Conditions"
      subtitle="Add conditions to only process certain data from a workflow source."
      primaryButtonProps={{ text: groups.length > 0 ? "Save" : "Done", onClick: () => closeModal(() => props.mutateWorkflow()) }}
      size="sm"
    >

      {(groups.length > 0) &&
        <div className="flex flex-col gap-4">
          {groups.map((conditions, index) => <WorkflowSourceConditionGroup key={index} source={source} refreshSource={refreshSource} conditions={conditions} firstGroup={firstGroup} lastGroup={lastGroup} />)}
        </div>
      }

      {(groups.length === 0) &&
        <div className="flex items-center gap-1 mt-1 font-gilroy font-semibold text-blue cursor-pointer hover:underline" onClick={handleAddCondition}>
          <PlusCircleIcon className="w-4 h-4" /> Add Condition
        </div>
      }
    </Modal>
  );
}

export default WorkflowSourceConditionModal;
