import { useContext, useEffect, useState } from "react";
import { OAuthError, PaidFeatureError, BadRequestError, postData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import OAuthModal from "./OAuthModal";
import PayWallModalPro from "./PayWallModalPro";
import InaccessibleFilesModal from "./InaccessibleFilesModal";
import { CollectionIcon, FilterIcon } from "@heroicons/react/outline";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import useAutomationStatus from "../hooks/useAutomationStatus";
import { Link, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";

interface Props {
  disabled?: boolean
  setIsActive?: (isActive: boolean) => void;
}

const MAX_SEGMENTS = 20;

const COMPLETED_COLOUR_MAP: Record<string, string> = {
  "INITIAL": "bg-white/0",
  "CANCELLED": "bg-yellow",
  "COMPLETED": "bg-green",
  "ERROR": "bg-red",
}

function WorkflowAutomationPanelManual(props: Props) {
  const { user } = useUser();

  const { openModal } = useModal();

  const { workflow, mutateWorkflow, automationOptions, updateAutomationOptions, setShowAutomationPanel } = useContext(WorkflowContext);

  const [currentAutomation, setCurrentAutomation] = useState(workflow.latestAutomation);
  const latestAutomationStatus = useAutomationStatus(currentAutomation?.id);

  const dataGroupingField = workflow.source?.config?.dataGroupingField;
  const validDataGroupingField = !!(dataGroupingField && workflow.source?.sourceFields.map(sf => sf.fieldName).includes(dataGroupingField));

  const [error, setError] = useState<string>();
  const [missingFields, setMissingFields] = useState<Array<string> | undefined>();
  const [checkWorkflow, setCheckWorkflow] = useState(true);

  const [isStarting, setIsStarting] = useState(false);
  const [isStopping, setIsStopping] = useState(false);

  const status = !!error ? "ERROR" : currentAutomation?.status ?? (isStarting ? "PENDING" : "INITIAL");
  const active = isStarting || status === "PENDING" || status === "RUNNING";

  const [completed, setCompleted] = useState(0);
  const expected = Math.min(currentAutomation?.itemsExpected ?? 1, MAX_SEGMENTS);

  const { url } = useRouteMatch();

  useEffect(() => {
    if (latestAutomationStatus)

      setCurrentAutomation(latestAutomationStatus);
  }, [latestAutomationStatus, setCurrentAutomation]);

  useEffect(() => {
    const percent = currentAutomation ? (currentAutomation.itemsCompleted / currentAutomation.itemsExpected) : 0;
    const completed = Math.floor(percent * expected);

    const adjustment = (status === "CANCELLED") ? 1 : 0;
    setCompleted(completed - adjustment);

  }, [currentAutomation, expected, status]);

  function handleChangeOverwrite(e: React.ChangeEvent<HTMLInputElement>) {
    updateAutomationOptions({ overwriteOutputs: !automationOptions.overwriteOutputs });
  }

  function handleChangeIgnoreMissing(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckWorkflow(f => !f);
  }

  async function handleRunAutomation() {
    if (!user?.subscription)
      return openModal(<PayWallModalPro />);

    setError(undefined); // clear error
    setMissingFields(undefined);

    setIsStarting(true);

    try {
      setCurrentAutomation(null);

      if (checkWorkflow) // Will throw if workflow checks fail
        await postData(`/workflows/${workflow.id}/check/`);

      const latestAutomation = await postData(`/workflows/${workflow.id}/run/`, { options: automationOptions });

      setCurrentAutomation(latestAutomation);
      mutateWorkflow({ ...workflow, latestAutomation });

      //@ts-ignore
      const dataLayer = window.dataLayer;
      dataLayer.push({ 'event': 'start_automation', 'automation_type': 'manual', 'automation_items_expected': latestAutomation.itemsExpected });

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)

      else if (error instanceof PaidFeatureError)
        openModal(<PayWallModalPro reason={error.reason} />)

      else if (error instanceof BadRequestError) {
        if (error.reason === "MISSING_FIELDS") {
          setCurrentAutomation(null);
          setMissingFields(error.payload.missingFields);

        } else if (error.reason === "INACCESSIBLE_FILES") {
          setCurrentAutomation(null);
          openModal(<InaccessibleFilesModal {...error.payload} />);

        }
      } else
        setError("UNKNOWN");
    };

    setIsStarting(false);
  }

  async function handleStopAutomation() {
    setIsStopping(true);

    await postData(`/workflows/automations/${currentAutomation!.id}/stop/`);
    setCurrentAutomation(automation => ({ ...automation!, status: "CANCELLED" }));
    mutateWorkflow({ ...workflow, latestAutomation: { ...workflow.latestAutomation!, status: "CANCELLED" } });

    setIsStopping(false);
  }

  return (
    <>
      {automationOptions.overwriteOutputs &&
        <div className="flex text-sm items-center w-52 cursor-pointer select-none">
          <input id="overwrite-checkbox" type="checkbox" className="w-4 h-4 rounded-md border text-gray-600 border-gray-400 bg-white mr-2" checked={!!automationOptions.overwriteOutputs} onChange={handleChangeOverwrite} disabled={false} />
          <label htmlFor="overwrite-checkbox">Overwrite existing outputs</label>
        </div>
      }

      {(workflow.source && validDataGroupingField) &&
        <div className="flex text-xs items-center truncate w-64">
          <CollectionIcon className="w-4 h-4 text-gray-600 mr-2 flex-shrink-0" />
          <div>Grouping by <span className="font-semibold">{dataGroupingField}</span></div>
        </div>
      }

      {(workflow.source && workflow.source.sourceConditions.length > 0) &&
        <div className="flex text-xs items-center w-full cursor-pointer select-none text-center hover:text-blue hover:underline" onClick={(e) => { e.stopPropagation(); openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />) }}>
          <FilterIcon className="w-4 h-4 text-gray-600 mr-2" />
          <div>Filtering by <span className="font-semibold">{workflow.source.sourceConditions.length}</span> condition{workflow.source.sourceConditions.length === 1 ? "" : "s"}</div>
        </div>
      }

      <div className="flex flex-col items-center w-full -mb-2">
        {/* Button */}

        {(status === "INITIAL" || status === "COMPLETED" || status === "CANCELLED" || status === "ERROR") &&
          <button className="btn btn-blue w-full" onClick={handleRunAutomation} disabled={props.disabled}>
            Start
          </button>
        }

        {(status === "PENDING" || status === "RUNNING") &&
          <button className="btn btn-blue w-full" disabled>
            Running...
          </button>
        }

        {/* Progress Bar */}

        <div className="flex w-full gap-px h-2 overflow-hidden relative mt-2 px rounded-full bg-gray-100">
          {Array(expected).fill(0).map((_, n) =>
            <div key={n} className={`w-full h-2 transition-colors ${n <= completed ? (COMPLETED_COLOUR_MAP[status] ?? "bg-blue") : "bg-gray-300"} ${(n === completed && (status === "RUNNING" || status === "PENDING")) ? "animate-pulse bg-opacity-50" : "animate-none"}`} />
          )}
        </div>

        <div className="flex items-center justify-between w-full text-xs mt-1">

          {/* Status */}
          {(status === "INITIAL") &&
            <div className="flex"></div>
          }

          {(status === "PENDING") &&
            <div className="flex">
              {(currentAutomation?.itemsExpected ?? 0) > 0
                ? <div className="flex">Preparing {currentAutomation?.itemsExpected} items<span className="loading-ellipse" /> </div>
                : <div className="flex">Preparing automation<span className="loading-ellipse" /> </div>
              }
            </div>
          }

          {(status === "RUNNING") &&
            <div className="flex">Processing item {currentAutomation!.itemsCompleted + 1} of {currentAutomation?.itemsExpected}<span className="loading-ellipse" /></div>
          }

          {status === "CANCELLED" &&
            <div className="flex">
              {currentAutomation?.itemsCompleted === 0
                ? <div className="flex">No items were completed</div>
                : <div className="flex">Completed {currentAutomation?.itemsCompleted} of {currentAutomation?.itemsExpected} items</div>
              }
            </div>
          }

          {status === "COMPLETED" &&
            <div className="w-full text-center">
              <div className="flex">Completed {currentAutomation?.itemsCompleted} items</div>
            </div>
          }

          {status === "ERROR" &&
            <div className="text-gray-600 font-semibold text-xs text-center w-full">An error occured in the previous automation.<br />View <Link className="underline hover:text-blue" to={url + "/automations"} onClick={() => setShowAutomationPanel(false)}>Automation History</Link> for more details.</div>
          }

          {/* Secondary Button */}

          {((status === "PENDING" && !isStarting) || status === "RUNNING") &&
            <button className="font-semibold text-gray-400 disabled:text-gray-600 hover:text-red hover:underline disabled:no-underline cursor-pointer disabled:cursor-progress ml-auto" onClick={handleStopAutomation} disabled={isStopping}>
              Stop Automation
            </button>
          }

          {(status === "CANCELLED") &&
            <div className="font-semibold text-gray-600 ml-auto">Cancelled</div>
          }
        </div>

        {missingFields &&
          <div className="flex flex-col gap-1 mt-2 w-full">
            <div className="font-gilroy font-semibold text-sm text-red">
              Template contains missing fields
            </div>
            {missingFields.map(field =>
              <div key={field} className="text-sm text-gray-600">
                • {'{{'}{field}{'}}'}
              </div>
            )}
            <div className="flex text-sm items-center w-52 cursor-pointer select-none font-semibold">
              <input id="ignore-missing" type="checkbox" className="w-4 h-4 rounded-md border border-gray-400 bg-white mr-2" checked={!checkWorkflow} onChange={handleChangeIgnoreMissing} disabled={active} />
              <label htmlFor="ignore-missing">Ignore missing fields</label>
            </div>
          </div>
        }
      </div>
    </>
  );

}

export default WorkflowAutomationPanelManual;
