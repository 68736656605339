import { Dispatch, SetStateAction, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import DOMPurify from 'dompurify';
import { LastFocusedEditorContext } from "../contexts";
import { HTMLTextEditorRef } from "./TemplatedTextEditor";
import { ALLOWED_TAGS, formatHTML } from '../utils'

type Props = {
  initialValue: string;
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  onChange?: () => void;
  onFocus?: (ref: HTMLInputElement) => void;
}

function HTMLEmailEditor(props: Props) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const newRef = useRef<HTMLTextEditorRef>(null);
  const { setEditorRef } = useContext(LastFocusedEditorContext);
  const [textHTML, setTextHTML] = useState(formatHTML(props.initialValue, true, true));

  useImperativeHandle(newRef, () => ({
    focus: () => inputRef.current!.focus(),
    getValue: () => {
      const spaceRemoved = textHTML.replace(/>\s+</g, '><');
      return DOMPurify.sanitize(spaceRemoved, { ALLOWED_TAGS })
    },
    insertTag: (tag) => handleInsertTag(tag),
  }));

  const handleInsertTag = (tag: string) => {
    const formattedTag = `<span class="tag">{{${tag}}}</span>`
    const inputField = inputRef.current;
    if (!inputField) return;
    if (inputField.selectionStart || inputField.selectionStart === 0) {
      const startPos = inputField.selectionStart;
      const endPos = inputField.selectionEnd;
      let auxValue = inputField.value.substring(0, startPos)
        + formattedTag
        + inputField.value.substring(endPos, inputField.value.length);
      setTextHTML(auxValue)
    } else {
      setTextHTML(val => val + formattedTag)
    }
    inputField.selectionStart += formattedTag.length;
  }


  useEffect(() => {
    if (textHTML) {
      const spaceRemoved = textHTML.replace(/>\s+</g, '><');
      props.setValue?.(DOMPurify.sanitize(spaceRemoved, { ALLOWED_TAGS }));
      props.onChange?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textHTML]);

  if (!DOMPurify.isSupported) return (
    <div className="flex w-full h-full justify-center items-center bg bg-gray-200 text-gray-500 text-lg">
      This feature isn't available on your browser.
    </div>
  );


  return (
    <textarea
      className="w-full flex-grow outline-none resize-none overflow-auto"
      value={textHTML}
      onChange={({ target }) => setTextHTML(target.value)}
      onFocus={() => setEditorRef(newRef)}
      ref={inputRef}
    />
  );
}

export default HTMLEmailEditor;
