import { useState } from "react";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import Modal from "./Modal";
import SmallTextInputField from "./SmallTextInputField";
import { deleteData, putData, uploadFile } from "../backend";
import ErrorModal from "./ErrorModal";
import { PhotographIcon, XIcon } from "@heroicons/react/outline";

function SettingsTeamModalEdit() {
  const { closeModal, openModal } = useModal();

  const { user, refreshUser } = useUser();

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [deleteLogo, setDeleteLogo] = useState<boolean>(false);

  const [team, setTeam] = useState(user!.activeTeam!);

  const [saveState, setSaveState] = useState<"INITIAL" | "SAVING" | "SAVED">("INITIAL")

  const imageBase64 = logoFile ? URL.createObjectURL(logoFile) : team.brandingLogo;

  async function handleSave() {
    setSaveState("SAVING");

    try {
      if (logoFile)
        await uploadFile("/auth/team/upload/", logoFile);

      if (deleteLogo && user?.activeTeam?.brandingLogo)
        await deleteData("/auth/team/delete-logo/");

      await putData("/auth/team/", team)
        .then((team) => {
          refreshUser((user: any) => ({ ...user, team }));
        })

      setSaveState("SAVED");
      closeModal();

    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }
  }

  function updateBrandingColour(value: string) {
    value = value.replaceAll(/[^0-9a-fA-F]/g, "").slice(0, 6).toLowerCase();
    value = "#" + value;

    setTeam(t => ({ ...t, brandingColour: value }))
  }

  return (
    <Modal
      title="Edit Team"
      subtitle="Customize your team's branding"
      secondaryButtonProps={{
        text: "Cancel", onClick: () => closeModal()
      }}
      primaryButtonProps={{
        onClick: handleSave, disabled: saveState === "SAVING", text: saveState === "SAVING" ? "Saving..." : "Save"
      }}
      size="sm"
    >
      <div className="flex flex-col">

        <SmallTextInputField value={team.name} onChange={(name) => setTeam(t => ({ ...t, name }))} label="Team Name" />

        <div className="font-gilroy font-semibold text-sm mt-4">
          Team Branding
        </div>
        <div className="font-gilroy font-semibold text-gray-600 text-sm">
          Branding can be provided to customize your public facing eSignature portal
        </div>

        <div className="flex items-start justify-between mt-2">
          <div className="flex flex-col gap-2">
            <div className="font-gilroy font-semibold text-gray-600 text-sm">
              Color
            </div>

            <div className="flex items-center">
              <div className="w-8 h-8 rounded shadow bg-gray-100" style={{ backgroundColor: team.brandingColour }} />
              <div className="font-gilroy font-semibold text-gray-600 text-lg ml-2 mr-1">#</div>
              <SmallTextInputField className="font-mono" value={team.brandingColour.replace("#", "")} onChange={updateBrandingColour} />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <div className="font-gilroy font-semibold text-gray-600 text-sm">
                Logo (400 x 400px)
              </div>
              <label>
                <div className="btn btn-white h-8 w-32">Upload</div>
                <input type="file" className="invisible absolute" accept="image/*" onChange={e => { setLogoFile(e.currentTarget.files![0]); setDeleteLogo(false); }} />
              </label>
            </div>

            {(imageBase64 ?? team.brandingLogo) && !deleteLogo
              ? (
                <div className="relative group">
                  <XIcon className="absolute -right-1 -top-1 w-4 h-4 text-white cursor-pointer -ml-8 mt-auto rounded-full bg-black hover:bg-red p-px" onClick={() => setDeleteLogo(true)} />
                  <img src={imageBase64 ?? team.brandingLogo} className="h-16 w-16 object-fill shadow" alt="Logo" />
                </div>
              )
              : <PhotographIcon className="w-16 h-16 text-gray-400" />
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SettingsTeamModalEdit;
