import { ExternalLinkIcon, PencilIcon, TagIcon } from '@heroicons/react/outline';
import { patchData, putData } from '../backend';
import { SourceField, Workflow } from '../interfaces';
import { useState } from 'react';
import { Popover } from '@headlessui/react';
import { useModal } from '../contexts';
import GooglePickerButton from "./GooglePickerButton";
import Modal from './Modal';
import ProLabel from './ProLabel';
import useUser from '../hooks/useUser';
import PayWallModalPro from './PayWallModalPro';
import { KeyedMutator } from "swr";

interface Props {
  workflow: Workflow;
  mutateWorkflow: KeyedMutator<Workflow>;
}

function WorkflowOutputFolderModal(props: Props) {
  const { workflow, mutateWorkflow } = props;
  const { openModal, closeModal } = useModal();

  const { user } = useUser();
  const featureCustomFolders = !!user?.subscription?.featureCustomFolders

  const [customFolderPath, setCustomFolderPath] = useState(featureCustomFolders ? workflow.customFolderPath : "");
  const [isSaving, setIsSaving] = useState(false);

  function handlePickedFolder(folder: any) {
    putData(`/workflows/${workflow.id}/output-folder/`, { folder })
      .then(() => mutateWorkflow())
      .then(() => closeModal())
      .catch(console.error);
  }

  function updateCustomFolderPath() {
    setIsSaving(true);
    patchData(`/workflows/${workflow.id}/`, { customFolderPath })
      .then(() => mutateWorkflow())
      .then(() => featureCustomFolders ? closeModal() : openModal(<PayWallModalPro />))
      .catch(console.error)
      .finally(() => setIsSaving(false));
  }

  function handleInsertField(fieldName: string): void {
    setCustomFolderPath(s => s + "{{" + fieldName + "}}");
  }

  return (
    <Modal
      title="Output Folder"
      secondaryButtonProps={{
        text: "Cancel",
        onClick: closeModal,
        className: `${workflow.outputFolder ? '' : 'hidden'}`
      }}
      primaryButtonProps={{
        text: "Save",
        onClick: updateCustomFolderPath,
        disabled: isSaving,
        className: `${workflow.outputFolder ? '' : 'hidden'}`
      }}
    >
      <div className='flex items-end justify-between gap-1 w-full text-sm font-semibold'>
        {workflow.outputFolder &&
          <a className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 underline text-sm hover:text-blue hover:underline cursor-pointer" href={workflow.outputFolder.url} target="_blank" rel="noreferrer">
            <img src="https://static.portant.co/drive-icon.svg" className="w-6 h-6" alt="Drive icon" />
            {workflow.outputFolder?.name}
            <ExternalLinkIcon className='w-4 h-4 flex-shrink-0 mb-1' />
          </a>
        }
        <GooglePickerButton folder onPicked={handlePickedFolder} className='btn btn-white flex-shrink-0 whitespace-nowrap px-2 py-1'>
          <PencilIcon className='w-4 h-4 flex-shrink-0 mr-2' />
          <div className='hover:text-blue hover:underline'>{!!workflow.outputFolder ? "Change" : "Select"} Folder</div>
        </GooglePickerButton>
      </div>
      <hr className='my-4' />
      <div className="text-gray-600 font-gilroy font-semibold flex items-center">
        Custom Folders <ProLabel />
      </div>
      <div className='text-sm font-semibold text-gray-500 -mt-2'>
        Place outputs in custom folders within your Google Drive via a templated paths,<br />
        these path can contain {"{{"}Template Tags{"}}"} or direct names
      </div>
      {!workflow.outputFolder &&
        <div className='text-sm font-semibold text-yellow'>
          Please select the root folder to get started with custom folders
        </div>
      }
      {workflow.outputFolder &&
        <>
          <div className='relative flex items-center w-full'>
            <div className='border-2 flex items-center roudned w-full text-sm font-semibold font-gilroy rounded'>
              <GooglePickerButton folder onPicked={handlePickedFolder} className='hover:underline hover:text-blue flex-shrink-0 bg-gray-200 p-2'>
                <div>{workflow.outputFolder?.name}</div>
              </GooglePickerButton>
              <div className='mx-1'>
                /
              </div>
              <input type="text" className='w-full h-full outline-none pr-2' placeholder='{{Template Tag}} / Folder Name' value={customFolderPath} onChange={e => setCustomFolderPath(e.currentTarget.value)} />
              <Popover className="relative right-1 top-px">
                {({ open }) => (
                  <>
                    <Popover.Button className={`relative border rounded-md py-1 px-1 ml-2  bg-white ${open ? "border-blue" : "border-blue"}`}>
                      <TagIcon className={`w-4 h-4 cursor-pointer ${open ? "text-blue" : "text-blue"}`} />
                    </Popover.Button>
                    <Popover.Panel className="absolute mt-1 right-0 z-40 flex flex-col max-h-52 w-40 bg-white border rounded shadow overflow-y-auto">
                      {workflow.source?.sourceFields.map((sourceField: SourceField) => (
                        <Popover.Button key={sourceField.id} className="text-left text-sm border-b border-gray-400 last:border-none px-2 py-1 hover:bg-blue hover:bg-opacity-10 cursor-pointer" onClick={() => handleInsertField(sourceField.fieldName)}>
                          {sourceField.fieldName}
                        </Popover.Button>
                      ))}
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div className='text-sm font-semibold text-gray-500'>
            If the folder already exists Portant will use the folder, otherwise a new one will be created
          </div>
        </>
      }
    </Modal>
  );
}

export default WorkflowOutputFolderModal;
