import { WebhookEvent } from "../interfaces";
import { capitalize } from "../utils";
import moment from "moment-timezone";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useState } from "react";

function WebhookEventTableRow(props: { event: WebhookEvent }) {
  const { event } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className="flex-col text-sm">
      <div className="flex items-center border-b px-4 py-2 cursor-pointer" onClick={() => setIsExpanded(f => !f)}>
        <div className="w-1/6 font-semibold flex items-center flex-shrink-0">
          <ChevronRightIcon className="w-4 h-4 mr-2" style={{ "rotate": isExpanded ? "90deg" : "0deg" }} />
          <div className="w-[100px] truncate">

            {event.origin}
          </div>
        </div>
        <div className="w-1/2">{event.eventId}</div>
        <div className="w-1/6 flex">
          {event.responseStatus === 200
            ? <div className="bg-green text-white px-1 rounded">Received</div>
            : <div className="bg-red text-white px-1 rounded">Failed</div>
          }
        </div>
        <div className="w-1/6">{capitalize(moment(event.createdAt).fromNow())}</div>
      </div>
      {isExpanded &&
        <div className="bg-gray-100 px-4 py-2 shadow-inner border-b w-full">
          <div className="font-semibold mb-2">Event Data</div>
          <div className="text-xs max-h-[200px] overflow-y-scroll border bg-white rounded p-2 flex-grow-0 w-full">
            {JSON.stringify(event.requestBody, null, 2)}
          </div>
        </div>
      }
    </div>
  )
}

export default WebhookEventTableRow;
