import { useEffect, useState } from 'react';
import useScript from '../hooks/useScript';
import { postData } from '../backend';
import useUser from '../hooks/useUser';


function HubspotVisitor() {

  const { user } = useUser();

  const [hasConfigured, setHasConfigured] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const scriptLoaded = useScript(hasConfigured ? "https://js.hs-scripts.com/23509132.js" : null);

  useEffect(() => {
    if (hasConfigured)
      return;

    //@ts-ignore
    window.hsConversationsSettings = {
      loadImmediately: false
    };

    setHasConfigured(true);
  }, [hasConfigured]);

  useEffect(() => {
    if (!scriptLoaded || !hasConfigured || !user || hasLoaded)
      return;

    function onConversationsAPIReady() {
      //@ts-ignore
      const HubSpotConversations = window.HubSpotConversations;

      postData("/auth/hubspot-visitor-token/")
        .then(({ token }) => {
          //@ts-ignore
          window.hsConversationsSettings = {
            loadImmediately: false,
            identificationEmail: user!.email,
            identificationToken: token,
          };

          HubSpotConversations.widget.load();
        })
        .catch(console.error);
    }

    //@ts-ignore
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      //@ts-ignore
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }

    setHasLoaded(true);

  }, [user, scriptLoaded, hasConfigured, hasLoaded]);

  return null;
}

export default HubspotVisitor;
