import { CheckCircleIcon, DotsHorizontalIcon, ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { OAuthError, deleteData, postData, putData } from "../backend";
import { useModal } from "../contexts";
import { EmailAlias, User, UserProfile } from "../interfaces";
import SelectField from "./SelectField";
import TextInputField from "./TextInputField";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import ErrorModal from "./ErrorModal";
import OAuthModal from "./OAuthModal";
import useUser from "../hooks/useUser";
import { COMPANY_INDUSTRY_OPTIONS, COMPANY_ROLE_OPTIONS, COMPANY_SIZE_OPTIONS } from "../utils";

type SaveState = "INITIAL" | "SAVING" | "SAVED" | "ERROR";

const BLANK_PROFILE: UserProfile = {
  primaryUse: "",
  companyIndustry: "",
  companyName: "",
  companyRole: "",
  companySize: "",
  onboardingCompleted: false,
  onboardingCurrentStage: 0,
};

function SettingsProfile() {
  const { openModal } = useModal();
  const { user, refreshUser } = useUser();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [saveState, setSaveState] = useState<SaveState>("INITIAL");

  const [aliasName, setAliasName] = useState("");
  const [aliasEmail, setAliasEmail] = useState("");

  const [addingAlias, setAddingAlias] = useState(false);
  const [verifyingAlias, setVerifyingAlias] = useState<string | null>(null);

  const [profile, setProfile] = useState<UserProfile>(BLANK_PROFILE);

  const [companyIndustryOther, setCompanyIndustryOther] = useState("");
  const [companyRoleOther, setCompanyRoleOther] = useState("");

  useEffect(() => {
    if (!user?.profile)
      return;

    let companyIndustry = user.profile.companyIndustry;
    if (!COMPANY_INDUSTRY_OPTIONS.map(opt => opt.value).includes(companyIndustry)) {
      setCompanyIndustryOther(companyIndustry);
      companyIndustry = '__OTHER';
    }

    let companyRole = user.profile.companyRole;
    if (!COMPANY_ROLE_OPTIONS.map(opt => opt.value).includes(companyRole)) {
      setCompanyRoleOther(companyRole);
      companyRole = '__OTHER';
    }

    setProfile({ ...user.profile, companyIndustry, companyRole });

  }, [user?.profile]);

  async function addAlias() {
    if (!user?.subscription?.featureAliases)
      return openModal(<PayWallModalPro />);

    if (!aliasName || !aliasEmail)
      return;

    setAddingAlias(true);

    try {
      const alias: EmailAlias = await postData("/auth/aliases/", { aliasName, aliasEmail });
      refreshUser();
      verifyAlias(alias.id);

    } catch (error) {
      openModal(<ErrorModal details={error} />);
    }

    setAliasName("");
    setAliasEmail("");
    setAddingAlias(false);
  }

  async function verifyAlias(id: string) {
    setVerifyingAlias(id);

    try {
      await postData(`/auth/aliases/${id}/verify/`);
      setTimeout(() => { refreshUser(); setVerifyingAlias(null); }, 10_000);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);

      setVerifyingAlias(null);
    }
  }

  async function deleteAlias(id: string) {
    await deleteData(`/auth/aliases/${id}/`)
      .then(refreshUser)
      .catch(console.error);
  }

  function updateProfile(field: keyof UserProfile, value: string) {
    setHasChanges(true);
    setProfile((profile) => ({ ...profile, [field]: value }));
  }

  async function handleSave() {
    setSaveState("SAVING");

    const companyRole = profile.companyRole === '__OTHER' ? companyRoleOther : profile.companyRole;
    const companyIndustry = profile.companyIndustry === '__OTHER' ? companyIndustryOther : profile.companyIndustry;

    const updatedProfile = { ...profile, companyRole, companyIndustry };
    refreshUser({ ...user, profile: updatedProfile } as User, { revalidate: false });

    return await putData("/auth/profile/", updatedProfile)
      .then(() => {
        setSaveState("SAVED");
        setHasChanges(false);
      })
      .catch(() => setSaveState("ERROR"));
  }

  let saveIndicator = null;
  if (saveState === "SAVING") {
    saveIndicator = (
      <div className="flex items-center">
        <DotsHorizontalIcon className="h-6 w-6 mr-1 mt-1 text-gray-400" />
        <div className="text-gray-400">Saving</div>
      </div>
    );
  }
  else if (saveState === "SAVED") {
    saveIndicator = (
      <div className="flex items-center">
        <CheckCircleIcon className="h-6 w-6 mr-1 mb-1 text-green" />
        <div className="text-green">Saved</div>
      </div>
    );
  }
  else if (saveState === "ERROR") {
    saveIndicator = (
      <div className="flex items-center text-red">
        <ExclamationCircleIcon className="h-6 w-6 mr-1 mb-1" /> Error
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-top justify-between p-8 pb-0">
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold text-lg">
            Profile
          </div>
          <div className="text-gray-400 text-sm font-semibold">
            Your personal profile will allow to us tailor your experience.
          </div>
        </div>
        <div className="flex items-center font-gilroy font-medium h-10 mb-2">
          <div className="mr-4">
            {saveIndicator}
          </div>
          <button className="btn btn-blue" onClick={handleSave} disabled={saveState === "SAVING" || !hasChanges}>
            Save
          </button>
        </div>
      </div>
      {profile &&
        <div className="overflow-y-auto  pt-0 p-8" style={{ height: "calc(100vh - 200px)" }}>
          <div className="flex mt-10 gap-10">
            <TextInputField className="w-full" label="Email" value={user!.email} onChange={() => { }} disabled />
          </div>
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-5">
            <TextInputField className="w-full" label="First Name" value={user!.firstName} onChange={() => { }} disabled />
            <TextInputField className="w-full" label="Last Name" value={user!.lastName} onChange={() => { }} disabled />
          </div>
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-5">
            <div className="w-full flex items-center gap-2">
              <SelectField className="w-full" label="Company Role" value={profile.companyRole} onChange={value => updateProfile("companyRole", value)}>
                <option key="__NONE" disabled value="">Select an option</option>
                {COMPANY_ROLE_OPTIONS.map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
              </SelectField>
              {profile.companyRole === '__OTHER' ? (
                <TextInputField
                  className="w-full"
                  label="My Company Role"
                  value={companyRoleOther}
                  onChange={setCompanyRoleOther}
                />
              ) : null
              }
            </div>
            <div className="w-full flex items-center gap-2">
              <SelectField
                className="w-full"
                label="Company Industry"
                value={profile.companyIndustry}
                onChange={value => updateProfile("companyIndustry", value)}
              >
                <option disabled value="" key="__NONE">Select an option</option>
                {COMPANY_INDUSTRY_OPTIONS.map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
              </SelectField>
              {profile.companyIndustry === '__OTHER' ? (
                <TextInputField
                  className="w-full"
                  label="My Company Industry"
                  value={companyIndustryOther}
                  onChange={setCompanyIndustryOther}
                />
              ) : null}
            </div>
          </div>
          <div className="flex gap-2 flex-row-reverse lg:flex-row lg:gap-10 mt-5">
            <TextInputField className="w-full" label="Company Name" value={profile.companyName} onChange={value => updateProfile("companyName", value)} />
            <SelectField className="w-full" label="Company Size" value={profile.companySize} onChange={value => updateProfile("companySize", value)}>
              <option key="__NONE" disabled value="">Select an option</option>
              {COMPANY_SIZE_OPTIONS.map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
            </SelectField>
          </div>

          <div className="flex flex-col mt-8">
            <div className="flex items-center font-gilroy font-semibold text-lg text-gray-600 w-44">
              <h2 className="-mb-1">Email Aliases</h2> <ProLabel />
            </div>
            <p className="text-sm text-gray-400 font-gilroy font-semibold mt-1 mb-4">
              Send emails and signature requests from any of your email aliases configured in Gmail
            </p>

            {user!.aliases.map(alias =>
              <div key={alias.id} className="flex flex-col lg:flex-row lg:items-center gap-2 mt-2 lg:h-8 relative pr-12 lg:pr-0">
                <h4 className="font-semibold flex flex-col lg:flex-row lg:gap-4 lg:w-[450px]">
                  <span className="truncate">{alias.aliasName}</span>
                  <span>{"<"}{alias.aliasEmail}{">"}</span>
                </h4>
                {alias.verified &&
                  <div className="flex items-center justify-center gap-1 text-green font-semibold w-32">
                    Verified <CheckCircleIcon className="w-6 h-6 -mt-1" />
                  </div>
                }
                {(!alias.verified && verifyingAlias !== alias.id) &&
                  <button className="btn btn-white text-sm py-1 lg:w-32" onClick={() => verifyAlias(alias.id)}>Verify</button>
                }
                {(!alias.verified && verifyingAlias === alias.id) &&
                  <div className="flex items-center justify-center text-gray-400 font-semibold w-32">
                    Verifying<span className="loading-ellipse w-5" />
                  </div>
                }
                <XIcon className="absolute right-0 top-1/2 -translate-y-1/2 lg:translate-y-0 lg:static w-6 h-6 ml-4 hover:text-red text-gray-400 cursor-pointer" onClick={() => deleteAlias(alias.id)} />
              </div>
            )}
            <div className="flex flex-col lg:flex-row lg:items-end gap-2 mt-8">
              <TextInputField className="w-full xl:w-80" label="Name" value={aliasName} onChange={setAliasName} />
              <TextInputField className="w-full xl:w-80" label="Email" value={aliasEmail} onChange={setAliasEmail} />
              <button className="btn btn-blue text-sm w-32" onClick={addAlias} disabled={addingAlias}>{addingAlias ? "Adding..." : "Add"}</button>
            </div>
            <div className="text-sm text-gray-600 mt-1">
              Before adding a new alias in Portant, ensure it is registered in your Gmail settings. <a className="text-blue underline" href="https://support.google.com/mail/answer/22370?hl=en" target="_blank" rel="noreferrer">Learn more</a>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsProfile;
