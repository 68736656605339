import { useState } from "react";
import { useModal } from "../contexts";
import { Source } from "../interfaces";
import Modal from "./Modal";
import TextInputField from "./TextInputField";

interface Props {
  source: Source;
  updateBlock: (name: any) => void;
}

function WorkflowModalWebhookNameChange({ source, updateBlock }: Props) {
  const { closeModal } = useModal();

  const [name, setName] = useState<string>(source.webhookConfig!.name);

  return (
    <Modal
      title="Update Webhook Source Name"
      secondaryButtonProps={{ onClick: closeModal, text: "Cancel" }}
      primaryButtonProps={{ onClick: () => { updateBlock(name); closeModal(); }, text: "Update" }}
      size="sm"
    >
      <TextInputField label="Webhook Source Name" value={name} onChange={name => setName(name)} />
    </Modal>
  )
}

export default WorkflowModalWebhookNameChange;
