import { useEffect } from 'react';
import { postData } from '../backend';
import { setAuthToken, setRefreshToken } from '../auth';

function AppAutoLogin() {

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const state = params["state"]

    postData("/auth/auto-login/", { state })
      .then((data: any) => {
        setAuthToken(data["token"]);
        setRefreshToken(data["refreshToken"]);
      })
      .catch(console.error)
      .finally(window.close);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen bg-white">
      <div className="font-gilroy font-bold text-gray-400 mb-10 text-xl">
        Logging in to Portant...
      </div>
    </div>
  );
}

export default AppAutoLogin;
