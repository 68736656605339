import { useCallback, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import { postData } from "../backend";
import ConversionSignIn from "./ConversionSignIn";
import ConversionHubspot from "./ConversionHubspot";
import ConversionTemplatePicker from "./ConversionTemplatePicker";
import { CalculatorIcon, CashIcon, GiftIcon, PencilAltIcon } from "@heroicons/react/outline";
import ConversionGoogleDrive from "./ConversionGoogleDrive";
import BreadcrumbStepper from "./BreadcrumbStepper";
import { useHistory, useLocation } from "react-router-dom";
import ConversionPdfPicker, { DocumentLink } from "./ConversionPdfPicker";
import LoadingModal from "./LoadingModal";
import { useModal } from "../contexts";
import ErrorModal from "./ErrorModal";
import ConversionFormsPicker from "./ConversionFormsPicker";
import ConversionPdfSuccess from "./ConversionPdfSuccess";

function AppPortalConversion() {
  const { openModal, closeModal } = useModal();
  const [step, setStep] = useState(0);
  const [file, setFile] = useState<DocumentLink>();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { user } = useUser();

  const handleCreateInvoice = async () => {
    setStep(step => step + 1);
    openModal(<LoadingModal title="Creating Workflow from Template..." subtitle="This may take some time as we create the template documents In your Google&nbsp;Drive." />);
    try {
      const workflow = await postData(`/templates/tmp_1GQqjNDm6pfLLk/duplicate/`); // TODO: Move this to slugs so we are not hardcoding IDs
      history.push(`/w/${workflow.id}?tour`);
      closeModal();
    } catch (error) {
      openModal(<ErrorModal details={error} />)
    }
  }

  const handlePickPdf = (document: DocumentLink) => {
    setStep(step => step + 1);
    setFile(document)
  }

  const moveToNextStep = useCallback(() => {
    setStep(step => step + 1)
  }, [setStep])

  const hubspotSteps = [
    { title: 'Create account', component: <ConversionSignIn /> },
    { title: 'Connect Hubspot', component: <ConversionHubspot onFinish={moveToNextStep} setLoading={setLoading} /> },
    { title: 'Connect Google Drive', component: <ConversionGoogleDrive setLoading={setLoading} onFinish={moveToNextStep} /> },
    { title: 'Finish', component: <ConversionTemplatePicker templateOptions={TEMPLATE_OPTIONS} /> }
  ]

  const formsToPdfSteps = [
    { title: 'Create account', component: <ConversionSignIn /> },
    { title: 'Connect Google Drive', component: <ConversionGoogleDrive setLoading={setLoading} onFinish={moveToNextStep} /> },
    { title: 'Select Google Form', component: <ConversionFormsPicker /> },
    { title: 'Generate PDF' }
  ]

  const pdfToDocsSteps = [
    { title: 'Create account', component: <ConversionSignIn /> },
    { title: 'Connect Google Drive', component: <ConversionGoogleDrive setLoading={setLoading} onFinish={moveToNextStep} /> },
    { title: 'Select PDF', component: <ConversionPdfPicker onFinish={handlePickPdf} /> },
    { title: 'Generate Google Doc', component: <ConversionPdfSuccess file={file} /> }
  ]

  const invoiceSteps = [
    { title: 'Create account', component: <ConversionSignIn /> },
    { title: 'Connect Google Drive', component: <ConversionGoogleDrive setLoading={setLoading} onFinish={handleCreateInvoice} /> },
    { title: 'Generate invoices' }
  ]

  const routeSteps = {
    '/hubspot-get-started': hubspotSteps,
    '/pdf-to-google-docs': pdfToDocsSteps,
    '/google-forms-to-pdf': formsToPdfSteps,
    '/invoices': invoiceSteps,
  }

  const steps = routeSteps[location.pathname as keyof typeof routeSteps];

  useEffect(() => {
    if (user && step === 0)
      setStep(step => step + 1)
  }, [user, step]);

  return (
    <div className="absolute left-0 top-0">
      <div className="w-screen h-screen bg-white relative flex items-center justify-center">
        {!user?.profile?.onboardingCompleted && <img className='absolute left-10 top-10 w-32' src='https://static.portant.co/logo-full.svg' alt='Portant Logo' />}
        <div className="w-full max-h-[34rem] h-full max-w-4xl flex flex-col items-center gap-14">
          <BreadcrumbStepper currentStep={step} steps={steps.map(item => item.title)} />
          {loading && (
            <div className="flex flex-col items-center justify-center gap-4">
              <h2 className="text-2xl font-gilroy font-bold">Loading...</h2>
              <img src="https://static.portant.co/portant-loading-blue.svg" className="w-24 h-24" alt="Loading Logo" />
            </div>
          )}
          {!loading && steps[step].component}
        </div>
      </div>
    </div>
  );
}

export default AppPortalConversion;

const TEMPLATE_OPTIONS = [
  {
    label: <span className="flex items-center gap-3"> <CalculatorIcon className="h-6 w-6" /> Quotes </span>,
    value: 'QUOTE'
  },
  {
    label: <span className="flex items-center gap-3"> <GiftIcon className="h-6 w-6" /> Proposals </span>,
    value: 'PROPOSAL'
  },
  {
    label: <span className="flex items-center gap-3"> <PencilAltIcon className="h-6 w-6" /> Contracts (with eSignature) </span>,
    value: 'CONTRACT'
  },
  {
    label: <span className="flex items-center gap-3"> <CashIcon className="h-6 w-6" /> Invoices </span>,
    value: 'INVOICE'
  },
]
