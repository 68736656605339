import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import AppHubspotContactsInput from "./AppHubspotContactsInput";
import { HubspotAppContext } from "../contexts";

interface Props {
  selectedContacts: Record<string, string>;
  setSelectedContacts: Dispatch<SetStateAction<Record<string, string>>>;
}

function AppHubspotContacts(props: Props) {
  const { setSelectedContacts } = props;

  const { pickerData, setCurrentPage } = useContext(HubspotAppContext);
  const { signatureRequestNumRecipients, signatureRequestInitialRecipients, associatedObjectOptions } = pickerData;

  const contactOptions = useMemo(() => associatedObjectOptions.find((objList) => objList.toId === "contacts")?.items ?? [], [associatedObjectOptions]);
  const [hasSetAssumedOverrides, setHasSetAssumedOverrides] = useState(false);

  useEffect(() => {
    if (!contactOptions || hasSetAssumedOverrides)
      return;

    const initialContacts: Record<string, string> = {};

    for (const index in signatureRequestInitialRecipients) {
      const recipientNumber = parseInt(index) + 1;

      const initialValue = signatureRequestInitialRecipients[index];
      initialContacts[recipientNumber] = initialValue;

      const hubspotContactTag = initialValue.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
      if (!hubspotContactTag)
        continue;

      const filteredContact = contactOptions.filter((contact) => !!contact.email);

      initialContacts[recipientNumber] = filteredContact[0].email!;

      const labelInternalName = hubspotContactTag[1];
      if (!labelInternalName)
        continue;

      for (const contact of filteredContact) {
        const hasLabel = !!contact.associationLabels?.find((label) => label.split(":")[0] === labelInternalName);

        if (hasLabel && contact.email) {
          initialContacts[recipientNumber] = contact.email;
          break;
        }
      }
    }

    setHasSetAssumedOverrides(true);
    setSelectedContacts(initialContacts);

  }, [contactOptions, hasSetAssumedOverrides, signatureRequestInitialRecipients, setSelectedContacts]);

  function handleSetValue(recipientNumber: number, value: string) {
    props.setSelectedContacts(prev => ({ ...prev, [recipientNumber]: value }));
  }

  return (
    <>
      <div className="flex items-center gap-1 font-semibold font-gilroy text-sm mb-4">
        <ChevronLeftIcon className="h-6 w-6 text-gray-600 hover:text-blue cursor-pointer" onClick={() => setCurrentPage("ASSOCIATED_OBJECTS")} />
        <span className="text-gray-600">Select signature request recipients</span>
      </div>
      <div className="flex flex-col overflow-y-auto mb-4 gap-4 h-full">
        {Array.from({ length: signatureRequestNumRecipients }).map((_, index) =>
          <AppHubspotContactsInput key={index} index={index} value={props.selectedContacts[index + 1]} setValue={handleSetValue} />
        )}
      </div>
    </>
  );
}

export default AppHubspotContacts;
