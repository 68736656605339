import { BeakerIcon } from "@heroicons/react/outline";

function BetaLabel(props: { hidden?: boolean }) {
  if (props.hidden)
    return null;

  return (
    <div className="flex items-center gap-1 bg-blue/20 px-2 rounded-sm text-blue font-gilroy font-semibold ml-auto h-min">
      <BeakerIcon className="h-4 w-4 text-blue" />
      Beta
    </div>
  )
}

export default BetaLabel;
