import { HTMLProps, useState } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  url: string;
  frame?: string;
}

const FRAME_MAP: Record<string, string> = {
  "inital": "border-white",
  "bronze": "border-[#cd7f32]",
  "silver": "border-[#c0c0c0]",
  "gold": "border-[#ffd700]",
  "platinum": "border-[#e5e4e2]",
  "diamond": "border-[#b9f2ff]",
}

function Avatar(props: Props) {
  const { url, ...htmlProps } = props;

  const [avatar, setAvatar] = useState<string>(url || "https://static.portant.co/default-user.png");

  function handleImageOnError() {
    setAvatar("https://static.portant.co/default-user.png");
  }

  const frame = FRAME_MAP[props.frame || "initial"];

  return (
    <div {...htmlProps}>
      <img className={`h-full w-full rounded-full border-2 ${frame} drop-shadow`} src={avatar} alt={"User Avatar"} onError={handleImageOnError} />
    </div>
  );
}

export default Avatar;
