import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { postData, useResource } from '../backend';
import { useModal } from '../contexts';
import { SubscriptionTier } from '../interfaces';
import Modal from './Modal';

const REASON_MAP: Record<string, { title: string, subtitle: string }> = {
  "DEFAULT": { title: "Upgrade to Pro to do more with Portant", subtitle: "Get the most out of Portant with these awesome features." },
  "QUOTA": { title: "Upgrade to Pro to create more documents", subtitle: "Your current quota has been exceeded." },
  "BRANDING": { title: "Upgrade to Pro to remove Portant Branding", subtitle: "PDF documents currently include Portant Branding." },
  "SIGNATURES": { title: "Upgrade to Pro to request multiple signatures with Portant", subtitle: "Your current subscription does not include multiple signatures." },
}

function PayWallModalPro(props: { reason?: string }) {
  const tier: SubscriptionTier | null = useResource("/billing/tiers/pro/");

  const [checkoutUrl, setCheckoutUrl] = useState<string>();
  const { closeModal } = useModal();

  const { title, subtitle } = REASON_MAP[props.reason ?? "DEFAULT"] ?? REASON_MAP["DEFAULT"];

  useEffect(() => {
    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'view_paywall', 'paywall_type': 'pro' });
  }, [])

  useEffect(() => {
    if (!tier)
      return

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'event': 'click_upgrade', 'upgrade_tier': tier.slug });

    postData(`/billing/tiers/${tier.slug}/checkout/`, { stripePriceId: tier.stripeMonthlyPriceId })
      .then(data => setCheckoutUrl(data.url));
  }, [tier]);

  return (
    <Modal title={title} subtitle={subtitle}>
      <div className="flex flex-col gap-1 w-full">
        <div className="font-gilroy font-semibold">
          Pro
        </div>
        <div className="flex items-center">
          <div className="flex items-start flex-wrap flex-shrink-0">
            <div className="text-sm">$</div>
            {!!tier
              ? <div className="text-3xl font-semibold">{String(Math.floor(tier.stripeMonthlyPrice / 100))}<span className="text-xs ml-1 mt-auto mb-1">/month (USD)</span></div>
              : <div className="text-3xl font-semibold loading-ellipse" />
            }
          </div>
        </div>
      </div>
      <button className='btn btn-blue w-full' onClick={() => checkoutUrl && window.open(checkoutUrl, "_blank")}>
        Upgrade
      </button>
      <div className='flex flex-col text-sm font-semibold gap-2 mt-2'>
        <div className='text-gray-400 font-bold'>What's included</div>
        <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;{tier?.featureDocumentLimit} documents per month</div>
        <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Easily create and share PDF documents</div>
        <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Remove branding from emails and PDFs created with Portant</div>
        <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Request multiple signatures from anyone with Portant</div>
        <div><span className='text-green'>&#9679;</span>&nbsp;&nbsp;Send emails and signature requests from email aliases</div>
      </div>
      <Link to="/settings/billing" className='text-center w-full text-sm text-gray-400 underline' onClick={() => closeModal()}>
        Compare all plans
      </Link>
    </Modal>
  );
}

export default PayWallModalPro;
