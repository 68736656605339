import { PlusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { OAuthError, postData } from "../backend";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import ErrorModal from "./ErrorModal";
import { useModal } from "../contexts";

type Props = {
  onFinish: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

function ConversionGoogleDrive(props: Props) {
  const { setLoading, onFinish } = props;
  const { openModal } = useModal();

  async function refreshGoogleOnFocus() {
    window.removeEventListener("focus", refreshGoogleOnFocus);
    handleConnectGoogle();
  }

  const handleConnectGoogle = async () => {
    setLoading(true);
    try {
      await postData("/auth/access-token/", { scopes: ["https://www.googleapis.com/auth/drive"] });
      setLoading(false);
      onFinish();
    } catch (error) {
      setLoading(false);
      if (error instanceof OAuthError) {
        window.open(error.oauthUrl);
        window.addEventListener("focus", refreshGoogleOnFocus);
      } else
        openModal(<ErrorModal details={error} />);
    }
  }

  const initialize = useCallback(async () => {
    try {
      await postData("/auth/access-token/", { scopes: ["https://www.googleapis.com/auth/drive"] });
      onFinish();
    } catch (error) { }
  }, [onFinish]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Connect Google Drive to Portant
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          Allow Portant to access files in your Google Drive <br />
          so we can automatically create the required documents
        </p>
      </div>

      <div className="flex justify-center gap-16 items-center">
        <img
          className='h-12 w-12'
          src={`${process.env.REACT_APP_STATIC_URL}/drive-icon.svg`}
          alt='Google Drive Logo'
        />
        <PlusIcon className="h-7 w-7 text-gray-500" />
        <img
          className='h-12 w-12'
          src={`${process.env.REACT_APP_STATIC_URL}/logo-mark-blue.svg`}
          alt='Portant Logo Small'
        />
      </div>

      <div className="flex justify-center gap-48 items-center w-full">
        <Link to="/">
          <button className="btn btn-gray w-32">
            Cancel
          </button>
        </Link>
        <button className={`btn btn-blue w-32`} onClick={handleConnectGoogle}>
          Connect
        </button>
      </div>
    </>
  );
}

export default ConversionGoogleDrive;
