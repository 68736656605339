import { UserGroupIcon } from "@heroicons/react/outline";
import { useModal } from "../contexts";
import PayWallModalTeams from "./PayWallModalTeams";
import useUser from "../hooks/useUser";

function TeamsLabel(props: { hidden?: boolean }) {
  const { openModal } = useModal();
  const { user } = useUser();

  function handleOpen() {
    openModal(<PayWallModalTeams />)
  }

  const tier = user?.subscription?.tier;
  if (props.hidden || tier === "teams" || tier === "enterprise")
    return null;

  return (
    <div className="flex justify-center items-center bg-gray-100 w-20 rounded-sm shadow ml-auto cursor-pointer" onClick={handleOpen}>
      <div className="font-gilroy font-semibold text-black text-sm mr-1">Teams</div>
      <UserGroupIcon className="h-4 w-4 text-blue" />
    </div>
  )
}

export default TeamsLabel;
