import { useModal } from "../contexts";
import Modal from './Modal';
import useUser from '../hooks/useUser';

interface Props {
  oauthUrl: string;
  onClose?: () => void;
}

function OAuthModalXero(props: Props) {
  const { closeModal } = useModal();
  const { refreshUser } = useUser();

  function handleInstallIntegration() {
    window.open(props.oauthUrl, "_blank");
    window.addEventListener("focus", getCurrentUserOnFocus);
  }

  async function getCurrentUserOnFocus() {
    window.removeEventListener("focus", getCurrentUserOnFocus);

    refreshUser();
    closeModal(props.onClose);
  }

  return (
    <Modal
      size="sm"
      title="Authorise Xero with Portant"
      subtitle="Portant requires access to invoice data within your Xero account."
      primaryButtonProps={{ text: "Authorise Xero with Portant", onClick: handleInstallIntegration }}
    />
  );
}

export default OAuthModalXero;
