import Modal from './Modal';
import { useModal } from '../contexts';

interface Props {
  onConfirm: () => void;
  title: string;
}

function EmailInputModeModal(props: Props) {
  const { onConfirm } = props;
  const { closeModal } = useModal();

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  }

  return (
    <Modal
      title={props.title}
      subtitle={
        <p className="font-semibold text-sm text-yellow">
          Warning, this action will delete your current email content!
        </p>
      }
      primaryButtonProps={{ text: 'Confirm', onClick: handleConfirm, className: 'btn-red' }}
      secondaryButtonProps={{ text: 'Cancel', onClick: closeModal, className: 'btn-white' }}
      size='sm'
    >
      <div className='w-full h-full relative'>
        <p className="font-semibold text-sm text-gray-600">
          You can switch back between email modes but the current content of the email will be lost.
        </p>
      </div>
    </Modal>
  );
}

export default EmailInputModeModal;
