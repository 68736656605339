import { useContext, useState } from "react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { RECIPIENT_COLOUR_MAP } from "../utils";
import { Combobox } from "@headlessui/react";
import { HubspotAppContext } from "../contexts";

interface Props {
  index: number;
  value?: string;
  setValue: (recipientNumber: number, value: string) => void;
}

function AppHubspotContactsInput(props: Props) {
  const { pickerData } = useContext(HubspotAppContext);

  const recipientNumber = props.index + 1;

  const initialValue = pickerData.signatureRequestInitialRecipients[props.index];
  const [textColor, bgColor] = RECIPIENT_COLOUR_MAP[recipientNumber];

  const contactOptions = pickerData.associatedObjectOptions.find((objList) => objList.toId === "contacts")?.items ?? [];

  const hubspotContactTag = initialValue.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const labelDisplayName = hubspotContactTag?.[2];

  const selectedContact = contactOptions.find((contact) => contact.email === props.value);

  const [searchValue, setSearchValue] = useState(hubspotContactTag ? "" : initialValue);
  const filetedContacts = contactOptions.filter((contact) => !!contact.email && (contact.label + " " + (contact.email ?? "")).toLowerCase().includes((searchValue ?? "").toLowerCase()));

  const isValid = props.value?.match(/(.+@.+\..+)|(\{\{.+\}\})/);

  function updateSelectedContact(value: string) {
    props.setValue(recipientNumber, value);
  }

  return (
    <div className={`relative flex-shrink-0 p-2 rounded border bg-opacity-10 ${bgColor}`}>
      <div className="flex items-end justify-between mb-2">
        <div className={`font-gilroy font-semibold text-sm ${textColor}`}>
          Recipient #{recipientNumber}
        </div>
        {labelDisplayName &&
          <div className={`font-gilroy font-semibold text-sm ${textColor} ${bgColor} bg-opacity-20 px-2 rounded`}>
            {labelDisplayName}
          </div>
        }
      </div>

      <div className="relative w-full">
        <Combobox onChange={updateSelectedContact}>
          {selectedContact
            ? (
              <div className={`border-2 rounded py-1 px-2 w-full outline-none text-sm flex items-center bg-white ${isValid ? "border-gray-200" : "border-yellow"}`}>
                <div className="text-sm font-semibold text-gray-600">{selectedContact?.label || initialValue}</div>
                <Combobox.Button className="absolute right-0 top-0 bottom-0 flex items-center pr-2" onClick={() => { setSearchValue(""); updateSelectedContact(""); }}>
                  <XIcon className="w-4 h-4 text-gray-400 cursor-pointer" />
                </Combobox.Button>
              </div>
            ) : (
              <div className="relative">
                <Combobox.Input value={searchValue} displayValue={() => searchValue} className={`border-2 rounded py-1 px-2 w-full outline-none text-sm ${isValid ? "border-gray-200" : "border-yellow"}`} placeholder="Recipient" onChange={(event) => setSearchValue(event.target.value)} />
                <Combobox.Button className="absolute right-0 top-0 bottom-0 flex items-center pr-2" onClick={() => { setSearchValue((hubspotContactTag) ? "" : initialValue); updateSelectedContact((hubspotContactTag) ? "" : initialValue); }}>
                  <ChevronDownIcon className="w-4 h-4 text-gray-400 cursor-pointer" />
                </Combobox.Button>
              </div>
            )
          }
          {(filetedContacts.length > 0 || searchValue.length > 0) &&
            <Combobox.Options className="absolute top-9 w-full z-40 flex flex-col max-h-52 bg-white border rounded shadow overflow-y-auto">
              {filetedContacts.map((contact) => (
                <Combobox.Option key={contact.id} value={contact.email} className="flex flex-col text-sm text-left border-b border-gray-200 last:border-none px-2 pt-3 pb-1 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <div className="font-gilroy font-semibold text-gray-600">
                        {contact.label}
                      </div>
                      <div className="text-xs text-gray-400 font-semibold">
                        {contact.email}
                      </div>
                    </div>
                    {(contact.associationLabels?.length ?? 0) > 0 &&
                      <div className=" ml-auto text-xs bg-blue-50 rounded-sm px-2 font-semibold font-gilroy text-gray-600">
                        {contact.associationLabels?.[0].split(":")[1]}
                        {(contact.associationLabels?.length ?? 0) > 1 &&
                          <>, ...</>
                        }
                      </div>
                    }
                  </div>
                  {(contact.associationLabels?.length ?? 0) > 1 &&
                    <div className="hidden group-hover:flex justify-end items-center gap-2 flex-wrap pt-2">
                      {contact.associationLabels!.map((label) =>
                        <div key={label} className="text-xs bg-white rounded-sm px-2 font-semibold font-gilroy text-gray-600">
                          {label.split(":")[1]}
                        </div>
                      )}
                    </div>
                  }
                </Combobox.Option>
              ))}
              {searchValue.length > 0 &&
                <Combobox.Option value={searchValue} className="flex flex-col text-sm text-left border-b border-gray-200 last:border-none p-2 font-semibold text-gray-400 hover:bg-blue hover:bg-opacity-10 cursor-pointer group flex-shrink-0">
                  {searchValue}
                </Combobox.Option>
              }
            </Combobox.Options>
          }
        </Combobox>
      </div>
    </div >
  );
}

export default AppHubspotContactsInput;
