import { CheckIcon, ChevronDownIcon, ExclamationIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { TeamRole, TeamUser } from "../interfaces";
import useUser from "../hooks/useUser";
import { useModal } from "../contexts";
import SettingsTeamUserModalAccess from "./SettingsTeamUserModalAccess";

interface Props {
  teamUser?: TeamUser;
  role: TeamRole;
  onSelectRole: (role: TeamRole) => void;
  onRemove?: () => void;
  isUpdating?: boolean;
}

const OPTIONS: Array<TeamRole> = ["ADMIN", "EDITOR", "RUNNER", "VIEWER"];
const OPTIONS_TEXT: Record<TeamRole, string> = { "VIEWER": "Viewer", "RUNNER": "User (Runner)", "EDITOR": "Editor", "ADMIN": "Admin", "OWNER": "Owner" };

function SettingsTeamUserRole(props: Props) {
  const { user, hasPermission } = useUser();

  const { openModal } = useModal();

  const disabled = !hasPermission(user?.activeTeam, "teams.admin") || props.role === "OWNER";

  if (disabled || props.isUpdating)
    return (
      <div className="flex items-center font-semibold text-sm text-gray-600 w-full px-4 py-1 border-2 bg-gray-100">
        {props.isUpdating ? "Updating..." : OPTIONS_TEXT[props.role]}
      </div>
    );

  return (
    <Popover className="relative w-full font-semibold text-sm text-gray-600">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center font-semibold text-sm w-full py-1 px-4 border-2 border-gray-400 rounded bg-white hover:bg-blue-50">
            {OPTIONS_TEXT[props.role]} <ChevronDownIcon className={`w-4 h-4 ml-auto cursor-pointer transform transition-all ${open ? "rotate-180" : "rotate-0"}`} />
          </Popover.Button>

          <Popover.Panel className="absolute mt-1 right-0 z-10 flex flex-col w-40 bg-white border rounded shadow overflow-y-auto">

            {OPTIONS.map(option =>
              <Popover.Button key={option} className="flex items-center font-semibold text-sm w-full gap-1 px-2 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={() => props.onSelectRole(option)}>
                <CheckIcon className={`w-4 h-4 text-blue flex-shrink-0 ${props.role === option ? "visible" : "invisible"}`} />
                {OPTIONS_TEXT[option]}
              </Popover.Button>
            )}
            {props.teamUser &&
              <Popover.Button className="flex items-center font-semibold text-sm text-gray-400 hover:text-blue w-full gap-1 px-2 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap border-t group" onClick={() => openModal(<SettingsTeamUserModalAccess teamUser={props.teamUser!} />)}>
                <ExternalLinkIcon className="h-4 w-4 flex-shrink-0 invisible group-hover:visible" /> Check Access
              </Popover.Button>
            }
            {props.onRemove &&
              <Popover.Button className="flex items-center font-semibold text-sm text-gray-400 hover:text-red w-full gap-1 px-2 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap group" onClick={() => props.onRemove?.()}>
                <ExclamationIcon className="h-4 w-4 flex-shrink-0 invisible group-hover:visible" /> Remove User
              </Popover.Button>
            }
          </Popover.Panel>
        </>
      )}
    </Popover>

  );
}

export default SettingsTeamUserRole;
