import { useLayoutEffect } from "react";

function ConversionSignIn() {

  useLayoutEffect(() => {
    // @ts-ignore
    const google = window.google;

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        "size": "large",
        "text": "signup_with",
        "theme": "filled_blue",
        "width": "300"
      }
    );
  }, []);

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Create your free Portant account
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          No credit card required
        </p>
      </div>

      <div id="google-signin-button" />
    </>
  );
}

export default ConversionSignIn;
