import { InputHTMLAttributes } from "react";

type Option = { label: React.ReactNode, value: string }

interface Props extends Omit<InputHTMLAttributes<HTMLOptGroupElement>, 'onChange'> {
  options: Option[];
  multiple?: boolean;
  onChange: (selected: string[]) => void;
  selected?: string[];
  optionClassName?: string;
  className?: string;
}

function OptionsButton(props: Props) {

  const onSelect = (option: string) => {
    if (props.selected?.includes(option))
      props.onChange(props.selected.filter(selectedOpt => selectedOpt !== option))
    else if (props.multiple)
      props.onChange([...(props.selected ?? []), option])
    else
      props.onChange([option])
  }

  const buttonClasses = "text-sm border-2 rounded-md p-2 transition-colors focus-visible:outline-none focus-visible:border-blue-30 hover:bg-blue-50 flex items-center gap-2 truncate shadow";

  return (
    <form
      className={`flex flex-col gap-2 ${props.className}`}
      onSubmit={(e) => e.preventDefault()}
    >
      {
        props.options.map(option => (
          <button
            className={`
              ${buttonClasses}
              ${props.selected?.includes(option.value) ? 'border-blue-30 bg-blue-50' : 'border-gray-400'}
              ${props.optionClassName ?? ''}
            `}
            key={option.value}
            value={option.value}
            onClick={() => onSelect(option.value)}
          >
            {option.label}
          </button>
        ))
      }
    </form>
  );
}

export default OptionsButton;
