import { useCallback, useEffect, useState } from "react";
import { OAuthError, deleteData, getData, postData } from "../backend";
import { useModal } from "../contexts";
import { TeamUser, Workflow } from "../interfaces";
import { CogIcon, ExclamationIcon, } from "@heroicons/react/outline";
import useWorkflow from "../hooks/useWorkflow";
import useUser from "../hooks/useUser";
import Modal from "./Modal";
import { capitalize } from "../utils";
import Avatar from "./Avatar";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import { useHistory } from "react-router-dom";

interface Props {
  workflow: Workflow;
}

type ACCESS = "VALID" | "INVALID" | "PARTIAL" | "PENDING" | "GRANTING" | "ERROR";

function WorkflowModalShare(props: Props) {
  const history = useHistory();
  const { openModal, closeModal } = useModal();

  const { user } = useUser();
  const team = user?.activeTeam!;

  const { workflow, mutateWorkflow } = useWorkflow(props.workflow.id);

  const [isUpdatingTeam, setIsUpdatingTeam] = useState(false);
  const [isCheckinggAccess, setIsCheckingAccess] = useState(false);
  const [isGrantingAccess, setIsGrantingAccess] = useState(false);
  const [preventAutoCheck, setPreventAutoCheck] = useState(false);

  const [userAccess, setUserAccess] = useState<{ [email: string]: ACCESS }>();
  const noUserAccess = userAccess?.[user!.email] === undefined;

  async function shareWithTeam() {
    setIsUpdatingTeam(true);
    setPreventAutoCheck(true);

    const workflow = await postData(`/workflows/${props.workflow.id}/team/`)
      .catch(console.error);

    setIsUpdatingTeam(false);
    mutateWorkflow(workflow);

    const userAccess = await checkUserAccess();
    await grantAccess(userAccess);
  }

  async function removeFromTeam() {
    setIsUpdatingTeam(true);

    const workflow = await deleteData(`/workflows/${props.workflow.id}/team/`)
      .catch(console.error);

    setIsUpdatingTeam(false);
    mutateWorkflow(workflow);
  }

  const checkUserAccess = useCallback(async () => {
    try {
      setIsCheckingAccess(true);
      return await getData(`/workflows/${props.workflow.id}/check-access/`);
    } catch (error) {
      if (error instanceof OAuthError)
        return openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else if (error)
        return openModal(<ErrorModal details={error} />);
    } finally {
      setIsCheckingAccess(false);
    }
  }, [props.workflow.id, openModal]);

  const grantAccess = async (userAccess: { [email: string]: ACCESS }) => {
    setIsGrantingAccess(true);

    for (const teamUser of nonValidUsers)
      userAccess![teamUser.email] = "PENDING";

    setUserAccess(userAccess);

    for (const teamUser of nonValidUsers) {
      setUserAccess(prev => ({ ...prev, [teamUser.email]: "GRANTING" }));

      try {
        await postData(`/workflows/${props.workflow.id}/grant-access/`, { user: teamUser.id });
        setUserAccess(prev => ({ ...prev, [teamUser.email]: "VALID" }));
      } catch (e) {
        setUserAccess(prev => ({ ...prev, [teamUser.email]: "ERROR" }));
      }
    }

    setIsGrantingAccess(false);
  }

  useEffect(() => {
    if (userAccess !== undefined || isCheckinggAccess || !workflow?.team || preventAutoCheck)
      return;

    checkUserAccess()
      .then(setUserAccess);
  }, [userAccess, isCheckinggAccess, checkUserAccess, workflow, preventAutoCheck]);

  const sortedUsers: Array<TeamUser> = team.members
    .sort((a: TeamUser, b: TeamUser) => {
      if (a.id === workflow?.owner?.id) return -1;
      if (b.id === workflow?.owner?.id) return 1;

      const aStr = a.fullName.toLowerCase() + a.email.toLowerCase();
      const bStr = b.fullName.toLowerCase() + b.email.toLowerCase();

      if (aStr < bStr) return -1;
      if (aStr > bStr) return 1;
      return 0;
    });

  const nonValidUsers = sortedUsers.filter(user => !!userAccess && userAccess[user.email] !== "VALID");

  if (!workflow)
    return null;

  return (
    <Modal
      title={`Share '${workflow.name}'`}
      onClose={() => closeModal()}
      size="xl"
    >
      <div className="mt-2">
        {workflow.team &&
          <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col text-sm font-semibold">
              <div className="flex gap-2">
                <div className="text-green">Workflow Shared</div>
                <div className="text-gray-400">|</div>
                <button className="btn btn-gray hover:text-red" onClick={() => removeFromTeam()}>
                  {isUpdatingTeam ? "Removing..." : "Remove"}
                </button>
              </div>
              <div className="text-gray-600">
                All members of you team will be able to see this workflow
              </div>
            </div>
          </div>
        }
        {!workflow.team &&
          <div className="text-gray-600 text-sm font-semibold">
            By sharing this workflow all members in your team will be able to access the source and template files along with the the output folder.
          </div>
        }
      </div>

      {userAccess !== undefined && noUserAccess &&
        <div className="flex gap-2  items-center text-yellow mt-2 text-sm font-semibold">
          <ExclamationIcon className="w-8 h-8 flex-shrink-0" />
          The files within this workflow have not been shared with you.<br />Please contact the workflow owner to grant access.
        </div>
      }

      <div className="w-full mt-4 scrollbar-gutter">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-sm font-gilroy text-gray-600 text-left border-b">
              <th className="text-left w-auto">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Team Members
                  <CogIcon className="w-4 h-4 flex-shrink-0 text-gray-400 hover:text-gray-600 -mt-1" onClick={() => { history.push("/settings/team"); closeModal() }} />
                </div>
              </th>
              <th className="text-left w-[200px]">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Role
                </div>
              </th>
              <th className="text-left w-[100px]">
                <div className="flex items-center gap-1 cursor-pointer select-none">
                  Access
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="overflow-y-scroll max-h-[300px] scrollbar-visible">
        <table className="table-auto w-full">
          <tbody>
            {sortedUsers.map(teamUser =>
              <tr key={teamUser.id}>
                <td className="w-auto">
                  <div className="flex items-center gap-2 text-sm my-2">
                    <Avatar url={teamUser.avatar} className="h-10 w-10 flex-shrink-0" />
                    <div className="flex flex-col">
                      <div className="font-gilroy font-bold">{teamUser.fullName}</div>
                      <div className="text-gray-400">{teamUser.email}</div>
                    </div>
                  </div>
                </td>
                <td className="w-[200px] text-sm">
                  <div className="font-semibold font-gilroy text-gray-600">
                    {teamUser.id === workflow?.owner?.id
                      ? "Workflow Owner"
                      : (
                        teamUser.teamRole === "OWNER"
                          ? "Team Owner"
                          : capitalize(teamUser.teamRole)
                      )
                    }
                  </div>
                </td>
                <td className="w-[100px] text-sm">
                  {isCheckinggAccess
                    ? <div className="font-semibold font-gilroy text-gray-400">Checking<span className="loading-ellipse" /></div>
                    : (<>
                      {userAccess?.[teamUser.email] === undefined &&
                        <>
                          {teamUser.id === workflow?.owner?.id
                            ? <div className="font-semibold font-gilroy text-green">Valid</div>
                            : <div className="font-semibold font-gilroy text-gray-400">Not shared</div>
                          }
                        </>
                      }
                      {userAccess?.[teamUser.email] === "INVALID" &&
                        <div className="font-semibold font-gilroy text-red">Invalid</div>
                      }
                      {userAccess?.[teamUser.email] === "PARTIAL" &&
                        <div className="font-semibold font-gilroy text-yellow">Partial</div>
                      }
                      {userAccess?.[teamUser.email] === "VALID" &&
                        <div className="font-semibold font-gilroy text-green">Valid</div>
                      }
                      {userAccess?.[teamUser.email] === "PENDING" &&
                        <div className="font-semibold font-gilroy text-gray-400">Pending</div>
                      }
                      {userAccess?.[teamUser.email] === "GRANTING" &&
                        <div className="font-semibold font-gilroy text-gray-600">Granting<span className="loading-ellipse" /></div>
                      }
                      {userAccess?.[teamUser.email] === "ERROR" &&
                        <div className="font-semibold font-gilroy text-red">Error</div>
                      }
                    </>)
                  }
                </td>
              </tr>
            )}
            {sortedUsers.length === 0 &&
              <tr className="w-full">
                <td className="flex justify-center items-center py-8" colSpan={5}>
                  <div className="font-semibold text-gray-400">
                    No results
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="w-full scrollbar-gutter border-t pt-4 mt-auto flex items-center justify-between">
        <button className="btn btn-gray" onClick={() => closeModal()} type="button">Cancel</button>
        {!workflow.team &&
          <button className="btn btn-blue w-52 flex-shrink-0" onClick={shareWithTeam} disabled={isUpdatingTeam}>
            {isUpdatingTeam ? "Sharing..." : "Share with Team"}
          </button>
        }
        {workflow.team &&
          <button className="btn btn-blue w-52 flex-shrink-0" onClick={() => nonValidUsers.length > 0 ? grantAccess(userAccess ?? {}) : closeModal()} disabled={isCheckinggAccess || isGrantingAccess || noUserAccess}>
            {isCheckinggAccess ? "Checking..." : (isGrantingAccess ? "Granting..." : (nonValidUsers.length > 0 ? "Grant Access" : "Done"))}
          </button>
        }
      </div>
    </Modal>
  )

}

export default WorkflowModalShare;
