import { useContext, useState } from "react";
import { WorkflowContext } from "../contexts";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import SmallSelectField from "./SmallSelectField";
import SmallTextInputField from "./SmallTextInputField";
import { convertToRange } from "../utils";

function WorkflowAutomationPanelExcel() {
  const { automationOptions, updateAutomationOptions } = useContext(WorkflowContext);

  const [automationIsActive, setAutomationIsActive] = useState(false);

  function handleChangeRange(value: string) {
    updateAutomationOptions({ range: convertToRange(value) });
  }

  return (
    <div className="flex flex-col gap-2 select-none">
      <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer">
        Process Existing Rows
      </div>
      <div className="flex items-center justify-between text-sm w-full gap-2">
        <div className="flex-shrink-0">Run workflow for</div>
        <SmallSelectField value={automationOptions.mode} onChange={(mode) => updateAutomationOptions({ mode })} className="w-full" disabled={automationIsActive}>
          <option value="ALL">all data</option>
          <option value="CUSTOM">custom range</option>
        </SmallSelectField>
      </div>

      {(automationOptions.mode === "CUSTOM") &&
        <div className="flex text-sm w-full items-center justify-between">
          <SmallTextInputField label="Row Range" className="w-full" placeholder="e.g. 2-5, 8, 10+" value={automationOptions.range ?? ""} onChange={handleChangeRange} autoFocus invalid={!automationOptions.range} disabled={automationIsActive} />
        </div>
      }

      <WorkflowAutomationPanelManual disabled={automationOptions.mode === "CUSTOM" && !automationOptions.range} setIsActive={setAutomationIsActive} />
    </div>
  );

}

export default WorkflowAutomationPanelExcel;
