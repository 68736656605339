import { useEffect } from "react";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import Modal from "./Modal";

function NewUserModal() {
  const { openModal } = useModal();
  const { user } = useUser();

  useEffect(() => {
    const tourFlag = localStorage.getItem("hasNewUserModalShown");

    if (user?.id && !tourFlag) {
      localStorage.setItem("hasNewUserModalShown", "true");

      setTimeout(() => openModal(<NewUserComponent />), 1000);
    }
  }, [user, openModal]);

  return null;
}

function NewUserComponent() {
  return (
    <Modal title="&#127881; Welcome to Portant Workflow &#127881;">
      <iframe
        title="Portant Workflow Tutorial"
        className="self-center"
        width="736"
        height="416"
        src="https://www.youtube.com/embed/rKgFQQul8TM"
      />
    </Modal>
  )
}

export default NewUserModal;
