import Modal from "./Modal";
import { BLOCK_ICON_MAP, MIMETYPE_ICON_MAP } from "../utils";
import { Attachment, AttachmentType, Workflow } from "../interfaces";
import GooglePickerButton from "./GooglePickerButton";
import { CheckIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { useState } from "react";

interface Props {
  onClose?: () => void;
  workflow: Workflow;
  attachments: Array<Attachment>;
  addAttachment: (attachmentType: AttachmentType, payload: { file?: any, workflowDocument?: string }) => void;
  removeAttachment: (attachmentId: string) => void;
  showMicrosoft?: boolean;
  postSigningStage?: boolean;
}

function EmailModalAttachment(props: Props) {
  const { workflow, attachments } = props;

  function toggleAttachment(attachmentType: AttachmentType, workflowDocument: string) {
    const attachment = findAttachment(attachmentType, workflowDocument)

    if (attachment)
      props.removeAttachment(attachment.id);
    else
      props.addAttachment(attachmentType, { workflowDocument });
  }

  function findAttachment(attachmentType: string, workflowDocument: string): Attachment | undefined {
    return attachments.find((a: Attachment) => a.workflowDocument === workflowDocument && a.attachmentType === attachmentType);
  }

  const [showPreview, setShowPreview] = useState(true);

  return (
    <Modal title="Add Attachments">
      <div className="flex flex-col gap-6 items-center">
        <div className="overflow-y-auto flex flex-col gap-4 max-h-[300px] pr-6 -mr-6 w-full items-center">

          {workflow.documents.map((document) =>
            <div key={document.id} className="flex flex-col gap-1 w-full">
              <div className="font-gilroy font-semibold text-gray-600 text-sm truncate w-80">
                {document.file?.name}
              </div>
              <div className="flex gap-4">

                {!(document.removeOutput || document.documentType === "PDF_FILLABLE") &&
                  <div className={`flex flex-col gap-1 p-2 w-[188px] h-[160px] bg-gray-100 shadow rounded border border-gray-100 ${!findAttachment("INSERT_OUTPUT_AS_LINK", document.id) ? "cursor-pointer hover:border-blue" : "cursor-not-allowed hover:border-red"}`} onClick={() => toggleAttachment("INSERT_OUTPUT_AS_LINK", document.id)}>
                    <div className="text-xs flex items-bottom truncate flex-shrink-0 gap-1 font-semibold">
                      <img className="w-3 h-3 flex-shrink-0" src={BLOCK_ICON_MAP[document.documentType]} alt="Document icon" />
                      Share via Link
                      {findAttachment("INSERT_OUTPUT_AS_LINK", document.id) &&
                        <CheckIcon className="w-3 h-3 bg-green text-white rounded-full flex-shrink-0 p-px ml-auto" />
                      }
                    </div>
                    <div className="relative w-full h-40 bg-gray-200 border-gray-200 overflow-hidden rounded flex">
                      <div className="absolute flex items-center justify-center w-full h-full top-0 left-0"><DocumentTextIcon className="w-20 h-20 text-gray-300" /></div>
                      {(showPreview && document.file?.id) &&
                        <img className="relative w-full rounded shadow m-auto border border-gray-200" onError={() => setShowPreview(false)} src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${document.file!.id}`} alt="Thumbnail" />
                      }
                    </div>
                  </div>
                }

                <div className={`flex flex-col gap-1 p-2 w-[188px] h-[160px] bg-gray-100 shadow rounded border border-gray-100 ${!findAttachment("ATTACH_OUTPUT_AS_PDF", document.id) ? "cursor-pointer hover:border-blue" : "cursor-not-allowed hover:border-red"}`} onClick={() => toggleAttachment("ATTACH_OUTPUT_AS_PDF", document.id)}>
                  <div className="text-xs flex items-bottom truncate flex-shrink-0 gap-1 font-semibold">
                    <img className="w-3 h-3 flex-shrink-0" src={MIMETYPE_ICON_MAP["application/pdf"]} alt="PDF icon" />
                    Attach {(document.isSignable && props.postSigningStage) ? "Signed" : "as"} PDF
                    {findAttachment("ATTACH_OUTPUT_AS_PDF", document.id) &&
                      <CheckIcon className="w-3 h-3 bg-green text-white rounded-full flex-shrink-0 p-px ml-auto" />
                    }
                  </div>
                  <div className="relative w-full h-40 bg-gray-200 border-gray-200 overflow-hidden rounded flex">
                    <div className="absolute flex items-center justify-center w-full h-full top-0 left-0"><DocumentTextIcon className="w-20 h-20 text-gray-300" /></div>
                    {(showPreview && document.file?.id) &&
                      <img className="relative w-full rounded shadow m-auto border border-gray-200" onError={() => setShowPreview(false)} src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${document.file!.id}`} alt="Thumbnail" />
                    }
                  </div>
                </div>

                {(props.showMicrosoft && document.documentType !== "PDF_FILLABLE") &&
                  <div className={`flex flex-col gap-1 p-2 w-[188px] h-[160px] bg-gray-100 shadow rounded border border-gray-100 ${!findAttachment("ATTACH_OUTPUT_AS_MS_FILE", document.id) ? "cursor-pointer hover:border-blue" : "cursor-not-allowed hover:border-red"}`} onClick={() => toggleAttachment("ATTACH_OUTPUT_AS_MS_FILE", document.id)}>
                    <div className="text-xs flex items-bottom truncate flex-shrink-0 gap-1 font-semibold">
                      <img
                        className="w-3 h-3 flex-shrink-0"
                        src={document.documentType === "GOOGLE_DOCS"
                          ? "https://static.portant.co/docx-icon.svg"
                          : "https://static.portant.co/pptx-icon.svg"
                        }
                        alt={`${document.documentType === "GOOGLE_DOCS" ? "Docx" : "Pptx"} icon`}
                      />
                      Attach {document.documentType === "GOOGLE_DOCS" ? "as Word" : "PowerPoint"}
                      {findAttachment("ATTACH_OUTPUT_AS_MS_FILE", document.id) &&
                        <CheckIcon className="w-3 h-3 bg-green text-white rounded-full flex-shrink-0 p-px ml-auto" />
                      }
                    </div>
                    <div className="relative w-full h-40 bg-gray-200 border-gray-200 overflow-hidden rounded flex">
                      <div className="absolute flex items-center justify-center w-full h-full top-0 left-0"><DocumentTextIcon className="w-20 h-20 text-gray-300" /></div>
                      {(showPreview && document.file?.id) &&
                        <img className="relative w-full rounded shadow m-auto border border-gray-200" onError={() => setShowPreview(false)} src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${document.file!.id}`} alt="Thumbnail" />
                      }
                    </div>
                  </div>
                }
              </div>

            </div>
          )}
        </div>

        <GooglePickerButton onPicked={(file: any) => props.addAttachment("ATTACH_DRIVE_FILE", { file })} blockType="ALL" displayUploadTab>
          <div className="flex items-center gap-2 text-sm font-gilroy font-semibold text-gray-600 hover:underline hover:text-blue">
            <img src="https://static.portant.co/drive-icon.svg" className="w-5 h-5" alt="Drive icon" />
            Select from Google Drive
          </div>
        </GooglePickerButton>
      </div>
    </Modal>
  )
}

export default EmailModalAttachment;
