import { useModal } from '../contexts';
import Modal from './Modal';

function UpgradeSuccessModal() {

  const { closeModal } = useModal();

  return (
    <Modal
      title={<h3 className="font-gilroy font-semibold text-xl text-green"> Success! </h3>}
      subtitle="You have successfully upgraded your Portant subscription"
      primaryButtonProps={{ text: "Close", onClick: closeModal }}
      size='sm'
    />
  );
}

export default UpgradeSuccessModal;
