import { ClipboardCopyIcon, ClockIcon, CodeIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { WorkflowAutomation } from "../interfaces";
import { useState } from "react";
import moment from "moment";
import { capitalize } from "../utils";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import CopyToClipboard from "react-copy-to-clipboard";

const ERROR_REASON_TEXT: Record<string, string> = {
  "INVALID_DRIVE_CREDENTIALS": "Portant was unable to connect to your Google Drive account.",
  "INACCESSIBLE_FILES": "Files within the workflow could not be found.",
  "NO_SOURCE_DATA": "No source data could be found to start the automation.",
  "WRITE_TO_SOURCE_GRID_LIMITS": "Unable to write output data back to the source Google Sheet",
}

interface Props {
  automation: WorkflowAutomation;
}

function WorkflowAutomationsListItem(props: Props) {

  const history = useHistory();
  const { url } = useRouteMatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  function handleOnCopySupport() {
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 2000);
  }

  return (
    <>
      <tr className={`text-sm font-semibold font-gilroy text-gray-600 h-14 text-center ${!isExpanded && "border-b border-gray-200"}`}>
        <td className="w-6">
          <ClockIcon className="w-6 h-6 text-gray-400" />
        </td>

        <td className="w-40">
          {moment(props.automation.createdAt).format("MMM D, h:mma")}
        </td>

        <td className="w-40">
          {capitalize(props.automation.status)}
        </td>

        <td className="w-auto">
          {props.automation.status === "COMPLETED" &&
            <div className="flex items-center gap-2 justify-end">
              {(props.automation.itemsCompletedWithErrors > 0) &&
                <div className="text-yellow text-xs mr-auto">Some items completed with errors</div>
              }
              <button className="btn btn-white text-xs py-1" onClick={(e) => { e.stopPropagation(); history.push(url + "/outputs?" + new URLSearchParams({ automation: props.automation.id }).toString()); }}>
                View Outputs
              </button>
            </div>
          }
          {props.automation.status === "ERROR" &&
            <div className="flex items-center w-full justify-center gap-2">
              <div className="text-red font-semibold">{ERROR_REASON_TEXT[props.automation.errorReason] ?? "An unknown error occured"}</div>
              {props.automation.errorReason === "INVALID_DRIVE_CREDENTIALS" &&
                <Link className="hover:text-blue underline" to={"/settings/permissions"}>Reconnect</Link>
              }
            </div>
          }
        </td>

        <td className="w-40">
          {props.automation.itemsCompleted}
        </td>

        <td className="w-2 text-gray-400">
          of
        </td>

        <td className="w-40">
          {props.automation.itemsExpected}
        </td>

        {/* Additional options (kebeb menu) */}
        <td className="w-10">
          <div className="flex justify-center relative">
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              show={showCopiedMessage}
              unmount
            >
              <div className="absolute bg-blue whitespace-nowrap text-white rounded top-0 right-10 z-10 shadow px-2 -pb-1 pointer-events-none">
                Support code copied to clipboard
              </div>
            </Transition>
            <Popover className="relative" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <Popover.Button>
                <DotsVerticalIcon className="w-4 h-4 text-gray-400 hover:text-gray-600" />
              </Popover.Button>
              <Popover.Panel className="absolute mt-1 right-0 z-20 flex flex-col w-42 bg-white border rounded shadow overflow-y-auto">
                {/* TODO: Add rerun automation endpoint
                <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" disabled onClick={() => { }}>
                <RefreshIcon className="w-4 h-4 mr-2" /> Rerun Automation
                </Popover.Button>
              */}
                <CopyToClipboard text={props.automation.id} onCopy={handleOnCopySupport}>
                  <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap">
                    <ClipboardCopyIcon className="w-4 h-4 mr-2" /> Copy Support Code
                  </Popover.Button>
                </CopyToClipboard>
                <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={() => setIsExpanded(f => !f)}>
                  <CodeIcon className="w-4 h-4 mr-2" /> View JSON Payload
                </Popover.Button>
              </Popover.Panel>
            </Popover>
          </div>
        </td>
      </tr>

      {isExpanded &&
        <tr>
          <td className=" w-full" colSpan={7}>
            <pre className="text-xs p-1 bg-gray-100 rounded-sm">
              {JSON.stringify(props.automation, null, 2)}
            </pre>
          </td>
        </tr>
      }
    </>
  );
}

export default WorkflowAutomationsListItem;
