import { postData } from "../backend";
import useWorkflows from "../hooks/useWorkflows";
import { Workflow } from '../interfaces';
import AppCopperWorkflow from './AppCopperWorkflow';
import { useState } from 'react';

function AppCopperWorkflowList(props: { sdk: any }) {
  const { data, isLoading } = useWorkflows();

  const workflows: Array<Workflow> | undefined = data?.results;
  const copperWorkflows = workflows?.filter((workflow: Workflow) => workflow.previewSourceType === "COPPER");

  const [isCreating, setIsCreating] = useState(false);

  async function createWorkflow() {
    setIsCreating(true);

    const initialWorkflow = {
      "name": "New Copper Workflow",
      "color": "#eb4968",
      "icon": "UserCircle",
    }

    try {
      const workflow = await postData(`/workflows/create-from-copper/`, initialWorkflow);

      if ((workflows?.length ?? 0) <= 1)
        window.open("/workflow/", "_blank");
      else
        window.open("/w/" + workflow.id, "_blank");

    } catch (error) {
      console.error(error);
    }

    setIsCreating(false);
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-center gap-2 font-gilroy font-semibold text-sm'>
        <div className=' text-gray-600'>Run a Workflow</div>
        <div className='text-gray-400'>|</div>
        <a className='text-gray-400 hover:text-blue hover:underline cursor-pointer' href={process.env.PUBLIC_URL + "/"} target="_blank" rel='noreferrer'>Add</a>
      </div>

      <div className='overflow-auto flex flex-col gap-1 border-t border-b py-2 min-h-[140px]'>
        {copperWorkflows?.map((workflow: Workflow) =>
          <AppCopperWorkflow key={workflow.id} workflow={workflow} sdk={props.sdk} />
        )}
        {copperWorkflows?.length === 0 &&
          <button className='btn btn-blue text-sm m-auto w-60' onClick={createWorkflow} disabled={isCreating}>
            {isCreating ? "Creating..." : "Create Workflow"}
          </button>
        }
        {isLoading &&
          <img src="https://static.portant.co/portant-loading-gray.svg" alt="Loading Logo" className='m-auto w-10 h-10' />
        }
      </div>
    </div>
  )
}

export default AppCopperWorkflowList;
