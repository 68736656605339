import { useContext } from "react";
import { BLOCK_ICON_MAP } from "../utils";
import { CheckIcon, DocumentSearchIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { useModal, WorkflowContext } from "../contexts";
import { WorkflowAutomationItem } from "../interfaces";
import { deleteData, getData } from "../backend";
import useSWR from "swr";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoadingModal from "./LoadingModal";

function WorkflowOverviewBlockReview() {
  const isValidBlock = true; // TODO: Check if there are documents in the workflow

  const { openModal, closeModal } = useModal();
  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const { data: reviewItems } = useSWR<Array<WorkflowAutomationItem>>(`/workflows/${workflow.id}/review-items/`, getData);
  const pendingItems = reviewItems?.filter(item => item.stage === "REVIEW_PENDING");

  const history = useHistory();
  const { url } = useRouteMatch();

  function handleOpenBlock() {
    const item = pendingItems?.[0];
    if (!item)
      return history.push(url + "/outputs?review");

    history.push(url + "/review?" + new URLSearchParams({ item: item.id }).toString())
  }

  async function removeBlock(e: React.MouseEvent) {
    e.stopPropagation();

    try {
      openModal(<LoadingModal title="Deleting Block" subtitle="Please wait as this may take a moment." />);
      await deleteData(`/workflows/reviews/${workflow.review!.id}/`);
    } catch (error) {
      console.error(error);
    } finally {
      await mutateWorkflow();
      closeModal();
    }
  }

  return (
    <button className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={handleOpenBlock}>
      {isValidBlock
        ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
        : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
      }
      <img src={BLOCK_ICON_MAP["REVIEW"]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
      <div className="flex flex-col w-full overflow-hidden mr-4">
        <p className="text-sm text-gray-400 whitespace-nowrap overflow-ellipsis overflow-hidden text-start">
          Human in the Loop
        </p>
        <h3 className={"font-gilroy font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden text-start " + (isValidBlock ? "text-gray-500" : "text-yellow")}>
          Review Created Documents
        </h3>
      </div>
      {(pendingItems?.length ?? 0) > 0 &&
        <div className="text-white text-sm font-semibold flex items-center justify-between relative rounded shadow bg-blue px-2 pt-1">
          <DocumentSearchIcon className="w-4 h-4 mb-1 mr-1" />
          {pendingItems?.length}
        </div>
      }
      {hasPermission(workflow.team, "workflows.edit") &&
        <div className="hidden group-hover:flex justify-center items-center text-white w-6 h-6 absolute -top-2 -right-2 bg-gray-400 hover:bg-red rounded-full shadow-md" onClick={removeBlock}>
          <TrashIcon className="w-4 h-4" />
        </div>
      }
    </button>
  );
}

export default WorkflowOverviewBlockReview;
