import { PlusCircleIcon } from "@heroicons/react/outline";

function WorkflowOverviewBlockPlaceholder(props: { text: string, onClick: () => void }) {
  return (
    <div className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 border-dashed border-2 border-gray-300 cursor-pointer group rounded w-[600px] h-[114px] text-gray-300 hover:text-blue hover:underline`} onClick={props.onClick}>
      <div className="flex w-full overflow-hidden mr-4 justify-center items-center">
        <div className="flex items-center gap-1 text-xl whitespace-nowrap overflow-ellipsis overflow-hidden font-gilroy font-semibold">
          <PlusCircleIcon className="w-6 h-6" /> {props.text}
        </div>
      </div>
    </div>
  );
}

export default WorkflowOverviewBlockPlaceholder;
