import { deleteData } from '../backend';
import { useModal } from '../contexts';
import { BLOCK_ICON_MAP } from '../utils';
import Modal from './Modal';
import useUser from '../hooks/useUser';

function SettingsModalMicrosoft(props: { onDisconnect?: () => void }) {
  const { refreshUser } = useUser();
  const { closeModal } = useModal();

  async function handleDeleteMicrosoftCredentials() {
    await deleteData("/microsoft/credentials/")
      .catch(console.error);

    props.onDisconnect?.();
    refreshUser();
    closeModal();
  }

  return (
    <Modal
      primaryButtonProps={{
        text: "Disconnect Portant from Microsoft",
        onClick: handleDeleteMicrosoftCredentials,
        className: "btn-red flex-shrink-0"
      }}
      className='relative justify-start'
      size='sm'
    >
      <div className="flex items-center gap-2">
        <img className="h-10 w-10" src={BLOCK_ICON_MAP["MICROSOFT"]} alt="Microsoft Icon" />
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold">
            Microsoft
          </div>
          <div className="text-gray-600 text-sm" onClick={handleDeleteMicrosoftCredentials}>Portant currently has access to your data in Microsoft</div>
        </div>
      </div>
    </Modal>
  );
}

export default SettingsModalMicrosoft;
