import Modal from './Modal';
import { useModal } from '../contexts';

interface Props {
  onConfirm: () => void;
}

function UnsavedContentModal(props: Props) {
  const { onConfirm } = props;
  const { closeModal } = useModal();

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  }

  return (
    <Modal
      title="You have unsaved content!"
      subtitle="If you go to a different section right now, the changes you made will be lost."
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ className: "btn-red", onClick: handleConfirm, text: "Confirm" }}
      size='sm'
    />
  );
}

export default UnsavedContentModal;
