import { PlusIcon } from "@heroicons/react/outline";
import { Dispatch, SetStateAction, useCallback, useEffect, } from "react";
import { Link } from "react-router-dom";
import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import ErrorModal from "./ErrorModal";

type Props = {
  onFinish: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>
}

function ConversionHubspot(props: Props) {

  const { openModal } = useModal();
  const { onFinish, setLoading } = props;

  const handleConnectHubspot = async () => {
    setLoading(true)
    try {
      await postData("/hubspot/access-token/");
      setLoading(false);
      onFinish();
    } catch (error) {
      setLoading(false);
      if (error instanceof OAuthError && error.oauthUrl.includes("app.hubspot")) {
        window.open(error.oauthUrl, "_blank");
        window.addEventListener("focus", refreshHubspotOnFocus);
      }
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function refreshHubspotOnFocus() {
    window.removeEventListener("focus", refreshHubspotOnFocus);
    handleConnectHubspot();
  }

  const initialize = useCallback(async () => {
    try {
      await postData("/hubspot/access-token/");
      onFinish();
    } catch (error) { }
  }, [onFinish]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Connect HubSpot to Portant
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          Allow portant to use your HubSpot data to personalize documents, <br />
          then associate and save them in HubSpot records
        </p>
      </div>

      <div className="flex justify-center gap-16 items-center">
        <img
          className='h-12 w-12'
          src={`${process.env.REACT_APP_STATIC_URL}/hubspot-icon.svg`}
          alt='HubSpot Logo'
        />
        <PlusIcon className="h-7 w-7 text-gray-500" />
        <img
          className='h-12 w-12'
          src={`${process.env.REACT_APP_STATIC_URL}/logo-mark-blue.svg`}
          alt='Portant Logo Small'
        />
      </div>

      <div className="flex justify-center gap-48 items-center w-full">
        <Link to="/">
          <button className="btn btn-gray w-32">
            Cancel
          </button>
        </Link>
        <button className={`btn btn-blue w-32`} onClick={handleConnectHubspot}>
          Connect
        </button>
      </div>
    </>
  );
}

export default ConversionHubspot;
