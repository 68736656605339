import { ChevronDownIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { WorkflowContext } from "../contexts";
import ToggleButton from "./ToggleButton";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import SmallTextInputField from "./SmallTextInputField";
import SmallSelectField from "./SmallSelectField";
import { convertToRange } from "../utils";

function WorkflowAutomationPanelForms() {
  const { workflow, automationOptions, updateAutoCreate, updateAutomationOptions } = useContext(WorkflowContext);

  const [showManualAutomation, setShowManualAutomation] = useState(false);
  const [automationIsActive, setAutomationIsActive] = useState(false);

  function handleChangeRange(value: string) {
    updateAutomationOptions({ range: convertToRange(value) });
  }

  return (
    <>
      <div className="flex flex-col gap-2">

        <div className="flex justify-between font-gilroy font-semibold text-sm">
          Auto-Create
          <ToggleButton value={workflow.autoCreate} onChange={updateAutoCreate} bgColor="bg-green" />
        </div>

        <div className="text-xs text-gray-600">
          Portant will run this workflow whenever a new response is submitted to the form.
        </div>
      </div>

      <div className="flex items-center gap-2 my-2">
        <div className="h-px bg-gray-400 w-full" />
        <div className="flex-shrink-0 font-gilroy font-semibold text-xs text-gray-600">or</div>
        <div className="h-px bg-gray-400 w-full" />
      </div>

      <div className="flex flex-col gap-2 select-none">
        <div className="group flex items-center gap-1 font-gilroy font-semibold text-sm cursor-pointer" onClick={() => setShowManualAutomation(f => !f)}>
          {workflow.autoCreate &&
            <ChevronDownIcon className={`mr-2w-5 h-5 flex-shrink-0 ${showManualAutomation ? "rotate-0" : "-rotate-90"}`} />
          }
          Process Existing Responses
        </div>

        {(showManualAutomation || !workflow.autoCreate) &&
          <>
            <div className="flex items-center justify-between text-sm w-full gap-2">
              <div className="flex-shrink-0">Run workflow for</div>
              <SmallSelectField value={automationOptions.mode} onChange={(mode) => updateAutomationOptions({ mode })} className="w-full" disabled={automationIsActive}>
                <option value="ALL">all data</option>
                <option value="CUSTOM">custom range</option>
              </SmallSelectField>
            </div>

            {(automationOptions.mode === "CUSTOM") &&
              <div className="flex text-sm w-full items-center justify-between">
                <SmallTextInputField label="Response Range" className="w-full" placeholder="e.g. 1-5, 8, 10+" value={automationOptions.range ?? ""} onChange={handleChangeRange} autoFocus invalid={!automationOptions.range} disabled={automationIsActive} />
              </div>
            }

            <WorkflowAutomationPanelManual disabled={automationOptions.mode === "CUSTOM" && !automationOptions.range} setIsActive={setAutomationIsActive} />
          </>
        }
      </div>
    </>
  );

}

export default WorkflowAutomationPanelForms;
