import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useRef } from "react";


type DateInputFieldProps = {
  value: string,
  onChange: (value: string) => void,
  label: string,
  format?: string,
  className?: string,
  isActive?: boolean,
}

function DateInputField(props: DateInputFieldProps) {
  dayjs.extend(customParseFormat)
  const formatToUse = props.format ?? 'DD-MM-YYYY';
  const pickerRef = useRef<HTMLInputElement | null>(null);
  const isValidDate = dayjs(props.value, formatToUse).isValid();
  const handleClick = () => {
    (pickerRef.current as any)?.showPicker();
  }

  return (
    <div
      className={`h-9 w-24 relative border-solid border-2 rounded p-1 mt-2 flex flex-col justify-center cursor-pointer ${props.isActive ? 'border-blue' : 'border-gray-400'} ${props.className ?? ''}`}
      onClick={handleClick}
    >
      <span className="absolute text-xs font-gilroy font-semibold text-gray-600 bg-white left-1 -top-2 px-1 z-10 select-none">
        {props.label}
      </span>
      <span className="flex-shrink-0 truncate">
        {isValidDate ? props.value : "--/--/--"}
      </span>
      <input
        type="date"
        ref={pickerRef}
        onChange={({ target }) => props.onChange(target.value ? dayjs(target.value, "YYYY-MM-DD").format(formatToUse) : "")}
        className="h-0 w-0"
      />
    </div>
  );
}

export default DateInputField;
