import { useCallback, useEffect, useState } from 'react';
import { useModal } from '../contexts';
import { OAuthError, postData } from '../backend';
import { PickerResponse } from './WorkflowSelectMicrosoftButton';
import OAuthModalMicrosoft from './OAuthModalMicrosoft';
import MicrosoftPopupModal from './MicrosoftPopupModal';
import ErrorModal from './ErrorModal';

interface Props {
  className?: string;
  style?: any;
  blockType?: string;
  multipleBlockTypes?: Array<string>;
  disabled?: boolean;
  folder?: boolean;
  displayUploadTab?: boolean;
  openOnLoad?: boolean;
  onPicked: (response: PickerResponse) => void;
  children: JSX.Element | JSX.Element[];
}

function MicrosoftPickerButton(props: Props) {
  const [pickerIsOpen, setPickerIsOpen] = useState<boolean>(false);

  const [oauthUrl, setOauthUrl] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();

  const [checkToken, setCheckToken] = useState(true);

  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (checkToken)
      postData("/microsoft/access-token/", {})
        .then((accessToken) => { setAccessToken(accessToken); setOauthUrl(undefined) })
        .catch(error => {
          if (error instanceof OAuthError)
            setOauthUrl(error.oauthUrl);
          else
            console.error(error)
        })
        .finally(() => setCheckToken(false));
  }, [checkToken]);

  const openMicrosoftPicker = useCallback(() => {
    if (oauthUrl)
      return openModal(<OAuthModalMicrosoft oauthUrl={oauthUrl} onClose={() => setCheckToken(true)} />);

    if (props.disabled || !accessToken)
      return;

    // NOTE: This never set back to false, this is just to prevent `openOnLoad` from opening the picker multiple times.
    setPickerIsOpen(true);

    openModal(<MicrosoftPopupModal />);

    // @ts-ignore
    const options = {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      action: "query",
      accountSwitchEnabled: false,
      advanced: {
        filter: ".xls,.xlsx",
        redirectUri: window.location.origin
      },
      success: (response: PickerResponse) => { closeModal(); props.onPicked(response); },
      cancel: closeModal,
      error: (error: Error) => openModal(<ErrorModal details={error} />)
    }

    // @ts-ignore
    OneDrive.open(options);

  }, [props, oauthUrl, accessToken, openModal, closeModal]);

  useEffect(() => {
    if (props.openOnLoad && !pickerIsOpen)
      openMicrosoftPicker();
  }, [props.openOnLoad, accessToken, oauthUrl, openMicrosoftPicker, pickerIsOpen]);

  return (
    <button style={props.style} className={props.className} onClick={openMicrosoftPicker} disabled={props.disabled || (!oauthUrl && !accessToken)}>
      {props.children}
    </button>
  );
}

export default MicrosoftPickerButton;
