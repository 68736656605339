import { Transition } from "@headlessui/react";
import { ArrowDownIcon, ArrowUpIcon, DocumentTextIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getData, patchData } from "../backend";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowSelectModal from "./WorkflowSelectModal";
import SelectField from "./SelectField";
import SmallTextInputField from "./SmallTextInputField";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";

export interface Organistaion {
  id: string,
  authEventId: string,
  tenantId: string,
  tenantName: string,
  createdDateUtc: Date,
  updatedDateUtc: Date
};

interface Invoice {
  id: string;
  status: string;
  reference: string | null;
  invoiceNumber: string;
  contactName: string;
  total: string;
  date: string;
  checked: boolean;
};

type SortBy = "INVOICE_NUMBER" | "REFERENCE" | "NAME" | "TOTAL" | "DATE" | "STATUS";
type SortOrder = "ASC" | "DESC";


function WorkflowSourceXero() {
  const { openModal } = useModal();

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, showAutomationPanel, updateAutomationOptions, mutateWorkflow } = workflowContext;

  const history = useHistory();
  const { url } = useRouteMatch();

  const source = workflow.source;

  const [organistaions, setOrganisations] = useState<Organistaion[]>([]);
  const [tenantId, setTenantId] = useState(source?.xeroConfig?.tenantId ?? "");
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [invoiceQuery, setInvoiceQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [sortBy, setSortBy] = useState<SortBy>("DATE");
  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");

  useEffect(() => {
    getData("/xero/organisations/")
      .then(setOrganisations)
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (!tenantId)
      return;

    setLoadingInvoices(true)

    getData(`/xero/${tenantId}/invoices/`)
      .then(data => setInvoices(data.map((invoice: Invoice) => ({ ...invoice, checked: false }))))
      .catch(console.error)
      .finally(() => setLoadingInvoices(false));
  }, [tenantId]);

  const handleOrganisationOnChange = useCallback((tenantId: string) => {
    setTenantId(tenantId);

    patchData(`/xero/config/${source!.xeroConfig?.id}/`, { tenantId })
      .then(() => mutateWorkflow())
      .catch(console.error);
  }, [mutateWorkflow, source]);

  useEffect(() => {
    if (!tenantId && organistaions.length > 0)
      handleOrganisationOnChange(organistaions[0].tenantId);
  }, [tenantId, organistaions, handleOrganisationOnChange]);

  const organisationName = useMemo(() => {
    if (!tenantId)
      return "";

    const org = organistaions.find((organistaion) => organistaion.tenantId === tenantId)
    return org?.tenantName ?? "";
  }, [tenantId, organistaions]);

  if (!source)
    return null;

  function refreshInvoices() {
    handleSelectAll(false);

    setIsRefreshing(true);
    getData(`/xero/${tenantId}/invoices/`)
      .then(data => setInvoices(data.map((invoice: Invoice) => ({ ...invoice, checked: false }))))
      .catch(console.error)
      .finally(() => setIsRefreshing(false));
  }


  function handleInvoiceOnChange(invoiceId: string, value: boolean) {
    const updatedInvoices = invoices.map(currentInvoice =>
      currentInvoice.id === invoiceId ? { ...currentInvoice, checked: value } : currentInvoice
    );

    setInvoices(updatedInvoices);

    const sourceItemIds = updatedInvoices.filter(invoice => invoice.checked).map(invoice => invoice.id);
    updateAutomationOptions({ sourceItemIds, selectedTotal: sourceItemIds.length });
  }

  function handleSelectAll(value: boolean) {
    setSelectAll(value);

    const updatedInvoices = invoices
      .map(currentInvoice =>
        filterByQuery(currentInvoice) ? { ...currentInvoice, checked: value } : currentInvoice
      );

    setInvoices(updatedInvoices);
    updateAutomationOptions({ sourceItemIds: updatedInvoices.filter(invoice => invoice.checked).map(invoice => invoice.id) });
  }

  function handleSortByOnChange(newSortBy: SortBy) {
    if (newSortBy === sortBy)
      setSortOrder(current => current === "ASC" ? "DESC" : "ASC");

    setSortBy(newSortBy);
  }

  function filterByQuery(invoice: Invoice) {
    const query = invoiceQuery.toLowerCase();

    if (query.length === 0)
      return true;
    else
      return (invoice.reference ?? "").toLowerCase().includes(query) ||
        (invoice.invoiceNumber ?? "").toLowerCase().includes(query) ||
        (invoice.contactName ?? "").toLowerCase().includes(query) ||
        (invoice.date ?? "").toLowerCase().includes(query)
  }

  function sortByColumn(a: Invoice, b: Invoice) {
    switch (sortBy) {
      case "INVOICE_NUMBER":
        if (!a.invoiceNumber) return +1;
        if (!b.invoiceNumber) return -1;
        return a.invoiceNumber.localeCompare(b.invoiceNumber) * (sortOrder === "ASC" ? -1 : 1);
      case "REFERENCE":
        if (!a.reference) return +1;
        if (!b.reference) return -1;
        return a.reference.localeCompare(b.reference) * (sortOrder === "ASC" ? -1 : 1);
      case "NAME":
        if (!a.contactName) return +1;
        if (!b.contactName) return -1;
        return a.contactName.localeCompare(b.contactName) * (sortOrder === "ASC" ? -1 : 1);
      case "TOTAL":
        return (parseFloat(a.total) - parseFloat(b.total)) * (sortOrder === "ASC" ? -1 : 1);
      case "DATE":
        return (new Date(a.date).getTime() - new Date(b.date).getTime()) * (sortOrder === "ASC" ? -1 : 1);
      case "STATUS":
        return a.status.localeCompare(b.status) * (sortOrder === "ASC" ? -1 : 1);
    }
  }

  const sortArrow = sortOrder === "ASC"
    ? <ArrowUpIcon className="w-3 h-3" />
    : <ArrowDownIcon className="w-3 h-3" />;

  const sortArrowHidden =
    <ArrowUpIcon className="w-3 h-3 opacity-0" />;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions &&
            <>
              <div className="flex flex-col gap-2 w-full">
                <SelectField label="Organisation" value={tenantId} onChange={handleOrganisationOnChange}>
                  {organistaions.map(organisation =>
                    <option key={organisation.tenantId} value={organisation.tenantId}>{organisation.tenantName}</option>
                  )}
                </SelectField>
                {/* TODO: Add payslips as a resource
              <SelectField label="Resource" value={tenantId} onChange={handleOrganisationOnChange}>
                <option value="INVOCIES">Invoices</option>
                <option value="PAYSLIPS">Payslips</option>
              </SelectField> */}
              </div>
              <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} showRefreshSpinning={isRefreshing} disableCopy />
            </>
          }

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Template" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}

        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[source.sourceType]} alt="Xero Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text font-semibold font-gilroy truncate max-w-[400px]">
              Xero
            </div>
            <div className="flex text-sm font-semibold font-gilroy font-gray-400 truncate max-w-[400px] -mt-1">
              Data Source
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 text-xs hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>

        <div className="flex flex-col mt-2 gap-4 overflow-hidden border border-gray-200 rounded h-full pt-2 px-1">

          <div className="flex flex-col gap-2 w-full overflow-y-auto">


            <div className="flex justify-between items-end ml-4">
              <span className="font-gilroy font-semibold">Select Invoice Data</span>
              <div className="flex items-center gap-2">
                <RefreshIcon className={`w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={refreshInvoices} />
                <SmallTextInputField className="w-80" value={invoiceQuery} placeholder="Search invoices..." onChange={setInvoiceQuery} />
              </div>
            </div>

            <div className="w-full overflow-y-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left">
                    <th className="py-1 sticky top-0 bg-gray-100">
                      <div className="flex justify-center items-center">
                        <input type="checkbox" checked={selectAll} onChange={e => handleSelectAll(e.currentTarget.checked)} />
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("INVOICE_NUMBER")}>
                      <div className="flex gap-2 items-center">
                        Invoice Number {sortBy === "INVOICE_NUMBER" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("NAME")}>
                      <div className="flex gap-2 items-center">
                        Contact Name {sortBy === "NAME" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("REFERENCE")}>
                      <div className="flex gap-2 items-center">
                        Reference {sortBy === "REFERENCE" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("TOTAL")}>
                      <div className="flex gap-2 items-center">
                        Total {sortBy === "TOTAL" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("DATE")}>
                      <div className="flex gap-2 items-center">
                        Date {sortBy === "DATE" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>

                    <th className="py-1 font-semibold sticky top-0 bg-gray-100 cursor-pointer" onClick={() => handleSortByOnChange("STATUS")}>
                      <div className="flex gap-2 items-center">
                        Status {sortBy === "STATUS" ? sortArrow : sortArrowHidden}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loadingInvoices &&
                    <tr>
                      <td colSpan={6} className="text-center p-2">
                        Fetching invoices{organisationName && " for " + organisationName}...
                      </td>
                    </tr>
                  }

                  {(!loadingInvoices && invoices.length > 0) &&
                    invoices
                      .filter(filterByQuery)
                      .sort(sortByColumn)
                      .map(invoice =>
                        <tr key={invoice.id} className={`${invoice.checked ? "bg-blue-50" : "bg-white"} text-sm hover:bg-blue-50/50 cursor-pointer`} onClick={(value) => handleInvoiceOnChange(invoice.id, !invoice.checked)}>
                          <td className="py-1"><div className="flex justify-center items-center"><input type="checkbox" checked={invoice.checked} onChange={(e) => handleInvoiceOnChange(invoice.id, e.currentTarget.checked)} /></div></td>
                          <td className="py-1">{invoice.invoiceNumber}</td>
                          <td className="py-1">{invoice.contactName}</td>
                          <td className="py-1">{invoice.reference}</td>
                          <td className="py-1">${invoice.total}</td>
                          <td className="py-1">{invoice.date}</td>
                          <td className="py-1">{invoice.status}</td>
                        </tr>
                      )
                  }

                  {(!loadingInvoices && invoices.length <= 0) &&
                    <tr>
                      <td colSpan={6} className="text-center p-2">
                        No invoices found{organisationName && " for " + organisationName}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </Transition.Child>
    </>
  );

}

export default WorkflowSourceXero;
