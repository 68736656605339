import Modal from './Modal';
import { useEffect, useState } from 'react';

interface Props {
  title?: string;
  subtitle?: string;
}

function LoadingModal(props: Props) {
  const { title, subtitle } = props;

  const [allowClose, setAllowClose] = useState(false);

  useEffect(() => {
    setTimeout(() => setAllowClose(true), 30_000)
  }, []);

  return (
    <Modal preventClose={!allowClose} title={title ?? "Loading..."} subtitle={subtitle} size='sm'>
      <img src="https://static.portant.co/portant-loading-blue.svg" className="m-16 w-24 h-24 mx-auto" alt="Loading Logo" />
    </Modal>
  );
}

export default LoadingModal;
