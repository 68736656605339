import { useEffect, useState } from "react";
import { useModal } from "../contexts";
import SettingsTeamUser from "./SettingsTeamUser"
import SettingsTeamCreate from "./SettingsTeamCreate";
import useUser from "../hooks/useUser";
import { ChevronDownIcon, PencilIcon, SearchIcon, UserGroupIcon } from "@heroicons/react/outline";
import SmallTextInputField from "./SmallTextInputField";
import { Team, TeamUser } from "../interfaces";
import moment from "moment";
import SettingsTeamModalEdit from "./SettingsTeamModalEdit";
import SettingsTeamModalInvite from "./SettingsTeamModalInvite";
import SettingsTeamUserModalAccessBatch from "./SettingsTeamUserModalAccessBatch";
import PayWallModalTeams from "./PayWallModalTeams";
import useSWR from "swr";
import { getData } from "../backend";
import { BLOCK_ICON_MAP } from "../utils";
import SettingsModalStripe from "./SettingsModalStripe";

type SortingOrder = "ASC" | "DESC";
type SortingField = "NAME" | "ROLE" | "USAGE" | "LAST_LOGIN";

moment.updateLocale(moment.locale(), { invalidDate: "" });

function SettingsTeam() {
  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const { data: team, mutate: revalidateTeam } = useSWR<Team>("/auth/team/", getData);

  const [searchValue, setSearchValue] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [sortingField, setSortingField] = useState<SortingField>("NAME");
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>("DESC");

  useEffect(() => {
    revalidateTeam();
  }, [user?.activeTeam?.id, revalidateTeam]);

  const sortingMap = {
    "NAME": sortByName,
    "ROLE": sortByRole,
    "USAGE": sortByUsage,
    "LAST_LOGIN": sortByLastLogin,
  }

  function toggleSortingField(newField: SortingField) {
    setSortingField(newField as any);
    setSortingOrder(prev => (newField === sortingField && prev === "DESC") ? "ASC" : "DESC");
  }

  function sortByName(a: TeamUser, b: TeamUser) {
    if (a.teamRole === "OWNER") return -1;
    if (b.teamRole === "OWNER") return 1;

    const aStr = a.fullName.toLowerCase() + a.email.toLowerCase();
    const bStr = b.fullName.toLowerCase() + b.email.toLowerCase();

    if (aStr < bStr) return sortingOrder === "DESC" ? -1 : 1;
    if (aStr > bStr) return sortingOrder === "DESC" ? 1 : -1;
    return 0;
  }

  function sortByRole(a: TeamUser, b: TeamUser) {
    const roleOrder = ["OWNER", "ADMIN", "EDITOR", "RUNNER", "VIEWER"];

    const aOrder = roleOrder.indexOf(a.teamRole);
    const bOrder = roleOrder.indexOf(b.teamRole);

    if (aOrder < bOrder) return sortingOrder === "DESC" ? -1 : 1;
    if (aOrder > bOrder) return sortingOrder === "DESC" ? 1 : -1;

    return sortByName(a, b);
  }

  function sortByUsage(a: TeamUser, b: TeamUser) {
    // NOTE: Swap natural order so most usage is first
    if (a.usage < b.usage) return sortingOrder === "ASC" ? -1 : 1;
    if (a.usage > b.usage) return sortingOrder === "ASC" ? 1 : -1;

    return sortByName(a, b);
  }

  function sortByLastLogin(a: TeamUser, b: TeamUser) {
    const aDate = moment(a.dateLastLogin);
    const bDate = moment(b.dateLastLogin);

    const aTimestamp = aDate.isValid() ? aDate.unix() : 0;
    const bTimestamp = bDate.isValid() ? bDate.unix() : 0;

    // NOTE: Swap natural order so latest is first
    if (aTimestamp < bTimestamp) return sortingOrder === "ASC" ? -1 : 1;
    if (aTimestamp > bTimestamp) return sortingOrder === "ASC" ? 1 : -1;

    return sortByName(a, b);
  }

  if (!user?.subscription?.featureTeams)
    return <div className="flex flex-col items-center justify-center flex-1">
      <UserGroupIcon className="w-6 h-6 text-gray-500" />
      <h4 className="text-lg font-gilroy font-bold">Invite Your Colleagues</h4>
      <p className="text-base">Step into a world of seamless teamwork by upgrading to our Team Plan</p>
      <button className="btn btn-blue mt-8 py-3 w-60" onClick={() => openModal(<PayWallModalTeams />)}>Upgrade</button>
    </div>

  if (user && !user.activeTeam)
    return <SettingsTeamCreate />;

  if (!team || !team.members)
    return null;

  const sortedUsers = team.members
    .filter((user: TeamUser) => !showSearchBar || !searchValue || (user.fullName + user.email).toLowerCase().includes(searchValue.toLocaleLowerCase()))
    .sort((a: TeamUser, b: TeamUser) => sortingMap[sortingField](a, b));

  const isOwnerOfStripeConnectedAccount = (team.stripeConnectedAccount && team.stripeConnectedAccount.user.id === user.id);

  return (
    <div className="flex flex-col p-8 w-full">

      <div className="flex items-end">
        <div className="flex flex-col">
          {hasPermission(team, "teams.admin") &&
            <button className="flex items-baseline gap-1 group cursor-pointer" onClick={() => openModal(<SettingsTeamModalEdit />)}>
              <div className="font-gilroy font-semibold text-lg group-hover:underline">
                {team.name}
              </div>
              <PencilIcon className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
              <div className="hidden group-hover:block text-xs text-gray-600 font-semibold">Edit</div>
            </button>
          }
          {!hasPermission(team, "teams.admin") &&
            <div className="font-gilroy font-semibold text-lg">
              {team.name}
            </div>
          }
          <div className="text-gray-400 text-sm font-semibold">
            Create a team with up to {team.seats} members to share workflows with
          </div>
        </div>

        <div className="flex items-center ml-auto gap-4">
          <div className='flex items-center relative'>
            <SmallTextInputField tabIndex={!showSearchBar ? -1 : 0} className={`transition-all ${showSearchBar ? "w-60 opacity-100" : "w-10 opacity-0 pointer-events-none"}`} value={searchValue} onChange={setSearchValue} placeholder="Search" autoFocus />
            <button onClick={() => setShowSearchBar(f => !f)} tabIndex={showSearchBar ? -1 : 0}>
              <SearchIcon className={`w-6 h-6 cursor-pointer -ml-8 text-gray-400 hover:text-gray-500 ${showSearchBar ? "z-10" : "z-0"}`} />
            </button>
          </div>
          <button className='btn btn-blue text-sm py-1.5 px-8' onClick={() => openModal(<SettingsTeamModalInvite initialEmail={showSearchBar ? searchValue : undefined} />)} disabled={!hasPermission(user.activeTeam, "teams.admin")}>
            Invite
          </button>
        </div>
      </div>

      <div className="overflow-y-scroll h-full scrollbar-visible max-w-[calc(100vw-384px)]">
        <table className="table-auto w-full min-w-[700px] mt-4">
          <thead className="scrollbar-gutter">
            <tr className="text-sm font-gilroy text-gray-600 text-left border-b">
              <th className="text-left w-auto">
                <button className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("NAME")}>
                  Team Members
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "NAME" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </button>
              </th>
              <th className="text-left w-[200px]">
                <button className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("ROLE")}>
                  Role
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "ROLE" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </button>
              </th>
              <th className="text-left w-[100px]">
                <button className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("USAGE")}>
                  Usage
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "USAGE" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </button>
              </th>
              <th className="text-left w-[200px]">
                {/* NOTE: No sorting is offered on this field */}
                <div className="select-none cursor-not-allowed">
                  Linked Accounts
                </div>
              </th>
              <th className="text-left w-[200px]">
                <button className="flex items-center gap-1 cursor-pointer select-none hover:underline" onClick={() => toggleSortingField("LAST_LOGIN")}>
                  Last Login
                  <ChevronDownIcon className={`w-4 h-4 ${sortingField === "LAST_LOGIN" ? "visible" : "invisible"} ${sortingOrder === "DESC" ? "rotate-0" : "rotate-180"}`} />
                </button>
              </th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            {sortedUsers.map(user =>
              <SettingsTeamUser key={user.id} user={user} team={team} onRemove={revalidateTeam} />
            )}
            {sortedUsers.length === 0 &&
              <tr className="w-full">
                <td className="flex justify-center items-center py-8" colSpan={5}>
                  <div className="font-semibold text-gray-400">
                    No results
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="w-full scrollbar-gutter border-t border-b pt-1">
        <table className="table-auto w-full">
          <tbody>
            <tr className="text-sm font-semibold font-gilroy text-gray-600">
              <td className="w-auto">
                <div className="flex gap-1">
                  <p className="text-gray-400">
                    Members:
                  </p>
                  <p>
                    {team.members.length} / {team.seats}
                  </p>
                </div>
              </td>
              <td className="max-w-[300px] pr-1">
                <div className="flex justify-end gap-1">
                  <p className="text-gray-400">
                    Total Usage:
                  </p>
                  <p>
                    {team.totalUsage}
                  </p>
                </div>
              </td>
              <td className="max-w-[200px]">
                <div className="flex items-center justify-end gap-1 h-full">
                  {team.teamIntegrations.map(icon => <img key={icon} src={BLOCK_ICON_MAP[icon]} className="w-4 h-4 flex-shrink-0" alt="Linked account icon" />)}
                </div>
              </td>
              <td className="text-left max-w-[200px]">
                <button className="btn btn-gray ml-auto" onClick={() => openModal(<SettingsTeamUserModalAccessBatch />)}>
                  Check Access
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex flex-col items-start gap-2 flex-shrink-0 mt-4">
        <div className="flex items-center font-gilroy font-semibold text-gray-600">
          <img className="h-8 w-8 mr-2 rounded" src=" https://static.portant.co/stripe-logo.png" alt="Stripe" />
          <div className="flex flex-col">
            Accept Payments via Stripe
            <div className="font-semibold text-xs text-gray-400">
              Connect a Stripe account to your team to accept payments from your workflows
            </div>
          </div>
        </div>
        <div className="flex items-end gap-2">
          <button className="btn btn-blue w-40 text-xs" onClick={() => openModal(<SettingsModalStripe />)} disabled={!hasPermission(team, "teams.admin") || !isOwnerOfStripeConnectedAccount}>
            {!team.stripeConnectedAccount ? "Connect" : "Manage"}
          </button>
          {!team.stripeConnectedAccount && !hasPermission(team, "teams.admin") &&
            <div className="font-semibold text-xs text-yellow w-64">
              Please contact your team owner or admin to connect a Stripe account.
            </div>
          }
          {team.stripeConnectedAccount &&
            <div className="font-semibold text-xs text-green w-64">
              Stripe account has been connected by&nbsp;{team.stripeConnectedAccount.user.fullName.replace(" ", "\u00A0")}
            </div>
          }
        </div>
      </div>

    </div>
  );
}

export default SettingsTeam;
