import { ExternalLinkIcon } from "@heroicons/react/outline";
import { BLOCK_ICON_MAP } from "../utils";
import { DocumentLink } from "./ConversionPdfPicker";

type Props = {
  file?: DocumentLink;
}
function ConversionPdfSuccess(props: Props) {
  const { file } = props;

  if (!file) return null;

  return (
    <>
      <h2 className="font-semibold text-gray-600 text-4xl text-center">
        Document Converted ✅
      </h2>

      <div className='bg-white w-[600px] m-auto p-8 rounded flex flex-col gap-6 shadow'>
        <a className='bg-gray-100 flex justify-between items-center p-4 rounded gap-5' href={file.webViewLink} target="_blank" rel="noreferrer">
          <div className='flex gap-4 items-center truncate'>
            <img src={BLOCK_ICON_MAP["GOOGLE_DOCS"]} className="w-8 h-8" alt="Google docs icon" />
            <div className='text-sm truncate font-semibold font-gilroy text-gray-600'>{file.name}</div>
          </div>
          <button className='btn btn-blue flex gap-2'>
            Open
            <ExternalLinkIcon className="h-5 w-5 mb-1" />
          </button>
        </a>
        <div className='flex flex-col gap-1'>
          <div className='font-semibold text-blue font-gilroy'>What is Portant?</div>
          <div className='text-sm text-gray-500'>A free document automation tool for contracts, proposals, invoices and more...</div>
          <div className='text-sm text-gray-500'>✍️ Request contract signatures</div>
          <div className='text-sm text-gray-500'>📄 Bulk generate documents like invoices</div>
          <div className='text-sm text-gray-500 flex'>
            <img src={BLOCK_ICON_MAP["GOOGLE_DOCS"]} className="w-4 h-4 mr-[3px]" alt="Google docs icon" />
            Google Docs & Slides templates
          </div>
        </div>
        <a href="/" className='btn btn-white' target='_blank' onClick={() => localStorage.removeItem("hasNewUserModalShown")}>Try it now for free</a>
      </div>
    </>
  );
}

export default ConversionPdfSuccess;
