import { ForwardedRef, forwardRef, KeyboardEventHandler, PropsWithChildren, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";
import { createEditor, Descendant, Editor, Element as SlateElement, Node, Range, Text, Transforms } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { insertTag, wrapTag } from "../emailEditorHelpers";
import Element from "./elements/Element";
import Leaf from "./elements/Leaf";
import TemplatedTextEditorTagList from "./TemplatedTextEditorTagList";
import { Workflow } from "../interfaces";
import { WorkflowOnlyContext } from "../contexts";

const TAG_LIST_Y_OFFSET = 24;
const ELEMENTS_CONTAINING_TAGS = [
  "bulleted-list",
  "numbered-list",
  "list-item",
  "paragraph"
];

function withTags(editor: Editor): Editor {
  const { isInline, isVoid, normalizeNode } = editor;

  editor.isInline = element => element.type === "tag" || isInline(element);
  editor.isVoid = element => element.type === "tag" || isVoid(element);

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (SlateElement.isElement(node) && ELEMENTS_CONTAINING_TAGS.includes(node.type))
      for (const [childNode, childPath] of Node.children(editor, path))
        if (Text.isText(childNode)) {
          const containsTag = childNode.text.match(/\{\{(?<tag>.+?)\}\}/);

          if (containsTag) {
            const tag = containsTag.groups!.tag;

            const start = containsTag.index!;
            const end = start + tag.length + "{{}}".length;

            wrapTag(editor, childPath, start, end, tag, end === childNode.text.length);
          }
        }

    normalizeNode(entry);
  }

  return editor;
}

export interface TemplatedTextEditorRef {
  focus: () => void;
  getEditor: () => Editor;
  getValue: () => string;
  insertTag: (tag: string) => void;
  allowFormatting: () => boolean,
};

export interface HTMLTextEditorRef {
  focus: () => void;
  getValue: () => string;
  insertTag: (tag: string) => void;
};

type Props = {
  initialValue: string;
  placeholder?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  className: string;
  disabled?: boolean;
  onChange?: (value: Descendant[]) => void;
  onFocus?: () => void;
  onKeyDown?: KeyboardEventHandler;
  withOverrides?: (editor: Editor) => Editor;
  deserializer: (input: string) => Descendant[];
  serializer: (node: Node) => string;
  workflow?: Workflow;
} & PropsWithChildren;

function TemplatedTextEditor(props: Props, forwardedRef: ForwardedRef<TemplatedTextEditorRef>) {
  const { initialValue, placeholder, autoFocus, multiline, className, onChange, onFocus, onKeyDown, withOverrides, deserializer, serializer, children, workflow } = props;

  const tagNames = useMemo(() => workflow?.source?.sourceFields.map(field => field.fieldName) || [], [workflow?.source]);

  const [editor] = useState(() => {
    const initialEditor = withTags(withReact(createEditor()));
    return withOverrides ? withOverrides(initialEditor) : initialEditor;
  });

  const [previousValue, setPreviousValue] = useState<Descendant[]>();

  const tagListElement = useRef<HTMLDivElement>(null);

  const [tagMatch, setTagMatch] = useState<Range>();
  const [tagListQuery, setTagListQuery] = useState<string>("");
  const [possibleTags, setPossibleTags] = useState(tagNames.filter(value => value.toLowerCase().startsWith(tagListQuery.toLowerCase())));

  // Force normalisation on initial render
  const normalizedInitialValue = useMemo(() => {
    editor.children = deserializer(initialValue);
    Editor.normalize(editor, { force: true });
    return editor.children;
  }, [editor, initialValue, deserializer]);

  useLayoutEffect(() => {
    if (tagMatch && possibleTags.length > 0) {
      if (!tagListElement.current)
        return;

      const tagDOMRange = ReactEditor.toDOMRange(editor, tagMatch);
      const tagRect = tagDOMRange.getBoundingClientRect();

      tagListElement.current.style.top = (tagRect.top + window.scrollY + TAG_LIST_Y_OFFSET) + "px";
      tagListElement.current.style.left = (tagRect.left + window.scrollX) + "px";
      tagListElement.current.style.display = "block";
    } else if (tagListElement.current)
      tagListElement.current.style.display = "none";
  }, [editor, possibleTags.length, tagMatch, tagListElement]);

  useImperativeHandle(forwardedRef, () => ({
    allowFormatting: () => false,
    focus: () => ReactEditor.focus(editor),
    getEditor: () => editor,
    getValue: () => serializer(editor),
    insertTag: (tag: string) => insertTag(editor, tag)
  }));

  useEffect(() => {
    setPossibleTags(tagNames.filter(value => value.toLowerCase().startsWith(tagListQuery.toLowerCase())))
  }, [tagListQuery, tagNames]);

  function checkForPossibleTag() {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      try {
        const [start] = Range.edges(selection);
        const startOfCurrentLine = Editor.before(editor, start, { unit: "line" });
        const currentLineToCursor = startOfCurrentLine && Editor.range(editor, startOfCurrentLine, start);
        const currentLineToCursorText = currentLineToCursor && Editor.string(editor, currentLineToCursor);
        const possibleTagMatch = currentLineToCursorText && currentLineToCursorText.match(/^.*\{\{(?<tag>.*?)$/);

        const nextPointToCursor = Editor.after(editor, start);
        const rangeFromCursorToNextPoint = Editor.range(editor, start, nextPointToCursor);
        const textFromCursorToNextPoint = Editor.string(editor, rangeFromCursorToNextPoint);
        const textFromCurorIsBlank = textFromCursorToNextPoint.match(/^(\s|$)/);

        if (possibleTagMatch && textFromCurorIsBlank) {
          const tagMatch = possibleTagMatch.groups!.tag;
          setTagMatch(Editor.range(editor, Editor.before(editor, start, { distance: tagMatch.length + "{{".length })!, start));
          setTagListQuery(tagMatch)
          return;
        }
      } catch (e) {
        // An error occurs when the editor is focused - the selection is out of range by 1 so just ignore the error since it
        // doesn't effect any functionality on our end.
      }
    }

    setTagMatch(undefined);
  }

  function handleOnFocus() {
    if (onFocus)
      onFocus();

    checkForPossibleTag();
  }

  function handleOnChange(value: Descendant[]) {
    if (previousValue && onChange && JSON.stringify(previousValue) !== JSON.stringify(value))
      onChange(value);

    setPreviousValue(value);

    checkForPossibleTag();
  }

  function handleSelectTag(tag: string) {
    if (!tagMatch)
      return;

    Transforms.select(editor, tagMatch!);
    insertTag(editor, tag);
    setTagMatch(undefined);
  }

  return (
    <WorkflowOnlyContext.Provider value={{ workflow }}>
      <Slate
        editor={editor}
        value={normalizedInitialValue}
        onChange={handleOnChange}
      >
        <Editable
          className={className}
          autoFocus={autoFocus}
          autoComplete="off"
          placeholder={placeholder}
          renderElement={props => <Element {...props} />}
          renderLeaf={props => <Leaf {...props} />}
          readOnly={props.disabled}
          onBlur={() => setTagMatch(undefined)}
          onFocus={handleOnFocus}
          onKeyDown={(event) => {
            if (!multiline && event.key === "Enter")
              event.preventDefault();

            if (onKeyDown)
              onKeyDown(event);
          }}
        />
        {tagMatch && possibleTags.length > 0 &&
          <TemplatedTextEditorTagList tags={possibleTags} onSelect={handleSelectTag} onCancel={() => setTagMatch(undefined)} ref={tagListElement} />
        }
        {children}
      </Slate>
    </WorkflowOnlyContext.Provider>
  )
}

const _TemplatedTextEditor = forwardRef<TemplatedTextEditorRef, Props>(TemplatedTextEditor);
export default _TemplatedTextEditor;
