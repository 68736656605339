import { ChevronDownIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useState } from "react";

interface Props {
  response: any;
  responseNumber: number;
  questions?: Record<string, string>;
}

function WorkflowFormResponse(props: Props) {
  const [showAnswers, setShowAnswers] = useState(false);

  const answers: Array<any> = Object.values(props.response.answers);
  const [fisrtAnswer, ...remainingAnswers] = answers;

  return (
    <div className="flex flex-col border-b border-gray-200 mx-4 px-4 py-2" onClick={() => setShowAnswers(f => !f)}>

      <div className="flex items-center gap-4">
        <div className="flex items-center cursor-pointer w-8">
          <ChevronDownIcon className={`w-6 h-6 text-gray-400 cursor-pointer transform transition-all ${showAnswers ? "rotate-0" : "-rotate-90"}`} onClick={(e) => { setShowAnswers(f => !f); e.stopPropagation(); }} />
        </div>
        <div className="flex flex-col w-20">
          <div className="font-semibold text-xs text-gray-400">
            Response No.
          </div>
          <div>
            {props.responseNumber}
          </div>
        </div>
        <div className="flex flex-col flex-shrink">
          <div className="font-semibold text-xs text-gray-400">
            {(props.questions ? props.questions[fisrtAnswer.questionId] : undefined) ?? <div className="loading-ellipse" />}
          </div>
          <div>
            {fisrtAnswer.textAnswers?.answers?.[0]?.value}
          </div>
        </div>
        <div className="ml-auto w-50">
          {moment(props.response.lastSubmittedTime).format("LT, ll")}
        </div>
      </div>

      {(showAnswers && remainingAnswers.length === 0) &&
        <div className="flex flex-col gap-2 mt-2 py-2 bg-gray-100 rounded">
          <div className="flex items-end">
            <div className="w-36" />
            <div className="flex flex-col flex-shrink">
              <div className="font-semibold text-xs text-gray-400">
                This response contains no more answers
              </div>
            </div>
          </div>
        </div>
      }

      {(showAnswers && remainingAnswers.length > 0) &&
        <div className="flex flex-col gap-2 mt-2 py-2 bg-gray-100 rounded">
          {remainingAnswers.map(answer => (
            <div key={answer.questionId} className="flex items-end">
              <div className="w-36" />
              <div className="flex flex-col flex-shrink">
                <div className="font-semibold text-xs text-gray-400">
                  {(props.questions ? props.questions[answer.questionId] : undefined) ?? <div className="loading-ellipse" />}
                </div>
                <div>
                  {answer.textAnswers?.answers?.[0]?.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );

}

export default WorkflowFormResponse;
