import { FolderOpenIcon } from "@heroicons/react/outline";
import GooglePickerButton, { Ref as GooglePickerRef } from "./GooglePickerButton";
import { useModal } from "../contexts";
import LoadingModal from "./LoadingModal";
import { OAuthError, postData } from "../backend";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import { useHistory } from "react-router-dom";
import { useRef } from "react";

function ConversionFormsPicker() {
  const { openModal, closeModal } = useModal();
  const history = useHistory();
  const googlePickerRef = useRef<GooglePickerRef | null>(null);

  function handleGooglePickerButtonOnLoad() {
    googlePickerRef?.current?.openGooglePicker()
  }

  async function handleCreateWorkflow(result: any) {
    openModal(<LoadingModal title="Creating Google Forms to PDF Workflow" subtitle="This may take a minute as we create the template document in your Google&nbsp;Drive." />);

    const workflowDetails = {
      "color": "#6e4db7", "icon": "ClipboardList", "name": "Google Forms to PDF",
      source: {
        sourceType: "GOOGLE_FORMS",
        file: result,
      }
    }

    try {
      const workflow = await postData('/workflows/create-from-source/', workflowDetails)

      history.push(`/w/${workflow.id}`);
      closeModal();
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }
  }

  return (
    <>
      <div>
        <h2 className="font-semibold text-gray-600 text-4xl text-center">
          Select a Form
        </h2>
        <p className="mt-2 text-gray-400 text-center font-semibold">
          Select the Google Form you want to transform from your Google Drive
        </p>
      </div>

      <GooglePickerButton
        className="btn btn-blue mx-auto"
        blockType="GOOGLE_FORMS"
        onPicked={handleCreateWorkflow}
        onLoad={handleGooglePickerButtonOnLoad}
        ref={googlePickerRef}
      >
        <> <FolderOpenIcon className="w-6 h-6 mr-2" /> Select Google Forms File </>
      </GooglePickerButton>

    </>
  );
}

export default ConversionFormsPicker;
