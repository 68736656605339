function AppHubspotAutomationSkeleton() {

  const completionStyles = ['w-1/12', 'w-2/12', 'w-3/12', 'w-4/12', 'w-5/12', 'w-6/12', 'w-7/12', 'w-8/12', 'w-9/12', 'w-10/12', 'w-11/12', 'w-full'];
  const blueSegmentBase = "h-2 bg-blue rounded-lg animate-pulse transition-width duration-1000";
  const graySegmentBase = "h-2 bg-gray-200 rounded-lg";

  return (
    <div className="h-full w-full py-4 px-6 overflow-scroll">
      <div className="bg-white border border-[#c7c7c7] flex flex-col py-12 px-20 gap-2">
        <div className={`w-52 self-center ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-48 self-center mt-4 ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>

        <div className={`w-36 mt-10 ${graySegmentBase}`}> <div className="w-1/2 h-2 bg-blue rounded-lg animate-pulse" /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-1/3 ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>

        <div className={`w-36 mt-10 ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-full ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
        <div className={`w-1/3 ${graySegmentBase}`}> <div className={`${blueSegmentBase} ${completionStyles[Math.floor(Math.random() * 12)]}`} /> </div>
      </div>
    </div>
  );
}

export default AppHubspotAutomationSkeleton;
