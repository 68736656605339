import { RefreshIcon } from "@heroicons/react/outline";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { postData } from "../backend";
import { WorkflowContext } from "../contexts";
import WorkflowFormResponse from "./WorkflowFormResponse";

function WorkflowFormResponseList(props: React.HTMLProps<HTMLDivElement>) {

  const { workflow } = useContext(WorkflowContext);
  const source = workflow.source;

  const [responses, setResposnes] = useState<Array<any>>();
  const [questions, setQuestions] = useState<Record<string, string>>();

  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const getResponses = useCallback((async () => {
    const formId = source?.file?.id;

    // TODO: Request drive or drive.file scope and open OAuth modal on 403
    const token = await postData("/auth/access-token/", {});

    await fetch(`https://forms.googleapis.com/v1/forms/${formId}/responses/`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(async res => {
      setIsLoading(false);

      if (!res.ok)
        return;

      const json = await res.json();
      const responses: Array<any> = json.responses ?? [];

      responses.sort((a, b) => Date.parse(b.createTime) - Date.parse(a.createTime));
      setResposnes(responses);
    });

    await fetch(`https://forms.googleapis.com/v1/forms/${formId}/`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(async res => {
      if (!res.ok)
        return;

      const json = await res.json();
      const items: Array<any> = json.items;

      const questionMap: Record<string, string> = {};

      for (const item of items) {
        const questionItem = item.questionItem;
        if (!questionItem)
          continue;

        const question = questionItem.question;
        if (!question)
          continue;

        questionMap[question.questionId] = item.title;
      }

      setQuestions(questionMap);
    });
  }), [source?.file?.id]);

  useEffect(() => {
    if (responses === undefined)
      getResponses();
  }, [responses, getResponses])


  function handleClickRefresh() {
    setIsRefreshing(true);
    getResponses()
      .finally(() => setIsRefreshing(false));
  }

  return (
    <div {...props}>
      {(responses === undefined && isLoading) &&
        <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
          <img src="https://static.portant.co/portant-loading-gray.svg" className="w-24 h-24" alt="Loading Logo" />
          <div className="flex font-gilroy font-semibold text-gray-400">
            Loading Google Form Responses...
          </div>
        </div>
      }

      {(responses === undefined && !isLoading) &&
        <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
          <div className="flex font-gilroy font-semibold text-gray-400">
            Unable to load responses
          </div>
        </div>
      }

      {responses !== undefined &&
        <div className="flex flex-col w-full h-full">
          <div className="flex items-end mx-4 px-4 pt-2 pb-1 border-b-2 border-gray-200 flex-shrink-0">
            <div className="font-gilroy font-semibold text-gray-600">
              Form Responses
            </div>
            <RefreshIcon className={`h-6 w-6 ml-auto text-gray-400 hover:text-blue cursor-pointer ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={handleClickRefresh} />
          </div>
          <div className="flex flex-col h-full overflow-y-auto">
            {responses.length === 0 &&
              <div className="flex flex-col items-center justify-center flex-shrink-0 h-full">
                <div className="flex font-gilroy text-gray-600 font-semibold">
                  No Responses
                </div>
                <div className="text-sm">
                  Once a response is submitted to your form you will see it here
                </div>
              </div>
            }
            {responses.map((response, index) => <WorkflowFormResponse key={response.responseId} responseNumber={responses.length - index} response={response} questions={questions} />)}
          </div>
        </div>
      }
    </div>
  );

}

export default WorkflowFormResponseList;
