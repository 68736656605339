import HeroIcon from './HeroIcon';

const ICON_LIST = [
  // Generic
  "DocumentText",
  "Mail",
  "Cube",
  "Clock",
  "Tag",
  "Bell",
  "Flag",
  "Exclamation",
  // Use Cases
  "ReceiptTax", // Invoices
  "AcademicCap", // School
  "Beaker",
  "BookOpen", // Libraries
  "BadgeCheck",
  "PresentationChartLine",
  "Briefcase",
  "ClipboardList",
  "Home", // Real Estate
  "Scale",
  "Truck", // Logistics
  "Film",
  "Identification", // Onboarding
  "CurrencyDollar",
  "ShieldCheck",
  "Qrcode",
  // Fun
  "EmojiHappy",
  "Heart",
  "Star",
  "Cloud",
  "LightningBolt",
  "Fire",
  "Globe",
  "Cake",
];

interface Props {
  value: string;
  onChange: (color: string) => void;
  selectColour: string;
}

function IconPickerField(props: Props) {
  return (
    <div className='grid grid-cols-8 gap-1 w-full h-full'>
      {ICON_LIST.map((icon) => {
        const isSelected = icon === props.value;
        return (
          <div key={icon} className="flex items-center justify-center w-8 h-8 rounded cursor-pointer" style={{ color: isSelected ? "white" : "black", backgroundColor: isSelected ? props.selectColour : "white" }} onClick={() => props.onChange(icon)}>
            <HeroIcon icon={icon} className="h-6 w-6" />
          </div>
        );
      })}
    </div>
  );
}

export default IconPickerField;
