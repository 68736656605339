type Props = {
  reason?: 'INCOMPLETE_WF' | 'DEFAULT' | 'UNKNOWN_ERROR';
}

function AppHubspotError({ reason = 'DEFAULT' }: Props) {
  const title = {
    INCOMPLETE_WF: "Incomplete Workflow",
    DEFAULT: "Trouble Connecting",
    UNKNOWN_ERROR: "Something Went Wrong",
  }
  const subtitle = {
    INCOMPLETE_WF: "Please complete the workflow in Portant",
    DEFAULT: "Please check your connection",
    UNKNOWN_ERROR: "An unknown error has occured. Please try again later.",
  }

  const detail = {
    INCOMPLETE_WF: <>
      The workflow you are trying to run is incomplete. Please complete the workflow in
      <a className="text-blue underline" target="_blank" rel="noreferrer" href="https://app.portant.co"> Portant </a>
      before starting the automation from HubSpot.
    </>,
    DEFAULT: <>
      Your Portant account might not be connected, or might be connected to a different Hubpost account.Please visit the
      <a className="text-blue underline" target="_blank" rel="noreferrer" href="https://app.portant.co/settings/permissions"> Permissions Settings </a>
      in Portant and ensure you are connected to the correct HubSpot account.
    </>,
    UNKNOWN_ERROR: ""
  }

  return (
    <div className="flex flex-col w-screen h-screen justify-center items-center">
      <div className="w-[600px] mx-auto">
        <h3 className="font-gilroy font-semibold text-xl text-red">{title[reason]}</h3>
        <p className="font-semibold text-gray-600 mb-8">{subtitle[reason]}</p>
        <div className="w-full mx-auto text-gray-600">
          {detail[reason]}
        </div>
      </div>
    </div>
  );
}

export default AppHubspotError;
