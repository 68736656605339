import { useModal } from '../contexts';
import Modal from './Modal';

interface Props {
  onSave: () => void;
  onDontSave: () => void;
}

function WorkflowBlockModalSave(props: Props) {
  const { closeModal } = useModal();

  function handleSave() {
    props.onSave();
    closeModal();
  }

  function handleDontSave() {
    props.onDontSave();
    closeModal();
  }

  return (
    <Modal
      title="Unsaved Changes"
      subtitle="Would you like to save the changes you've made before exiting?"
      secondaryButtonProps={{ onClick: handleDontSave, text: "Don't Save" }}
      primaryButtonProps={{ onClick: handleSave, text: "Save" }}
      size='sm'
    />
  );
}

export default WorkflowBlockModalSave;
