import { Transition } from "@headlessui/react";
import { DocumentTextIcon, FilterIcon, XIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowSelectModal from "./WorkflowSelectModal";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import { OAuthError, postData } from "../backend";
import OAuthModalCopper from "./OAuthModalCopper";
import LoadingModal from "./LoadingModal";


function WorkflowSourceCopper() {
  const { openModal, closeModal } = useModal();

  const { hasPermission } = useUser();

  const { user, refreshUser } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;

  const history = useHistory();
  const { url } = useRouteMatch();

  const source = workflow.source;

  async function openCopperModal() {
    openModal(<LoadingModal />)

    postData("/copper/access-token/")
      .then(() => { refreshUser(); closeModal(); })
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModalCopper oauthUrl={error.oauthUrl} onClose={refreshUser} />);
        else
          console.error(error)
      });
  }

  if (!source)
    return null;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {/* TODO: Check and enforce copper oauth be provided */}

          {showSourceOptions && <>
            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} disableCopy />

            <div className="flex flex-col">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <FilterIcon className="w-4 h-4 mr-2" /> Source Filter Conditions
              </div>
              <div className="flex items-center font-gilroy font-semibold text-sm text-gray-400">
                Only process data that meets certain conditions
              </div>
              {(source!.sourceConditions.length > 0) &&
                <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600">
                  <div>Filtering by <span className="font-semibold">{source!.sourceConditions.length}</span> condition{source!.sourceConditions.length === 1 ? "" : "s"}</div>
                </div>
              }
              <button className="btn btn-white text-sm w-52 mt-2" onClick={() => openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />)}>
                {source!.sourceConditions.length === 0 ? "Add Conditions" : "Edit Conditions"}
              </button>
            </div>

          </>}

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Temaplate" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP["COPPER"]} alt="Copper Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy">
              <div className="truncate max-w-[400px]">
                Copper
              </div>
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>

        <div className="flex flex-col gap-2 items-center justify-center h-full">

          {!user?.copperCredentials?.copperAccessToken &&
            <>
              <div className="font-semibold text-yellow">Please allow Portant to access your data in Copper</div>
              <button className="btn btn-blue" onClick={openCopperModal}>
                Connect Copper
              </button>
            </>
          }
          {user?.copperCredentials?.copperAccessToken &&
            <>
              <div className="font-semibold text-green">Copper is connected!</div>
              <div className="font-semibold text-gray-600">Create documents directly from Copper</div>
            </>
          }
        </div>

      </Transition.Child>
    </>
  );

}

export default WorkflowSourceCopper;
